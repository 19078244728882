import clsx from 'clsx';
import PulseSelectOption, { PulseSelectOptionProps } from 'components/pulse-select/base/components/pulse-select-option';
import React, { ReactElement, Ref, useMemo } from 'react';
import styles from './pulse-tasks-select-option.module.scss';

type PulseTimesheetActivityOptionProps = PulseSelectOptionProps;

export const PulseTimesheetActivityOption = (
  props: PulseTimesheetActivityOptionProps,
  ref: Ref<HTMLDivElement>,
): ReactElement => {
  const { classes, data = {}, isValue = false, PulseLabelProps, removeable } = props;

  const { classes: PulseLabelClasses, ...restPulseLabelProps } = PulseLabelProps || {};

  /**
   * We want to pass a custom PulseLabelProps
   */
  const internalPulseLabelProps: Partial<typeof PulseLabelProps> = {
    classes: {
      ...PulseLabelClasses,
      root: clsx(styles['pulse-timesheet-activity-option__pulseLabelRoot'], PulseLabelClasses?.root),
    },
    icon: false,
    isShowFull: !isValue,
    label: `[#${data?.value}] ${data?.label}`,
    ...restPulseLabelProps,
  };

  return (
    <PulseSelectOption
      {...props}
      classes={{
        ...classes,
        root: clsx([
          classes?.root,
          styles['pulse-timesheet-activity-option__root'],
          removeable && styles['pulse-timesheet-activity-option__removeable'],
        ]),
      }}
      forwardedRef={ref}
      PulseLabelProps={useMemo(() => internalPulseLabelProps, [PulseLabelProps])}
    />
  );
};

export default React.forwardRef<HTMLDivElement, PulseTimesheetActivityOptionProps>(PulseTimesheetActivityOption);
