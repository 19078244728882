import { PulseClassName } from 'pulse-commons/types';
import { TippyProps } from '@tippyjs/react';

export interface PulseStatusProgressProps {
  classes?: {
    root?: PulseClassName;
    container?: PulseClassName;
  };
  view?: 'review' | 'stage' | 'user decision';
  /**
   * This prop is used to set the background color of the deactive step in SOCD component
   * You can pass the name of color like 'white' or the hex of color '#ebebeb'
   */
  deactiveColor?: string;
  stepsData?: Array<string>;
  TippyProps?: Partial<TippyProps>;
  alignArrow?: 'icon' | 'center';
  progress: {
    notified: boolean;
    opened: boolean;
    commented: boolean;
    completed: boolean;
  };
}

export interface PulseProgressProps {
  stepName?: string;
  isActive?: boolean;
}

export const VIEW = {
  REVIEW: {
    name: 'review',
    color: '#31718E',
  },
  STAGE: {
    name: 'stage',
    color: '#57AEAD',
  },
  USER_DECISION: {
    name: 'user decision',
    color: '#6F6F70',
  },
};
