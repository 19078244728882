import React, { FC } from 'react';
import clsx from 'clsx';
import { Colors, IconSizes } from 'pulse-commons/types';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { PromoteToMasterModalProps } from './promote-to-master-modal-types';
import styles from './promote-to-master-modal.module.scss';

const PromoteToMasterModal: FC<PromoteToMasterModalProps> = props => {
  const { isOpen, timeline, currentMaster, onCancel, onConfirm } = props;

  return (
    <PulseModalBase
      variant="jqconfirm"
      open={isOpen}
      headerIconClasses="fal fa-exclamation-circle"
      PulseModalHeaderProps={{
        onCloseClick: onCancel,
        primaryText: 'SET AS MASTER',
        classes: {
          root: 'header-root',
          closeBtn: 'header-root__close-btn',
        },
      }}
      footer={false}
      onModalClose={onCancel}
    >
      <div className={styles['promote-to-master-modal']}>
        <div className={styles['promote-to-master-modal__icon']}>
          <i className="fal fa-exclamation-circle"></i>
        </div>
        <div className={styles['promote-to-master-modal__heading']}>SET AS MASTER</div>

        <div className={styles['promote-to-master-modal__title']}>
          <div>
            Are you sure you want to mark <strong>{timeline.timelineName}</strong> as master?
          </div>
          {!!currentMaster?.isMaster && (
            <div className={styles['promote-to-master-modal__title--warning']}>
              This will remove {currentMaster.timelineName} as the master.
            </div>
          )}
        </div>
        <div className={styles['promote-to-master-modal__actions']}>
          <PulseButtonBase classes={[styles['promote-to-master-modal__button']]} label={'Cancel'} onClick={onCancel} />
          <PulseButtonBase
            classes={[
              clsx(styles['promote-to-master-modal__button'], styles['promote-to-master-modal__button--confirm']),
            ]}
            iconSize={IconSizes.sm}
            color={Colors.success}
            onClick={onConfirm}
            label="Confirm"
          />
        </div>
      </div>
    </PulseModalBase>
  );
};

export default PromoteToMasterModal;
