import { METHODS, PulseRequestObject, PulseResourceObject, PulseResponseObject } from 'pulse-api/base';

export enum DELETE_BOOKING_RESPONSES {
  SUCCESS = 'Deleted booking successfully!',
}

export type BookingRequest<METHOD extends Exclude<METHODS, 'GET'>> = PulseRequestObject<
  BookingModel,
  'bookings',
  METHOD
>;

export type BookingResponse = PulseResponseObject<BookingResourceObject>;

export type BookingResourceObject<METHOD = METHODS.GET> = PulseResourceObject<BookingModel, 'bookings', METHOD>;

export enum BookingEventColor {
  red = 'red',
  pink = 'pink',
  purple = 'purple',
  violet = 'violet',
  indigo = 'indigo',
  blue = 'blue',
  cyan = 'cyan',
  teal = 'teal',
  green = 'green',
  lime = 'lime',
  yellow = 'yellow',
  orange = 'orange',
  'deep-orange' = 'deep-orange',
  gray = 'gray',
}

export enum BookingPriority {
  standard = 'standard',
  escalated = 'escalated',
}

export enum BookingProbability {
  tentative = 'tentative',
  likely = 'likely',
  confirmed = 'confirmed',
  rejected = 'rejected',
  cancelled = 'cancelled',
}

type BookingModel = {
  locked: 'n' | 'y';
  createdAt: string;
  duration: number;
  priority: BookingPriority;
  probability: BookingProbability;
  assignedId: number;
  ptoRequestId: number | null;
  updatedAt: string;
  singleDay: boolean;
  showDescription: boolean;
  updatedBy: number | null;
  bookingId: number;
  activity: string | number;
  bookedUser: string | number;
  color: BookingEventColor;
  description: string | null;
  escalate: string;
  hoursPerDay: string;
  linkBooking: Array<string | number | null>;
  project: string | number;
  requestedBy: Array<string | number | null>;
  status: string;
  startDate: string;
  endDate: string;
  totalHours: string;
  editable: boolean | undefined;
  draggable: boolean | undefined;
};

// export type BookingPatchData = { data: BookingResourceObject<METHODS.PATCH> };
