import { TaskModel } from '@bryntum/gantt-thin';

export interface PulseTimelineModel {
  id: number;
  timelineName: string;
  isMaster: boolean;
  author: string;
  endDate: string | Date;
  startDate: string | Date;
  itemsCount: number;
  lastModifiedDate: string | Date;
}

export class PulseTimelineTaskModel extends TaskModel {
  static get fields() {
    return [
      { name: 'color' },
      { name: 'status' },
      { name: 'percentDone', dataSource: 'progress' },
      { name: 'orderIndex' },
      { name: 'taskId' },
    ];
  }
}
