import React from 'react';
import PCSSpinner, { PCSSpinnerVariants } from '../../../spinner/pcs-spinner';
import { PulseXhrRequestStatusProps, PulseXhrRequestDefaultMessages } from './pulse-xhr-request-status-types';
import { RequestStatus, Sizes } from 'pulse-commons/types';
import styles from './pulse-xhr-request-status.module.scss';
import { composeClassNameFromArray } from 'pulse-commons/helpers';

const PulseAnimatedIconCheck: React.FunctionComponent<Record<string, unknown>> = () => {
  return (
    <div className={composeClassNameFromArray([styles['f-modal-icon'], styles['f-modal-success'], styles['animate']])}>
      <span
        className={composeClassNameFromArray([
          styles['f-modal-line'],
          styles['f-modal-tip'],
          styles['animateSuccessTip'],
        ])}
      ></span>
      <span
        className={composeClassNameFromArray([
          styles['f-modal-line'],
          styles['f-modal-long'],
          styles['animateSuccessLong'],
        ])}
      ></span>
      <div className={styles['f-modal-placeholder']}></div>
      <div className={styles['f-modal-fix']}></div>
    </div>
  );
};

const PulseAnimatedIconError: React.FunctionComponent<Record<string, unknown>> = () => {
  return (
    <div className={composeClassNameFromArray([styles['f-modal-icon'], styles['f-modal-error'], styles['animate']])}>
      <span className={styles['f-modal-x-mark']}>
        <span
          className={composeClassNameFromArray([
            styles['f-modal-line'],
            styles['f-modal-left'],
            styles['animateXLeft'],
          ])}
        ></span>
        <span
          className={composeClassNameFromArray([
            styles['f-modal-line'],
            styles['f-modal-right'],
            styles['animateXRight'],
          ])}
        ></span>
      </span>
      <div className={styles['f-modal-placeholder']}></div>
      <div className={styles['f-modal-fix']}></div>
    </div>
  );
};

const PulseXhrRequestStatus: React.FunctionComponent<PulseXhrRequestStatusProps> = (
  props: PulseXhrRequestStatusProps,
) => {
  const {
    description,
    errorIcon = <PulseAnimatedIconError />,
    errorMessage = PulseXhrRequestDefaultMessages.error,
    pendingIcon = (
      <PCSSpinner
        variant={PCSSpinnerVariants.circularSpinner}
        size={Sizes.xl}
        classes={{ root: [styles['pulse-xhr-request-status__pending']] }}
      />
    ),
    pendingMessage = PulseXhrRequestDefaultMessages.pending,
    status = RequestStatus.pending,
    successIcon = <PulseAnimatedIconCheck />,
    successMessage = PulseXhrRequestDefaultMessages.success,
  } = props;

  switch (status) {
    case RequestStatus.error:
      return (
        <div className={styles['pulse-xhr-request-status__ctn']}>
          {errorIcon}
          <h2>{errorMessage}</h2>
          {description}
        </div>
      );
    case RequestStatus.pending:
      return (
        <div className={styles['pulse-xhr-request-status__ctn']}>
          {pendingIcon}
          <h2>{pendingMessage}</h2>
          {description}
        </div>
      );
    case RequestStatus.success:
      return (
        <div className={styles['pulse-xhr-request-status__ctn']}>
          {successIcon}
          <h2>{successMessage}</h2>
          {description}
        </div>
      );
    default:
      return null;
  }
};

export default PulseXhrRequestStatus;
