import React, { ReactElement, forwardRef, Ref } from 'react';
import { PulseButtonBaseProps } from './pulse-button-base-types';
import PulseIcon from '../../pulse-icons/pulse-icons';
import styles from './pulse-button-base.module.scss';
import { Colors, ButtonVariants, ButtonSizes } from 'pulse-commons/types';
import PCSSpinner, { PCSSpinnerVariants } from '../../spinner/pcs-spinner';
import { PulseIconProps } from '../../../types/icon-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

/**
 * Use `PulseButtonBase` if you want to a display a button
 * in the UI
 *
 * Switch to Canvas tab and play around with the knobs until you are
 * happy with how the button look.
 *
 * You can then copy the *Story Source* and paste it in your
 * script file in PulseRepo
 */

export const PulseButtonBase = forwardRef<HTMLButtonElement, PulseButtonBaseProps>(
  (props: PulseButtonBaseProps, ref: Ref<HTMLButtonElement>): ReactElement => {
    const { t } = useTranslation();
    const {
      children,
      classes = [],
      color = Colors.default,
      disabled = false,
      icon = false,
      iconClasses = {},
      iconPosition = 'left',
      iconName = '',
      iconSize,
      label = 'My Button',
      onClick,
      showLoadMoreSpinner = false,
      sizes = ButtonSizes.md,
      variant = ButtonVariants.contained,
      type = 'button',
      ...rest
    } = props;
    const buttonClassNameArray = [
      styles['pulse-button'],
      styles['pulse-button--base'],
      styles[color],
      styles[sizes],
      styles[variant],
      iconPosition === 'right' && styles['pulse-button__iconRight'],
      ...classes,
    ];
    const buttonClassName = clsx(buttonClassNameArray);

    const pulseButtonIconClasses: PulseIconProps['classes'] = {
      ...iconClasses,
      icon: clsx(styles['pulse-button__icon'], iconClasses?.icon),
    };

    return !children ? (
      <button
        data-testid="pulse-button"
        className={buttonClassName}
        onClick={onClick}
        disabled={disabled}
        type={type}
        ref={ref}
        {...rest}
      >
        {icon && <PulseIcon classes={pulseButtonIconClasses} iconName={iconName} size={iconSize} />}
        {Boolean(label) && t(label as string)}
        {!disabled && showLoadMoreSpinner && (
          <PCSSpinner
            classes={{ root: [styles['pulse-button__spinner']] }}
            variant={PCSSpinnerVariants.circularSpinner}
          />
        )}
      </button>
    ) : (
      <button
        data-testid="pulse-button-with-children"
        className={buttonClassName}
        onClick={onClick}
        disabled={disabled}
        type={type}
        ref={ref}
      >
        {children}
        {!disabled && showLoadMoreSpinner && (
          <PCSSpinner
            classes={{ root: [styles['pulse-button__spinner']] }}
            variant={PCSSpinnerVariants.circularSpinner}
          />
        )}
      </button>
    );
  },
);

PulseButtonBase.displayName = 'PulseButtonBase';

export default PulseButtonBase;
