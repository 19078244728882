import React, { ReactElement } from 'react';
import { PulseRadioGroupProps, RadioIconProps } from './pulse-radio-group-types';
import clsx from 'clsx';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styles from './pulse-radio-group.module.scss';

const DefaultRadioIcon = (props: RadioIconProps): ReactElement => {
  const { classes } = props;
  return <span className={clsx(styles['pulse-radio-group__icon'], classes?.root)} />;
};

const DefaultRadioCheckedIcon = (props: RadioIconProps): ReactElement => {
  const { classes } = props;
  return (
    <span
      className={clsx(
        styles['pulse-radio-group__icon'],
        styles['pulse-radio-group__icon--checked'],
        classes?.root,
        classes?.checked,
      )}
    />
  );
};

export const PulseRadioGroup = (props: PulseRadioGroupProps): ReactElement => {
  /** Destructure your props here */
  const {
    classes,
    icon,
    checkedIcon,
    data,
    RadioGroupProps,
    FormControlLabelProps,
    RadioProps,
    RadioIconProps,
  } = props;
  return (
    <RadioGroup {...RadioGroupProps} classes={{ root: clsx(styles['pulse-radio-group__root'], classes?.root) }}>
      {data.map(item => (
        <FormControlLabel
          {...FormControlLabelProps}
          classes={{
            root: clsx(styles['pulse-radio-group__formControlRoot'], FormControlLabelProps?.classes?.root),
            label: clsx(styles['pulse-radio-group__formControlLabel'], FormControlLabelProps?.classes?.label),
          }}
          key={item.value}
          value={item.value}
          label={item.label}
          control={
            <Radio
              {...RadioProps}
              classes={{ root: clsx(styles['pulse-radio-group__buttonRoot'], RadioProps?.classes?.root) }}
              disableRipple
              icon={icon || <DefaultRadioIcon {...RadioIconProps} />}
              checkedIcon={checkedIcon || <DefaultRadioCheckedIcon {...RadioIconProps} />}
              disabled={item?.disabled}
            />
          }
        />
      ))}
    </RadioGroup>
  );
};

export default PulseRadioGroup;
