import React, { ReactElement } from 'react';
import { PulseNavigationCardProps } from './pulse-navigation-card-types';
import styles from './pulse-navigation-card.module.scss';
import PulseIcon from '../pulse-icons/pulse-icons';
import get from 'lodash/get';
import clsx from 'clsx';
import isFunction from 'lodash/isFunction';

const PulseNavigationCard = (props: PulseNavigationCardProps): ReactElement => {
  const { title, iconProps, description, id, destination, onClick, classes, iconComponent, ...rest } = props;

  const navigationCardClasses: string = clsx([
    styles['pulse-navigation-card__ctn'],
    get(classes, 'pulseNavigationCardCtn'),
  ]);

  const cardTitleCLasses: string = clsx([
    styles['pulse-navigation-card__title'],
    get(classes, 'pulseNavigationCardTitle'),
  ]);

  const cardDescription: string = clsx([
    styles['pulse-navigation-card__description'],
    get(classes, 'pulseNavigationCardDescription'),
  ]);

  const handleClick = () => {
    isFunction(onClick) && id && destination && onClick(id, destination);
  };
  return (
    <div className={navigationCardClasses} data-testid="pulse-navigation-card__ctn" {...rest}>
      <div onClick={handleClick} className={styles['pulse-navigation-card__content']}>
        {iconComponent}
        {!iconComponent && iconProps && <PulseIcon size={iconProps.size} classes={iconProps.classes} iconName={''} />}
        <h2 className={cardTitleCLasses}>{title}</h2>
        <p className={cardDescription}>{description}</p>
      </div>
    </div>
  );
};

export default PulseNavigationCard;
