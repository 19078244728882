import React, { ReactElement } from 'react';
import { PulseSwitchProps } from './pulse-switch-types';
import Switch from '@mui/material/Switch';
import { StylesProvider } from '@mui/styles';
import styles from './pulse-switch.module.scss';
import { Colors } from 'pulse-commons/types';
import clsx from 'clsx';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';

const PulseSwitch = (props: PulseSwitchProps): ReactElement => {
  const {
    color = Colors.default,
    label,
    labelLeft,
    labelRight,
    onLabelClick,
    classes,
    SwitchProps,
    checked = false,
    disabled = false,
    TippyProps,
    onChange,
  } = props;

  /** Set the parent state for the checkbox to false */
  const handleLeftLabelClick = (): void => {
    onLabelClick && onLabelClick(false);
  };

  /** Set the parent state for the checkbox to true */
  const handleRightLabelClick = (): void => {
    onLabelClick && onLabelClick(true);
  };

  return (
    <StylesProvider injectFirst>
      {label && (
        <div className={clsx(styles['pulse-switch__label'], classes?.labelRoot)} data-testid="switch-label">
          <span className={clsx(styles['pulse-switch__label-content'], classes?.label)}>{label.labelContent}</span>
          {label.labelIconContent && (
            <PulseTooltip
              classes={{ tooltipRoot: styles['pulse-switch__label-tooltip-root'] }}
              TippyProps={{
                content: label.labelIconContent,
                popperOptions: {
                  strategy: 'fixed',
                },
                placement: 'top',
                ...TippyProps,
              }}
            >
              <i
                className={clsx(
                  styles['pulse-switch__label-tooltip-icon'],
                  classes?.icon || 'fal fa-info-circle',
                  classes?.labelIcon,
                )}
              />
            </PulseTooltip>
          )}
        </div>
      )}
      <div data-testid="pulse-switch__ctn" className={clsx(styles['pulse-switch__ctn'], classes?.container)}>
        {labelLeft && (
          <label
            className={clsx(
              styles['pulse-switch__label--left'],
              classes?.labelLeft,
              !checked && styles['pulse-switch__label--enabled'],
              !checked && classes?.labelEnabled,
            )}
            onClick={handleLeftLabelClick}
          >
            {labelLeft}
          </label>
        )}
        <Switch
          disableFocusRipple={true}
          disableRipple={true}
          classes={{
            root: clsx(styles['pulse-switch__root'], SwitchProps?.classes?.root),
            switchBase: clsx(styles['pulse-switch__switchBase'], styles[color]),
            thumb: clsx(styles['pulse-switch__thumb'], SwitchProps?.classes?.thumb),
            track: clsx(styles['pulse-switch__track'], SwitchProps?.classes?.track),
            checked: clsx(styles['pulse-switch__checked'], SwitchProps?.classes?.checked),
            input: clsx(styles['pulse-switch__input'], SwitchProps?.classes?.input),
          }}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        {labelRight && (
          <label
            className={clsx(
              styles['pulse-switch__label--right'],
              classes?.labelRight,
              checked && styles['pulse-switch__label--enabled'],
              checked && classes?.labelEnabled,
            )}
            onClick={handleRightLabelClick}
          >
            {labelRight}
          </label>
        )}
      </div>
    </StylesProvider>
  );
};

export default PulseSwitch;
