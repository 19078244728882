import React, { ReactElement, Ref, useEffect, useRef, useState } from 'react';
import { PulseLabelProps } from './pulse-label-types';
import PulseIcon from '../pulse-icons/pulse-icons';
import styles from './pulse-label.module.scss';
import { Colors, IconSizes, LabelSizes, LabelVariants, ActionButtonPlacement } from 'pulse-commons/types';
import PulseIconButton from '../pulse-icon-button/pulse-icon-button';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import clsx from 'clsx';
import mergeRefs from 'react-merge-refs';

export const PulseLabel = React.forwardRef<HTMLSpanElement, PulseLabelProps>(
  (props, ref): ReactElement => {
    const {
      actionButton = false,
      actionButtonPlacement = ActionButtonPlacement.RIGHT,
      actionButtonProps,
      actionComponent,
      classes,
      color = Colors.default,
      size = LabelSizes.md,
      icon = false,
      iconComponent,
      innerRef,
      label,
      maxWidth = 120,
      onClick,
      PulseIconProps,
      TippyProps = {
        placement: 'top',
      },
      variant = LabelVariants.contained,
      isShowFull = false,
      style = {},
      wrapText = false,
    } = props;

    const [hideTooltip, setHideTooltip] = useState(true);
    const truncatedEl: Ref<HTMLSpanElement> = useRef(null);

    useEffect(() => {
      if (typeof label !== 'string') return;

      /**
       * We create a canvas and render the text
       * for the label to know if it should truncate
       * This fixes issues where non-rendered label
       * will return isTruncated false as isTruncated
       * method relies on scrollWidth and clientWidth
       * which requires the label to be rendered.
       *
       */
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const defaultFontSize = parseInt(window.getComputedStyle(document.body).getPropertyValue('font-size'));
      let fontSize = defaultFontSize;
      switch (size) {
        case LabelSizes.sm:
          fontSize = parseFloat(styles.pulseLabelSizeMultiplierSM) * defaultFontSize;
          break;
        case LabelSizes.lg:
          fontSize = parseFloat(styles.pulseLabelSizeMultiplierLG) * defaultFontSize;
          break;
        default:
          break;
      }
      if (context) {
        context.font = `${fontSize}px Lato`;
        const textWidth = Math.floor(context.measureText(label as string).width);
        /** The horizontal padding on the pulse-label__text */

        if (truncatedEl.current) {
          textWidth + 2 * parseInt(styles.pulseLabelTextPadding) >
          (truncatedEl.current as HTMLSpanElement).getBoundingClientRect().width
            ? setHideTooltip(false)
            : setHideTooltip(true);
        } else {
          setHideTooltip(true);
        }
      }
    }, [props]);

    let processedClassName = clsx(
      styles['pulse-label__root'],
      isShowFull && wrapText ? styles['pulse-label__root--full'] : '',
      styles[color],
      styles[size],
      styles[variant],
      classes?.root,
    );

    /**
     * We want to assign default classes to PulseIcon
     */
    let pulseIconInstance: ReactElement | undefined = undefined;
    if (icon && PulseIconProps && !iconComponent) {
      const { classes: pulseIconPropsClasses, ...restPulseIconProps } = PulseIconProps;
      const pulseIconClasses = {
        ...pulseIconPropsClasses,
        icon: clsx(styles['pulse-label__icon'], pulseIconPropsClasses?.icon),
      };
      processedClassName = clsx(processedClassName, styles['pulse-label__root--icon']);
      pulseIconInstance = <PulseIcon classes={pulseIconClasses} iconName="" {...restPulseIconProps} />;
    } else {
      pulseIconInstance = iconComponent;
    }

    let actionButtonInstance: ReactElement | undefined = undefined;
    if (actionButton && actionButtonProps && !actionComponent) {
      const {
        classes: actionButtonPropsClasses,
        size: actionIconSize,
        ...restPulseIconButtonProps
      } = actionButtonProps;

      const actionButtonClasses = {
        ...actionButtonPropsClasses,
        root: clsx(
          styles['pulse-label__action-button'],
          styles[color],
          actionButtonPropsClasses?.root,
          actionButtonPlacement === ActionButtonPlacement.LEFT && styles['pulse-label__action-button--left'],
        ),
      };

      processedClassName = clsx(processedClassName, styles['pulse-label__root--action']);

      /** The following ensures that the right size is passed to the action button icon */
      let processedActionIconSize;
      if (!actionIconSize) {
        switch (size) {
          case LabelSizes.sm:
            processedActionIconSize = IconSizes.sm;
            break;
          case LabelSizes.lg:
            processedActionIconSize = IconSizes.lg;
            break;
          default:
            processedActionIconSize = IconSizes.md;
        }
      } else {
        processedActionIconSize = actionIconSize;
      }

      actionButtonInstance = (
        <PulseIconButton
          classes={actionButtonClasses}
          iconName=""
          size={processedActionIconSize}
          {...restPulseIconButtonProps}
        />
      );
    } else if (actionComponent) {
      actionButtonInstance = actionComponent;
    }
    return (
      <Tippy arrow content={label} disabled={hideTooltip} interactive appendTo={document.body} {...TippyProps}>
        <span
          ref={ref && innerRef ? mergeRefs([ref, innerRef]) : ref}
          data-label={label}
          className={processedClassName}
          style={style}
          onClick={onClick}
        >
          {actionButtonPlacement === ActionButtonPlacement.LEFT && actionButtonInstance}
          {pulseIconInstance}
          <span
            className={styles['pulse-label__text-ctn']}
            style={{
              maxWidth: isShowFull ? 'auto' : maxWidth,
            }}
          >
            <span ref={truncatedEl} className={clsx(styles['pulse-label__text'], classes?.labelText)}>
              {label}
            </span>
          </span>
          {actionButtonPlacement === ActionButtonPlacement.RIGHT && actionButtonInstance}
        </span>
      </Tippy>
    );
  },
);

PulseLabel.displayName = 'PulseLabel';

export default PulseLabel;
