import React, { FC } from 'react';
import clsx from 'clsx';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { Colors, IconSizes } from 'pulse-commons/types';
import { GanttChartDeleteModalProps } from 'components/pulse-timeline/pulse-timeline-types';
import styles from './pulse-timeline-gantt.module.scss';

const GanttDeleteModal: FC<GanttChartDeleteModalProps> = props => {
  const { deleteRecords, isOpen, onClose, onDeleteItems } = props;

  return (
    <PulseModalBase
      variant="jqconfirm"
      headerIconClasses="fal fa-trash-alt"
      PulseModalHeaderProps={{
        onCloseClick: onClose,
        primaryText: deleteRecords.length > 1 ? 'DELETE ITEM' : 'DELETE ITEMS',
        classes: {
          root: 'header-root',
          closeBtn: 'header-root__close-btn',
        },
      }}
      footer={false}
      open={isOpen}
      onModalClose={onClose}
    >
      <div className={styles['gantt-chart-delete-modal']}>
        {deleteRecords?.some((record: any) => record.children) ? (
          <div className={styles['gantt-chart-delete-modal__title']}>
            <div>
              {deleteRecords.length > 1
                ? 'Some of the selected items are parent items. Are you sure you want to delete the parent items?'
                : 'Are you sure you want to delete this parent item?'}{' '}
            </div>
            <div>If you proceed, the parent item and all child items will be deleted.</div>
          </div>
        ) : (
          <div className={styles['gantt-chart-delete-modal__title']}>
            {`Are you sure you want to delete these item${deleteRecords.length > 1 ? 's' : ''}?`}
          </div>
        )}
        <div className={styles['gantt-chart-delete-modal__buttonCtn']}>
          <PulseButtonBase classes={[styles['gantt-chart-delete-modal__button']]} label={'Cancel'} onClick={onClose} />
          <PulseButtonBase
            classes={[
              clsx(styles['gantt-chart-delete-modal__button'], styles['gantt-chart-delete-modal__button--delete']),
            ]}
            icon
            iconClasses={{
              icon: 'fal fa-check-circle',
            }}
            iconSize={IconSizes.sm}
            color={Colors.success}
            onClick={onDeleteItems}
            label="Confirm"
          />
        </div>
      </div>
    </PulseModalBase>
  );
};

export default GanttDeleteModal;
