import React, { FC, Ref, Suspense, useCallback, useEffect, useRef, useState, Fragment, useMemo } from 'react';
import PulseInput from 'components/pulse-input/pulse-input';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import PulseIconButton from 'components/pulse-icon-button/pulse-icon-button';
import FilterFlyout, { LAST_APPLIED_FILTERS } from './FilterFlyout/filter-flyout';
import { EventTemplate, EventRenderer } from './event-template/event-template';
import EventTooltipTemplate from './event-tooltip';
const EditForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pulse-resource-scheduler-edit-form" */
      /* webpackMode: "eager" */
      './edit-form'
    ),
);
import { ColumnStore, GridConfig } from '@bryntum/grid-thin';
import { ViewPresetConfig } from '@bryntum/scheduler-thin';
import { CalendarModel, ProjectModel, SchedulerPro } from '@bryntum/schedulerpro-thin';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react-thin';
import '@bryntum/scheduler-thin/lib/feature/ResourceTimeRanges.js';
import '@bryntum/scheduler-thin/lib/feature/EventEdit.js';
import '@bryntum/scheduler-thin/lib/feature/GroupSummary.js';
import '@bryntum/scheduler-thin/lib/column/ResourceInfoColumn.js';
import styles from '../pulse-resource-planner.module.scss';
import { useResourcePlannerDispatch, useResourcePlannerState } from '../context/resource-planner-context';
import {
  Actions,
  calculateTotalHours,
  DEFAULT_EVENT_COLOR,
  EditBookingType,
  getDefaultViewPreset,
  // getEventsWeekends,
  initializeNewBooking,
  isHoliday,
  ResourcePlannerAction,
  ResourcePlannerState,
  RP_DATE_SERVER_FORMAT,
  SCHEDULE_DEFAULT_KEY_VIEW,
  COLUMN_HIDDEN_CONFIG,
  updateDraggedBooking,
  updateEventDate,
  validBookingDate,
  DEFAULT_MAX_HOURS,
  getDefaultZoomLevel,
  formatBookingDate,
  DEFAULT_ZOOM_LEVEL,
} from '../reducers/resource-planner-reducer';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import { IconSizes } from 'pulse-commons/types';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import clsx from 'clsx';
import { BookingContextMenu } from './BookingContextMenu/booking-context-menu';
import { cancelSource, CancelToken, v2Endpoint } from 'pulse-api/base';
import { CancelTokenSource } from 'axios';
import qs from 'qs';
import parseISO from 'date-fns/parseISO';
import PulseMenu from 'components/pulse-menu/pulse-menu';
import addDays from 'date-fns/addDays';
import endOfDay from 'date-fns/endOfDay';
import isSameDay from 'date-fns/isSameDay';
import isWeekend from 'date-fns/isWeekend';
import startOfDay from 'date-fns/startOfDay';
import subMilliseconds from 'date-fns/subMilliseconds';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import format from 'date-fns/format';
import '../pulse-resource-planner-events.scss';
import PulseLoader from 'components/pulse-loader/pulse-loader';
import { presets, PRESET_ID } from '../components/presets';
import {
  GroupRenderer,
  GroupSummaryRenderer,
  ResourcePlannerProps,
} from 'components/pulse-resource-planner/pulse-resource-planner-types';
import CreateMenuButtons from './CreateBooking/create-booking';
import { UserOptionType } from './booking-types';
const DeleteBookingModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pulse-resource-scheduler-delete-modal" */
      /* webpackMode: "eager" */
      './DeleteBookingModal/delete-booking-modal'
    ),
);
const UserSettingModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pulse-resource-scheduler-user-setting-modal" */
      /* webpackMode: "eager" */
      './UserSettingModal/user-setting-modal'
    ),
);
import withDeepLink from 'HOC/withDeepLink';
import RPBookingModel from '../models/rp-booking-model';
import RPUserModel from '../models/rp-user-model';
// import data from '../data.json';
// import data from '../data-project.json';
import ResourcePlannerGroupBy, { GROUP_BY, getGroupLabel } from './group-by';
import Tippy from '@tippyjs/react';
import RPServices from 'pulse-services/resource-planner';
import { CellClickEvent } from './resource-planner-types';
import ResourcePlannerPreset, { PRESET_VIEW } from './PresetViews/index';
import PulseResourceLegend from '../components/LegendResourcePlanner/legend-resource-planner';
import { Collapse } from '@mui/material';
import GridNavigation from './grid-navigation';
import addHours from 'date-fns/addHours';
import startOfHour from 'date-fns/startOfHour';
import Jsona from 'jsona';
import {
  transformResponse,
  transformResponseBatching,
  transformersGetBookingsByProject,
} from '../transformers/resource-planner-transformers';
import addWeeks from 'date-fns/addWeeks';
import { addMinutes, roundToNearestMinutes, set, subWeeks } from 'date-fns';
import { calculateHours, recalculateEndHours } from './edit-form';
import uniqBy from 'lodash/uniqBy';
import { bookingsApi } from 'pulse-api/bookings/bookings';
import { GetConfig } from 'pulse-types/pulse-resource';

const DEFAULT_COLUMN_WIDTH = 150;
const MAX_USERS = 100;
const IS_DEV = process.env.NODE_ENV === 'development';
const PULSE_LOCAL_URL = process.env.PULSE_LOCAL_URL || '';
const LAST_GROUP_BY = 'schedulerGroupBy';
export const REMOVE_LINK_PARAMS = {
  link: {
    hidden: 1,
  },
};
export const REMOVE_PAGE_PARAMS = {
  page: {
    hidden: 1,
    per_page: 100,
  },
};

let source: CancelTokenSource;

/* eslint-disable @typescript-eslint/ban-ts-comment */
const pulseProject = new ProjectModel({
  eventModelClass: RPBookingModel,
  resourceModelClass: RPUserModel,
});
/* eslint-enable @typescript-eslint/ban-ts-comment */

const addBookingsForRange = (
  startDate: Date,
  endDate: Date,
  rpState: ResourcePlannerState,
  scheduler: SchedulerPro,
) => {
  const rpStartDate = format(startDate, RP_DATE_SERVER_FORMAT);
  const rpEndDate = format(endDate, RP_DATE_SERVER_FORMAT);
  const filter = {
    ...rpState.filters,
    'booking.startdate': rpStartDate,
    'booking.enddate': rpEndDate,
  };

  return rpState.columnsGroupBy === GROUP_BY.projects
    ? RPServices.getBookingsByProject({
        filter,
      })
        .then(async res => {
          const formatter = new Jsona();
          const userBookings = res.responses?.[0]?.body;
          transformResponseBatching(userBookings.data);
          const dataTransformers = transformersGetBookingsByProject(formatter.deserialize(userBookings));
          return dataTransformers;
        })
        .then(res => {
          scheduler.eventStore.beginBatch();
          const existingEventIds =
            scheduler.eventStore.allRecords.map(event => {
              return (event as any).data.bookingId;
            }) || [];
          const filteredExistingEvents = (res.events.rows as RPBookingModel[]).reduce<RPBookingModel[]>(
            (acc, event) => {
              if (event.bookingId && !existingEventIds.includes(event.bookingId)) {
                acc.push(event);
              }
              return acc;
            },
            [],
          );
          scheduler.eventStore.add(filteredExistingEvents);
          const existingResourceIds = scheduler.resourceStore.allRecords.map(event => event.id) || [];
          const filteredExistingResources = (res.resources.rows as RPUserModel[]).reduce<RPUserModel[]>(
            (acc, resource) => {
              if (resource.id && !existingResourceIds.includes(resource.id)) {
                acc.push(resource);
              }
              return acc;
            },
            [],
          );
          scheduler.resourceStore.add(filteredExistingResources);
          scheduler.eventStore.endBatch();
        })
    : RPServices.getBookings({
        params: {
          filter,
        },
      }).then(async (events: RPBookingModel[]) => {
        const existingIds =
          scheduler.eventStore.allRecords.map(event => {
            return (event as any).data.bookingId;
          }) || [];

        // Use to check missing resources when filter in infinite scroll
        let existingResourceIds: Array<string> = [];

        // Only get current resource data if events existed.
        if (events.length) {
          existingResourceIds = scheduler.resourceStore.allRecords.map(event => `${event.id}`) || [];
        }
        const missingResources: RPUserModel[] = [];

        const filteredEvents = events.reduce<RPBookingModel[]>((acc, event) => {
          if (event.bookingId && !existingIds.includes(event.bookingId)) {
            acc.push(event);
          }
          // Check events data for adding missing resource when infinite scroll
          if (!!event.bookedUser.raw && !existingResourceIds.includes(`${event.bookedUser.value}`)) {
            missingResources.push({
              id: event.resourceId,
              name: event.bookedUser.label,
              departments: event.bookedUser.raw.departments ?? {
                name: '',
                id: '',
              },
              defaultTimesheetActivity: event.bookedUser.raw.defaultTimesheetActivity,
              isAgresso: event.bookedUser.raw.isAgresso,
              maxHours: event.bookedUser.raw.maxHours,
              primaryClient: event.bookedUser.raw.primaryClient,
              roles: event.bookedUser.raw.roles,
              client: event?.bookedUser?.raw?.client as string,
            } as RPUserModel);
          }
          return acc;
        }, []);
        const removeDuplicateResources = missingResources.reduce((resource: any, o) => {
          if (!resource.some(obj => obj.id === o.id)) {
            resource.push(o);
          }
          return resource;
        }, []);

        scheduler.suspendRefresh();

        scheduler.eventStore.add(filteredEvents);
        if (missingResources.length) {
          await scheduler.resourceStore.add(removeDuplicateResources);
        }

        scheduler.resumeRefresh(true);

        await scheduler.project.commitAsync();
      });
};

const debounceAddBookingsForRange = debounce(
  (startDate, compareStartDate, rpState, scheduler) =>
    addBookingsForRange(startDate, compareStartDate, rpState, scheduler),
  100,
  { trailing: true },
);

/**
 * Generate Project Resource Planner for scheduler
 * It use to add to scheduler for display new project booking after booking created
 * @param bookingValues the values of booking
 */
const generateUserResource = (bookingValues: RPBookingModel): RPUserModel | null => {
  const { bookedUser, job } = bookingValues;
  if (bookedUser?.value && bookedUser?.raw) {
    const newResource = {
      id: bookingValues.bookedUser.value,
      name: bookedUser.label,
      departments: bookedUser.raw.departments,
      defaultTimesheetActivity: bookedUser.raw.defaultTimesheetActivity,
      isAgresso: bookedUser.raw.isAgresso,
      maxHours: bookedUser.raw.maxHours,
      primaryClient: bookedUser.raw.primaryClient,
      roles: bookedUser.raw.roles,
      job: job?.raw
        ? {
            type: 'jobs',
            id: job.raw.jobId,
            attributes: {
              brandId: job.raw.brandId,
              brandTitle: job.raw.brandTitle,
              editMode: job.raw.editMode,
              isAgresso: job.raw.isAgresso,
              isNC: job.raw.isNC,
              is_finance_manager: job.raw.is_finance_manager,
              jobExtension: job.raw.jobExtension,
              jobId: job.raw.jobId,
              jobTitle: job.raw.jobTitle,
              status: job.raw.status,
            },
          }
        : undefined,
    };
    return newResource as RPUserModel;
  }
  return null;
};

/**
 * Generate User Resource Planner for scheduler
 * It use to add to scheduler for display new user booking after booking created
 * @param bookingValues the values of booking
 */
const generateProjectResource = (bookingValues: RPBookingModel): RPUserModel | null => {
  const { bookedUser, job } = bookingValues;
  if (!bookedUser.raw || !job?.raw) {
    return null;
  }

  const newResource = {
    ...generateUserResource(bookingValues),
    id: !bookedUser?.value || !job?.value ? '' : `${bookedUser.value}-${job.value}`,
  };
  return newResource as RPUserModel;
};

const fetchResources = async (rpState: ResourcePlannerState, allOfficesIds: string[]) => {
  // if (IS_DEV) {
  //   return data;
  // }

  /** Cancel any previous calls */
  cancelSource(source, 'Cancelling previous resource planner batch call.');

  /** Create a new source for the following axios call */
  source = CancelToken.source();
  const queryParams = {
    filter: rpState.bookingPermissions.isSuperUser
      ? rpState.filters
      : {
          ...rpState.filters,
          'user.client.id': [...(rpState.filters?.['user.client.id'] ?? []), ...allOfficesIds],
        },
    include: rpState.include.join(','),
    ...REMOVE_LINK_PARAMS,
    ...REMOVE_PAGE_PARAMS,
  };

  // When fetching data we need to fetch data 4 weeks prior to the current start date to have bookings rendered immediate to the first scroll.
  queryParams['filter'] = {
    ...queryParams.filter,
    'booking.startdate':
      rpState?.filters &&
      rpState?.filters['booking.startdate'] &&
      format(subWeeks(new Date(rpState?.filters['booking.startdate']), 4), RP_DATE_SERVER_FORMAT),
  };

  const queryParamsString = qs.stringify(queryParams, { encode: false });
  const url = `/v2/api/users?${queryParamsString}`;

  const holidaysQueryParams = {
    filter: {
      client_id: rpState.filters?.['user.client.id'],
      start_date: rpState.filters?.['booking.startdate'],
      end_date: rpState.filters?.['booking.enddate'],
      for_current_user: rpState.loggedUserId,
    },
    include: 'holidayDates,client',
  };
  const holidaysQueryParamsString = qs.stringify(holidaysQueryParams, { encode: false });
  const holidaysUrl = `/v2/api/holidays?${holidaysQueryParamsString}`;

  return v2Endpoint
    .post(
      `${PULSE_LOCAL_URL}/v2/api/batch`,
      {
        type: 'load',
        requests: [
          {
            method: 'GET',
            url,
            requestId: 'users',
          },
          {
            method: 'GET',
            url: holidaysUrl,
            requestId: 'holidays',
          },
        ],
      },
      {
        cancelToken: source.token,
      },
    )
    .then(res => {
      const formatter = new Jsona();
      const handleResponseBatching = res.data.responses.reduce((accumulator, { requestId, body }) => {
        if (requestId === 'users') transformResponseBatching(body.data);

        const result = {
          [requestId]: formatter.deserialize(body),
        };
        return { ...accumulator, ...result };
      }, {});

      const dataTransformers = transformResponse(handleResponseBatching);
      isFunction(rpState.onLoad) && rpState.onLoad();

      return dataTransformers;
    })
    .catch(error => {
      IS_DEV && console.log(error);
      !IS_DEV && isFunction(rpState.onLoadFail) && rpState.onLoadFail();
    });
};

/**
 * Splits the compound id on the delimiter `-`
 * and returns the first value which should be a user id
 * @param compoundId The resource id when in groupedBy project
 * @returns booked user id
 */
export const splitCompoundUserJobId = (compoundId: string): string | undefined => {
  if (typeof compoundId === 'string') {
    return compoundId.split('-').shift() || '';
  }
};

/**
 * Function to generate the RPBookingModel job property
 * from the RPUserModel job property when in groupedBy Project
 * @param resourceRecord The resourceRecord for the booking
 * @returns
 */
export const setJob = (resourceRecord: RPUserModel): RPBookingModel['job'] | undefined => {
  if (resourceRecord.job) {
    const {
      job: {
        attributes: { jobId: value, jobTitle: label, jobExtension, brandId, brandTitle, isNC },
      },
    } = resourceRecord;

    return {
      label,
      value,
      jobExtension,
      brandId,
      brandTitle,
      isNC,
      raw: {
        ...resourceRecord.job.attributes,
      },
    };
  }
};

const dragCreateEnd = (
  dispatch: React.Dispatch<ResourcePlannerAction>,
  currentUser: UserOptionType,
  columnsGroupBy: GROUP_BY,
  zoomLevel: PRESET_VIEW,
) => ({ eventRecord, resourceRecord }: { source: any; eventRecord: RPBookingModel; resourceRecord: RPUserModel }) => {
  const { id, name, maxHours, calendar } = resourceRecord;

  const typedEndDate = validBookingDate(eventRecord.endDate, calendar as CalendarModel);
  const typedStartDate = validBookingDate(eventRecord.startDate, calendar as CalendarModel);

  if (!typedEndDate || !typedStartDate) {
    window?.utilities?.notification.danger('Invalid start or end date. dragCreateEnd failed.');
    return;
  }

  const newBooking = initializeNewBooking();

  newBooking.bookedUser = {
    label: name,
    value: id as string,
    raw: resourceRecord,
  };
  newBooking.hoursPerDay = `${Number(maxHours).toFixed(2)}`;

  const isSameDate = isSameDay(typedStartDate, typedEndDate);
  const unwantedHours = Number(DEFAULT_MAX_HOURS) - Number(maxHours);
  if (zoomLevel !== PRESET_VIEW.Hours) {
    newBooking.endDate = new Date(typedEndDate.setHours(17, 0, 0));
    newBooking.startDate = new Date(typedStartDate.setHours(9, 0, 0));
    if (unwantedHours > 0) {
      newBooking.endDate = addMinutes(newBooking.endDate, -60 * unwantedHours);
    }
  } else {
    newBooking.endDate = isSameDate ? typedEndDate : new Date(typedEndDate.setHours(17, 0, 0));
    newBooking.startDate = isSameDate ? typedStartDate : new Date(typedStartDate.setHours(9, 0, 0));
    if (isSameDate) {
      newBooking.hoursPerDay = calculateHours(
        roundToNearestMinutes(new Date(newBooking.startDate), { nearestTo: 15 }),
        roundToNearestMinutes(new Date(newBooking.endDate), { nearestTo: 15 }),
      );
    }
  }
  newBooking.resourceId = id;
  newBooking.totalHours = calculateTotalHours({
    startDate: startOfDay(typedStartDate),
    endDate: endOfDay(typedEndDate),
    hoursPerDay: maxHours,
  });
  newBooking.requestedBy = [currentUser];

  /**
   * If the view is in grouped by projects,
   * then the following should autofill:
   * * Booked User with the correct id
   * * Project
   * * Activity
   */

  if (columnsGroupBy === GROUP_BY.projects) {
    /** Pass the correct booked user id to the form */
    const bookedUserId = splitCompoundUserJobId(newBooking.bookedUser.value);
    bookedUserId && (newBooking.bookedUser.value = bookedUserId);

    if (!bookedUserId) {
      window.utilities?.notification?.danger('Could not open form. Correct bookedUser id not set');
      return;
    }

    if (!resourceRecord.job) {
      window.utilities?.notification?.danger('Could not open form. Job not set.');
      return;
    }

    /** Pre-fill the new booking project */
    newBooking.job = setJob(resourceRecord);
  }

  eventRecord.remove();

  dispatch({
    type: Actions.newBooking,
    payload: {
      editBooking: newBooking,
      newBookingType: EditBookingType.STANDARD,
    },
  });
};

const beforeEventEdit = (
  dispatch: React.Dispatch<ResourcePlannerAction>,
  currentUser: UserOptionType,
  rpState: ResourcePlannerState,
) => ({ eventRecord, resourceRecord }: { eventRecord: RPBookingModel; resourceRecord: RPUserModel }) => {
  if (eventRecord.locked === 'y' || !eventRecord.editable || !eventRecord.bookedUser) {
    return false;
  }

  const data = eventRecord.toJSON() as RPBookingModel;

  if (rpState.bookingPermissions.canViewBooking && !rpState.bookingPermissions.canEditBooking) {
    if (data.bookingStatus.value !== 'unapproved') {
      return false;
    }

    if (data.requestedBy[0].label !== currentUser.label && data.requestedBy[0].value !== currentUser.value) {
      return false;
    }
  }

  const endDate = eventRecord.endDate as Date;
  const startDate = eventRecord.startDate as Date;

  /** Sub 1 millisecond to the booking form data so the date range picker shows correct dates */
  data.endDate = eventRecord.bookingId ? subMilliseconds(endDate, 1) : endDate;
  data.startDate = startDate;
  data.bookedUser = {
    label: resourceRecord.name,
    value: resourceRecord.id as string,
    raw: resourceRecord,
  };
  data.requestedBy = rpState.isNew ? [currentUser] : eventRecord.requestedBy || [];

  data.bookedUser.raw = resourceRecord;

  dispatch({
    type: Actions.editBooking,
    payload: {
      editBooking: data,
      eventRecord,
      selectedUser: resourceRecord,
    },
  });
  return false;
};

const beforeTaskEdit = () => {
  return false;
};

const handleEventResize = (
  rpState: ResourcePlannerState,
  currentUser,
  scheduler: SchedulerPro,
  zoomLevel: PRESET_VIEW,
) => ({ changed, eventRecord }: { changed: boolean; eventRecord: RPBookingModel }) => {
  changed &&
    updateEventDate(eventRecord, currentUser, rpState, scheduler, zoomLevel)
      .then(() => {
        isFunction(rpState.onEventResizeSuccess) && rpState.onEventResizeSuccess();
      })
      .catch(err => {
        isFunction(rpState.onEventResizeFail) && rpState.onEventResizeFail(err);
      });
};

const resizeValidation = ({ endDate, eventRecord }: { endDate: Date; eventRecord: RPBookingModel }) => {
  const parsedNewEndDate = subMilliseconds(endDate, 1);
  const parsedEndDate = eventRecord.endDate as Date;
  const { calendar } = eventRecord.getResource() as RPUserModel;

  if (parsedNewEndDate > parsedEndDate) {
    const days = eachDayOfInterval({ start: parsedEndDate, end: parsedNewEndDate });
    // Find at least one working day in interval
    return days.some(day => !isWeekend(day) && !isHoliday(day, calendar as CalendarModel));
  }

  return true;
};

const handleLoadDateRange = (startDateNew: Date, endDateNew: Date, dispatch: React.Dispatch<ResourcePlannerAction>) => {
  if (startDateNew || endDateNew) {
    const startDate = format(startDateNew, RP_DATE_SERVER_FORMAT);
    const endDate = format(endDateNew, RP_DATE_SERVER_FORMAT);
    dispatch({
      type: Actions.setInfiniteScroll,
      payload: {
        resourcePlannerDateRange: {
          startDate,
          endDate,
        },
      },
    });
  }
};

const notificationText = `<div>
  <p>Max ${MAX_USERS} Users Loaded</p>
  <p>Use the <span style="text-decoration:underline;" id="open-filter-flyout-link">Filters</span> to Refine View</p>
</div>`;

/**
 *
 * @param isUserGrouping under user grouping, different ui are shown
 * @returns string
 */
const groupRenderer = (isUserGrouping = false) => ({ count, isFirstColumn, groupRowFor, record }: GroupRenderer) => {
  const { groupField } = record?.meta ?? GROUP_BY.nogrouping;

  const groupLabel = `${groupRowFor ? groupRowFor : `[No ${getGroupLabel(groupField)}]`} (${count})`;
  return isUserGrouping ? isFirstColumn && groupRowFor : isFirstColumn && groupLabel;
};

const debounceSearch = debounce(handleSearch => handleSearch(), 500);

const getScrollDate = (presetId: PRESET_ID): Date => {
  const currentDate = new Date();

  switch (presetId) {
    case PRESET_ID.pulseDay:
      return addDays(currentDate, 1);
    case PRESET_ID.pulseDayAndHourly:
      return addHours(currentDate, 7);
    case PRESET_ID.pulseMonthAndDate:
      return addDays(currentDate, 10);
    case PRESET_ID.pulseMonthAndQuarter:
      return addWeeks(currentDate, 3);
    default:
      return addDays(currentDate, 4);
  }
};

const ResourcePlanner: FC<ResourcePlannerProps> = (props: ResourcePlannerProps) => {
  const {
    currentUser,
    onSaveBookingFail,
    primaryClient,
    isProjectView,
    currentJob,
    allOfficesIds,
    idElementFormOutSide,
    hasFilterParams,
  } = props || {};
  const [isShowLegend, setIsShowLegend] = useState<boolean>(false);
  // fill stick isn't applicable for hour and quarters preset
  const [isFillStick, setIsFillStick] = useState<boolean>(
    getDefaultZoomLevel() === PRESET_VIEW.Hours || getDefaultZoomLevel() === PRESET_VIEW.Quarters ? false : true,
  );
  const [zoomLevel, setZoomLevel] = useState<PRESET_VIEW>(hasFilterParams ? DEFAULT_ZOOM_LEVEL : getDefaultZoomLevel());
  const rpState: ResourcePlannerState = useResourcePlannerState();
  const rpDispatch = useResourcePlannerDispatch();

  const schedulerInstance: Ref<BryntumSchedulerPro> = useRef<BryntumSchedulerPro>(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const [timeRangesHoliday, setTimeRangesHoliday] = useState<Record<string, any>[]>([]);
  const [zoomRendered, setZoomRendered] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
  const prevDragStartTime = useRef<Date>(new Date());
  const prevDragEndTime = useRef<Date>(new Date());
  const pulseFilterRef: Ref<any> = useRef(null);
  const { canEditBooking, canViewBooking } = rpState.bookingPermissions;
  const groupBy = useRef<string>('');
  const isDragging = useRef<boolean>(false);
  const [buffer, setBuffer] = useState<{
    threshold: number;
    coef: number;
  }>({
    coef: 1,
    threshold: 1,
  });

  const defaultResourceColumnRenderer = (
    data: any,
    dispatch: React.Dispatch<ResourcePlannerAction>,
    manageUsers: boolean,
  ) => {
    const { value, record } = data;

    const handleOpenQuickEditUser = (id: number) => {
      if (!manageUsers) {
        return;
      }
      dispatch({
        type: Actions.editUserFormVisible,
        payload: {
          isEditUserFormVisible: true,
          editUserIdForm: id,
        },
      });
    };

    return (
      <div className="b-resource-info">
        <div
          className={groupBy.current === GROUP_BY.nogrouping || !groupBy.current ? '' : 'b-resource-info__utilization'}
        >
          <dl>
            <dt>{groupBy.current === GROUP_BY.utilization ? record?.roles?.name : value}</dt>
            <dd className="b-resource-events">
              {groupBy.current === GROUP_BY.utilization && `${Number(record?.maxHours).toFixed(2)} Hours / Day`}
            </dd>
          </dl>
        </div>
        <div className="rp-quick-edit" onClick={() => handleOpenQuickEditUser(record.originalData.id)}>
          <i className={clsx('fal fa-edit', manageUsers ? 'edit-icon__visible' : 'edit-icon__hidden')}></i>
        </div>
      </div>
    );
  };

  const handleEventDrop = useCallback(
    async ({ eventRecords, targetResourceRecord, context, isCopy }) => {
      if (isCopy) {
        const eventStore = schedulerInstance.current?.instance.eventStore;
        const targetUser = { ...targetResourceRecord.data };
        const targetedBooking = { ...eventRecords[0].data };
        const { startDate, endDate } = context;
        const setStartDate = set(startDate, {
          hours: new Date(targetedBooking.startDate).getHours(),
          minutes: new Date(targetedBooking.startDate).getMinutes(),
          seconds: new Date(targetedBooking.startDate).getSeconds(),
        });
        const setEndDate = set(endDate, {
          hours: new Date(targetedBooking.endDate).getHours(),
          minutes: new Date(targetedBooking.endDate).getMinutes(),
          seconds: new Date(targetedBooking.endDate).getSeconds(),
        });
        const copiedBooking = {
          activity: targetedBooking.timesheetActivity.value,
          bookedUser: targetUser.id,
          endDate: formatBookingDate(setEndDate, 'end'),
          escalate: 'standard',
          hoursPerDay: targetedBooking.hoursPerDay,
          project: targetedBooking.job.value,
          requestedBy: targetedBooking.requestedBy.map(user => user.value),
          startDate: formatBookingDate(setStartDate, 'start'),
          status: 'confirmed',
        };
        bookingsApi
          .post(
            {
              data: {
                type: 'bookings',
                attributes: copiedBooking,
              },
            },
            {
              params: { ...REMOVE_LINK_PARAMS },
              options: {
                serialiseParams: true,
              },
            } as GetConfig,
          )
          .then(
            async ({
              data: {
                data: { attributes: bookingAttributes },
              },
            }) => {
              const { bookingId, draggable, editable, assignedId, endDate, startDate } = bookingAttributes;
              const lastEvent = eventStore?.last as RPBookingModel;
              if (lastEvent) {
                const newBooking: RPBookingModel = {
                  ...targetedBooking,
                  bookingId,
                  bookedUser: {
                    label: targetUser.name,
                    value: targetUser.id,
                  },
                  job: {
                    brandId: targetedBooking?.job?.raw?.brandId,
                    brandTitle: targetedBooking?.job?.raw?.brandTitle,
                    jobExtension: targetedBooking?.job?.raw?.jobExtension,
                    value: targetedBooking?.job?.value,
                    label: targetedBooking?.job?.label,
                    isNC: targetedBooking?.job?.raw?.isNC ?? isNonChargeTime,
                    raw: {
                      clientId: targetedBooking?.job?.raw?.clientId,
                      isAgresso: targetedBooking?.job?.raw?.isAgresso,
                    },
                  },
                  resourceId: assignedId,
                  showDescription: true,
                  endDate: endDate,
                  startDate: startDate,
                  draggable,
                  editable,
                };
                eventStore?.remove(lastEvent);
                await eventStore?.addAsync(newBooking);
              }

              window.utilities?.notification?.success('Copy booking success');
            },
          )
          .catch(error => {
            window.utilities?.notification?.warning('Copy booking error');
            IS_DEV && console.log(error);
          });
      } else {
        isDragging.current = false;
        updateDraggedBooking(
          eventRecords,
          targetResourceRecord,
          currentUser,
          rpState,
          prevDragStartTime.current,
          prevDragEndTime.current,
          zoomLevel,
        )
          .then(() => {
            const onSuccess = rpState.onEventDropSuccess;
            isFunction(onSuccess) && onSuccess();
          })
          .catch(err => {
            const onFail = rpState.onEventDropFail;
            isFunction(onFail) && onFail(err);
          });
      }
    },
    [schedulerInstance.current, currentUser, rpState, prevDragEndTime.current, prevDragStartTime.current],
  );

  const DEFAULT_COLUMNS: GridConfig['columns'] = useMemo(() => {
    const columnsConfigsStorage = localStorage.getItem(COLUMN_HIDDEN_CONFIG);
    let columnHiddenConfig: Record<string, { hidden: boolean; width: number }> = {};
    if (columnsConfigsStorage) {
      columnHiddenConfig = JSON.parse(columnsConfigsStorage);
    }
    return [
      {
        enableCellContextMenu: false,
        field: 'name',
        minWidth: DEFAULT_COLUMN_WIDTH,
        text: 'Name',
        type: 'resourceInfo',
        showImage: false,
        hidden: columnHiddenConfig['name']?.hidden ?? false,
        width: columnHiddenConfig['name']?.width ?? DEFAULT_COLUMN_WIDTH,
        htmlEncode: false, // allow to use HTML code
        renderer: data => {
          return defaultResourceColumnRenderer(data, rpDispatch, rpState.bookingPermissions.canManageUsers);
        },
        sortable(user1, user2) {
          const user1Name = (user1 as RPUserModel).name.toLowerCase();
          const user2Name = (user2 as RPUserModel).name.toLowerCase();
          return user1Name < user2Name ? -1 : 1;
        },
      },
      {
        enableCellContextMenu: false,
        field: 'departments.name',
        hidden: columnHiddenConfig['departments.name']?.hidden ?? true,
        minWidth: DEFAULT_COLUMN_WIDTH,
        text: 'Department',
        width: columnHiddenConfig['departments.name']?.width ?? DEFAULT_COLUMN_WIDTH,
        sortable(user1, user2) {
          const user1Department = (user1 as RPUserModel)?.departments?.name.toLowerCase() || '';
          const user2Department = (user2 as RPUserModel)?.departments?.name.toLowerCase() || '';
          return user1Department < user2Department ? -1 : 1;
        },
        cellCls: rpState.columnsGroupBy !== GROUP_BY.nogrouping ? 'b-grid-cell__hasGroup' : '',
      },
      {
        enableCellContextMenu: false,
        field: 'roles.name',
        hidden: columnHiddenConfig['roles.name']?.hidden ?? true,
        minWidth: DEFAULT_COLUMN_WIDTH,
        text: 'Role',
        width: columnHiddenConfig['roles.name']?.width ?? DEFAULT_COLUMN_WIDTH,
        sortable(user1, user2) {
          const user1Role = (user1 as RPUserModel)?.roles?.name.toLowerCase() || '';
          const user2Role = (user2 as RPUserModel)?.roles?.name.toLowerCase() || '';
          return user1Role < user2Role ? -1 : 1;
        },
        cellCls: rpState.columnsGroupBy !== GROUP_BY.nogrouping ? 'b-grid-cell__hasGroup' : '',
      },
      {
        enableCellContextMenu: false,
        field: 'client',
        hidden: columnHiddenConfig['client']?.hidden ?? true,
        minWidth: DEFAULT_COLUMN_WIDTH,
        text: 'Office',
        width: columnHiddenConfig['client']?.width ?? DEFAULT_COLUMN_WIDTH,
        sortable(user1, user2) {
          const user1Client = (user1 as RPUserModel).client.toLowerCase();
          const user2Client = (user2 as RPUserModel).client.toLowerCase();
          return user1Client.toLowerCase() < user2Client.toLowerCase() ? -1 : 1;
        },
        cellCls: rpState.columnsGroupBy !== GROUP_BY.nogrouping ? 'b-grid-cell__hasGroup' : '',
      },
    ];
  }, [rpState.columnsGroupBy]);

  const [isNonChargeTime, setIsNonChargeTime] = useState<boolean>(false);
  const createNewBooking = useCallback(
    (newBookingType: EditBookingType = EditBookingType.STANDARD) => {
      const newBooking = initializeNewBooking();
      newBooking.requestedBy = [currentUser];

      if (newBookingType === EditBookingType.REQUEST) {
        newBooking.bookingStatus = { label: 'Unapproved', value: 'unapproved' };
        newBooking.eventColor = DEFAULT_EVENT_COLOR;
      }

      setIsNonChargeTime(newBookingType === EditBookingType.NC);

      newBooking.startDate = new Date((newBooking.startDate as Date).setHours(9, 0, 0));
      newBooking.endDate = new Date(addDays(new Date(), 1).setHours(17, 0, 0));
      newBooking.job = rpState?.editBooking?.job;

      while (!isSameDay(newBooking.startDate, validBookingDate(newBooking.startDate) as Date)) {
        newBooking.startDate = new Date(addDays(newBooking.startDate as Date, 1).setHours(9, 0, 0));
      }

      while (!isSameDay(newBooking.endDate, validBookingDate(newBooking.endDate) as Date)) {
        newBooking.endDate = new Date(addDays(newBooking.endDate as Date, 1).setHours(17, 0, 0));
      }

      rpDispatch({
        type: Actions.newBooking,
        payload: {
          editBooking: newBooking,
          newBookingType,
        },
      });
    },
    [rpDispatch, rpState?.editBooking?.job, idElementFormOutSide],
  );

  const handleCellSingleClick = useCallback(
    (cellClickEvent: CellClickEvent) => {
      if (
        !schedulerInstance.current ||
        (rpState.bookingPermissions.canViewBooking && !rpState.bookingPermissions.canEditBooking)
      ) {
        return;
      }

      const cellSelector = cellClickEvent.cellSelector;

      // Do nothing if cell clicked is name or group header
      if (cellSelector.columnId.includes('name') || cellSelector.id.includes('group-header-')) {
        return;
      }

      const scheduler = schedulerInstance.current.instance;

      // Get date of clicked cell
      const clickedDate = scheduler.getDateFromDomEvent(cellClickEvent.event as Event);

      // Do nothing if cell is weekend
      if (isWeekend(clickedDate)) {
        return;
      }

      // Get clicked resource's data
      const clickedResource = cellClickEvent.record.data;

      // Inital new booking with clicked date
      const newBooking = initializeNewBooking(clickedDate);
      isNonChargeTime && setIsNonChargeTime(false);

      newBooking.hoursPerDay =
        zoomLevel === PRESET_VIEW.Hours ? '1.00' : `${Number(clickedResource.maxHours).toFixed(2)}`;

      if (zoomLevel === PRESET_VIEW.Hours) {
        newBooking.startDate = startOfHour(new Date(clickedDate));
        // if create a new booking start from 11pm we need to keep the end day still be the same with start date
        newBooking.endDate =
          new Date(clickedDate).getHours() === 23
            ? subMilliseconds(startOfHour(addHours(new Date(clickedDate), 1)), 1)
            : startOfHour(addHours(new Date(clickedDate), 1));
      } else {
        newBooking.startDate = new Date(clickedDate.setHours(9, 0, 0));
        newBooking.endDate = recalculateEndHours(newBooking.startDate, Number(newBooking.hoursPerDay));
      }

      newBooking.requestedBy = [currentUser];

      let bookedUserId = clickedResource.id;

      // Set default project of booking if scheduler is group by projects
      if (rpState.columnsGroupBy === GROUP_BY.projects) {
        const jobId = clickedResource.job.id;

        // Extract booking user id from combined id
        bookedUserId = bookedUserId.replace(`-${jobId}`, '');

        // Mapping selected project to booking
        newBooking.job = {
          label: clickedResource.job.attributes.jobTitle,
          value: jobId,
          raw: clickedResource.job,
          brandId: clickedResource.job.attributes.brandId,
          brandTitle: clickedResource.job.attributes.brandTitle,
          isAgresso: clickedResource.job.attributes.isAgresso,
          isNC: clickedResource.job.attributes.isNC,
          jobExtension: clickedResource.job.attributes.jobExtension,
        };
      }

      // Mapping selected user to booking
      newBooking.bookedUser = {
        value: bookedUserId,
        label: clickedResource.name,
        raw: clickedResource,
      };

      // Dispatch open modal
      rpDispatch({
        type: Actions.newBooking,
        payload: {
          editBooking: newBooking,
          newBookingType: EditBookingType.STANDARD,
        },
      });
    },
    [rpDispatch, rpState.columnsGroupBy, zoomLevel, isNonChargeTime],
  );

  const setDefaultSortOrder = () => {
    schedulerInstance?.current?.instance.resourceStore.sort((user1, user2) => {
      return user1.name?.toLowerCase() < user2.name?.toLowerCase() ? -1 : 1;
    });
  };

  const handleShowLegend = () => {
    setIsShowLegend(!isShowLegend);
  };

  const handleExportData = () => {
    const queryParams = {
      filter: {
        'booking.startdate': rpState.filters?.['booking.startdate'],
        'booking.enddate': rpState.filters?.['booking.enddate'],
        'booking.user.id': rpState.filters?.['user.id'],
        'booking.user.client.id': rpState.filters?.['user.client.id'],
        'booking.user.group.id': rpState.filters?.['user.group.id'],
        'booking.user.department.id': rpState.filters?.['user.department.id'],
        'booking.user.role.id': rpState.filters?.['user.role.id'],
        'booking.job.id': rpState.filters?.['booking.job.id'],
        'booking.job.client.id': rpState.filters?.['booking.job.client.id'],
        'booking.filter.pto': rpState.filters?.['booking.filter.pto'],
        'booking.job.jobtitle': rpState.filters?.['booking.job.jobtitle'],
        'booking.job.status': rpState.filters?.['booking.job.status'],
        'booking.job.jobextension': rpState.filters?.['booking.job.jobextension'],
        'booking.job.brand.id': rpState.filters?.['booking.job.brand.id'],
        'booking.job.brand.category.id': rpState.filters?.['booking.job.brand.category.id'],
        'booking.status.id': rpState.filters?.['booking.status.id'],
        'booking.reportedby.id': rpState.filters?.['booking.reportedby.id'],
        'booking.requestedBy': rpState.filters?.['booking.requestedBy'],
        'booking.probability': rpState.filters?.['booking.probability'],
        'booking.activityid': rpState.filters?.['booking.activityid'],
        'booking.user.status': rpState.filters?.['pulse_active_status'],
        'booking.user.bookable': rpState.filters?.['bookable'],
      },
    };
    const queryParamsString = qs.stringify(queryParams, { encode: false });
    const exportUrl = `${PULSE_LOCAL_URL}/v2/api/bookings/export?${queryParamsString}`;
    window.open(exportUrl);
  };

  const handleGroup = useCallback(
    (selectedGroupBy: GROUP_BY): void => {
      localStorage.setItem(LAST_GROUP_BY, selectedGroupBy);
      if (selectedGroupBy === rpState.columnsGroupBy) {
        return;
      }
      const scheduler: SchedulerPro = schedulerInstance.current?.instance as SchedulerPro;

      scheduler.features.group.disabled = selectedGroupBy === GROUP_BY.nogrouping ? true : false;

      if (selectedGroupBy === GROUP_BY.projects || rpState.columnsGroupBy === GROUP_BY.projects) {
        /**
         * Need to scroll back to the top
         * if not there are issues with the rendering
         */
        scheduler.scrollToTop();
      }

      if (rpState.columnsGroupBy !== GROUP_BY.projects) {
        switch (selectedGroupBy) {
          case GROUP_BY.projects:
            rpDispatch({
              type: Actions.setGroupByProject,
            });
            break;
          case GROUP_BY.nogrouping:
            rpDispatch({
              type: Actions.setNoGrouping,
            });
            break;
          case GROUP_BY.utilization:
            rpDispatch({
              type: Actions.setGroupByUtilisation,
            });
            break;
          case GROUP_BY.departments:
            rpDispatch({
              type: Actions.setDepartment,
            });
            break;
          case GROUP_BY.roles:
            rpDispatch({
              type: Actions.setRole,
            });
            break;
          case GROUP_BY.offices:
            rpDispatch({
              type: Actions.setOffice,
            });
            break;
          default:
            scheduler.resourceStore.group({ field: selectedGroupBy, ascending: true });
            break;
        }
      } else if (selectedGroupBy !== GROUP_BY.projects && rpState.columnsGroupBy === GROUP_BY.projects) {
        rpDispatch({
          type: Actions.unsetGroupByProject,
          payload: {
            columnsGroupBy: selectedGroupBy,
          },
        });
      }

      (scheduler.columns as any).map(column => {
        column.field === selectedGroupBy && (column.hidden = false);
      });

      groupBy.current = selectedGroupBy;

      rpDispatch({
        type: Actions.setResourcePlannerDateRange,
        payload: {
          startDate: scheduler?.visibleDateRange?.startDate,
        },
      });

      rpDispatch({
        type: Actions.setColumnsGroupBy,
        payload: selectedGroupBy,
      });
    },
    [schedulerInstance.current, rpDispatch, rpState.columnsGroupBy],
  );

  const handleExpand = () => {
    const instance = schedulerInstance?.current?.instance;
    instance && instance.expandAll();
  };

  const handleCollapse = () => {
    const instance = schedulerInstance?.current?.instance;
    instance && instance.collapseAll();
  };

  const handleChangeView = useCallback(
    (selectedView: PRESET_VIEW): void => {
      if (schedulerInstance.current?.instance) {
        const instance = schedulerInstance.current?.instance;
        if (selectedView === 1 || selectedView === 2 || selectedView === 3) {
          setIsFillStick(true);
        } else {
          setIsFillStick(false);
        }
        const bufferCoef = selectedView > 2 ? 1 : 0.9;
        selectedView <= instance.maxZoomLevel &&
          selectedView >= instance.minZoomLevel &&
          rpDispatch({
            type: Actions.set,
            payload: {
              zoomLevel: selectedView,
            },
          });
        instance.zoomToLevel(selectedView, {
          centerDate: !zoomRendered && startOfDay(new Date()),
        });
        localStorage.setItem(SCHEDULE_DEFAULT_KEY_VIEW, JSON.stringify(selectedView));
        setZoomLevel(selectedView);
        setZoomRendered(true);
      }
    },
    [schedulerInstance.current],
  );

  const handleChangeSearchInput = e => {
    setSearchValue(e?.currentTarget?.value);
  };

  const handleClearSearchInput = () => {
    setSearchValue('');
  };

  const handleSearch = () => {
    let value = searchValue;
    const scheduler: SchedulerPro = schedulerInstance?.current?.instance as SchedulerPro;
    scheduler.resourceStore.clearFilters();
    scheduler.eventStore.clearFilters();
    if (!value) {
      (scheduler.columns as any).records[0].renderer = data =>
        defaultResourceColumnRenderer(data, rpDispatch, rpState.bookingPermissions.canManageUsers);
    } else {
      value = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      scheduler.resourceStore.filterBy((resource: RPUserModel) => {
        return (
          resource?.name.match(new RegExp(value, 'i')) ||
          resource?.departments?.name.match(new RegExp(value, 'i')) ||
          resource?.roles?.name.match(new RegExp(value, 'i')) ||
          resource?.client?.match(new RegExp(value, 'i')) ||
          resource.events.some(event => {
            return (
              (event as RPBookingModel).job?.jobExtension?.match(new RegExp(value, 'i')) ||
              (event as RPBookingModel).job?.label.match(new RegExp(value, 'i')) ||
              (event as RPBookingModel).job?.brandTitle?.match(new RegExp(value, 'i')) ||
              (event as RPBookingModel).bookedUser?.label.match(new RegExp(value, 'i')) ||
              (event as RPBookingModel).bookingStatus?.label?.match(new RegExp(value, 'i')) ||
              (event as RPBookingModel).timesheetActivity?.label?.match(new RegExp(value, 'i'))
            );
          })
        );
      });

      scheduler.eventStore.filterBy((event: RPBookingModel) => {
        return (
          event.job?.jobExtension?.match(new RegExp(value, 'i')) ||
          event.job?.label.match(new RegExp(value, 'i')) ||
          event.job?.brandTitle?.match(new RegExp(value, 'i')) ||
          event.bookedUser?.label.match(new RegExp(value, 'i')) ||
          event.bookedUser?.raw?.departments?.name.match(new RegExp(value, 'i')) ||
          event.bookedUser?.raw?.roles?.name.match(new RegExp(value, 'i')) ||
          event.bookedUser?.raw?.client.match(new RegExp(value, 'i')) ||
          event.bookingStatus?.label?.match(new RegExp(value, 'i')) ||
          event.timesheetActivity?.label?.match(new RegExp(value, 'i'))
        );
      });
    }
  };

  const handleDeleteRecord = useCallback(async eventRecord => {
    const scheduler = schedulerInstance.current?.instance;
    if (scheduler) {
      await scheduler?.eventStore.remove(eventRecord);
      scheduler.refreshRows();
    }
  }, []);

  // Handler to add new event bookings
  const handleSaveBooking = useCallback(
    async (newBookingRecord, bookingValues, resourceId) => {
      const scheduler = schedulerInstance.current?.instance;
      // override to make sure that resource id is correct
      const newBooking = newBookingRecord;
      newBooking.resourceId = resourceId;
      const hasResource = Boolean(scheduler?.resourceStore.getById(resourceId));
      if (scheduler) {
        // Check resource existed in planner or not
        // if no, handle add new resource
        if (!hasResource) {
          switch (rpState.columnsGroupBy) {
            case GROUP_BY.projects:
              // Generate user project resource for add to scheduler
              const newResource = generateProjectResource(bookingValues);
              newResource && (await scheduler.resourceStore.addAsync(newResource));
              break;
            case GROUP_BY.nogrouping:
              // Generate user resource for add to scheduler
              const newDefaultResource = generateUserResource(bookingValues);
              newDefaultResource && (await scheduler.resourceStore.addAsync(newDefaultResource));
              break;
            default:
              break;
          }
        }
        await scheduler.eventStore.addAsync(newBooking);
      }
    },
    [rpState.columnsGroupBy],
  );

  const onEditBooking = () => false;

  const groupSummaryRenderer = useCallback(
    ({ events, resources, startDate }: GroupSummaryRenderer) => {
      const totalDailyHours = events.reduce((acc: number, current: RPBookingModel): number => {
        return (acc += parseFloat(current.hoursPerDay));
      }, 0);

      const isUtilizationGrouping = rpState.columnsGroupBy === GROUP_BY.utilization;
      const isUserGrouping = rpState.columnsGroupBy === GROUP_BY.users;

      const colorLevel = (totalDailyHours / Number(resources[0].maxHours)) * 100;
      const maxHeightEvent = 20;
      const heightLevel = colorLevel > 100 ? maxHeightEvent : (maxHeightEvent * colorLevel) / 100;
      let overbookedClasses = '';

      switch (true) {
        case colorLevel > 100:
          overbookedClasses = styles['over-booked-heavy'];
          break;

        case colorLevel === 100:
          overbookedClasses = styles['over-booked-3'];
          break;

        case colorLevel >= 60:
          overbookedClasses = styles['over-booked-2'];
          break;
        case colorLevel > 0:
          overbookedClasses = styles['over-booked-1'];
          break;

        case colorLevel === 0:
          overbookedClasses = styles['over-booked-none'];
          break;
        default:
          overbookedClasses = styles['over-booked-heavy'];
          break;
      }

      if (isWeekend(startDate)) {
        return `<div style="background-color: #e6e6e6; height: 100%"></div>`;
      }
      if (totalDailyHours === 0) {
        return `<div class="${styles['summary-root']}"></div>`;
      }

      if (!isUtilizationGrouping) {
        return `<div style="justify-content: center" class="${clsx(
          styles['summary-root'],
          isWeekend(startDate) && styles['summary-weekend'],
          isUserGrouping && styles['summary-user'],
        )}">
    <span class="${styles['summary-overbooked-hours']}">${totalDailyHours && totalDailyHours.toFixed(2)}</span>
    </div>`;
      }

      return `<div style="justify-content: flex-end" class="${clsx(styles['summary-root'])}">
      <div style="height: ${heightLevel}px" class="${
        !isWeekend(startDate) && clsx(styles['summary-overbooked-hours-div'], overbookedClasses)
      }"></div>
      <span class="${styles['summary-overbooked-hours']}">${
        !isWeekend(startDate) && totalDailyHours ? totalDailyHours.toFixed(2) : '0.0'
      }</span>
    </div>`;
    },
    [rpState.columnsGroupBy, schedulerInstance.current],
  );

  const handleClickOpenFormOutSidePlanner = () => {
    createNewBooking?.();
  };
  useEffect(() => {
    const scheduler: SchedulerPro = schedulerInstance?.current?.instance as SchedulerPro;
    scheduler.setStartDate(parseISO(rpState.resourcePlannerDateRange.startDate));
    scheduler.setEndDate(parseISO(rpState.resourcePlannerDateRange.endDate));

    const lockedSubGrid = scheduler.getSubGrid('locked');
    lockedSubGrid.on(
      'resize',
      debounce(resizeParams => {
        sessionStorage.setItem('lockedSubGridWidth', resizeParams.width || DEFAULT_COLUMN_WIDTH);
      }, 500),
    );

    /**
     * Set the default order to name
     */
    setDefaultSortOrder();

    /**
     * Set the primaryClient of the logged in user
     * and set the filters from deep link
     */

    if (isProjectView && currentJob) {
      rpDispatch({
        type: Actions.set,
        payload: {
          zoomLevel: 1,
        },
      });

      rpDispatch({
        type: Actions.setFilter,
        payload: {
          selectedFilters: [
            {
              label: currentJob?.label,
              type: 'jobs',
              value: currentJob?.value,
            },
          ],
        },
      });
    } else {
      const appliedFiltersStorage = localStorage.getItem(LAST_APPLIED_FILTERS);
      if (appliedFiltersStorage && !hasFilterParams) {
        const appliedFilters = JSON.parse(appliedFiltersStorage);
        pulseFilterRef.current.applyFilters(appliedFilters);
      } else {
        const onLoadSelectedFilters =
          rpState.deepLinkFilters?.filter(deepLinkFilter => {
            return deepLinkFilter.value !== `userOffices${primaryClient.clientId}`;
          }) || [];

        onLoadSelectedFilters &&
          onLoadSelectedFilters.push({
            type: 'userOffices',
            value: `userOffices${primaryClient.clientId}`,
            label: primaryClient.company,
          });

        rpDispatch({
          type: Actions.setFilter,
          payload: {
            selectedFilters: onLoadSelectedFilters,
          },
        });
      }
    }
    /** Cancel request if component unmount */
    return () => {
      source && source.cancel('Component unmounted');
    };
  }, [hasFilterParams]);

  useEffect(() => {
    const groupByStorage = localStorage.getItem(LAST_GROUP_BY);
    if (groupByStorage && !hasFilterParams) {
      handleGroup(groupByStorage as GROUP_BY);
    }
  }, []);

  const openFilterFlyout = () => {
    pulseFilterRef.current.openFilterFlyout();
    setIsOpenNotification(false);
  };

  const handleRemoveResource = (resourceId: number) => {
    const scheduler: SchedulerPro = schedulerInstance?.current?.instance as SchedulerPro;
    if (scheduler) {
      scheduler?.resourceStore?.remove(resourceId);
    }
  };
  useEffect(() => {
    if (isOpenNotification) {
      document.getElementById('open-filter-flyout-link')?.addEventListener('click', openFilterFlyout);
    }

    return () => {
      document.getElementById('open-filter-flyout-link')?.removeEventListener('click', openFilterFlyout);
    };
  }, [isOpenNotification]);
  useEffect(() => {
    const scheduler = schedulerInstance.current?.instance;
    if (rpState.filters && !rpState.rpNav && scheduler) {
      /**
       * Because we are switching from group by project
       * and the other group by options, we need to
       * nullify the tooltipData.
       */
      rpDispatch({
        type: Actions.fetchData,
      });

      /**
       * Append filters to url params
       */
      withDeepLink.appendFiltersToURL(rpState.filters, currentUser.value);

      /**
       * Need to do this because if the view is grouped
       * a server request will throw an error client side.
       * So we need to expand all the headers first to prevent
       * the error.
       * Also removing all the resources so expanding the groups
       * is not shown client side
       */
      if (!scheduler) {
        throw new Error('Scheduler instance is required');
      }
      fetchResources(rpState, allOfficesIds || [])
        .then(res => {
          if (!res) {
            throw new Error();
          }
          /** Needed to prevent scrolling issue */
          scheduler.assignmentStore.removeAll();
          scheduler.eventStore.removeAll();
          scheduler.resourceStore.removeAll();
          /** Needed to prevent scrolling issue */
          return res;
        })
        .then(res => {
          /**
           * Loop through the bookings to set time on PTO bookings
           * so they render properly.
           *
           * Also filter out any bookings that end up on a weekend
           */
          res.events.rows = uniqBy(res.events.rows, 'bookingId');
          const uniqueEventIds = scheduler.eventStore.map(event => event.id);
          res.events.rows = res.events.rows.reduce((acc: RPBookingModel[], event: RPBookingModel) => {
            const isoStartDate = parseISO(event.startDate as string);
            const isoEndDate = parseISO(event.endDate as string);

            if (uniqueEventIds.includes(event.bookingId)) {
              return acc;
            }
            if (isWeekend(isoStartDate) && isWeekend(isoEndDate)) {
              return acc;
            }
            if (event.ptoRequestId && isSameDay(isoStartDate, isoEndDate)) {
              event.endDate = (event.endDate as string).replace('00:00:00', '23:59:59');
            }
            acc.push(event);
            return acc;
          }, []);

          // Parse start date and end date to Date
          res.calendars.rows = res.calendars.rows.map(calendar => {
            calendar.intervals = calendar.intervals.map(interval => {
              if (interval.startDate) {
                interval.startDate = startOfDay(parseISO(interval.startDate));
              }
              if (interval.endDate) {
                interval.endDate = startOfDay(addDays(parseISO(interval.endDate), 1));
              }
              return interval;
            });
            return calendar;
          });

          // Parse start date and end date to Date
          res.timeRanges.rows = res.timeRanges.rows.map(timeRange => {
            if (timeRange.startDate) {
              timeRange.startDate = startOfDay(parseISO(timeRange.startDate));
            }
            if (timeRange.endDate) {
              timeRange.endDate = startOfDay(addDays(parseISO(timeRange.endDate), 1));
            }
            return timeRange;
          });

          setTimeRangesHoliday(scheduler.timeRanges as Record<string, any>[]);
          return res;
        })
        .then(async res => {
          if (rpState.columnsGroupBy !== GROUP_BY.projects) {
            /** Remove column job title and job number when don't group */
            (scheduler.columns as any).get('job.attributes.jobTitle')?.remove();
            (scheduler.columns as any).get('job.attributes.jobExtension')?.remove();
          }

          scheduler.suspendRefresh();

          scheduler.eventStore.add(res.events.rows);
          scheduler.resourceStore.add(res.resources.rows);
          scheduler.calendars = res.calendars.rows;
          scheduler.project.calendar = res.project.calendar;
          scheduler.timeRanges = res.timeRanges.rows;

          scheduler.resumeRefresh(true);

          await scheduler.project.commitAsync();

          rpState.columnsGroupBy !== GROUP_BY.nogrouping && scheduler.resourceStore.group(rpState.columnsGroupBy, true);
          return res;
        })
        .then(res => {
          const instance = schedulerInstance?.current?.instance;
          scheduler.refreshRows();
          if (instance && rpState.columnsGroupBy === GROUP_BY.utilization) {
            instance.collapseAll();
          }

          rpDispatch({
            type: Actions.setLoadingFalse,
          });

          setIsOpenNotification(true);
          res.resources.rows.length >= MAX_USERS && window.utilities?.notification.success(notificationText);
        })
        .catch(error => {
          !isInitialLoad &&
            rpDispatch({
              type: Actions.setLoadingFalse,
            });
          !isInitialLoad && window.utilities && window.utilities?.notification.danger(error);
          IS_DEV && console.log(error);
        });
    }
  }, [rpState.filters, rpState.jq]);

  useEffect(() => {
    if (!schedulerInstance.current) {
      return;
    }
    const scheduler = schedulerInstance.current.instance;
    (scheduler.resourceStore as any).listeners = {
      group: ({ groupers }) => {
        if (groupers[0]) {
          scheduler.scrollToTop();
          rpDispatch({
            type: Actions.setColumnsGroupBy,
            payload: groupers[0].field,
          });
        } else {
          rpDispatch({
            type: Actions.setColumnsGroupBy,
            payload: GROUP_BY.nogrouping,
          });
        }
      },
    };

    const columnStore = scheduler.columns as ColumnStore;

    columnStore.on('change', ({ source }: Record<string, any>) => {
      const columns = source.reduce((results, column) => {
        results[column.field] = { hidden: column.hidden, width: column.width };
        return results;
      }, {});

      localStorage.setItem(COLUMN_HIDDEN_CONFIG, JSON.stringify(columns));
    });
  }, [schedulerInstance.current]);

  useEffect(() => {
    if (!schedulerInstance.current) {
      return;
    }
    (schedulerInstance.current.instance.features.eventMenu as any).items = BookingContextMenu(
      rpDispatch,
      rpState,
      currentUser,
      schedulerInstance.current.instance,
    );
    schedulerInstance.current.instance.features.scheduleTooltip.disabled = true;
    (schedulerInstance.current.instance.features.eventDragCreate as any).showTooltip = false;
    (schedulerInstance.current.instance as any).listeners = {
      dragCreateStart: () => {
        isNonChargeTime && setIsNonChargeTime(false);
      },
      dragCreateEnd: dragCreateEnd(rpDispatch, currentUser, rpState.columnsGroupBy, zoomLevel),
      beforeTaskEdit,
      beforeEventEdit: beforeEventEdit(rpDispatch, currentUser, rpState),
      eventClick: ({ eventRecord }) => {
        setIsNonChargeTime(!!eventRecord?.job?.isNC);
      },
      eventResizeEnd: handleEventResize(rpState, currentUser, schedulerInstance.current.instance, zoomLevel),
      eventDrop: handleEventDrop,
      beforeEventDropFinalize: ({ context }) => {
        const { origStart, origEnd } = context;
        prevDragStartTime.current = origStart;
        prevDragEndTime.current = origEnd;
      },
      eventDragStart: () => {
        isDragging.current = true;
      },
      visibleDateRangeChange: async ({ new: { startDate, endDate } }) => {
        if (schedulerInstance.current) {
          const scheduler = schedulerInstance.current.instance;

          // Load data for 4 weeks prior to the current start date and 4 weeks past the current end date
          const newStartDate = new Date(format(subWeeks(startDate, 6), RP_DATE_SERVER_FORMAT));
          const newEndDate = new Date(format(addWeeks(endDate, 4), RP_DATE_SERVER_FORMAT));
          const lastFetchedStartDate = rpState.resourcePlannerDateRange.startDate;
          const lastFetchedEndDate = rpState.resourcePlannerDateRange.endDate;

          if (subWeeks(startDate, 2) < new Date(lastFetchedStartDate)) {
            // Scrolling left and reached beyond 4 weeks. No wee need to fetch again for the next set of 4 weeks.
            await debounceAddBookingsForRange(newStartDate, newEndDate, rpState, scheduler);
            handleLoadDateRange(newStartDate, newEndDate, rpDispatch);
          }

          if (addWeeks(endDate, 2) > new Date(lastFetchedEndDate)) {
            // Scrolling right and reached beyond 4 weeks. No wee need to fetch again for the next set of 4 weeks.
            await debounceAddBookingsForRange(newStartDate, newEndDate, rpState, scheduler);
            handleLoadDateRange(newStartDate, newEndDate, rpDispatch);
          }
        }
      },
      eventMouseEnter: (source: any) => {
        /**
         * Please see permission requirements [here]{@link https://beta.extranet-system.com/v2/passport/1075/tasklist/46295}
         */
        if (!canEditBooking && canViewBooking && source.eventRecord.locked === 'y') {
          return false;
        } else {
          rpDispatch({
            type: Actions.setTooltipData,
            payload: {
              tooltipData: source,
            },
          });
        }
      },
      cellClick: handleCellSingleClick,
    };
  }, [rpDispatch, currentUser, canEditBooking, rpState, onEditBooking]);

  useEffect(() => {
    if (!schedulerInstance.current) {
      return;
    }
    debounceSearch(handleSearch);
  }, [searchValue]);

  useEffect(() => {
    if (schedulerInstance.current) {
      const scheduler = schedulerInstance.current.instance;
      (scheduler.eventStore as any).listeners = {
        load: () => {
          setIsInitialLoad(false);
        },
      };
    }
  }, [schedulerInstance.current, rpState]);

  useEffect(() => {
    const scheduler = schedulerInstance?.current?.instance;
    if (!scheduler) {
      return;
    }
    if (hasFilterParams) {
      rpDispatch({
        type: Actions.set,
        payload: {
          zoomLevel: DEFAULT_ZOOM_LEVEL,
        },
      });
      scheduler.zoomToLevel(DEFAULT_ZOOM_LEVEL, {
        centerDate: !zoomRendered && startOfDay(new Date()),
      });
    }
    scheduler.scrollToDate(getScrollDate(getDefaultViewPreset), {
      block: 'center',
    });
  }, []);

  useEffect(() => {
    if (idElementFormOutSide) {
      const buttonOutSide = document.getElementById(idElementFormOutSide);
      if (buttonOutSide) {
        buttonOutSide.addEventListener('click', handleClickOpenFormOutSidePlanner);
      }
    }
    return () => {
      if (idElementFormOutSide) {
        const buttonOutSide = document.getElementById(idElementFormOutSide);
        if (buttonOutSide) {
          buttonOutSide.addEventListener('click', handleClickOpenFormOutSidePlanner);
        }
      }
    };
  }, [idElementFormOutSide]);

  // handle buffer for infinite scroll
  useEffect(() => {
    const scheduler: SchedulerPro = schedulerInstance?.current?.instance as SchedulerPro;
    const normalSubGrid = scheduler.getSubGrid('normal');
    const WIDTH_SMALL_Zoom = 1903;
    // keep variable
    // const WIDTH_MEDIUM_ZOOM = 1690;
    const WIDTH_LARGE_ZOOM = 1306;
    const WIDTH_HEAVY_ZOOM = 867;
    const infiniteBufferDefault = {
      coef: 1,
      threshold: 1,
    };
    const infiniteBufferZoomIn = {
      coef: 2,
      threshold: 0.8,
    };
    const infiniteBufferZoomDeepIn = {
      coef: 2,
      threshold: 1.3,
    };
    const updateBuffer = (resizeParams?: Record<string, any>) => {
      const currentWidth = resizeParams?.width || normalSubGrid.width;
      let newBuffer = infiniteBufferDefault;

      // check width of screen and setting new buffer
      if (currentWidth <= WIDTH_SMALL_Zoom) {
        newBuffer = infiniteBufferDefault;
      }
      if (currentWidth <= WIDTH_LARGE_ZOOM) {
        newBuffer = infiniteBufferZoomIn;
      }
      if (currentWidth <= WIDTH_HEAVY_ZOOM) {
        newBuffer = infiniteBufferZoomDeepIn;
      }
      setBuffer(newBuffer);
    };
    updateBuffer();
    normalSubGrid.on('resize', debounce(updateBuffer, 500));
  }, []);

  return (
    <>
      <div className={clsx(styles['rp-header-root'], 'pulse-rp__headerRoot')}>
        <div className={styles['rp-search-root']}>
          <PulseInput
            classes={{
              root: styles['rp-search-input'],
              input: styles['rp-search-input__input'],
              label: styles['rp-search-input__label'],
            }}
            label={''}
            InputBaseProps={{
              onChange: handleChangeSearchInput,
              placeholder: 'Quick Search...',
              startAdornment: (
                <PulseIcon
                  classes={{
                    root: styles['rp-search-input__start-icon-root'],
                    icon: clsx('fal fa-search', styles['rp-search-input__start-icon']),
                  }}
                  iconName=""
                  size={IconSizes.lg}
                />
              ),
              endAdornment: searchValue && (
                <PulseIconButton
                  classes={{
                    pulseIcon: {
                      icon: clsx('fal fa-times', styles['rp-search-input__end-icon']),
                    },
                  }}
                  iconName=""
                  size={IconSizes.lg}
                  handleClick={handleClearSearchInput}
                />
              ),
              value: searchValue,
            }}
          />
        </div>
        <div className={styles['groupby-root']}>
          <ResourcePlannerGroupBy
            selectedGroupBy={rpState.columnsGroupBy}
            onChange={handleGroup}
            isProjectview={isProjectView}
          />
          <div className={styles['groupby_action-btn']}>
            <Tippy content={`Expand all rows`} placement="top" appendTo={document.body}>
              <PulseButtonBase
                classes={[styles['actions-btn']]}
                icon
                iconClasses={{ icon: clsx('fal fa-chevron-double-down', styles['actions-btn-icon']) }}
                label=""
                onClick={handleExpand}
              />
            </Tippy>
            <Tippy content={`Collapse all rows`} placement="top" appendTo={document.body}>
              <PulseButtonBase
                classes={[styles['actions-btn']]}
                icon
                iconClasses={{ icon: clsx('fal fa-chevron-double-up', styles['actions-btn-icon']) }}
                label=""
                onClick={handleCollapse}
              />
            </Tippy>
          </div>
        </div>
        {!isProjectView && (
          <>
            <div className={styles['create-root']}>
              <CreateMenuButtons onClick={createNewBooking} />
            </div>
            <FilterFlyout primaryClient={primaryClient} ref={pulseFilterRef} />
          </>
        )}
        <div className={styles['preset-root']}>
          <PulseButtonBase
            classes={[styles['actions-btn'], styles['show-legend-btn']]}
            label={isShowLegend ? 'HIDE LEGEND' : 'SHOW LEGEND'}
            onClick={handleShowLegend}
          />
          <GridNavigation scheduler={schedulerInstance.current?.instance as SchedulerPro} />
          <ResourcePlannerPreset selectedPreset={rpState.zoomLevel} onChange={handleChangeView} />
          <PulseButtonBase
            classes={[clsx(styles['actions-btn'], styles['export__btn'])]}
            icon
            iconClasses={{ icon: 'fal fa-file-excel' }}
            label="Export Data"
            onClick={handleExportData}
          />
        </div>
      </div>

      <Collapse in={isShowLegend}>
        <PulseResourceLegend />
      </Collapse>

      <div className={clsx('pulse-rp__bryntumContainer', styles['rp-bryntum-schedulerpro-ctn'])}>
        <BryntumSchedulerPro
          fillTicks={isFillStick}
          height="calc(100vh - 115px)"
          stripeFeature={false}
          barMargin={4}
          rowHeight={48}
          cellEditFeature={false}
          cellMenuFeature={false}
          scheduleMenuFeature={false}
          columns={DEFAULT_COLUMNS}
          dependenciesFeature={false}
          eventBodyTemplate={EventTemplate}
          eventDragFeature={{
            showTooltip: false,
          }}
          eventResizeFeature={{ validatorFn: resizeValidation, showTooltip: false }}
          eventDragCreateFeature={{
            disabled: !canEditBooking,
          }}
          useInitialAnimation={false}
          enableEventAnimations={false}
          eventMenuFeature={{
            items: BookingContextMenu(rpDispatch, rpState, currentUser, schedulerInstance?.current?.instance),
            processItems: ({ eventRecord, items }) => {
              const eventItem = eventRecord as RPBookingModel;
              if (!eventItem.editable) {
                delete items.editBooking;
                delete items.deleteBooking;
                delete items.splitBooking;
                delete items.moveBooking;
                delete items.copyBooking;
                delete items.reAssignBooking;
                /**
                 * Remove work completed option when booked user is DIFFERENT from login user if user is VIEW user
                 */
                eventItem?.bookedUser?.value !== currentUser?.value && delete items.completeBooking;
              }
              /**
               * Remove work completed option when booking complete
               */
              eventItem?.bookingStatus?.value === 'workCompleted' && delete items.completeBooking;
            },
          }}
          eventEditFeature={{ triggerEvent: 'eventClick' }}
          eventRenderer={EventRenderer}
          eventTooltipFeature={false}
          groupFeature={{
            renderer: groupRenderer(),
          }}
          groupSummaryFeature={{
            showTooltip: false,
            target: 'header',
            collapseToHeader: true,
            summaries: [
              {
                label: 'Label',
                renderer: groupSummaryRenderer,
              },
            ],
          }}
          nonWorkingTimeFeature={true}
          infiniteScroll={true}
          listeners={undefined}
          presets={presets as Partial<ViewPresetConfig>[]}
          project={pulseProject}
          ref={schedulerInstance}
          resourceTimeRangesFeature
          fixedRowHeight={false}
          timeAxisHeaderMenuFeature={false}
          timeRangesFeature={{
            showCurrentTimeLine: true,
          }}
          viewPreset={getDefaultViewPreset}
          visibleZoomFactor={1}
          zoomKeepsOriginalTimespan={true}
          zoomOnMouseWheel={false}
          zoomOnTimeAxisDoubleClick={false}
          resourceNonWorkingTimeFeature
          createEventOnDblClick={false}
          taskEditFeature={false}
          stickyEventsFeature={false}
          bufferCoef={buffer.coef}
          bufferThreshold={buffer.threshold}
        />
      </div>
      <Suspense fallback="Loading">
        {rpState.isEditFormVisible && (
          <EditForm
            currentUser={currentUser}
            onSaveSuccess={handleSaveBooking}
            onSaveBookingFail={onSaveBookingFail}
            isNonChargeTime={isNonChargeTime}
            primaryClient={primaryClient}
            allOfficesIds={allOfficesIds || []}
            schedulerInstance={schedulerInstance.current?.instance as SchedulerPro}
          />
        )}
      </Suspense>
      <Suspense fallback="Loading">
        {rpState.isEditUserFormVisible && (
          <UserSettingModal
            removeResource={handleRemoveResource}
            schedulerInstance={schedulerInstance.current?.instance as SchedulerPro}
          />
        )}
      </Suspense>
      {rpState.tooltipData && (
        <PulseMenu
          classes={{ menuCtn: styles['rp-event-tooltip-menu-ctn'] }}
          menuChildren={<EventTooltipTemplate />}
          TippyProps={{
            arrow: true,
            delay: 500,
            interactiveBorder: 20,
            offset: [0, 10],
            placement: 'top',
            appendTo: document.body,
            followCursor: 'horizontal',
            /**
             * event.target can change to the inner elements of the event template.
             * So we need to set this to the wrapper element to prevent reflow
             */
            reference: (rpState.tooltipData?.event?.target as HTMLDivElement).closest(`.rp-event-wrapper`),
          }}
        />
      )}
      {timeRangesHoliday.map(holiday => {
        const reference = document.querySelector(`.b-grid-header .timerange--holiday[data-id="${holiday.id}"]`);
        if (!reference) {
          return null;
        }
        return (
          <Tippy
            key={holiday.id}
            className={styles['timerange-header-tooltip']}
            placement="top"
            appendTo={document.body}
            content={
              <>
                <div>{holiday.name}</div>
                {holiday.client && <div>{holiday.client.company}</div>}
              </>
            }
            reference={reference}
          />
        );
      })}
      {rpState.isLoading && <PulseLoader isVisible={rpState.isLoading} />}
      <Suspense fallback="Loading">
        <DeleteBookingModal
          onDeleteSuccess={handleDeleteRecord}
          schedulerInstance={schedulerInstance.current?.instance as SchedulerPro}
        />
      </Suspense>
    </>
  );
};

export default ResourcePlanner;
