import React, { ReactElement, forwardRef, Ref } from 'react';
import { PulseToggleButtonProps } from './pulse-toggle-button-types';
import styles from './pulse-toggle-button.module.scss';
import PulseButton from '../base/pulse-button-base';
import clsx from 'clsx';

export const PulseToggleButton = forwardRef<HTMLButtonElement, PulseToggleButtonProps>(
  (props: PulseToggleButtonProps, ref: Ref<HTMLButtonElement>): ReactElement => {
    const { on = false, classes = [], ...restProps } = props;

    const buttonClasses = [
      clsx(
        styles['pulse-button__toggle'],
        styles['pulse-button__toggle--overrides'],
        on && styles['pulse-button__toggle--active'],
        ...classes,
      ),
    ];

    return <PulseButton classes={buttonClasses} {...restProps} ref={ref} />;
  },
);
PulseToggleButton.displayName = 'PulseToggleButton';

export default PulseToggleButton;
