import React, { ReactElement, useEffect, useState } from 'react';
import { PulseStatusProgressProps, PulseProgressProps, VIEW } from './pulse-status-progress-types';
import styles from './pulse-status-progress.module.scss';
import clsx from 'clsx';
import Tippy, { useSingleton } from '@tippyjs/react';

const defaultSteps = ['Sent', 'Opened', 'Comments Made', 'Decision Made'];

export const PulseStatusProgress = (props: PulseStatusProgressProps): ReactElement => {
  const {
    classes = { root: '', container: '' },
    stepsData = defaultSteps,
    view = '',
    progress,
    deactiveColor = 'white',
    TippyProps = { placement: 'top', arrow: true },
  } = props;

  const [reviewViewColor, setReviewViewColor] = useState<string>('');

  const [progressStatus, setProgressStatus] = useState<PulseProgressProps[]>([]);
  const [source, target] = useSingleton({
    overrides: ['placement'],
  });

  useEffect(() => {
    const valueProgress = Object.values(progress);
    const combinesStatus: PulseProgressProps[] = stepsData.map((step, index) => ({
      stepName: step,
      isActive: valueProgress[index] || false,
    }));
    setProgressStatus(combinesStatus);
  }, [progress]);

  useEffect(() => {
    setBackgroundColor();
  }, [view]);

  const setBackgroundColor = () => {
    switch (view) {
      case VIEW.REVIEW.name:
        setReviewViewColor(VIEW.REVIEW.color);
        break;
      case VIEW.STAGE.name:
        setReviewViewColor(VIEW.STAGE.color);
        break;
      case VIEW.USER_DECISION.name:
        setReviewViewColor(VIEW.USER_DECISION.color);
        break;
      default:
        setReviewViewColor('');
    }
  };

  return (
    <div className={clsx(styles['pulse-status-progress__root'], classes?.root)}>
      <div className={clsx(styles['pulse-status-progress__step'])}>
        <Tippy singleton={source} />
        {progressStatus?.map((eachStep, index) => {
          return (
            <Tippy content={eachStep?.stepName} singleton={target} {...TippyProps} key={index}>
              <div
                className={clsx(
                  styles['pulse-status-progress-textbox'],
                  eachStep.isActive && styles['active'],
                  classes?.container,
                )}
                style={eachStep.isActive ? { backgroundColor: reviewViewColor } : { backgroundColor: deactiveColor }}
              >
                {eachStep?.stepName?.charAt(0).toUpperCase()}
              </div>
            </Tippy>
          );
        })}
      </div>
    </div>
  );
};

export default PulseStatusProgress;
