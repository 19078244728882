import clsx from 'clsx';
import PulseLabel from 'components/pulse-label/pulse-label';
import { PulseLabelProps } from 'components/pulse-label/pulse-label-types';
import isFunction from 'lodash/isFunction';
import { PulseClassName } from 'pulse-commons/types';
import React, { ReactElement, Ref } from 'react';
import Skeleton from 'react-loading-skeleton';
import mergeRefs from 'react-merge-refs';
import { OptionProps } from 'react-select';
import { OptionTypeBase, ValueType } from 'react-select/src/types';
import styles from './pulse-select-option.module.scss';
import { PulseAvatarProps } from 'components/pulse-avatar/pulse-avatar-types';
import { PulseSelectBasePropTypes, PulseSelectOptionType } from '../pulse-select-base-types';

const PulseSelectLoadingComponent = (): ReactElement => {
  return (
    <>
      <span className={styles['pulse-select-option__skeleton']}>
        <Skeleton height={12} width={120} />
      </span>
    </>
  );
};

const isEqual = (prevProps: PulseSelectOptionProps, nextProps: PulseSelectOptionProps) => {
  return (
    prevProps.isFocused === nextProps.isFocused &&
    prevProps.onClick === nextProps.onClick &&
    prevProps.data?.value === nextProps.data?.value &&
    prevProps.PulseLabelProps === nextProps.PulseLabelProps
  );
};

export interface PulseSelectOptionProps extends Partial<OptionProps<PulseSelectOptionType>> {
  classes?: {
    root?: PulseClassName;
    selected?: PulseClassName;
    removeButton?: PulseClassName;
  };
  forwardedRef?: Ref<HTMLDivElement>;
  isLoading?: boolean;
  isLoadingComponent?: React.Component | ReactElement;
  isValue?: boolean;
  PulseLabelProps?: Partial<PulseLabelProps>;
  PulseAvatarProps?: Partial<PulseAvatarProps>;
  removeable?: boolean;
  onClick?: (value: ValueType<OptionTypeBase>[]) => void;
  variant?: PulseSelectBasePropTypes['variant'];
}

const PulseSelectOption = (props: PulseSelectOptionProps, ref: Ref<HTMLDivElement>): ReactElement => {
  const {
    classes,
    data = {},
    forwardedRef,
    isFocused,
    isLoading,
    isLoadingComponent,
    isValue,
    label,
    onClick,
    PulseLabelProps,
    removeable = false,
    innerRef,
    variant,
  } = props;

  const handleClick = () => {
    isFunction(onClick) && onClick(data);
  };

  const { actionButtonProps, classes: PulseLabelClasses, ...restPulseLabelProps } = PulseLabelProps || {};

  const { classes: PulseLabelActionButtonClasses, ...restActionButtonProps } = actionButtonProps || {};

  const refArray = [ref];
  forwardedRef && refArray.push(forwardedRef);
  innerRef && refArray.push(innerRef);

  return (
    <div
      data-testid="pulse-select__defaultOption"
      className={clsx(
        styles['pulse-select-option__root'],
        isValue && clsx(styles['pulse-select-option__root--selected'], classes?.selected),
        isFocused && styles['pulse-select-option__root--focused'],
        classes?.root,
        removeable && styles['pulse-select-option__removeable'],
      )}
      ref={mergeRefs(refArray)}
      data-label={label}
      {...props.innerProps}
    >
      {!isLoading && (
        <PulseLabel
          actionButton={removeable}
          actionButtonProps={{
            ...restActionButtonProps,
            classes: {
              root: clsx(
                styles['pulse-select-option__removeButton'],
                PulseLabelActionButtonClasses?.root,
                classes?.removeButton,
              ),
              pulseIcon: {
                icon: 'fal fa-times',
              },
            },
            handleClick: handleClick,
          }}
          classes={{
            root: clsx(styles['pulse-select-option__pulseLabelRoot'], PulseLabelClasses?.root),
            labelText: clsx(
              styles['pulse-select-option__pulseLabelText'],
              variant === 'select2' && styles['pulse-select-option__pulseLabelText--select2'],
              PulseLabelClasses?.labelText,
            ),
          }}
          isShowFull
          key={data.value}
          label={data.label}
          wrapText={!isValue}
          {...restPulseLabelProps}
        />
      )}
      {isLoading && !isLoadingComponent && <PulseSelectLoadingComponent />}
      {isLoading && isLoadingComponent && isLoadingComponent}
    </div>
  );
};

export default React.memo<PulseSelectOptionProps>(
  React.forwardRef<HTMLDivElement, PulseSelectOptionProps>(PulseSelectOption),
  isEqual,
);
