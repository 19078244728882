import React, { FC } from 'react';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { Colors, IconSizes } from 'pulse-commons/types';
import styles from './resource-overbooked.module.scss';
import { ResourceOverbookedProps } from '../booking-types';
import { OverBookingList } from '../booking-types';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

const ResourceOverbookedModal: FC<ResourceOverbookedProps> = props => {
  const { isOpen, onCancel, onConfirm, userInfo, overBookingList } = props;

  return (
    <PulseModalBase
      variant="jqconfirm"
      open={isOpen}
      headerIconClasses="fal fa-exclamation-circle"
      PulseModalHeaderProps={{
        onCloseClick: onCancel,
        primaryText: 'RESOURCE OVERBOOKED',
        classes: {
          root: 'header-root',
          closeBtn: 'header-root__close-btn',
        },
      }}
      footer={false}
      onModalClose={onCancel}
    >
      <div className={styles['resource-overbooked-modal']}>
        <div className={styles['resource-overbooked__icon']}>
          <i className="fal fa-exclamation-circle"></i>
        </div>
        <div className={styles['resource-overbooked__heading']}>RESOURCE OVERBOOKED</div>

        <div className={styles['resource-overbooked__content']}>
          <p>
            <span>{userInfo?.label}</span> will be overbooked for the following days:
          </p>
          {overBookingList.map((booking: OverBookingList) => {
            return (
              <p key={booking.date} className={styles['overbook-date']}>
                <span>{format(parseISO(booking.date), 'dd/MM/yyyy')}:</span> {booking.overbooked_hours} hours overbooked
              </p>
            );
          })}
          <p>Would you like to modify the booking to prevent overbooking?</p>
        </div>
        <div className={styles['resource-overbooked__actions']}>
          <PulseButtonBase
            classes={[styles['resource-overbooked__button']]}
            label={'Save Booking'}
            onClick={onConfirm}
          />
          <PulseButtonBase
            classes={[styles['resource-overbooked__button']]}
            iconSize={IconSizes.sm}
            color={Colors.success}
            icon
            onClick={onCancel}
            label="Modify Booking"
            iconClasses={{
              icon: 'fal fa-pen',
            }}
          />
        </div>
      </div>
    </PulseModalBase>
  );
};

export default ResourceOverbookedModal;
