import { AccordionDetails } from '@mui/material';
import React, { ReactElement } from 'react';
import { PulseAccordionDetailsProps } from './pulse-accordion-details-types';
import styles from '../../pulse-accordion.module.scss';
import clsx from 'clsx';

export const PulseAccordionDetails = (props: PulseAccordionDetailsProps): ReactElement => {
  const { children, classes, ...restProps } = props;

  return (
    <div data-testid="pulseAccordionDetailsRoot" className={clsx(styles.pulseAccordionDetailsRoot, classes?.ctn)}>
      <AccordionDetails classes={classes} {...restProps}>
        {children}
      </AccordionDetails>
    </div>
  );
};

export default PulseAccordionDetails;
