import React, { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import PulseLinkedTask from 'components/pulse-linked-tasks-list/components/pulse-linked-task/pulse-linked-task';
import { TaskOptionType } from 'components/pulse-resource-planner/components/booking-types';

type Props = {
  linkedTasks: Array<TaskOptionType>;
  onUnlinkTask?: (ticketId: string | number) => Promise<Array<TaskOptionType>>;
};

export const PulseTimelineGanttLinkedTaskTab: FC<Props> = props => {
  const { linkedTasks, onUnlinkTask } = props;
  const [tasks, setTasks] = useState(linkedTasks);

  const handleUnlinkTask = async (ticketId: number) => {
    if (!onUnlinkTask) {
      return;
    }
    const updated = await onUnlinkTask(ticketId);
    setTasks(updated);
  };

  useEffect(() => {
    if (!isEqual(linkedTasks, tasks)) {
      setTasks(linkedTasks);
    }
  }, [linkedTasks]);

  return (
    <div>
      {tasks?.map(it => (
        <PulseLinkedTask
          linkedTask={it}
          key={it.raw?.ticketid}
          iconRemove="fal fa-unlink"
          onRemove={handleUnlinkTask}
        />
      ))}
    </div>
  );
};
