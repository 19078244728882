import clsx from 'clsx';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import PulseSelectOption, { PulseSelectOptionProps } from 'components/pulse-select/base/components/pulse-select-option';
import PulseLoadingOption from 'components/pulse-select/commons/pulse-loading-option';
import React, { ReactElement, Ref, useMemo } from 'react';
import styles from './pulse-brand-option.module.scss';

type PulseRoleOptionProps = PulseSelectOptionProps;

export const PulseRoleOption = (props: PulseRoleOptionProps, ref: Ref<HTMLDivElement>): ReactElement => {
  const { classes, data = {}, isValue = false, PulseLabelProps, removeable } = props;

  const { classes: PulseLabelClasses } = PulseLabelProps || {};

  /**
   * We want to pass a custom PulseLabelProps
   */
  const internalPulseLabelProps: Partial<typeof PulseLabelProps> = {
    classes: {
      ...PulseLabelClasses,
      root: clsx(styles['pulse-brand-option__pulseLabelRoot'], PulseLabelClasses?.root),
    },
    icon: true,
    iconComponent: (
      <PulseIcon classes={{ root: styles['pulse-brand-option__icon'], icon: 'fal fa-copyright' }} iconName="" />
    ),
    isShowFull: !isValue,
    label: data?.label,
    ...PulseLabelProps,
  };

  return (
    <PulseSelectOption
      {...props}
      classes={{
        ...classes,
        root: clsx([
          classes?.root,
          styles['pulse-brand-option__root'],
          removeable && styles['pulse-brand-option__removeable'],
        ]),
      }}
      forwardedRef={ref}
      PulseLabelProps={useMemo(() => internalPulseLabelProps, [PulseLabelProps])}
      isLoadingComponent={<PulseLoadingOption icon="fal fa-copyright" />}
    />
  );
};

export default React.forwardRef<HTMLDivElement, PulseRoleOptionProps>(PulseRoleOption);
