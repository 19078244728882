import React, { useState, useEffect } from 'react';
import { MapContainer, useMap } from 'react-leaflet';
import { CRS, LatLngExpression, DomEvent, DomUtil, Control } from 'leaflet';
import clsx from 'clsx';
import { createPortal } from 'react-dom';

import PulseIconButton from '../../pulse-icon-button/pulse-icon-button';
import { Colors, IconSizes } from 'pulse-commons/types';
import { PulseImageViewerMapProps, PulseImageViewerCustomControlProps } from '../pulse-image-viewer-types';
import { PulseIconButtonProps } from '../../pulse-icon-button/pulse-icon-button-types';
import styles from '../pulse-image-viewer.module.scss';

const POSITION: LatLngExpression = [0, 0];
const ZOOM_DELTA = 0.2;
const ZOOM_CONTROL_POSITION = 'bottomright';
const DISABLED_ZOOM_PROPS = {
  doubleClickZoom: false,
  dragging: false,
  closePopupOnClick: false,
  touchZoom: false,
  trackResize: false,
  scrollWheelZoom: false,
  zoomSnap: false,
  zoomDelta: false,
};
const CUSTOM_CONTROL_CTN_NAME = 'leaflet-control-custom';
const CUSTOM_CONTROL_ELE_TYPE = 'div';

const handleButtonZoom = (zoomLevel: number, setZoom: React.Dispatch<React.SetStateAction<number>>) => (): void => {
  setZoom(zoomLevel);
};

const CustomControl = ({ position, zoom, children }: PulseImageViewerCustomControlProps) => {
  const map = useMap();

  const controlContainer = DomUtil.create(CUSTOM_CONTROL_ELE_TYPE, CUSTOM_CONTROL_CTN_NAME);

  useEffect(() => {
    DomEvent.disableClickPropagation(controlContainer);
    DomEvent.disableScrollPropagation(controlContainer);

    const customControl = new Control({
      position: position,
    });

    customControl.onAdd = function () {
      return controlContainer;
    };

    customControl.addTo(map);

    return () => {
      map.removeControl(customControl);
    };
  }, [map, controlContainer, position]);

  useEffect(() => {
    map.setZoom(zoom);
  }, [map, zoom]);

  return createPortal(children, controlContainer);
};

const PulseImageViewerMap = (props: PulseImageViewerMapProps): React.ReactElement => {
  /* istanbul ignore next */
  const { allowZoom, bounds, children, classes = {}, ...restProps } = props;

  const [zoom, setZoom] = useState(0);

  const mapAdditionalProps = allowZoom ? {} : DISABLED_ZOOM_PROPS;

  /* istanbul ignore next */
  const zoomButtonClasses: PulseIconButtonProps['classes'] = {
    ...classes.zoomControl,
    pulseIcon: {
      ...classes.zoomControl?.pulseIcon,
      root: clsx(styles['pulse-image-viewer__zoom-button-icon'], classes.zoomControl?.pulseIcon?.root),
    },
    label: clsx(styles['pulse-image-viewer__zoom-button-label'], classes.zoomControl?.label),
    root: clsx(styles['pulse-image-viewer__zoom-button'], classes.zoomControl?.root),
  };

  /* istanbul ignore next */
  return (
    <MapContainer
      attributionControl={false}
      bounds={bounds}
      center={POSITION}
      className={clsx(styles['pulse-image-viewer__map'], classes.imageCtn)}
      crs={CRS.Simple}
      maxZoom={10}
      minZoom={-10}
      zoom={zoom}
      zoomControl={false}
      zoomSnap={0}
      {...mapAdditionalProps}
      {...restProps}
    >
      {children}
      {allowZoom && (
        <CustomControl position={ZOOM_CONTROL_POSITION} zoom={zoom}>
          <PulseIconButton
            handleClick={handleButtonZoom(zoom + ZOOM_DELTA, setZoom)}
            color={Colors.default}
            classes={{
              ...zoomButtonClasses,
              pulseIcon: {
                icon: 'fal fa-plus-circle',
                ...zoomButtonClasses.pulseIcon,
              },
              root: clsx(styles['pulse-image-viewer__zoom-button--zoom-in'], zoomButtonClasses.root),
            }}
            size={IconSizes.sm}
          />
          <PulseIconButton
            handleClick={handleButtonZoom(zoom - ZOOM_DELTA, setZoom)}
            color={Colors.default}
            classes={{
              ...zoomButtonClasses,
              pulseIcon: {
                icon: 'fal fa-minus-circle',
                ...zoomButtonClasses.pulseIcon,
              },
              root: clsx(styles['pulse-image-viewer__zoom-button--zoom-out'], zoomButtonClasses.root),
            }}
            size={IconSizes.sm}
          />
        </CustomControl>
      )}
    </MapContainer>
  );
};

export default PulseImageViewerMap;
