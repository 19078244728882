import React, { FC } from 'react';
import { InfoTypes, PulseInfoBarProps } from './pulse-info-bar-types';
import 'tippy.js/dist/tippy.css';
import styles from './pulse-info-bar-modal.module.scss';
import clsx from 'clsx';

const getIconsName = (infoType: InfoTypes) => {
  switch (infoType) {
    case 'success':
      return 'fal fa-check-circle';
    case 'warning':
      return 'fal fa-exclamation-circle';
    case 'error':
      return 'fal fa-exclamation-triangle';
    default:
      return 'fal fa-info-circle';
  }
};

const PulseInfoBar: FC<PulseInfoBarProps> = props => {
  const { classes, infoType, children, title } = props;

  return (
    <div className={clsx(styles[`info-bar__main-ctn`], classes?.root)}>
      <div
        className={clsx([
          styles['info-bar__main-ctn__icon-wrapper'],
          styles[`info-bar__main-ctn__icon-wrapper__${infoType}`],
          classes?.iconCtn,
        ])}
      >
        <i className={getIconsName(infoType)} />
      </div>
      <div
        className={clsx([
          styles['info-bar__main-ctn__content'],
          styles[`info-bar__main-ctn__content__${infoType}`],
          classes?.contentCtn,
        ])}
      >
        <div className={styles['info-bar__main-ctn__content__header']}>{title} </div>
        <div className={styles['info-bar__main-ctn__content__description']}>{children}</div>
      </div>
    </div>
  );
};

PulseInfoBar.displayName = 'PulseInfoBar';

export default PulseInfoBar;
