import React, { ReactElement } from 'react';
import styles from './booking-base-modal.module.scss';
import PulseModalHeader from 'components/pulse-modal-base/components/pulse-modal-header/pulse-modal-header';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import BookingBaseModalTypes from './booking-base-modal-types';

const BookingBaseModal = (props: BookingBaseModalTypes): ReactElement => {
  const { headerIcon, PulseModalHeaderProps, PulseModalBaseProps, children } = props;
  return (
    <PulseModalBase
      {...PulseModalBaseProps}
      headerComponent={
        <PulseModalHeader
          {...PulseModalHeaderProps}
          classes={{
            root: styles['booking-modal__headerRoot'],
            closeBtn: styles['booking-modal__headerCloseBtn'],
            titlePrimary: styles['booking-modal__headerTitlePrimary'],
          }}
          closeButtonTooltip={''}
          headerIconClasses={`${headerIcon} ${styles['booking-modal__headerIcon']}`}
        />
      }
      classes={{
        root: [styles['booking-modal__root']],
        dialog: {
          root: styles['booking-modal__dialogRoot'],
          paper: styles['booking-modal__dialogPaper'],
        },
      }}
      allowClose
    >
      <div className={styles['booking-modal__ctn']}>{children}</div>
    </PulseModalBase>
  );
};

export default BookingBaseModal;
