import React, { PropsWithChildren, ReactElement } from 'react';
import { PulseIconButtonProps } from './pulse-icon-button-types';
import PulseIcon from '../pulse-icons/pulse-icons';
import { ButtonVariants, IconSizes } from 'pulse-commons/types';
import './pulse-icon-button.scss';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import clsx from 'clsx';

const isEqual = (
  prevState: PropsWithChildren<PulseIconButtonProps>,
  nextState: PropsWithChildren<PulseIconButtonProps>,
): boolean => {
  for (const key in prevState) {
    if (prevState[key] !== nextState[key]) {
      return false;
    }
  }
  return true;
};

export const PulseIconButton = React.memo<PulseIconButtonProps>((props: PulseIconButtonProps): ReactElement => {
  const {
    classes,
    color,
    disabled,
    iconName = '',
    handleClick,
    size = IconSizes.lg,
    tooltip: tooltipContent,
    variant = ButtonVariants.outlined,
    type = 'button',
    TippyProps,
    ...rest
  } = props;

  const rootClassNames = clsx('pulse-icon-button__ctn', classes?.root, color, variant);

  const labelClassNames = clsx('pulse-icon-button__label', classes?.label);

  const tooltip = tooltipContent || TippyProps?.content || '';

  return (
    <Tippy className="pulse-icon__tippy" content={tooltip} disabled={!tooltip} {...TippyProps}>
      <button
        data-testid="pulse-icon-button"
        className={rootClassNames}
        onClick={handleClick}
        disabled={disabled}
        type={type}
        {...rest}
      >
        <PulseIcon classes={classes?.pulseIcon} iconName={iconName} size={size} />
        <span className={labelClassNames}>{tooltip}</span>
      </button>
    </Tippy>
  );
}, isEqual);

PulseIconButton.displayName = 'PulseIconButton';

export default PulseIconButton;
