import React, { FC, ReactElement } from 'react';
import PulseSelectBase from '../base/pulse-select-base';
import {
  PulseSelectPropTypes,
  PulseSelectBasePropTypes,
  PulseSelectDataStructureTypes,
  PulseSelectOptionType,
} from '../base/pulse-select-base-types';
import { OptionProps, ValueType } from 'react-select';
import PulseTimesheetActivityOption from './components/pulse-tasks-select-option';
import isFunction from 'lodash/isFunction';

const DATA_STRUCTURE: PulseSelectDataStructureTypes = {
  dataKey: 'data',
  label: 'title',
  lastPage: 'meta.page.last_page',
  type: 'task',
  value: 'ticketid',
  isJsonApi: true,
  pageSize: 25,
  searchKey: 'or.title.ticketid',
};

const DEFAULT_PLACEHOLDER: PulseSelectBasePropTypes['placeholder'] = 'Search task id or task title';

const DEFAULT_URL = `/v2/api/tickets`;

export const PulseTasksSelect = (props: PulseSelectPropTypes): ReactElement => {
  const {
    value,
    isMulti = false,
    labelName = 'Select Task',
    placeholder = DEFAULT_PLACEHOLDER,
    requestFilterKey = 'ticketid',
    url = DEFAULT_URL,
    valueChangeHandler,
  } = props;

  const handleValueChange: PulseSelectPropTypes['valueChangeHandler'] = (
    value: ValueType<PulseSelectOptionType>,
  ): void => {
    isFunction(valueChangeHandler) &&
      valueChangeHandler({
        [requestFilterKey]: value,
      });
  };

  return (
    <PulseSelectBase
      dataStructure={DATA_STRUCTURE}
      isMulti={isMulti}
      components={{
        Option: PulseTimesheetActivityOption as FC<OptionProps<PulseSelectOptionType>>,
      }}
      isDisabled={!url}
      labelName={labelName}
      placeholder={placeholder}
      url={url}
      value={value}
      changeHandler={handleValueChange}
      {...props}
    />
  );
};

export default React.memo(PulseTasksSelect);
