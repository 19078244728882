import React, { ReactElement, useEffect, useState } from 'react';
import { PulseColorData, PulseColorPickerProps, PulseColorSwatchProps } from './pulse-color-picker-types';
import styles from './pulse-color-picker.module.scss';
import PulseMenu from 'components/pulse-menu/pulse-menu';
import clsx from 'clsx';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import defaultColorData from './pulse-color-picker-colors.json';
import { ucFirst } from 'pulse-commons/helpers';

const PulseColorSwatch = (props: PulseColorSwatchProps): ReactElement => {
  const { classes, colorsData = defaultColorData, onSelect, selectedColor } = props;

  const onClickTile = (color: PulseColorData) => () => {
    onSelect(color);
  };

  useEffect(() => {
    colorsData.map((color: PulseColorData) => {
      !selectedColor && color.default && onSelect(color);
    });
  }, [selectedColor]);

  return (
    <div className={clsx(styles['pulse-color-swatch__root'], classes?.root)}>
      {colorsData.map((color: PulseColorData) => {
        return (
          <span
            key={color.name}
            className={clsx(
              styles['pulse-color-swatch__tile'],
              color.colorCode === selectedColor?.colorCode && styles['pulse-color-swatch__tile--active'],
              classes?.tile,
            )}
            title={ucFirst(color.name)}
            style={{
              backgroundColor: color.colorCode,
            }}
            onClick={onClickTile(color)}
          ></span>
        );
      })}
    </div>
  );
};

export const PulseColorPicker = (props: PulseColorPickerProps): ReactElement => {
  const {
    classes,
    PulseButtonProps,
    PulseColorSwatchProps,
    PulseMenuProps,
    disabled = false,
    placeholder = 'select a color',
    labelName = 'color',
    variant,
  } = props;

  const { onSelect, selectedColor, ...restPulseColorSwatchProps } = PulseColorSwatchProps;

  const [isVisible, setIsVisible] = useState(false);

  const toogleVisible = () => {
    !disabled && setIsVisible(!isVisible);
  };

  const handleTileSelect = (color: PulseColorData) => {
    onSelect(color);
    setIsVisible(false);
  };

  return (
    <div className={clsx([styles['pulse-color-picker__root'], classes?.root])}>
      {labelName && <label className={clsx(styles['pulse-color-picker__label'], classes?.label)}>{labelName}</label>}
      <PulseMenu
        classes={{
          childrenRoot: styles['pulse-color-picker__menuChildren'],
          menuCtn: styles['pulse-color-picker__menuCtn'],
        }}
        {...PulseMenuProps}
        TippyProps={{
          placement: 'bottom-end',
          visible: isVisible,
          onClickOutside: toogleVisible,
          ...PulseMenuProps?.TippyProps,
        }}
        menuChildren={
          <>
            <PulseColorSwatch
              {...restPulseColorSwatchProps}
              onSelect={handleTileSelect}
              selectedColor={selectedColor}
            />
          </>
        }
      >
        {variant === 'colorPickerV2' ? (
          <PulseButtonBase
            onClick={toogleVisible}
            classes={[
              clsx(
                styles['pulse-color-picker__button'],
                styles['pulse-color-picker__button--v2'],
                disabled && styles['pulse-color-picker__button-v2--disable'],
                classes?.button,
              ),
            ]}
            icon
            iconPosition="right"
            label={selectedColor ? '' : placeholder}
            style={{ background: selectedColor?.colorCode }}
            {...PulseButtonProps}
          >
            <div className={clsx(styles['pulse-color-picker__selectCtn-v2'])} onClick={toogleVisible}>
              <div className={styles['selectCtn-v2-col']}>
                <span
                  className={styles['selectCtn-v2__selected']}
                  style={{
                    backgroundColor: selectedColor?.colorCode,
                  }}
                />
                <span>{selectedColor?.name}</span>
              </div>
              <div className={styles['selectCtn-v2-col']}>
                <i className={clsx('pulse-icon fal fa-chevron-down pulse-icon--md', classes?.buttonIcon)} />
              </div>
            </div>
          </PulseButtonBase>
        ) : (
          <PulseButtonBase
            onClick={toogleVisible}
            classes={[clsx(styles['pulse-color-picker__button'], classes?.button)]}
            icon
            iconPosition="right"
            label={selectedColor ? '' : placeholder}
            style={{ background: selectedColor?.colorCode }}
            iconClasses={{
              icon: clsx('fal fa-chevron-down', classes?.buttonIcon),
              root: styles['pulse-color-picker__button-icon'],
            }}
            {...PulseButtonProps}
          />
        )}
      </PulseMenu>
    </div>
  );
};

export default PulseColorPicker;
