import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { PulseListSelectorProps } from './pulse-list-selector-types';
import Checkbox from '../checkbox/checkbox';
import PulseSearch from '../search/pulse-search';
import { InputTypes, PulseSearchPropTypes } from '../search/pulse-search-types';
import styles from './pulse-list-selector.module.scss';
import get from 'lodash/get';
import PulseListSelectorItemDraggable from '../pulse-list-selector-item/pulse-list-selector-item-draggable';
import PulseListItem from '../pulse-list-selector-item/pulse-list-selector-item';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import clsx from 'clsx';
import noop from 'lodash/noop';
import PulseListSelectorBulkAddProjectModal from './pulse-bulk-add-projects-modal';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { ButtonVariants } from 'pulse-commons/types';

const DEFAULT_PULSE_SEARCH_PROPS: PulseSearchPropTypes = {
  placeholder: 'Search job title or job extension number',
  type: InputTypes.text,
  onChangeHandler: noop,
};

export const PulseListSelector = (props: PulseListSelectorProps): ReactElement => {
  const {
    allowSelectAll,
    classes = {},
    data,
    isSelectAll,
    itemSize = 60,
    listHeaderText,
    onSelectAll,
    PulseListSelectorItemProps = { selectable: false },
    PulseSearchProps = DEFAULT_PULSE_SEARCH_PROPS,
    PulseListBulkAddProjectModalProps,
    SelectAllCheckboxProps,
    searchable = false,
    selectLimit,
    allowDropAndDrag = false,
    onDragEnd,
  } = props;

  const [showBulkPrjModal, setShowBulkPrjModal] = useState<boolean>(false);

  const searchProps = {
    ...DEFAULT_PULSE_SEARCH_PROPS,
    ...PulseSearchProps,
  };

  const pulseListClasses = clsx([styles['pulse-list__root'], ...get(classes, 'root', [])]);

  const selectAllCheckboxProps = {
    showLabel: true,
    label: 'Select All',
    labelPlacement: 'left',
    onClick: onSelectAll,
    checked: isSelectAll,
    ...SelectAllCheckboxProps,
  };

  const totalSelected = data.filter(project => project.selected).length;
  const itemData = {
    data: data.map(item => ({
      ...item,
      selectable:
        (PulseListSelectorItemProps.selectable || allowSelectAll) &&
        (selectLimit && Number.isInteger(selectLimit) ? totalSelected < selectLimit || item.selected : true),
    })),
    ...PulseListSelectorItemProps,
  };

  /**
   * Reorder items when drag was end
   * Should be dispatch or call parent actions
   */
  const handleDragEnd = useCallback(
    (dropResult: DropResult) => {
      // Dropped outside the list
      if (!dropResult.destination) {
        return;
      }

      const nextItems = [...data];
      const [removed] = nextItems.splice(dropResult.source.index, 1);
      nextItems.splice(dropResult.destination.index, 0, removed);

      onDragEnd && onDragEnd(nextItems);
    },
    [data],
  );

  const handleShowBulkPrjModal = useCallback(() => setShowBulkPrjModal(true), []);

  const handleSearchJobs = (jobCodes: Array<string>) => {
    const { onSearchJobs } = PulseListBulkAddProjectModalProps || {};
    try {
      onSearchJobs && onSearchJobs(jobCodes);
    } catch (e) {
      setShowBulkPrjModal(false);
    }
  };

  let ItemSelector: ReactNode = null;

  if (data && data.length) {
    // Enable drop and drag
    ItemSelector = allowDropAndDrag ? (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {provided => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { data: itemData, classes, ...rest } = PulseListSelectorItemProps?.data || {};
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {data.map((it, index) => (
                  <PulseListSelectorItemDraggable
                    classes={{
                      ...classes,
                      root: clsx(styles['pulse-list__body-list__item'], classes?.root),
                      primaryText: clsx(styles['pulse-list__body-list__item__primary'], classes?.primaryText),
                    }}
                    key={it.id}
                    index={index}
                    data={it}
                    {...rest}
                  />
                ))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    ) : (
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList itemData={itemData} height={height} width={width} itemCount={data.length} itemSize={itemSize}>
            {PulseListItem}
          </FixedSizeList>
        )}
      </AutoSizer>
    );
  } else {
    ItemSelector = <div className={styles['pulse-list__empty']}>No Result Found</div>;
  }

  return (
    <div
      data-testid="pulse-list__ctn"
      className={clsx(styles['pulse-list__ctn'], allowDropAndDrag ? styles['pulse-list__ctn--dragable'] : '')}
    >
      <div className={pulseListClasses}>
        <div className={styles['pulse-list__heading']}>
          <h2 className={styles['pulse-list__heading-title']}>{listHeaderText}</h2>
          {allowSelectAll && (selectLimit && Number.isInteger(selectLimit) ? !(selectLimit < data.length) : true) && (
            <div data-testid="pulse-list__select-all" className={styles['pulse-list__heading-select-all']}>
              <Checkbox {...selectAllCheckboxProps} />
            </div>
          )}
        </div>
        <div className="pulse-list__body">
          {searchable && <PulseSearch {...searchProps} />}
          {Boolean(PulseListBulkAddProjectModalProps) && (
            <div className={styles['pulse-list__body-bulk-actions']}>
              <PulseButtonBase
                classes={[
                  styles['pulse-list__body-bulk-actions__item'],
                  PulseListBulkAddProjectModalProps?.classNames?.bulkButton || '',
                ]}
                type="button"
                variant={ButtonVariants.text}
                onClick={handleShowBulkPrjModal}
              >
                <i className="fas fa-plus" />
                Bulk Add Projects
              </PulseButtonBase>
            </div>
          )}
          <div className={styles['pulse-list__body-list']} data-dragable={allowDropAndDrag}>
            {ItemSelector}
          </div>
        </div>
      </div>
      {showBulkPrjModal && (
        <PulseListSelectorBulkAddProjectModal
          data={data}
          onSearchJobs={handleSearchJobs}
          onCloseModal={() => setShowBulkPrjModal(false)}
          onConfirmAddProjects={PulseListBulkAddProjectModalProps?.onConfirmAddProjects}
        />
      )}
    </div>
  );
};

export default PulseListSelector;
