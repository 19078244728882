import { CycleResolutionPopup } from '@bryntum/schedulerpro-thin';

export class CustomCycleResolutionPopup extends CycleResolutionPopup {
  constructor() {
    super();
    this.setConfig({
      id: 'pulse-timeline-gantt-cycle-resolution-modal',
      // root class
      cls: 'gantt-chart-cycle-resolution-modal',
      // body class
      bodyCls: 'gantt-chart-cycle-resolution-modal__body',
      // footer class
      bbar: {
        cls: 'gantt-chart-cycle-resolution-modal__footer',
      },
      // add icon to tbar
      tools: {
        headerIcon: {
          cls: 'header-icon',
          html: '<i class="fal fa-exclamation-circle"></i>',
          containsFocus: false,
          //class ranking cannot apply so inline here to override css of Bryntum
          style: 'background: none',
        },
      },
      header: 'SCHEDULING CYCLE: INVALID DEPENDANCIES',
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async resolve(event) {
    const result = super.resolve(event);
    const self = this as Record<string, any>;
    // change dependency combo label & placeholder
    if ('dependencyField' in this.widgetMap && self?.widgetMap?.dependencyField) {
      self.widgetMap.dependencyField.label = 'DEPENDENCY';
      self.widgetMap.dependencyField.placeholder = 'Select Dependency...';
    }
    // change invalidDependenciesDescription
    if ('invalidDependenciesDescription' in this.widgetMap && self?.widgetMap?.invalidDependenciesDescription) {
      self.widgetMap.invalidDependenciesDescription.content =
        'There are invalid dependencies involved that need to be addressed:';
    }
    // modify description
    if ('description' in this.widgetMap && self?.widgetMap?.description) {
      const cycleResolutiondescriptionArray = self.widgetMap.description.content?.split(':');
      if (cycleResolutiondescriptionArray?.length === 2) {
        const anounceText = cycleResolutiondescriptionArray[0];
        const depedencyIssuesText = cycleResolutiondescriptionArray[1];
        const cycleResolutiondescriptionHtml = `${anounceText} :<p><b>${depedencyIssuesText}</b></p>`;
        self!.widgetMap!.description!.content = cycleResolutiondescriptionHtml;
        self.widgetMap.description.content = cycleResolutiondescriptionHtml;
      }
    }
    return result;
  }
}
