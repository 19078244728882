import {
  ARCHIVE_TIMESHEET_ERROR_ITEM_SUCCESS,
  ARCHIVE_TIMESHEET_ERROR_ITEM_ERROR,
  CLEAR_FILTERS,
  FETCHING_TIMESHEET_ERROR_TYPES_DEMO,
  FETCHING_TIMESHEET_ERROR_ITEMS,
  FETCHING_TIMESHEET_ERROR_ITEMS_SUCCESS,
  FETCHING_TIMESHEET_ERROR_ITEMS_ERROR,
  FETCHING_TIMESHEET_ERROR_ITEMS_DEMO,
  FILTERING_TIMESHEET_ERROR_ITEMS,
  TOGGLE_TIMESHEET_ERROR_ITEM,
  RESUBMIT_TIMESHEET_ERROR_ITEM,
  RESUBMIT_TIMESHEET_ERROR_ITEM_ERROR,
  RESUBMIT_TIMESHEET_ERROR_ITEM_SUCCESS,
  FETCHING_TIMESHEET_ERROR_TYPES_SUCCESS,
} from './action-definitions';
import { TimesheetErrorReportStoreStateType, TimesheetErrorReportActionType, Filters } from './types';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

const initialTimesheetErrorReportState: TimesheetErrorReportStoreStateType = {
  selected: {},
  appliedFilters: {},
  timesheetErrorTypes: [],
};

export const TimesheetErrorReportReducer = (
  state = initialTimesheetErrorReportState,
  { type, payload }: TimesheetErrorReportActionType,
): TimesheetErrorReportStoreStateType => {
  switch (type) {
    case ARCHIVE_TIMESHEET_ERROR_ITEM_ERROR:
    case ARCHIVE_TIMESHEET_ERROR_ITEM_SUCCESS:
      return {
        ...state,
        selected: {},
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        ...payload.appliedFilters,
      };
    case FETCHING_TIMESHEET_ERROR_TYPES_SUCCESS:
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          ...payload.meta.appliedFilters,
        },
        timesheetErrorTypes: [...payload.timesheetErrorTypes],
      };
    case FETCHING_TIMESHEET_ERROR_TYPES_DEMO:
      return {
        ...state,
        timesheetErrorTypes: [...payload.timesheetErrorTypes],
      };
    case FETCHING_TIMESHEET_ERROR_ITEMS:
      return {
        ...state,
        timesheetErrorTypes: [
          ...state.timesheetErrorTypes.map(timesheetErrorType => {
            return timesheetErrorType.id == payload.errorTypeId
              ? {
                  ...timesheetErrorType,
                  isLoading: true,
                }
              : { ...timesheetErrorType };
          }),
        ],
      };
    case FETCHING_TIMESHEET_ERROR_ITEMS_SUCCESS:
      return {
        ...state,
        timesheetErrorTypes: [
          ...state.timesheetErrorTypes.map(timesheetErrorType => {
            return timesheetErrorType.id == payload.errorTypeId
              ? {
                  ...timesheetErrorType,
                  timesheetErrorItems: { ...payload.timesheetErrorItems },
                  isLoading: false,
                }
              : {
                  ...timesheetErrorType,
                };
          }),
        ],
        appliedFilters: {
          ...state.appliedFilters,
          ...payload.appliedFilters,
          userId: payload.appliedFilters.userId
            ? payload.appliedFilters.userId.map((user: any) => {
                return {
                  value: user.id,
                  label: user.text,
                };
              })
            : [],
        },
      };
    case FETCHING_TIMESHEET_ERROR_ITEMS_ERROR:
      return {
        ...state,
      };
    case FETCHING_TIMESHEET_ERROR_ITEMS_DEMO:
      return {
        ...state,
        timesheetErrorTypes: [
          ...state.timesheetErrorTypes.map(timesheetErrorType => {
            if (timesheetErrorType.id == payload.errorTypeId) {
              return {
                ...timesheetErrorType,
                timesheetErrorItems: { ...payload.timesheetErrorItems },
                isLoading: false,
              };
            } else {
              return {
                ...timesheetErrorType,
                timesheetErrorItems: { ...payload.timesheetErrorItems },
              };
            }
          }),
        ],
        appliedFilters: {
          ...state.appliedFilters,
          ...payload.appliedFilters,
        },
      };
    case FILTERING_TIMESHEET_ERROR_ITEMS:
      let newAppliedFilters: Filters = { ...state.appliedFilters };
      /** This formats the date value to the desired value for server processing */
      if (payload.filters.hasOwnProperty('startDate')) {
        !payload.filters.startDate && delete newAppliedFilters.startDate;
        isValid(payload.filters.startDate) &&
          (payload.filters.startDate = format(payload.filters.startDate, 'yyyy-MM-dd'));
      }
      /** This formats the date value to the desired value for server processing */
      if (payload.filters.hasOwnProperty('endDate')) {
        !payload.filters.endDate && delete newAppliedFilters.endDate;
        isValid(payload.filters.endDate) && (payload.filters.endDate = format(payload.filters.endDate, 'yyyy-MM-dd'));
      }

      for (const key in payload.filters) {
        if (Array.isArray(payload.filters[key]) && !payload.filters[key].length) {
          delete newAppliedFilters[key];
        } else {
          newAppliedFilters = {
            ...newAppliedFilters,
            ...payload.filters,
          };
        }
      }
      return {
        ...state,
        appliedFilters: {
          ...newAppliedFilters,
        },
      };
    case RESUBMIT_TIMESHEET_ERROR_ITEM:
      return {
        ...state,
        selected: {},
        timesheetErrorTypes: [
          ...state.timesheetErrorTypes.map(timesheetErrorType => {
            if (!payload.errorTypeIds.includes(timesheetErrorType.id)) {
              return timesheetErrorType;
            }
            return {
              ...timesheetErrorType,
              timesheetErrorItems: {
                ...timesheetErrorType.timesheetErrorItems,
                data: [
                  ...(timesheetErrorType.timesheetErrorItems.data || []).map(timesheetErrorItem => {
                    if (payload.submissionIds.includes(timesheetErrorItem.submission_id)) {
                      return {
                        ...timesheetErrorItem,
                        isResubmitting: true,
                      };
                    } else {
                      return timesheetErrorItem;
                    }
                  }),
                ],
              },
            };
          }),
        ],
      };
    case RESUBMIT_TIMESHEET_ERROR_ITEM_SUCCESS:
      return {
        ...state,
        selected: {},
        timesheetErrorTypes: [
          ...state.timesheetErrorTypes.map(timesheetErrorType => {
            return {
              ...timesheetErrorType,
              timesheetErrorItems: {
                ...timesheetErrorType.timesheetErrorItems,
                data: [
                  ...(timesheetErrorType.timesheetErrorItems.data || []).filter(timesheetErrorItem => {
                    return timesheetErrorItem.submission_id !== payload.submissionId;
                  }),
                ],
              },
            };
          }),
        ],
      };
    case RESUBMIT_TIMESHEET_ERROR_ITEM_ERROR:
      return {
        ...state,
        selected: {},
      };
    case TOGGLE_TIMESHEET_ERROR_ITEM:
      let newSelectedArray = {};
      payload.selected &&
        (newSelectedArray = {
          ...state.selected,
          [payload.errorTypeId]: state.selected[payload.errorTypeId]
            ? [...state.selected[payload.errorTypeId], payload.errorId]
            : [payload.errorId],
        });
      !payload.selected &&
        (newSelectedArray = {
          ...state.selected,
          [payload.errorTypeId]: [
            ...state.selected[payload.errorTypeId].filter(
              selectedTimesheetErrorItem => selectedTimesheetErrorItem !== payload.errorId,
            ),
          ],
        });
      return {
        ...state,
        selected: { ...newSelectedArray },
        timesheetErrorTypes: [
          ...state.timesheetErrorTypes.map(timesheetErrorType => {
            if (timesheetErrorType.id == payload.errorTypeId) {
              return {
                ...timesheetErrorType,
                timesheetErrorItems: {
                  ...timesheetErrorType.timesheetErrorItems,
                  data: [
                    ...(timesheetErrorType.timesheetErrorItems.data || []).map(timesheetErrorItem => {
                      if (timesheetErrorItem.id == payload.errorId) {
                        return {
                          ...timesheetErrorItem,
                          selected: payload.selected,
                        };
                      } else {
                        return timesheetErrorItem;
                      }
                    }),
                  ],
                },
              };
            } else {
              return timesheetErrorType;
            }
          }),
        ],
      };
    default:
      return state;
  }
};
