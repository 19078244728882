import { IXYAxis } from '@amcharts/amcharts5/.internal/charts/xy/series/XYSeries';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import clsx from 'clsx';
import am5 from 'components/amchart5/pcs-amchart-base';
import React, { ReactElement, useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { BulletTemplateColumn, PulseBulletChartProps, RangeChart } from './pulse-bullet-chart-types';
import styles from './pulse-bullet-chart.module.scss';

const ROOT_ID_CHART = 'bulletChart';
const MAX = 100;
const LIMIT_COLOR = '#E15B5D';
const TARGET_COLOR = '#53A92C';
const CURRENT_COLOR = '#EAAC6D';
const CURRENT_LINE_COLOR = '#31718E';
const TARGET_LINE_COLOR = '#EAAC6D';
const LIMIT_LINE_COLOR = '#E15B5D';
const REST_COLOR_CURRENT = '#EAAC6D';
const REST_COLOR_TARGET = '#53A92C';
const REST_COLOR_LIMIT = '#E15B5D';

const RANGE_LABEL_COLOR = am5.color(0x8d8d8d);
const SERIES_DEFAULT = {
  colorCurrent: CURRENT_COLOR,
  colorLimit: LIMIT_COLOR,
  colorTarget: TARGET_COLOR,
  colorRestCurrent: REST_COLOR_CURRENT,
  colorRestTarget: REST_COLOR_TARGET,
  colorRestLimit: REST_COLOR_LIMIT,
};
const TARGET_RANGE_DEFAULT: { color: string } & RangeChart = {
  color: TARGET_LINE_COLOR,
};
const LIMIT_RANGE_DEFAULT: { color: string } & RangeChart = {
  color: LIMIT_LINE_COLOR,
};
const CURRENT_RANGE_DEFAULT: { color: string } & RangeChart = {
  color: CURRENT_LINE_COLOR,
};
const isHexColor = (string: string): boolean => {
  return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/.test(string);
};
const isRGBColorFormat = (color: string) => {
  const rgbColorRegex = /^rgba?\((\s*\d+\s*,){2}\s*\d+\s*(,\s*\d*\.?\d+\s*)?\)$/;
  return rgbColorRegex.test(color);
};
const isValidColor = (string: string): boolean => {
  return isHexColor(string) || isRGBColorFormat(string);
};

const shareLabelConfig: am5xy.IAxisLabelSettings = {
  text: 'Text',
  visible: true,
  inside: true,
  fontSize: 12,
};
const TEMPLATE_COLUMN: Record<string, BulletTemplateColumn> = {
  current: 'currentColumnSettings',
  rest: 'restColumnSettings',
};

export const PulseBulletChart = ({
  layout = 'vertical',
  currentValue,
  limitValue,
  targetValue,
  XYSeries,
  targetRange,
  limitRange,
  currentRange,
  tooltipFields,
  classes,
  chartContainer,
  columnSettings,
  id,
  rangeSettings,
}: PulseBulletChartProps): ReactElement => {
  const idRoot = `${ROOT_ID_CHART}-${id ?? 0}`;
  const root = useRef<am5.Root>();
  const chart = useRef<am5xy.XYChart>();
  const xAxis = useRef<IXYAxis>();
  const yAxis = useRef<IXYAxis>();
  const current = currentValue && currentValue >= 100 ? 100 : currentValue;
  const target = targetValue && targetValue >= 100 ? 100 : targetValue;
  const limit = limitValue && limitValue >= 100 ? 100 : limitValue;
  const XYSeriesStyles = { ...SERIES_DEFAULT, ...XYSeries };
  const currentRangeProps = { ...CURRENT_RANGE_DEFAULT, ...currentRange };
  const limitRangeProps = { ...LIMIT_RANGE_DEFAULT, ...limitRange };
  const targetRangeProps = { ...TARGET_RANGE_DEFAULT, ...targetRange };
  const isHorizontal = layout === 'horizontal';
  const columnSettingsDefault: Omit<am5xy.IBaseColumnSeriesSettings, 'xAxis' | 'yAxis'> = {
    width: isHorizontal ? am5.percent(100) : am5.percent(60),
    height: isHorizontal ? am5.percent(60) : am5.percent(100),
    tooltipText: 'text',
    interactive: true,
    stateAnimationDuration: 20000,
    stateAnimationEasing: am5.ease.out(am5.ease.cubic),
    ...columnSettings,
  };

  // Define data
  const data = [
    {
      catBullet: 'metrics',
      current,
      max: MAX,
      target: target,
      limit: limit,
      noValueY: current ?? 0,
      noValueX: current ?? 0,
      [TEMPLATE_COLUMN.current]: {
        ...columnSettingsDefault,
        cornerRadiusTL: isHorizontal ? 5 : 0,
        cornerRadiusBL: 5,
        cornerRadiusTR: 0,
        cornerRadiusBR: isHorizontal ? 0 : 5,
      },
      [TEMPLATE_COLUMN.rest]: {
        ...columnSettingsDefault,
        cornerRadiusTL: isHorizontal ? 0 : 5,
        cornerRadiusBL: 0,
        cornerRadiusTR: 5,
        cornerRadiusBR: isHorizontal ? 5 : 0,
        opacity: 0.2,
      },
    },
  ];
  const getThemeColors = useMemo((): am5.Color[] => {
    let keyColor = 'colorCurrent';
    if (current && limit && target) {
      keyColor = current < limit ? 'colorLimit' : current > target ? 'colorTarget' : 'colorCurrent';
    }
    const restKeyColor = `colorRest${keyColor.slice(5)}`;

    return [
      am5.color(isValidColor(XYSeriesStyles?.[keyColor]) ? XYSeriesStyles?.[keyColor] : SERIES_DEFAULT[keyColor]),
      am5.color(
        isValidColor(XYSeriesStyles?.[restKeyColor]) ? XYSeriesStyles?.[restKeyColor] : SERIES_DEFAULT[restKeyColor],
      ),
    ];
  }, [XYSeriesStyles]);
  const hasLabelTouch = (): boolean => {
    if (current && target && limit) {
      const absDiffCurrentTarget = Math.abs(current - target);
      const absDiffCurrentLimit = Math.abs(current - limit);
      const absDiffTargetLimit = Math.abs(target - limit);

      return absDiffCurrentTarget <= 10 || absDiffCurrentLimit <= 10 || absDiffTargetLimit <= 10;
    }
    return false;
  };
  const initialStyle = (): void => {
    if (xAxis.current && yAxis.current) {
      //hide all label X
      const xRenderer = xAxis.current.get('renderer');
      xRenderer.labels.template.setAll({
        visible: false,
      });
      // hide all label Y
      const yRenderer = yAxis.current.get('renderer');
      yRenderer.labels.template.setAll({
        visible: false,
      });
      // hide grid of chart
      yAxis.current.get('renderer').grid.template.setAll({
        strokeWidth: 0,
        visible: false,
      });
      xAxis.current.get('renderer').grid.template.setAll({
        location: 0,
        strokeWidth: 0,
        visible: false,
      });
    }
  };
  const clearChart = (): void => {
    if (!root.current || !chart.current) {
      return;
    }
    chart.current.series.clear();
    chart.current.yAxes.clear();
    chart.current.xAxes.clear();
  };
  const clearRange = (): void => {
    if (!root.current || !chart.current) {
      return;
    }
    chart.current.xAxes.getIndex(0)?.axisRanges.clear();
    chart.current.yAxes.getIndex(0)?.axisRanges.clear();
  };
  const clearSeries = (): void => {
    if (!root.current || !chart.current) {
      return;
    }
    chart.current.series.clear();
  };
  const makeXAxis = (): IXYAxis => {
    if (chart?.current && root.current) {
      const xAxis = chart.current.xAxes.push(
        isHorizontal
          ? am5xy.ValueAxis.new(root.current, {
              renderer: am5xy.AxisRendererX.new(root.current, {}) as am5xy.AxisRenderer,
              min: 0,
              max: MAX,
            })
          : am5xy.CategoryAxis.new(root.current, {
              categoryField: 'catBullet',
              renderer: am5xy.AxisRendererX.new(root.current, {
                // start of cell
                cellStartLocation: -1,
              }) as am5xy.AxisRenderer,
              tooltip: am5.Tooltip.new(root.current, {}),
            }),
      );
      //   set data into axis X
      !isHorizontal && xAxis.data.setAll(data);
      return xAxis;
    }
    return xAxis.current as IXYAxis;
  };
  const makeYAxis = (): IXYAxis => {
    if (chart?.current && root.current) {
      const yAxis = chart.current.yAxes.push(
        isHorizontal
          ? am5xy.CategoryAxis.new(root.current, {
              categoryField: 'catBullet',
              maxDeviation: 0,
              renderer: am5xy.AxisRendererY.new(root.current, {
                // start of cell
                cellStartLocation: 0.3,
                cellEndLocation: 2,
              }) as am5xy.AxisRenderer,
              tooltip: am5.Tooltip.new(root.current, {}),
            })
          : am5xy.ValueAxis.new(root.current, {
              min: 0,
              max: MAX,
              renderer: am5xy.AxisRendererY.new(root.current, {
                visible: false,
              }) as am5xy.AxisRenderer,
            }),
      );
      //   set data into axis Y
      isHorizontal && yAxis.data.setAll(data);
      return yAxis;
    }
    return yAxis.current as IXYAxis;
  };
  const createSeries = (seriesSetting: am5xy.IXYSeriesSettings, templateColumn: BulletTemplateColumn): void => {
    if (chart.current && root.current) {
      const series = chart.current.series.push(
        am5xy.ColumnSeries.new(root.current, {
          stacked: true,
          ...seriesSetting,
        }),
      );
      series.columns.template.setAll({
        templateField: templateColumn,
      });
      series.data.setAll(data);
    }
  };
  const createRange = useCallback(
    (
      axis: am5xy.ValueAxis<am5xy.AxisRenderer>,
      dataItem: am5xy.IValueAxisDataItem,
      labelSetting: am5.ILabelSettings = {},
      gridColor = am5.color(CURRENT_LINE_COLOR),
    ): void => {
      const rangeData = axis.makeDataItem(dataItem);
      const range = axis.createAxisRange(rangeData);

      const shareGridConfig: am5xy.IGridSettings = {
        strokeOpacity: 1,
        location: 1,
        visible: true,
        stroke: gridColor,
        strokeWidth: 1.5,
        width: am5.percent(100),
        ...rangeSettings,
      };

      if (isHorizontal) {
        const rangeLabelDate = axis.makeDataItem({ value: dataItem.endValue });
        const rangeLabel = axis.createAxisRange(rangeLabelDate);
        rangeLabel.get('label')?.setAll({
          ...shareLabelConfig,
          dy: hasLabelTouch() ? 0 : -20,
          dx: hasLabelTouch() ? 0 : -30,
          ...labelSetting,
        });
      } else {
        range.get('label')?.setAll({
          ...shareLabelConfig,
          centerX: 0,
          dx: hasLabelTouch() ? 5 : -50,
          dy: hasLabelTouch() ? 0 : -10,
          ...labelSetting,
        });
        range.get('label')?.adapters.add('x', () => {
          if (chart.current?.plotContainer.width) {
            return chart.current.plotContainer.width();
          }
        });

        chart.current?.plotContainer.onPrivate('width', () => {
          range.get('label')?.markDirtyPosition();
        });
      }

      range.get('grid')?.setAll(shareGridConfig);
    },
    [layout, hasLabelTouch, limit, target, current, rangeSettings],
  );
  const makeAllSeries = (): void => {
    if (xAxis.current && yAxis.current) {
      const seriesConfig: am5xy.IXYSeriesSettings = {
        xAxis: xAxis.current,
        yAxis: yAxis.current,
      };
      const series: (am5xy.IXYSeriesSettings & { templateColumn: BulletTemplateColumn })[] = isHorizontal
        ? [
            {
              ...seriesConfig,

              name: 'current',
              valueXField: 'current',
              templateColumn: TEMPLATE_COLUMN.current,
              categoryYField: 'catBullet',
            },
            {
              ...seriesConfig,

              name: 'rest',
              valueXField: 'max',
              openValueYField: 'noValueY',
              templateColumn: TEMPLATE_COLUMN.rest,
              categoryYField: 'catBullet',
            },
          ]
        : [
            {
              ...seriesConfig,
              name: 'current',
              valueYField: 'current',
              templateColumn: TEMPLATE_COLUMN.current,
              categoryXField: 'catBullet',
            },
            {
              ...seriesConfig,
              name: 'rest',
              valueYField: 'max',
              openValueYField: 'noValueX',
              templateColumn: TEMPLATE_COLUMN.rest,
              categoryXField: 'catBullet',
            },
          ];

      series.forEach(seriesItem => {
        createSeries(
          {
            ...seriesItem,
          },
          seriesItem.templateColumn,
        );
      });
    }
  };
  const makeRanges = (): void => {
    if (xAxis.current && yAxis.current) {
      const defaultRange = {
        limit: {
          color: isValidColor(limitRangeProps?.color) ? am5.color(limitRangeProps.color) : am5.color(LIMIT_LINE_COLOR),
          labelSetting: {
            text: limitRange?.label ?? `${limit} %`,
            fill: RANGE_LABEL_COLOR,
            ...limitRange?.labelSetting,
          },
        },
        current: {
          labelSetting: {
            text: currentRange?.label ?? `${current} %`,
            fontWeight: '600',
            ...currentRange?.labelSetting,
          },
          color: isValidColor(currentRangeProps?.color)
            ? am5.color(currentRangeProps.color)
            : am5.color(CURRENT_LINE_COLOR),
        },
        target: {
          labelSetting: {
            text: targetRange?.label ?? `${target} %`,
            fill: RANGE_LABEL_COLOR,
            ...targetRange?.labelSetting,
          },
          color: isValidColor(targetRangeProps?.color)
            ? am5.color(targetRangeProps.color)
            : am5.color(TARGET_LINE_COLOR),
        },
      };
      const ranges: Record<string, any> = isHorizontal
        ? [
            {
              actualValue: limit,
              axis: xAxis.current,
              data: { value: 0, endValue: limit, above: false },
              ...defaultRange.limit,
            },
            {
              actualValue: current,
              axis: xAxis.current,
              data: { value: 0, endValue: current, above: true },
              ...defaultRange.current,
            },
            {
              actualValue: target,
              axis: xAxis.current,
              data: { value: 0, endValue: target, above: false },
              ...defaultRange.target,
            },
          ]
        : [
            {
              actualValue: limit,
              axis: yAxis.current,
              data: {
                value: limit,
                above: false,
              },
              ...defaultRange.limit,
            },
            {
              actualValue: current,
              axis: yAxis.current,
              data: {
                value: current,
                visible: false,
                above: true,
              },
              ...defaultRange.current,
            },
            {
              actualValue: target,
              axis: yAxis.current,
              data: {
                value: target,
                above: false,
                visible: false,
              },
              ...defaultRange.target,
            },
          ];

      ranges
        .filter(rangeFilter => rangeFilter.actualValue)
        .forEach(range => {
          createRange(range.axis, range.data, range.labelSetting, range.color);
        });
    }
  };

  const makeTooltip = useCallback(() => {
    if (!root.current || !chart.current) {
      return;
    }
    const tooltip = am5.Tooltip.new(root.current, {
      forceHidden: !tooltipFields,
      getFillFromSprite: false,
      autoTextColor: false,
      labelText: `Estimate: ${tooltipFields?.estimate ?? ''}\nActual: ${tooltipFields?.actual ?? ''}\nMargin: ${
        tooltipFields?.margin ?? ''
      }\nFormula: ${tooltipFields?.formula ?? ''}`,
    });
    tooltip.label.setAll({
      fill: am5.color(0xffffff),
    });
    tooltip?.get('background')?.setAll({
      fill: am5.color(0x000000),
      fillOpacity: 0.8,
    });
    chart.current.series.values.forEach(series => {
      series.set('tooltip', tooltip);
    });
  }, [tooltipFields, targetRange, limitRange, currentRange]);

  const setThemeColor = useCallback((): void => {
    if (chart.current) {
      // define theme colors for series
      chart.current.get('colors')?.set('colors', getThemeColors);
    }
  }, [getThemeColors]);
  useLayoutEffect(() => {
    // dispose if ROOT_ID_CHART is exist
    am5.array.each(am5.registry.rootElements, async root => {
      if (root) {
        if (root.dom.id == idRoot) {
          root.dispose();
        }
      }
    });
    const paddingChart = isHorizontal
      ? {
          paddingBottom: 40,
        }
      : {
          paddingRight: 40,
        };
    // chart initialization
    root.current = am5.Root.new(idRoot);
    chart.current = root.current?.container.children.push(
      am5xy.XYChart.new(root.current, {
        panY: false,
        layout: root.current.verticalLayout,
        ...paddingChart,
      }),
    );
    // set theme
    root.current?.setThemes([am5themes_Animated.new(root.current)]);
    return () => {
      root.current?.dispose();
    };
  }, [idRoot]);
  useLayoutEffect(() => {
    if (chart.current) {
      chart.current?.setAll({
        ...chartContainer,
      });
    }
  }, [chartContainer]);
  useLayoutEffect(() => {
    if (chart.current?.seriesContainer.children.values?.length) {
      chart.current?.seriesContainer.children.values.forEach((columnSeries, indexColumn) => {
        (columnSeries as am5xy.ColumnSeries).columns.getIndex(0)?.animate({
          key: 'fill',
          to: getThemeColors[indexColumn],
          duration: 200,
        });
      });
    }
  }, [XYSeriesStyles]);
  useLayoutEffect(() => {
    if (chart?.current?.series.values?.length) {
      makeTooltip();
    }
  }, [tooltipFields]);
  useLayoutEffect(() => {
    if (!root.current || !chart.current) {
      return;
    }
    // clear chart
    clearChart();
    //create axis X
    xAxis.current = makeXAxis();
    //create axis Y
    yAxis.current = makeYAxis();
    // default styles
    initialStyle();
  }, [isHorizontal]);

  useLayoutEffect(() => {
    if (!root.current || !chart.current || !xAxis.current || !yAxis.current) {
      return;
    }
    if (chart.current.series.values?.length) {
      // clear series
      clearSeries();
      // clear ranges
      clearRange();
    }
    // set theme
    setThemeColor();
    // create series or re-create
    makeAllSeries();
    // create series or re-create
    makeRanges();
    // create tooltip
    makeTooltip();
  }, [limit, current, target, targetRange, limitRange, currentRange, isHorizontal, rangeSettings]);

  return (
    <div
      id={idRoot}
      data-testid="pulse-bullet-chart"
      className={clsx(
        isHorizontal ? styles['pulse-bullet-chart__root--horizontal'] : styles['pulse-bullet-chart__root'],
        classes?.root,
      )}
    />
  );
};

PulseBulletChart.displayName = 'PulseBulletChart';
export default PulseBulletChart;
