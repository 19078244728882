import React, { ReactElement, useState } from 'react';
import { PulseTagData, PulseTagProps } from './pulse-tag-types';
import styles from './pulse-tag.module.scss';
import { AsyncSelectReturnTypes } from '../pulse-select/base/pulse-select-base-types';
import Creatable from 'react-select/creatable';
import { withAsyncPaginate } from 'react-select-async-paginate';
import { v2Endpoint } from 'pulse-commons/api';
import { transformQueryParams } from 'pulse-commons/helpers';
import { optionTransformer } from '../pulse-select/utils';
import PulseButtonBase from '../pulse-button/base/pulse-button-base';
import PulseLabel from '../pulse-label/pulse-label';
import PulseWithMore from '../pulse-with-more/pulse-with-more';
import { ButtonSizes, ButtonVariants, LabelSizes } from 'pulse-commons/types';
import PulseLazyTippy from '../common/pulse-lazy-tippy/pulse-lazy-tippy';
import PulseTagMultiValue from './components/pulse-tag-multi-value';
import PulseTagOption from './components/pulse-tag-option';
import clsx from 'clsx';
import isFunction from 'lodash/isFunction';

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const PULSE_LOCAL_URL = process.env.PULSE_LOCAL_URL || '';

const TAG_DATA_STRUCTURE = {
  dataKey: 'data',
  isSolrEndpoint: false,
  label: 'name',
  lastPage: 'pagination.pageCount',
  type: 'tags',
  value: 'id',
  searchKey: 'keyword',
};

const TAG_DEFAULT_PLACEHOLDER = 'Search for tag name';
const TAG_URL_GET = `${PULSE_LOCAL_URL}/api.v2.php?action=tasks&type=get-ticket-tags&limit=10`;
const CREATE_OPTION_POSITION_DEFAULT = 'first';
const TIPPY_PLACEMENT_DEFAULT = 'right';
const TIPPY_APPEND_TO_DEFAULT = 'parent';

export const PulseTag = (props: PulseTagProps): ReactElement => {
  /* istanbul ignore next */
  const {
    classes = {},
    onCreate,
    onDelete,
    onChange,
    data = [],
    dataStructure = TAG_DATA_STRUCTURE,
    getUrl = TAG_URL_GET,
    maxWidth = 250,
    readOnly = false,
    placement = TIPPY_PLACEMENT_DEFAULT,
    PulseWithMoreProps,
  } = props;

  const [showTippy, setShowTippy] = useState(false);

  const showPulseTagSelect = (): void => {
    setShowTippy(true);
  };

  const closePulseTagSelect = (): void => {
    setShowTippy(false);
  };

  /* istanbul ignore next */
  const loadTagOptions = async (
    search: string,
    loadedOptions: AsyncSelectReturnTypes['options'],
    { page }: { page: NonNullable<AsyncSelectReturnTypes['additional']>['page'] },
  ): Promise<AsyncSelectReturnTypes> => {
    let options: AsyncSelectReturnTypes['options'];
    let hasMore: boolean;

    try {
      const { lastPage, dataKey, searchKey } = dataStructure;
      const params: {
        [index: string]: any;
        page: number;
      } = {
        page,
      };
      searchKey && (params.searchKey = searchKey);
      const responseJSON = await v2Endpoint
        .get(getUrl, {
          params,
          paramsSerializer: params => {
            return transformQueryParams(params);
          },
          withCredentials: true,
        })
        .then(res => {
          return res.data;
        });

      hasMore = responseJSON && page < responseJSON[lastPage];
      options = await optionTransformer({ dataArray: responseJSON[dataKey], dataStructure });
    } catch (error) {
      process.env.NODE_ENV === 'development' && console.log(`Fetching remote data failed. Component pulse-tag.`);
      options = [];
      hasMore = false;
    }
    return {
      options,
      hasMore,
      additional: {
        page: hasMore ? page + 1 : page,
      },
    };
  };

  const handleDelete = (tag: PulseTagData) => (): void => {
    isFunction(onDelete) && onDelete(tag.value);
  };

  return (
    <div className={clsx(styles['pulse-tag__root'], classes.root)} data-testid="pulse-tag__root">
      {!readOnly && (
        // Use `PulseLazyTippy` because react-select was mounted when tippy was created
        // It needs lazy mounting to autofocus
        // https://github.com/atomiks/tippyjs-react#lazy-mounting
        <PulseLazyTippy
          interactive
          placement={placement}
          visible={showTippy}
          onClickOutside={closePulseTagSelect}
          appendTo={TIPPY_APPEND_TO_DEFAULT}
          render={attrs => (
            <div {...attrs}>
              <CreatableAsyncPaginate
                autoFocus
                additional={{
                  page: 1,
                }}
                className="pulse-tag__select"
                classNamePrefix="pulse-tag__select"
                closeMenuOnSelect={false}
                components={{
                  MultiValue: PulseTagMultiValue,
                  Option: PulseTagOption,
                }}
                debounceTimeout={500}
                isMulti
                loadOptions={loadTagOptions}
                onChange={onChange}
                onCreateOption={onCreate}
                openMenuOnFocus
                placeholder={TAG_DEFAULT_PLACEHOLDER}
                /* istanbul ignore next */
                styles={{
                  container: styles => ({ ...styles, minWidth: 200 }),
                  control: styles => ({ ...styles, borderRadius: 4, minHeight: 'auto' }),
                  indicatorsContainer: styles => ({ ...styles, display: 'none' }),
                  menu: styles => ({ ...styles, borderRadius: 4, marginTop: 4 }),
                  menuList: styles => ({ ...styles, padding: 0, borderRadius: 4 }),
                  option: (styles, state) => ({
                    ...styles,
                    cursor: 'pointer',
                    backgroundColor: state.isFocused && '#FEF8E8',
                    padding: '0 8px',
                  }),
                  placeholder: styles => ({ ...styles, marginTop: 2 }),
                  valueContainer: styles => ({
                    ...styles,
                    overflow: 'auto',
                    maxWidth: 300,
                    maxHeight: 300,
                  }),
                }}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#e5e5e5',
                    primary50: 'rgba(255, 194, 14, 0.3)',
                    primary25: 'rgba(255, 194, 14, 0.3)',
                  },
                })}
                value={data}
                createOptionPosition={CREATE_OPTION_POSITION_DEFAULT}
              />
            </div>
          )}
        >
          <PulseButtonBase
            onClick={showTippy ? closePulseTagSelect : showPulseTagSelect}
            variant={ButtonVariants.contained}
            icon
            iconClasses={{
              ...classes.addButtonIcon,
              icon: clsx('fal fa-plus', styles['pulse-tag__add-btn-icon'], classes.addButtonIcon?.icon),
            }}
            sizes={ButtonSizes.sm}
            label="Add tag"
            classes={[clsx(styles['pulse-tag__add-btn'], classes.addButton)]}
          />
        </PulseLazyTippy>
      )}
      {!showTippy && !!data.length && (
        <PulseWithMore
          childrenKey="label"
          maxWidth={maxWidth}
          MoreLabelProps={{
            size: LabelSizes.sm,
          }}
          HiddenChildProps={{
            isShowFull: true,
            TippyProps: {
              disabled: true,
            },
          }}
          {...PulseWithMoreProps}
        >
          {data.map(tag => {
            return (
              <PulseLabel
                classes={{
                  root: clsx(styles['pulse-tag__tag'], classes.pulseLabel?.root),
                }}
                key={tag.value}
                label={tag.label}
                size={LabelSizes.sm}
                actionButton={!readOnly}
                actionButtonProps={{
                  classes: {
                    pulseIcon: {
                      root: styles['pulse-tag__remove'],
                      icon: 'fal fa-times',
                    },
                  },
                  handleClick: handleDelete(tag),
                }}
                icon
                PulseIconProps={{
                  classes: {
                    root: styles['pulse-tag__icon'],
                    icon: 'fal fa-tag',
                  },
                }}
              />
            );
          })}
        </PulseWithMore>
      )}
    </div>
  );
};

export default PulseTag;
