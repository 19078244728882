import React, { FC } from 'react';
import clsx from 'clsx';
import { Colors, IconSizes } from 'pulse-commons/types';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { TimelineTemplateDeleteModalProps } from './timeline-template-modal-types';
import styles from './timeline-template-modal.module.scss';

const TimelineTemplateDeleteModal: FC<TimelineTemplateDeleteModalProps> = props => {
  const { isOpen, template, onCancel, onConfirm } = props;

  return (
    <PulseModalBase
      variant="jqconfirm"
      open={isOpen}
      headerIconClasses="fal fa-trash-alt"
      PulseModalHeaderProps={{
        onCloseClick: onCancel,
        primaryText: 'DELETE TIMELINE TEMPLATE',
      }}
      footer={false}
      onModalClose={onCancel}
    >
      <div className={clsx(styles['timeline-template-modal'], styles['timeline-template-modal--delete'])}>
        <div className={styles['timeline-template-modal__icon']}>
          <i className="fal fa-exclamation-circle"></i>
        </div>
        <div className={styles['timeline-template-modal__heading']}>DELETE TIMELINE TEMPLATE</div>

        <div className={styles['timeline-template-modal__title']}>
          <div>
            Are you sure you want to delete Template:
            <br />
            &nbsp;<strong>{template?.name}</strong>?
          </div>
          <div className={styles['timeline-template-modal__title--warning']}>
            Deleting this template will not affect any timelines that were created with this template.
            <strong>&nbsp;This action is not reversible.</strong>
          </div>
        </div>
        <div className={styles['timeline-template-modal__button-ctn']}>
          <PulseButtonBase classes={[styles['timeline-template-modal__button']]} label="Cancel" onClick={onCancel} />
          <PulseButtonBase
            classes={[
              clsx(styles['timeline-template-modal__button'], styles['timeline-template-modal__button--confirm']),
            ]}
            icon
            iconClasses={{
              icon: 'fal fa-trash-alt',
            }}
            iconSize={IconSizes.sm}
            color={Colors.danger}
            onClick={() => template.id && onConfirm(template.id)}
            label="delete template"
          />
        </div>
      </div>
    </PulseModalBase>
  );
};

export default TimelineTemplateDeleteModal;
