import React, { FC, memo } from 'react';
import { isEqual } from 'lodash';
import clsx from 'clsx';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import { PulseExportReceivedProps } from './pulse-export-modal-types';
import styles from './pulse-export-modal-styles.module.scss';

const PulseExportReceivedModal: FC<PulseExportReceivedProps> = props => {
  const { exportTitle, modalTitle, onClose } = props;
  return (
    <PulseModalBase
      open
      footer={false}
      headerPrimaryText={modalTitle}
      headerIconClasses={clsx(styles['pulse-export-modal__header--icon'], 'fal fa-file-export')}
      classes={{
        root: [styles['pulse-export-modal__container']],
      }}
      PulseModalHeaderProps={{
        classes: {
          root: [styles['pulse-export-modal__header']],
          titlePrimary: [styles['pulse-export-modal__header--label']],
        },
      }}
      onModalClose={onClose}
      onCancelClick={onClose}
    >
      <div className={styles['pulse-export-received-modal']}>
        <div className={styles['pulse-export-received-modal__icon']}>
          <i className="fal fa-check-circle"></i>
        </div>
        <div className={styles['pulse-export-received-modal__heading']}>Export Request Received</div>
        <div className={styles['pulse-export-received-modal__title']}>
          <div>
            Please check your email inbox for the report:
            <br />
            <strong>{exportTitle}.</strong>
          </div>
          <div className={styles['pulse-export-received-modal__title--warning']}>
            You may need to allow up to 10 minutes for the report to be processed.
          </div>
        </div>
      </div>
    </PulseModalBase>
  );
};

export default memo(PulseExportReceivedModal, isEqual);
