import React, { ReactElement, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ImageOverlay } from 'react-leaflet';
import { LatLngBoundsExpression } from 'leaflet';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';
import clsx from 'clsx';
import 'leaflet/dist/leaflet.css';

import { PulseImageViewerProps, PulseImageFullScreenProps } from './pulse-image-viewer-types';
import PulseIconButton from '../pulse-icon-button/pulse-icon-button';
import { IconSizes } from 'pulse-commons/types';
import PulseImageViewerMap from './components/pulse-image-viewer-map';
import { handleDownloadImage, handlePrintImage } from './utils';
import { PulseIconButtonProps } from '../pulse-icon-button/pulse-icon-button-types';
import styles from './pulse-image-viewer.module.scss';
import axios from 'axios';

const isDevelopment = process.env.NODE_ENV === 'development';

/**
 * This is a wrapper component to handle the backdrop when
 * switching to fullscreen.
 *
 * This prevents duplicating content for the backdrop
 *
 * @param props
 */
const PulseImageFullScreen = (props: PulseImageFullScreenProps) => {
  const { children, classes, isFullscreen } = props;
  const portalTarget = document.querySelector('body');
  return (
    <>
      {isFullscreen &&
        portalTarget &&
        ReactDOM.createPortal(
          <Backdrop className={clsx(styles['pulse-image-viewer__backdrop'], classes?.root)} open={isFullscreen}>
            {children}
          </Backdrop>,
          portalTarget,
        )}
      {!isFullscreen && children}
    </>
  );
};

export const PulseImageViewer = (props: PulseImageViewerProps): ReactElement => {
  /* istanbul ignore next */
  const {
    allowDownload = true,
    allowFullscreen = true,
    allowPrint = true,
    allowZoom = true,
    classes = {},
    isFullscreen: isFullscreenProp = false,
    downloadUrl,
    onDownload,
    onFullscreen,
    onPrint,
    src,
    title,
  } = props;

  const [isFullscreen, setIsFullscreen] = useState(isFullscreenProp);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [imgEl, setImgEl] = useState<HTMLImageElement | null>(null);
  const [bounds, setBounds] = useState<LatLngBoundsExpression>();

  useEffect(() => {
    isFullscreen ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
  }, [isFullscreen]);

  useEffect(() => {
    setIsFullscreen(isFullscreenProp);
  }, [isFullscreenProp]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    /* istanbul ignore next */
    axios
      .get(src, {
        withCredentials: true,
        cancelToken: source?.token,
        onDownloadProgress: progress => {
          !isLoading && setIsLoading(true);
          setProgress(Math.round((progress.loaded * 100) / progress.total));
        },
        responseType: 'arraybuffer',
      })
      .then(response => {
        const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        const img = new Image();
        img.addEventListener('load', e => {
          const target: HTMLImageElement = e.target as HTMLImageElement;
          setBounds([
            [0, 0],
            [target.naturalHeight, target.naturalWidth],
          ]);
        });
        img.src = `data:${response.headers['content-type']};base64,${base64}`;
        setImgEl(img);
        setIsLoading(false);
      })
      .catch(error => isDevelopment && console.log(`Download image failed.`, JSON.stringify(error, null, 2)));

    return () => {
      source && isDevelopment && source.cancel('Component unmounted');
    };
  }, [src]);

  const handleFullscreen = () => {
    onFullscreen ? onFullscreen(setIsFullscreen) : setIsFullscreen(!isFullscreen);
  };

  const handleDownload = () => {
    onDownload ? onDownload(handleDownloadImage) : handleDownloadImage(downloadUrl || `${src}&action=download`);
  };

  const handlePrint = () => {
    onPrint ? onPrint(handlePrintImage) : handlePrintImage(src);
  };

  /* istanbul ignore next */
  const actionButtonClasses: PulseIconButtonProps['classes'] = {
    ...classes.toolbar?.button,
    root: clsx(styles['pulse-image-viewer__action-button'], classes.toolbar?.button?.root),
    label: clsx(styles['pulse-image-viewer__action-button-label'], classes.toolbar?.button?.label),
    pulseIcon: {
      ...classes.toolbar?.button?.pulseIcon,
      root: clsx(styles['pulse-image-viewer__action-button-icon'], classes.toolbar?.button?.pulseIcon?.root),
    },
  };

  /* istanbul ignore next */
  const mapInstance = (
    <PulseImageViewerMap bounds={bounds} allowZoom={allowZoom} classes={classes}>
      {!imgEl && isLoading && (
        <>
          <LinearProgress
            variant="determinate"
            value={progress}
            classes={{
              root: styles['pulse-image-viewer__progress'],
              bar: styles['pulse-image-viewer__progress-bar'],
            }}
          />
          <div className={styles['pulse-image-viewer__downloadingPlaceholder']}>Downloading your image</div>
        </>
      )}
      {bounds && imgEl && (
        <ImageOverlay
          bounds={bounds}
          url={imgEl?.src}
          className={clsx(styles['pulse-image-viewer__image'], classes.image)}
        />
      )}
    </PulseImageViewerMap>
  );

  return (
    <div className={clsx(styles['pulse-image-viewer__root'], classes.root)}>
      <PulseImageFullScreen classes={{ root: classes?.backdrop }} isFullscreen={isFullscreen}>
        <>
          {(allowDownload || allowFullscreen || allowPrint) && (
            <div className={clsx(styles['pulse-image-viewer__toolbar'], classes.toolbar?.root)}>
              {isFullscreen && (
                <h4 className={clsx(styles['pulse-image-viewer__title'], classes.toolbar?.title)}>{title || src}</h4>
              )}
              {allowDownload && (
                <PulseIconButton
                  handleClick={handleDownload}
                  classes={{
                    ...actionButtonClasses,
                    pulseIcon: {
                      icon: 'fal fa-download',
                      ...actionButtonClasses.pulseIcon,
                    },
                  }}
                  size={IconSizes.sm}
                />
              )}
              {allowPrint && (
                <PulseIconButton
                  handleClick={handlePrint}
                  classes={{
                    ...actionButtonClasses,
                    pulseIcon: {
                      icon: 'fal fa-print',
                      ...actionButtonClasses.pulseIcon,
                    },
                  }}
                  size={IconSizes.sm}
                />
              )}
              {allowFullscreen && (
                <PulseIconButton
                  handleClick={handleFullscreen}
                  classes={{
                    ...actionButtonClasses,
                    pulseIcon: {
                      icon: isFullscreen ? 'fal fa-times' : 'fal fa-expand-arrows-alt',
                      ...actionButtonClasses.pulseIcon,
                    },
                  }}
                  size={IconSizes.sm}
                />
              )}
            </div>
          )}
          {mapInstance}
        </>
      </PulseImageFullScreen>
    </div>
  );
};

export default PulseImageViewer;
