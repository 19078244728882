import React, { ReactElement, useRef, useEffect, Ref } from 'react';
import { PulsePdfViewerProps } from './pulse-pdf-viewer-types';
import styles from './pulse-pdf-viewer.module.scss';
import './pulse-pdf-viewer.scss';
import clsx from 'clsx';

export const PulsePdfViewer = (props: PulsePdfViewerProps): ReactElement => {
  const { classes, documentUrl, viewerHTMLUrl = 'pdf-js/web/viewer.html?file=' } = props;
  const pdfViewerEl: Ref<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const iframe = document.createElement('iframe');
    iframe.src = `${viewerHTMLUrl}${encodeURIComponent(documentUrl)}`;
    iframe.width = '100%';
    iframe.height = '100%';
    pdfViewerEl.current && pdfViewerEl.current.appendChild(iframe);
  }, []);

  const pulsePdfViewerRootClasses = clsx(styles['pulse-pdf-viewer__root'], classes?.root);

  return <div ref={pdfViewerEl} className={pulsePdfViewerRootClasses}></div>;
};

export default PulsePdfViewer;
