import { Colors } from 'pulse-commons/types';
import React, { ReactElement, useState } from 'react';
import {
  useResourcePlannerDispatch,
  useResourcePlannerState,
} from 'components/pulse-resource-planner/context/resource-planner-context';
import styles from './delete-booking-modal.module.scss';
import { Actions } from 'components/pulse-resource-planner/reducers/resource-planner-reducer';
import { v2Endpoint } from 'pulse-api/base';
import { RequestStatus } from 'pulse-commons/types';
import PulseXhrRequestStatus from 'components/pulse-modal-base/components/pulse-xhr-request-status/pulse-xhr-request-status';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import BookingBaseModal from 'components/pulse-resource-planner/components/BookingBaseModal/booking-base-modal';
import bookingBaseStyles from '../BookingBaseModal/booking-base-modal.module.scss';
import { getEnv, ALLOWED_ENV } from 'pulse-commons/helpers';
import { SchedulerPro } from '@bryntum/schedulerpro-thin';

const PULSE_LOCAL_URL = getEnv(ALLOWED_ENV['PULSE_LOCAL_URL'], '');
const IS_DEV = process.env.NODE_ENV === 'development';

export interface DeleteBookingModalProps {
  onDeleteSuccess?: (eventRecord) => void;
  schedulerInstance: SchedulerPro | null;
}

const DeleteBookingModal = (props: DeleteBookingModalProps): ReactElement => {
  const state = useResourcePlannerState();
  const dispatch = useResourcePlannerDispatch();

  const { onDeleteSuccess } = props;

  const [progress, setProgress] = useState<{
    key: RequestStatus;
    message?: any;
  } | null>(null);
  const { eventRecord, isDeleteBookingModalVisible } = state;

  const closeDeleteBookingModal = () => {
    dispatch({
      type: Actions.hideDeleteBookingModal,
    });
    setProgress(null);
  };

  const deleteBooking = () => {
    setProgress({ key: RequestStatus.pending });
    if (!eventRecord) {
      throw new Error('eventRecord is empty.');
    }
    v2Endpoint
      .delete(`${PULSE_LOCAL_URL}/v2/api/bookings/${eventRecord['bookingId']}`)
      .then(async response => {
        if (response.status !== 200) {
          return;
        }

        /*TODO: commenting out for now. Will handle it on phase 4
        const resourceId = eventRecord.getResource().id;
        updateOverbooking(resourceId, state);
        */

        onDeleteSuccess && onDeleteSuccess(eventRecord);
        //TO DO: Success message is different from design, back end to change message
        setProgress({ key: RequestStatus.success, message: response.data.meta.message });
        dispatch({
          type: Actions.hideEditForm,
        });
      })
      .catch(error => {
        setProgress({ key: RequestStatus.error, message: error });
        IS_DEV && console.log(error);
      });
  };

  return (
    <BookingBaseModal
      headerIcon={'fal fa-bookmark'}
      PulseModalHeaderProps={{ onCloseClick: closeDeleteBookingModal, primaryText: 'Delete booking' }}
      PulseModalBaseProps={{
        onModalClose: closeDeleteBookingModal,
        open: isDeleteBookingModalVisible,
        footer: false,
      }}
    >
      {progress ? (
        <PulseXhrRequestStatus status={progress?.key} pendingMessage={'Loading'} successMessage={progress?.message} />
      ) : (
        <>
          <div className={bookingBaseStyles['booking-modal__title']}>Are you sure you want to delete this booking?</div>
          <div className={bookingBaseStyles['booking-modal__buttonCtn']}>
            <PulseButtonBase
              classes={[styles['delete-modal__button']]}
              onClick={closeDeleteBookingModal}
              label={'Cancel'}
            />
            <PulseButtonBase
              classes={[styles['delete-modal__button']]}
              color={Colors.danger}
              onClick={deleteBooking}
              label={'Delete booking'}
            />
          </div>
        </>
      )}
    </BookingBaseModal>
  );
};

export default React.memo(DeleteBookingModal);
