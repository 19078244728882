/**
 * Taken from the original example
 */
import { Column, ColumnStore } from '@bryntum/grid-thin';

/**
 * A column showing the status of a task
 *
 * @extends Gantt/column/Column
 * @classType statuscolumn
 */
class GanttIdColumn extends Column {
  static get $name() {
    return 'ItemId';
  }

  static get type() {
    return 'itemId';
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      // Set your default instance config properties here
      field: 'id',
      text: 'Item Id',
      editor: false,
      cellCls: 'b-id-column-cell',
      htmlEncode: false,
    };
  }

  renderer({ record }) {
    const { id } = record;

    return {
      tag: 'span',
      html: isNaN(id) ? '' : id,
    };
  }
}

export const registerIdColumn = () => {
  ColumnStore.registerColumnType(GanttIdColumn);
};
