import React, { ReactElement } from 'react';
import PulseSelectBase from '../base/pulse-select-base';
import {
  PulseSelectPropTypes,
  PulseSelectBasePropTypes,
  PulseSelectDataStructureTypes,
  PulseSelectOptionType,
} from '../base/pulse-select-base-types';
import { ValueType } from 'react-select';

const DATA_STRUCTURE: PulseSelectDataStructureTypes = {
  dataKey: 'data',
  isJsonApi: true,
  label: 'title',
  lastPage: 'meta.page.last-page',
  searchKey: 'search',
  type: 'folders',
  value: 'id',
};
const DEFAULT_PLACEHOLDER: PulseSelectBasePropTypes['placeholder'] = 'Filter by folder name';

const defaultProjectDirectoryApi = (jobId?: number | string): string => {
  if (!jobId) {
    return '';
  }
  return `/v2/api/jobs/${jobId}/directories`;
};

export interface PulseFolderSelectProps extends PulseSelectPropTypes {
  /**
   * The jobId to fetch folders for
   *
   * You could set this to a state variable which gets updated when a PulseProjectSelect is updated
   */
  jobId?: number | string;
}

export const PulseFolderSelect = (props: PulseFolderSelectProps): ReactElement => {
  const {
    value = [],
    isMulti = false,
    jobId,
    labelName = 'Folders',
    placeholder = DEFAULT_PLACEHOLDER,
    requestFilterKey = 'dirId',
    url,
    valueChangeHandler,
  } = props;

  const handleValueChange: PulseSelectPropTypes['valueChangeHandler'] = (
    value: ValueType<PulseSelectOptionType>,
    actionMeta,
  ) => {
    valueChangeHandler &&
      valueChangeHandler(
        {
          [requestFilterKey]: value,
        },
        actionMeta,
      );
  };

  /**
   * If a url is provided, follow that url else a jobid needs to be present
   */

  const jobDirectoryApi = url || defaultProjectDirectoryApi(jobId);

  return (
    <PulseSelectBase
      dataStructure={DATA_STRUCTURE}
      isDisabled={!jobId && !url}
      isMulti={isMulti}
      labelName={labelName}
      placeholder={!jobId && !url ? 'Missing jobId or url props' : placeholder}
      url={jobDirectoryApi}
      isCreatable
      value={value}
      changeHandler={handleValueChange}
      {...props}
    />
  );
};

PulseFolderSelect.displayName = 'PulseFolderSelect';

export default React.memo(PulseFolderSelect);
