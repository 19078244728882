export const FILE_EXTENSIONS_PDF: string[] = ['pdf'];
export const FILE_EXTENSIONS_IMAGE: string[] = ['png', 'jpg', 'jpeg', 'gif', 'webp', 'psd', 'ai', 'tiff'];
export const FILE_EXTENSIONS_OFFICE_SUPPORTED: string[] = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'msword',
  'powerpoint',
  'excel',
];
export const FILE_EXTENSIONS_VIDEO: string[] = ['mov', 'mpg', 'vod', '3gp', 'wma', 'mkv', 'avi', 'wmv', 'flv', 'mp4'];
export const FILE_EXTENSIONS_AUDIO: string[] = ['wav', 'mp3'];
