import React, { ReactElement, useState, useRef, useEffect, Ref } from 'react';
import { PulseFileNameEditorProps } from './pulse-file-name-editor-types';
import styles from './pulse-file-name-editor.module.scss';
import { officeFileExtension } from 'pulse-commons/types';
import PulseIconButton from 'components/pulse-icon-button/pulse-icon-button';
import { IconSizes } from 'pulse-commons/types';
import clsx from 'clsx';

export const PulseFileNameEditor = (props: PulseFileNameEditorProps): ReactElement => {
  const { classes, file, onRename } = props;

  const inputRef: Ref<HTMLInputElement> = useRef(null);

  const [editFileName, setEditFileName] = useState(false);
  const [fileNameState, setFileNameState] = useState(file.name);

  useEffect(() => {
    inputRef.current?.select();
  }, [inputRef.current]);

  const handleEditFileName = () => {
    setEditFileName(true);
  };

  const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileNameState(e.target.value);
  };

  const resetFileNameState = () => {
    setFileNameState(file.name);
    setEditFileName(false);
  };

  const getFileExtension = (file: File) => {
    const fileExtension = file.type.split('/').pop();
    if (!fileExtension) {
      return file.name.split('.').pop() ?? fileExtension;
    }
    return officeFileExtension[fileExtension] ?? fileExtension;
  };

  const acceptNewFileName = () => {
    const fileExtension = getFileExtension(file);
    const fileNameStateArray = fileNameState.split('.');
    const fileNameStateArrayLast = fileNameStateArray.pop();
    let newNameWithExtension = fileNameState;
    if (fileNameStateArrayLast !== fileExtension) {
      newNameWithExtension = `${fileNameState}.${fileExtension}`;
    }
    newNameWithExtension !== file.name && onRename(file.name, newNameWithExtension);
    setEditFileName(false);
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    (e?.key === 'Enter' || e.keyCode === 13) && acceptNewFileName();
    (e?.key === 'Escape' || e?.key === 'Esc' || e.keyCode === 27) && acceptNewFileName();
  };

  return (
    <div className={clsx(styles['pulse-file-name-editor__root'], classes?.root)}>
      {!editFileName && (
        <>
          <span className={styles['pulse-file-name-editor__filename']}>{file.name}</span>
          <PulseIconButton
            handleClick={handleEditFileName}
            classes={{
              root: clsx([styles['pulse-file-name-editor__btn'], styles['pulse-file-name-editor__editBtn']]),
              pulseIcon: { icon: 'fal fa-pencil' },
            }}
            size={IconSizes.sm}
          />
        </>
      )}
      {editFileName && (
        <>
          <span className={styles['pulse-file-name-editor__filename--edit']}>
            <input
              autoFocus
              className={styles['pulse-file-name-editor__input']}
              ref={inputRef}
              value={fileNameState}
              onChange={handleFileNameChange}
              onKeyDown={handleEnter}
            />
          </span>
          <div className={styles['pulse-file-name-editor__iconBtnCtn']}>
            <PulseIconButton
              handleClick={acceptNewFileName}
              classes={{ root: styles['pulse-file-name-editor__btn'], pulseIcon: { icon: 'fal fa-check' } }}
              size={IconSizes.sm}
            />
            <PulseIconButton
              handleClick={resetFileNameState}
              classes={{ root: styles['pulse-file-name-editor__btn'], pulseIcon: { icon: 'fal fa-times' } }}
              size={IconSizes.sm}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PulseFileNameEditor;
