import React, { ReactElement } from 'react';
import { PulseCommentsProps } from './pulse-comments-types';
import styles from './pulse-comments.module.scss';
import PulseEditor from 'components/pulse-editor/pulse-editor';
import PulseComment from './components/pulse-comment/pulse-comment';
import clsx from 'clsx';

export const PulseComments = (props: PulseCommentsProps): ReactElement => {
  const {
    allowAddComment = true,
    classes,
    isLoading,
    notes,
    onSave,
    PulseEditorProps,
    PulseCommentProps,
    title = 'Discussion',
    showReplyId,
    showComments = true,
  } = props;

  return (
    <div className={clsx([styles['pulse-comments__root'], classes?.root])}>
      {title && <h2 className={clsx([styles['pulse-comments__title'], classes?.title])}>{title}</h2>}
      {allowAddComment && (
        <div className={clsx([styles['pulse-comments__editor-ctn'], classes?.editorCtn])}>
          <PulseEditor
            classes={{
              root: styles['pulse-comments__editor'],
            }}
            mode="edit-only"
            footer
            onSave={onSave}
            SaveButtonProps={{
              icon: true,
              iconClasses: {
                icon: 'fal fa-comment',
              },
              label: 'Add Comment',
            }}
            {...PulseEditorProps}
          />
        </div>
      )}
      <div className={clsx([styles['pulse-comments__comments-ctn'], classes?.commentsCtn])}>
        {showComments && (
          <>
            {!notes?.length && isLoading && <PulseComment skeleton data={false} />}
            {!!notes?.length && !isLoading && (
              <>
                {notes.map(note => (
                  <PulseComment key={note.id} data={note} showReply={showReplyId === note.id} {...PulseCommentProps} />
                ))}
              </>
            )}
            {!notes?.length && !isLoading && <p>No comments found</p>}
          </>
        )}
      </div>
    </div>
  );
};

export default PulseComments;
