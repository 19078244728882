import React, { FC, forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import PulseBryntumGrid from 'components/pulse-bryntum-grid';
import { Colors } from 'pulse-commons/types';
import { v2Endpoint } from 'pulse-api/base';
import PulseLoader from 'components/pulse-loader/pulse-loader';
import PulseIconButton from 'components/pulse-icon-button/pulse-icon-button';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { NonWorkingDaysModel, TimelineNonWorkingDaysTabPanelProps } from '../non-working-days-modal-types';
import { BASE_URL, formatDate, getAjaxStoreConfig } from './helpers';
import AddNonWorkingDaysModal from './add-timeline-non-working-days-modal';
import styles from '../non-working-days-modal.module.scss';
import { BryntumColumnConfig } from 'components/pulse-bryntum-grid/pulse-bryntum-grid.type';

const NonWorkingDaysGrid = forwardRef<
  any,
  {
    ajaxStoreConfig: any;
    onRemoveNonWorkingDay: (record: NonWorkingDaysModel) => void;
  }
>((props, gridRef) => {
  const { ajaxStoreConfig, onRemoveNonWorkingDay } = props;
  const columns = useMemo(
    () => [
      {
        text: 'Description',
        field: 'name',
        flex: '1 1 100%',
        minWidth: 200,
        editor: true,
      },
      {
        text: 'Start Date',
        field: 'startDate',
        align: 'center',
        hideable: false,
        sortable: false,
        draggable: false,
        resizable: false,
        width: 120,
        editor: true,
        renderer: ({ value }) => formatDate(value),
      },
      {
        text: 'End Date',
        field: 'endDate',
        align: 'center',
        editor: true,
        hideable: false,
        sortable: false,
        draggable: false,
        resizable: false,
        width: 120,
        renderer: ({ value }) => formatDate(value),
      },
      {
        text: '',
        field: '',
        align: 'center',
        hideable: false,
        sortable: false,
        draggable: false,
        resizable: false,
        width: 80,
        renderer: props => {
          const { record } = props;
          return (
            <PulseIconButton
              classes={{
                root: styles['non-working-days-modal__tabs__action-delete-button'],
                pulseIcon: {
                  icon: 'fal fa-trash-alt',
                },
              }}
              handleClick={() => onRemoveNonWorkingDay(record.data)}
            />
          );
        },
      },
    ],
    [],
  );

  return (
    <PulseBryntumGrid
      ref={gridRef}
      ajaxStoreConfig={ajaxStoreConfig}
      classes={{
        root: styles['non-working-days-modal__tabs__grid'],
      }}
      gridProps={{
        columns: columns as BryntumColumnConfig,
      }}
      paginate={true}
    />
  );
});

const TimelineNonWorkingGrid = memo(NonWorkingDaysGrid, (props, nextProps) =>
  isEqual(props.ajaxStoreConfig, nextProps.ajaxStoreConfig),
);

const TimelineNonWorkinDaysTabPanel: FC<TimelineNonWorkingDaysTabPanelProps> = props => {
  const { timelineId, onNonWorkingDayChange } = props;
  const gridRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>();

  const handleRemoveWorkingDay = useCallback(
    (record: NonWorkingDaysModel) => {
      if (!gridRef.current) {
        return;
      }
      const { gridInstance } = gridRef.current;
      setIsLoading(true);
      v2Endpoint
        .delete(`${BASE_URL}api/timelines-non-working-days/${record.id}`)
        .then(async () => {
          await gridInstance.store.loadPage();
          onNonWorkingDayChange(record);
        })
        .catch(() => {
          window.utilities?.notification.danger('Remove Timeline Non-Working days failed');
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [gridRef.current],
  );

  const handleAddWorkingDay = useCallback(
    (record: NonWorkingDaysModel) => {
      if (!gridRef.current) {
        return;
      }
      const { gridInstance } = gridRef.current;
      setIsLoading(true);
      v2Endpoint
        .post(
          `${BASE_URL}api/timelines-non-working-days`,
          {
            data: {
              type: 'timelines-non-working-days',
              attributes: {
                title: record.name,
                timelines_id: timelineId,
                start_date: record.startDate,
                end_date: record.endDate,
                is_working: false,
                cls: 'timelineHoliday',
              },
            },
          },
          {
            headers: {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
            },
          },
        )
        .then(async () => {
          await gridInstance.store.loadPage();
          onNonWorkingDayChange(record);
        })
        .catch(() => {
          window.utilities?.notification.danger('Add Timeline Non-Working days failed');
        })
        .finally(() => {
          setShowAddModal(false);
          setIsLoading(false);
        });
    },
    [gridRef.current, timelineId],
  );

  const ajaxStoreConfig = useMemo(() => getAjaxStoreConfig(timelineId, 'timelineHoliday'), [timelineId]);

  useEffect(() => {
    if (!gridRef.current) {
      return;
    }
    const { gridInstance } = gridRef.current;
    gridInstance.store.load();
  }, []);

  return (
    <>
      <PulseLoader isVisible={isLoading} />
      <div className={styles['non-working-days-modal__warning-ctn']}>
        <div className={styles['non-working-days-modal__warning-ctn__icon-wrapper']}>
          <i className="fal fa-info-circle"></i>
        </div>
        <div className={styles['non-working-days-modal__warning-ctn__content']}>
          <div className={styles['non-working-days-modal__warning-ctn__content__header']}>
            Timeline Non-Working Days
          </div>
          <div className={styles['non-working-days-modal__warning-ctn__content__description']}>
            Any Non-Working Days added below will only apply to this timeline.
          </div>
        </div>
      </div>
      <div className={styles['non-working-days-modal__tabs__add-button-ctn']}>
        <PulseButtonBase
          label="ADD NON WORKING DAY"
          icon
          color={Colors.primary}
          iconClasses={{
            icon: 'fal fa-plus',
          }}
          onClick={() => setShowAddModal(true)}
        />
      </div>
      <TimelineNonWorkingGrid
        ref={gridRef}
        ajaxStoreConfig={ajaxStoreConfig}
        onRemoveNonWorkingDay={handleRemoveWorkingDay}
      />
      {showAddModal && <AddNonWorkingDaysModal onCancel={() => setShowAddModal(false)} onSave={handleAddWorkingDay} />}
    </>
  );
};

export default TimelineNonWorkinDaysTabPanel;
