import clsx from 'clsx';
import React, { FC } from 'react';
import { PulseListProps } from './pulse-list-types';
import styles from './pulse-list.module.scss';
import { List } from '@mui/material';

export const PulseList: FC<PulseListProps> = props => {
  const { children, classes, ...restProps } = props;

  return (
    <List
      data-testid="pulse-list"
      {...restProps}
      classes={{
        ...classes,
        root: clsx(styles.pulseListRoot, classes?.root),
      }}
    >
      {children}
    </List>
  );
};

export default PulseList;
