import React, { ReactElement, Suspense } from 'react';
import clsx from 'clsx';

import { PulseAssetViewerProps } from './pulse-asset-viewer-types';
import styles from './pulse-asset-viewer.module.scss';
import {
  FILE_EXTENSIONS_PDF,
  FILE_EXTENSIONS_IMAGE,
  FILE_EXTENSIONS_OFFICE_SUPPORTED,
  FILE_EXTENSIONS_VIDEO,
  FILE_EXTENSIONS_AUDIO,
} from 'pulse-commons/constants';
import { v2Endpoint, CancelToken } from 'pulse-commons/api';
import PulseOfficeViewer from './components/pulse-office-viewer/pulse-office-viewer';
import PulseUnsupportedViewer from './components/pulse-unsupported-viewer/pulse-unsupported-viewer';

const PulsePdfViewer = React.lazy(
  () => import(/* webpackChunkName: "pulse-pdf-viewer" */ 'components/pulse-pdf-viewer/pulse-pdf-viewer'),
);
const PulseImageViewer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pulse-image-viewer" */
      /* webpackMode: "eager" */
      'components/pulse-image-viewer/pulse-image-viewer'
    ),
);
const PulseVideoPlayer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pulse-video-player" */
      /* webpackMode: "eager" */
      'components/pulse-video-player/pulse-video-player'
    ),
);

const URLS = window.pulse?.config?.urls || '';
const utilities = window.utilities;

const getFileType = (filename: string): string => {
  if (!filename) return '';
  const filenameArr: string = filename.split('.').pop() || '';
  return filenameArr;
};

export const PulseAssetViewer = (props: PulseAssetViewerProps): ReactElement => {
  const {
    classes = {},
    title,
    url,
    PulseImageViewerProps,
    PulseOfficeViewerProps,
    PulsePdfViewerProps,
    PulseVideoPlayerProps,
    PulseUnsupportedViewerProps,
    assetId,
    mockOfficeAsset,
  } = props;

  const fileType = getFileType(title);
  const fileTypeLowerCase = fileType.toLowerCase();

  const handleDownload = () => {
    window.location.href = `${url}&action=download`;
  };
  const handleOpenInOfficeOnline = () => {
    const wopiApiUrl = `${URLS.app}wopi/${assetId}`;
    const source = CancelToken.source();
    utilities && utilities.blockPage();
    v2Endpoint
      .get(wopiApiUrl, {
        withCredentials: true,
        cancelToken: source.token,
      })
      .then(response => {
        const accessToken = response.data?.data?.access_token;
        if (accessToken) {
          window.open(`${URLS.app}oos/files/${assetId}?access_token=${accessToken}&action=load`);
        } else {
          utilities?.dismissNotifications();
          utilities?.notification?.danger('Failed to start office online session');
        }
      })
      .catch(() => {
        utilities?.dismissNotifications();
        utilities?.notification?.danger('Failed to get office online token');
      })
      .then(() => {
        utilities?.unblockPage();
      });
    return () => {
      source && source.cancel('Component unmounted');
    };
  };

  const renderViewer = (): ReactElement => {
    if (FILE_EXTENSIONS_PDF.includes(fileTypeLowerCase)) {
      return <PulsePdfViewer documentUrl={url} classes={classes.pdfViewer} {...PulsePdfViewerProps} />;
    }
    if (FILE_EXTENSIONS_IMAGE.includes(fileTypeLowerCase)) {
      return <PulseImageViewer src={url} classes={classes.imageViewer} title={title} {...PulseImageViewerProps} />;
    }
    if (FILE_EXTENSIONS_VIDEO.concat(FILE_EXTENSIONS_AUDIO).includes(fileTypeLowerCase)) {
      return <PulseVideoPlayer url={url} classes={classes.videoPlayer} {...PulseVideoPlayerProps} />;
    }
    if (FILE_EXTENSIONS_OFFICE_SUPPORTED.concat(FILE_EXTENSIONS_AUDIO).includes(fileTypeLowerCase)) {
      return (
        <PulseOfficeViewer
          enableMock={mockOfficeAsset}
          assetId={assetId}
          src={url}
          PulseUnsupportedViewerProps={{
            onDownload: handleDownload,
            onOpenOfficeOnline: handleOpenInOfficeOnline,
          }}
          {...PulseOfficeViewerProps}
        />
      );
    }

    return (
      <PulseUnsupportedViewer
        classes={classes.unsupportedViewer}
        onDownload={handleDownload}
        onOpenOfficeOnline={handleOpenInOfficeOnline}
        {...PulseUnsupportedViewerProps}
      />
    );
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className={clsx(styles['pulse-asset-viewer__root'], classes.root)}>{renderViewer()}</div>
    </Suspense>
  );
};

export default PulseAssetViewer;
