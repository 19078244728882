import { Combo, ComboConfig } from '@bryntum/core-thin';

export const DEFAULT_TIMELINE_COLORS = [
  {
    name: 'teal',
    colorCode: '#32BFBD',
  },
  {
    name: 'green',
    colorCode: '#588157',
  },
  {
    name: 'light blue',
    colorCode: '#42A5F5',
  },
  {
    name: 'blue',
    colorCode: '#5C6BC0',
  },
  {
    name: 'light purple',
    colorCode: '#AB47BC',
  },
  {
    name: 'purple',
    colorCode: '#7E57C2',
  },
  {
    name: 'orange',
    colorCode: '#D65B26',
  },
  {
    name: 'yellow',
    colorCode: '#FFB703',
  },
  {
    name: 'red',
    colorCode: '#EF5350',
  },
];

export default class TimelineColorField extends Combo {
  // Factoryable type name
  static get type(): string {
    return 'timelineColorField';
  }

  static get defaultConfig(): Partial<ComboConfig> {
    return {
      clearable: true,
      items: DEFAULT_TIMELINE_COLORS,
      valueField: 'name',
      displayField: 'name',
      picker: {
        cls: 'b-color-picker-container',
        itemCls: 'b-color-picker-item',
        itemTpl: (item: any) =>
          `
            <div class="b-color-picker-item__preview" style="background-color:${item.colorCode}"></div>
            <div class="b-color-picker-item__text">${item.name}</div>
          `,
      } as any,
    };
  }
}
