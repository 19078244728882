import React, { ReactElement } from 'react';
import PulseLinkedTask from 'components/pulse-linked-tasks-list/components/pulse-linked-task/pulse-linked-task';
import PulseTasksSelect from 'components/pulse-select/pulse-tasks/pulse-tasks-select';
import { FormikProps } from 'formik';
import styles from './booking-linked-task.module.scss';
import { ValueType } from 'react-select';
import { PulseSelectOptionType } from 'components/pulse-select/base/pulse-select-base-types';

export interface LinkedTasksProps {
  formikProps: FormikProps<any>;
}

const LinkedTasks = ({ formikProps }: LinkedTasksProps): ReactElement => {
  const handleRemoveTask = id => {
    const updatedTaskList = formikProps.values.linkedTasks?.filter(task => task.value !== id);
    formikProps.setFieldValue('linkedTasks', updatedTaskList);
  };

  const handleAddValue = (value: ValueType<PulseSelectOptionType>) => {
    if (formikProps.values.linkedTasks) {
      const updatedTaskList = [...formikProps.values.linkedTasks, value?.['ticketid']].filter((task, idx) => {
        return (
          idx === [...formikProps.values.linkedTasks, value?.['ticketid']].findIndex(each => task.value === each.value)
        );
      });
      formikProps.setFieldValue('linkedTasks', updatedTaskList);
    } else {
      formikProps.setFieldValue('linkedTasks', [value?.['ticketid']]);
    }
  };

  return (
    <div className={styles['add-linked-tasks']}>
      <PulseTasksSelect
        labelName={''}
        url={
          formikProps.values?.job?.value
            ? `/v2/api/tickets?filter[jobid]=${formikProps.values.job.value}`
            : '/v2/api/tickets'
        }
        valueChangeHandler={handleAddValue}
        variant="select2"
        isClearable
        labelIcon={{
          icon: 'fal fa-search',
        }}
        placeholder={'Search for a Task...'}
      />
      {formikProps.values.linkedTasks?.map(task => {
        return <PulseLinkedTask key={task.value} linkedTask={task} onRemove={handleRemoveTask} />;
      })}
    </div>
  );
};

export default LinkedTasks;
