import { Grid, GridConfig } from '@bryntum/grid-thin';
import { Splitter } from '@bryntum/core-thin';

class ManageTimelinePhasesFlyout extends Grid {
  /**
   * The splitter between flyout and gantt
   */
  splitter?: Splitter;

  // Factoryable type name
  static get type(): string {
    return 'timerangesgrid';
  }

  static get defaultConfig(): Partial<GridConfig> {
    return {
      cls: 'time-ranges-grid',
      minWidth: 400,
      features: {
        stripe: true,
      },
      columns: [
        {
          text: 'Phase',
          field: 'name',
          minWidth: 110,
        },
        {
          type: 'date',
          text: 'Start Date',
          width: 110,
          align: 'right',
          field: 'startDate',
        },
        {
          type: 'number',
          text: 'Calendar Duration',
          width: 100,
          field: 'duration',
          min: 0,
          instantUpdate: false,
          renderer: (data: any) => `${data.record.duration}d`,
        },
      ],
    };
  }

  construct(config: Partial<GridConfig>): void {
    super.construct(config);
  }
}

// Register this widget type with its Factory
ManageTimelinePhasesFlyout.initClass();

export default ManageTimelinePhasesFlyout;
