import { MenuItem } from '@mui/material';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { PulseSimpleSelectOptionProps } from './pulse-simple-select-option-types';
import styles from './pulse-simple-select-option.module.scss';

export const PulseSimpleSelectOption = (props: PulseSimpleSelectOptionProps): ReactElement => {
  const { children, classes, ...restProps } = props;

  return (
    <MenuItem
      {...restProps}
      classes={{
        ...classes,
        root: clsx(styles.pulseSimpleSelectOptionRoot, classes?.root),
        selected: clsx(styles.selected, classes?.selected),
      }}
    >
      {children}
    </MenuItem>
  );
};

export default PulseSimpleSelectOption;
