/**
 * Taken from the original example
 */
import { Column, ColumnStore } from '@bryntum/grid-thin';

/**
 * @module StatusColumn
 */

/**
 * A column showing the status of a task
 *
 * @extends Gantt/column/Column
 * @classType statuscolumn
 */
export class GanttStatusColumn extends Column {
  static get $name() {
    return 'StatusColumn';
  }

  static get type() {
    return 'pulsestatuscolumn';
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      // Set your default instance config properties here
      field: 'status',
      text: 'Status',
      editor: false,
      cellCls: 'b-status-column-cell',
      htmlEncode: false,
      filterable: {
        filterField: {
          type: 'combo',
          items: ['Open', 'New', 'In Progress', 'Closed'],
        },
      },
    };
  }

  renderer({ record }) {
    const status = record.status;

    return {
      tag: 'span',
      html: status ?? '',
    };
  }
}

export const registerStatusColumn = () => {
  ColumnStore.registerColumnType(GanttStatusColumn);
};
