import React, { ReactElement, useEffect, useRef, FC, Ref, RefObject } from 'react';
import { CheckboxProps } from './checkbox-types';
import styles from './checkbox.module.scss';
import { composeClassNameFromArray } from 'pulse-commons/helpers';
import clsx from 'clsx';

/* istanbul ignore next */
const getIndexOfCheckbox = (currentCheckbox: RefObject<HTMLInputElement>): number | undefined => {
  const checkboxes = document.querySelectorAll('input[type=checkbox]');
  if (!currentCheckbox) {
    return;
  }
  for (let i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i] === currentCheckbox.current) {
      return i;
    }
  }
};

const Checkbox: FC<CheckboxProps> = (props: CheckboxProps): ReactElement => {
  const {
    className = [],
    label,
    labelClasses,
    labelPlacement,
    name,
    onClick,
    value = '',
    showLabel = false,
    checked = false,
    data,
    disabled = false,
  } = props;

  /** Array to contain list of classes to compose for the label */
  const checkboxCtnClassesArray: string[] = [clsx(...className, styles.checkbox__ctn, labelClasses)];

  /** If the user sets a position for the label, then add the relevant class */
  showLabel && labelPlacement && checkboxCtnClassesArray.push(styles[`label-${labelPlacement}`]);
  !showLabel && checkboxCtnClassesArray.push(styles['label-hidden']);
  const processedCheckboxCtnClasses: string = composeClassNameFromArray(checkboxCtnClassesArray);

  const currentCheckbox: Ref<HTMLInputElement> = useRef(null);

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onClick && onClick(e, data);
  };

  useEffect(() => {
    /** This is to set a default name for the label and the input element */
    if (!name) {
      /** Get the index of the current checkbox */
      const currentCheckboxIndex = currentCheckbox && getIndexOfCheckbox(currentCheckbox);

      if (currentCheckbox && label) {
        const currentCheckboxEl = currentCheckbox.current;
        if (currentCheckboxEl) {
          /** Set the name for the label of the current checkbox */
          (currentCheckboxEl.parentNode as HTMLLabelElement)?.setAttribute('for', `checkbox${currentCheckboxIndex}`);

          /** Set the name for the current checkbox */
          currentCheckboxEl.setAttribute('name', `checkbox${currentCheckboxIndex}`);
          currentCheckboxEl.setAttribute('id', `checkbox${currentCheckboxIndex}`);
        }
      }
    }
  }, []);

  return (
    <div data-testid="pcs-checkbox" className={processedCheckboxCtnClasses}>
      <label className={labelClasses} htmlFor={name}>
        <span>{label && label}</span>
        <input
          id={name}
          ref={currentCheckbox}
          className={styles['pcs-checkbox__control']}
          onChange={handleClick}
          type="checkbox"
          value={value}
          name={name}
          checked={checked}
          disabled={disabled}
        />
      </label>
    </div>
  );
};

const isEqual = (prevState: CheckboxProps, nextState: CheckboxProps): boolean => {
  for (const key in prevState) {
    if (prevState[key] !== nextState[key]) {
      return false;
    }
  }
  return true;
};

export default React.memo(Checkbox, isEqual);
