import axios, { CancelTokenSource } from 'axios';
import { GetConfig, PulseSubRequestObject } from 'pulse-types/pulse-resource';
import { getEnv, ALLOWED_ENV } from 'pulse-commons/helpers';
import qs from 'qs';

export enum METHODS {
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  POST = 'POST',
}

export type PulsePageObject = {
  'current-page': number;
  'per-page': number;
  from: number;
  to: number;
  total: number;
  'last-page': number;
};

export type PulseMetaObject = {
  page: PulsePageObject;
};

export type PulseLinksObject = {
  first: string;
  next: string;
  last: string;
};

export type PulseResponseObject<ResourceObject> = {
  meta: PulseMetaObject;
  links: PulseLinksObject;
  data: ResourceObject | ResourceObject[];
};

export type PulseDeleteObject<T extends string> = {
  meta: {
    message: T;
  };
};

export type PulseBaseResourceObject<ResourceAttributes, ResourceType extends string, METHOD = METHODS.GET> = {
  type: ResourceType;
  attributes: METHOD extends METHODS.GET ? ResourceAttributes : Partial<ResourceAttributes>;
};

export type PulseResourceObject<
  ResourceAttributes,
  ResourceType extends string,
  METHOD = METHODS.GET
> = METHOD extends METHODS.POST
  ? PulseBaseResourceObject<ResourceAttributes, ResourceType, METHOD>
  : PulseBaseResourceObject<ResourceAttributes, ResourceType, METHOD> & { id: string };

export type PulseRequestObject<
  ResourceAttributes,
  ResourceType extends string,
  METHOD extends Exclude<METHODS, 'GET'>
> = {
  data: PulseResourceObject<ResourceAttributes, ResourceType, METHOD>;
};

const PULSE_LOCAL_URL = getEnv(ALLOWED_ENV['PULSE_LOCAL_URL'], '');
const isProd = process.env.NODE_ENV === 'production';

const v2Endpoint = axios.create({
  baseURL: isProd ? undefined : window?.pulse?.config?.urls?.pulse || PULSE_LOCAL_URL,
  responseType: 'json',
  withCredentials: true,
});

const CancelToken = axios.CancelToken;

/**
 * Common function to generate an axios
 * request config to send to axios.request()
 *
 * @param params
 * @param url The url to send the request to
 */
const getConfig: (config: GetConfig, url: string) => PulseSubRequestObject = (
  { params, requestId, jq, options },
  url,
): PulseSubRequestObject => {
  if (params) {
    params.jq = jq;
  }

  let requestConfig: PulseSubRequestObject = {
    method: 'GET',
    url,
    requestId,
    ...(options?.axiosRequestConfig || {}),
  };

  if (options?.serialiseParams) {
    const serialisedUrl = `${url}?${qs.stringify(params)}`;
    requestConfig = {
      ...requestConfig,
      url: serialisedUrl,
    };
  } else {
    requestConfig = {
      ...requestConfig,
      params,
    };
  }
  /** Add jq to the request config if provided */
  jq && (requestConfig.jq = jq);

  return requestConfig;
};

const cancelSource = (source: CancelTokenSource, msg = 'Cancelling previous request'): void => {
  if (source) {
    source.cancel(msg);
  }
};

export { cancelSource, getConfig, v2Endpoint, CancelToken };
