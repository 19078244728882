import React, { FC, forwardRef, memo, useEffect, useMemo, useRef } from 'react';
import PulseBryntumGrid from 'components/pulse-bryntum-grid';
import PulseSwitch from 'components/pulse-switch/pulse-switch';
import { isEqual } from 'lodash';
import { Colors } from 'pulse-commons/types';
import { NonWorkingDaysModel, OfficeNonWorkingDaysTabPanelProps } from '../non-working-days-modal-types';
import { formatDate, getAjaxStoreConfig } from './helpers';
import styles from '../non-working-days-modal.module.scss';
import { BryntumColumnConfig } from 'components/pulse-bryntum-grid/pulse-bryntum-grid.type';

const NonWorkingDaysGrid = forwardRef<
  any,
  { ajaxStoreConfig: any; onSwitchChanged: (record: NonWorkingDaysModel) => void }
>((props, gridRef) => {
  const { ajaxStoreConfig, onSwitchChanged } = props;
  const columnConfigs = useMemo(
    () => [
      {
        text: 'Description',
        field: 'name',
        minWidth: 200,
        flex: '1 1 100%',
        editor: false,
      },
      {
        text: 'Start Date',
        field: 'startDate',
        type: 'date',
        align: 'center',
        hideable: false,
        sortable: false,
        draggable: false,
        resizable: false,
        width: 120,
        editor: false,
        renderer: ({ value }) => formatDate(value),
      },
      {
        text: 'End Date',
        field: 'endDate',
        type: 'date',
        align: 'center',
        hideable: false,
        sortable: false,
        draggable: false,
        resizable: false,
        width: 120,
        editor: false,
        renderer: ({ value }) => formatDate(value),
      },
      {
        text: 'Enable',
        field: 'isWorking',
        align: 'center',
        hideable: false,
        sortable: false,
        draggable: false,
        resizable: false,
        width: 80,
        editor: false,
        renderer: ({ record, value }) => (
          <PulseSwitch
            key={record.id}
            color={Colors.secondary}
            checked={!value}
            onChange={() => {
              onSwitchChanged(record.data);
            }}
            labelLeft=""
            labelRight=""
          />
        ),
      },
    ],
    [],
  );
  return (
    <PulseBryntumGrid
      ref={gridRef}
      ajaxStoreConfig={ajaxStoreConfig}
      classes={{
        root: styles['non-working-days-modal__tabs__grid'],
      }}
      gridProps={{
        columns: columnConfigs as BryntumColumnConfig,
      }}
      paginate={true}
    />
  );
});

const OfficeNonWorkinDayGrid = memo(NonWorkingDaysGrid, (props, nextProps) =>
  isEqual(props.ajaxStoreConfig, nextProps.ajaxStoreConfig),
);

const OfficeNonWorkinDaysTabPanel: FC<OfficeNonWorkingDaysTabPanelProps> = props => {
  const { timelineId, officeNonWorkingDays, onWorkingChanged } = props;
  const gridRef = useRef<any>(null);
  const nonWorkingRefs = useRef<any>(officeNonWorkingDays || []);

  const ajaxStoreConfig = useMemo(
    () => ({
      ...getAjaxStoreConfig(timelineId, 'officeHoliday'),
      listeners: {
        afterRequest: () => {
          if (!gridRef.current || !nonWorkingRefs.current?.length) {
            return;
          }
          const { gridInstance } = gridRef.current;
          const officeHolidays = nonWorkingRefs.current;
          gridInstance.store.records.forEach(record => {
            const nonWorkingDay = officeHolidays.find(it => it.id == record.data.id);

            if (nonWorkingDay) {
              record.isWorking = nonWorkingDay.isWorking;
            }
          });
        },
      },
    }),
    [timelineId],
  );

  const handleChangeNonWorking = (record: NonWorkingDaysModel) => {
    if (!gridRef.current) {
      return;
    }
    const { gridInstance } = gridRef.current;
    const nonWorkingIndex = nonWorkingRefs.current.findIndex(it => it.id === record.id);

    const officeNonWorkingDays = [...nonWorkingRefs.current];

    if (nonWorkingIndex !== -1) {
      const nonWorking = officeNonWorkingDays[nonWorkingIndex];
      officeNonWorkingDays[nonWorkingIndex].isWorking = !nonWorking.isWorking;
    } else {
      officeNonWorkingDays.push({ ...record, isWorking: !record.isWorking });
    }

    // Update toggle status
    gridInstance.store.records.forEach(r => {
      if (r.id === record.id) {
        r.isWorking = !r.isWorking;
        return;
      }
    });

    // Update refs
    nonWorkingRefs.current = officeNonWorkingDays;

    // Publics changed to parent components
    onWorkingChanged(officeNonWorkingDays);
  };

  useEffect(() => {
    if (!gridRef.current) {
      return;
    }
    const { gridInstance } = gridRef.current;
    gridInstance.store.load();
  }, []);

  return (
    <>
      <div className={styles['non-working-days-modal__warning-ctn']}>
        <div className={styles['non-working-days-modal__warning-ctn__icon-wrapper']}>
          <i className="fal fa-info-circle"></i>
        </div>
        <div className={styles['non-working-days-modal__warning-ctn__content']}>
          <div className={styles['non-working-days-modal__warning-ctn__content__header']}>Office Non-Working Days</div>
          <div className={styles['non-working-days-modal__warning-ctn__content__description']}>
            Non-Working-Days are defined for the office where this project resides. Additional Non-Working-Days that are
            added will be applied to all other timelines created in this office.
          </div>
        </div>
      </div>
      <OfficeNonWorkinDayGrid
        ref={gridRef}
        ajaxStoreConfig={ajaxStoreConfig}
        onSwitchChanged={handleChangeNonWorking}
      />
    </>
  );
};

export default OfficeNonWorkinDaysTabPanel;
