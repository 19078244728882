import React, { ReactElement, useState } from 'react';
import { PulseLinkedTasksListProps } from './pulse-linked-tasks-list-types';
import { PulseSelectBasePropTypes } from 'components/pulse-select/base/pulse-select-base-types';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';
import PulseLinkedTask from './components/pulse-linked-task/pulse-linked-task';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import styles from './pulse-linked-tasks-list.module.scss';
import { PulseTasksSelect } from 'components/pulse-select/pulse-tasks/pulse-tasks-select';
import RPBookingModel from '../pulse-resource-planner/models/rp-booking-model';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { ButtonVariants } from 'pulse-commons/types';
import { useSingleton } from '@tippyjs/react';

import clsx from 'clsx';
import { Divider } from '@mui/material';

const SELECT_TASK_INCLUDE = 'type,ticketStatus,tags';
const SELECT_TASK_JQ =
  '{ data: [ .included as $included | .data[] as $data | $data | .relationships.type.data as $ticketType | .relationships.ticketStatus.data as $ticketStatus | .relationships.tags.data as $tags | { id: .id, type: .type, attributes: (.attributes %2B { ticketStatus: (if $ticketStatus then ($included[] | select(.type == "resourceStatus" and .id == $ticketStatus.id) | .attributes) else null end), ticketType: (if $ticketType then ($included[] | select(.type == "ticket-types" and .id == $ticketType.id) | .attributes) else null end), tags: (if $tags then ($tags | map(. as $tag | $included[] | select(.type == "tags" and .id == $tag.id) | .attributes )) else null end) }) }], meta: .meta, links: .links }';
const ENDPOINT = '/v2/api/tickets';

export const PulseLinkedTasksList = (props: PulseLinkedTasksListProps): ReactElement => {
  const {
    bookingEndDate = new Date(),
    label = 'Link booking to task',
    PulseLinkedTaskProps,
    onChange,
    onRemove,
    values,
    jobId,
    PulseSelectPropTypes,
    PulseTooltipProps,
    classes,
  } = props;

  const url = jobId ? `${ENDPOINT}?filter[jobid]=${jobId}` : ENDPOINT;
  const [openSelect, setOpenSelect] = useState(false);
  const [source, target] = useSingleton();

  const onChangeLinkedTasks = (task: { ticketid: PulseSelectBasePropTypes['value'] }): void => {
    /** We need to normalise the linked tasks as it can be an array of object or just an object */
    let processedLinkedTasks: RPBookingModel['linkedTasks'] = [];
    const { ticketid } = task;
    ticketid && (processedLinkedTasks = isArray(ticketid) ? ticketid : [ticketid]);
    isFunction(onChange) && onChange(processedLinkedTasks);
  };

  const handleClick = () => {
    setOpenSelect(!openSelect);
  };
  return (
    <section data-testid="linked-tasks-list-test" className={clsx(styles['linkTaskList__root'], classes?.root)}>
      <div className={clsx(styles['linkTaskList__selectTaskCtn'], classes?.selectCtn)}>
        <div className={styles['linkTaskList__selectTaskLabel--root']}>
          <label className={clsx([styles['linkTaskList__selectTaskLabel--text']], classes?.label)}>{label}</label>
        </div>
        <div className={styles['linkTaskList__selectTaskButton']}>
          {!openSelect ? (
            <PulseButtonBase
              onClick={handleClick}
              classes={[styles['linkTaskList__selectTaskButton--root']]}
              icon={true}
              iconClasses={{ icon: 'fal fa-plus' }}
              label={'add link task'}
              variant={ButtonVariants.text}
            />
          ) : (
            <PulseTasksSelect
              classes={{
                // label: styles['selectTask__label'],
                select: styles['selectTask__select'],
              }}
              isDisabled={false}
              renderSelectedValues={false}
              variant="select2"
              placeholder="Search for a Task…"
              url={url}
              extraParams={{ include: SELECT_TASK_INCLUDE, jq: SELECT_TASK_JQ }}
              /**
               * If the booking is not new, set this as a multi
               * select as before, bookings could be linked
               * to multiple tasks
               */
              key={jobId}
              labelName=""
              /**
               * If it's a new booking the select should be
               * a single select and render the selected
               * task
               */
              value={values}
              valueChangeHandler={onChangeLinkedTasks}
              {...PulseSelectPropTypes}
            />
          )}
        </div>
      </div>
      <div className={clsx(styles['linkTaskList__linkTaskCtn'], classes?.linkTaskCtn)}>
        <PulseTooltip TippyProps={{ singleton: source }} {...PulseTooltipProps} />
        {values?.map(linkedTask => {
          return (
            <React.Fragment key={linkedTask.value}>
              <PulseLinkedTask
                classes={{
                  root: styles['linkTaskList__linkTaskRoot'],
                  taskName: styles['linkTaskList__linkTaskName'],
                }}
                linkedTask={linkedTask}
                onRemove={onRemove}
                bookingEndDate={bookingEndDate}
                tippyTarget={target}
                {...PulseLinkedTaskProps}
              />
              <Divider
                classes={{ root: clsx(styles['linkTaskList__divider'], classes?.linkedTaskDivider) }}
                variant="middle"
              />
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default PulseLinkedTasksList;
