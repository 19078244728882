import { useSingleton } from '@tippyjs/react';
import clsx from 'clsx';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';
import { PULSE_LOCAL_URL } from 'pulse-commons/helpers';
import React, { ReactElement, useCallback } from 'react';
import PulseGalleryItem from '../pulse-gallery-item/pulse-gallery-item';
import { PulseGalleryItemsProps } from './pulse-gallery-items-types';
import styles from './pulse-gallery-items.module.scss';

export const PulseGalleryItems = (props: PulseGalleryItemsProps): ReactElement => {
  const { assets, classes, PulseGalleryItemProps, onSelectionChange, selectedAssets = [], showLoading = 0 } = props;
  const [source, target] = useSingleton();

  const generateLoadingAssets = useCallback((): any[] => {
    const loadingAssets: any[] = [];
    for (let i = 0; i < showLoading; i++) {
      loadingAssets.push({});
    }
    return loadingAssets;
  }, [showLoading]);

  return (
    <>
      <PulseTooltip TippyProps={{ singleton: source }} />
      <div className={clsx(styles.pulseGalleryItemsRoot, classes?.root)}>
        {Boolean(showLoading) &&
          generateLoadingAssets().map((fakeAsset, index) => (
            <PulseGalleryItem
              {...PulseGalleryItemProps}
              CardProps={{
                classes: {
                  root: styles.pulseGalleryItems_Item,
                },
              }}
              CardMediaProps={{
                height: 100,
                classes: {
                  root: styles.pulseGalleryItems_CardMediaRoot,
                },
              }}
              isLoading
              assetMetaData={fakeAsset}
              assetThumbnailSrc={`${PULSE_LOCAL_URL}/open.php?format=thumb&assetid=${fakeAsset.assetId}`}
              key={index}
            />
          ))}
        {!showLoading &&
          assets.length &&
          assets.map(asset => {
            return (
              <PulseGalleryItem
                {...PulseGalleryItemProps}
                CardProps={{
                  classes: {
                    root: styles.pulseGalleryItems_Item,
                  },
                }}
                CardMediaProps={{
                  height: 100,
                  classes: {
                    root: styles.pulseGalleryItems_CardMediaRoot,
                  },
                }}
                assetMetaData={asset}
                assetThumbnailSrc={`${PULSE_LOCAL_URL}/open.php?format=thumb&assetid=${asset.assetId}`}
                key={asset.assetId}
                onClick={onSelectionChange}
                PulseTooltipProps={{
                  TippyProps: {
                    singleton: target,
                  },
                }}
                selected={selectedAssets.includes(asset.assetId)}
              />
            );
          })}
      </div>
    </>
  );
};

export default PulseGalleryItems;
