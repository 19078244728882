import Cookies from 'js-cookie';
const LOCAL_STORAGE_PREFIX = 'PULSE';

const getStorageKey = (key: string) => `${LOCAL_STORAGE_PREFIX}_${key}`;

const PULSE_DOMAIN = (window?.pulse?.config?.urls?.pulse || '').replace(/(https:\/\/||http:\/\/)/, '');
/**
 * Get value from cookies
 * @param key name of key  in cookie
 * @returns value in Cookies as an object
 */
export const getCachedItems = (key: string): any => {
  const data = Cookies.get(getStorageKey(key));

  if (data) {
    return JSON.parse(data);
  }

  return {};
};

/**
 * Save value to cookies
 * @param key name of key  in cookie
 */
export const saveCachedItems = (key: string, value: unknown): void => {
  if (!value) {
    return;
  }
  Cookies.set(getStorageKey(key), JSON.stringify(value), { path: '/', domain: PULSE_DOMAIN });
};

/**
 * Remove value from cookies
 * @param key name of key  in cookie
 */
export const removeCachedItems = (key: string): void => Cookies.remove(getStorageKey(key), { path: '' });
