import React, { ReactElement } from 'react';
import { PulseLoaderProps } from './pulse-loader-types';
import styles from './pulse-loader.module.scss';
import clsx from 'clsx';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PCSSpinner, { PCSSpinnerVariants } from 'components/spinner/pcs-spinner';
export const PulseLoader = (props: PulseLoaderProps): ReactElement => {
  const { classes, isVisible = false, loadingText = 'Loading ...' } = props;

  return (
    <PulseModalBase
      classes={{ root: [clsx(styles['pulse-loader__root'], classes?.root)] }}
      header={false}
      footer={false}
      open={isVisible}
    >
      <div className={clsx(styles['pulse-loader__children'], classes?.children)}>
        <PCSSpinner
          classes={{ root: [clsx(styles['pulse-loader__spinner'], classes?.spinner)] }}
          variant={PCSSpinnerVariants.circularSpinner}
        />
        <div className={clsx(styles['pulse-loader__loading--text'], classes?.loadingText)}>{loadingText}</div>
      </div>
    </PulseModalBase>
  );
};

export default PulseLoader;
