/* eslint-disable @typescript-eslint/no-unused-vars */

import clsx from 'clsx';
import PulseMenuButton from 'components/pulse-button/menu/pulse-menu-button';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import { ButtonVariants } from 'pulse-commons/types';
import React, { FC } from 'react';
import styles from '../../pulse-resource-planner.module.scss';

/* eslint-enable @typescript-eslint/no-unused-vars */

type ResourcePlannerGroupByProps = {
  selectedGroupBy: GROUP_BY;
  onChange: (columnsGroupBy: GROUP_BY) => void;
  isProjectview: boolean;
};

export enum GROUP_BY {
  nogrouping = 'nogrouping',
  projects = 'job.attributes.jobTitle',
  departments = 'departments.name',
  roles = 'roles.name',
  offices = 'client',
  users = 'name',
  utilization = 'name',
}

export enum GROUP_BY_LABEL {
  default = 'Value',
  projects = 'Project',
  departments = 'Department',
  roles = 'Role',
  offices = 'Offices',
  users = 'Names',
  utilization = 'Utilization',
}
export const getGroupLabel = (groupField: GROUP_BY): string => {
  let label = GROUP_BY_LABEL.default;
  switch (groupField) {
    case GROUP_BY.projects:
      label = GROUP_BY_LABEL.projects;
      break;
    case GROUP_BY.departments:
      label = GROUP_BY_LABEL.departments;
      break;
    case GROUP_BY.roles:
      label = GROUP_BY_LABEL.roles;
      break;
    case GROUP_BY.offices:
      label = GROUP_BY_LABEL.offices;
      break;
    case GROUP_BY.users:
      label = GROUP_BY_LABEL.users;
      break;
    case GROUP_BY.utilization:
      label = GROUP_BY_LABEL.utilization;
      break;
    default:
      label = GROUP_BY_LABEL.default;
      break;
  }
  return label;
};

const GROUP_BY_DATA = [
  { label: 'Resources', icon: 'fal fa-user', groupBy: GROUP_BY.nogrouping },
  { label: 'Utilization', icon: 'fal fa-tachometer-alt-fast', groupBy: GROUP_BY.utilization },
  { label: 'Projects', icon: 'fal fa-rocket-launch', groupBy: GROUP_BY.projects },
  { label: 'Departments', icon: 'fal fa-door-open', groupBy: GROUP_BY.departments },
  { label: 'Roles', icon: 'fal fa-user-circle', groupBy: GROUP_BY.roles },
  { label: 'Offices', icon: 'fal fa-building', groupBy: GROUP_BY.offices },
];

const ResourcePlannerGroupBy: FC<ResourcePlannerGroupByProps> = (props: ResourcePlannerGroupByProps) => {
  const { selectedGroupBy, onChange, isProjectview } = props;

  const handleClick = (columnsGroupBy: GROUP_BY) => (): void => {
    isFunction(onChange) && onChange(columnsGroupBy);
  };

  const menuButtonData = GROUP_BY_DATA.filter(groupByData => {
    if (isProjectview) {
      return groupByData.groupBy !== GROUP_BY.utilization && groupByData.groupBy !== GROUP_BY.projects;
    }
    return groupByData;
  }).map(groupByData => {
    return {
      label: groupByData.label,
      classes: [clsx(styles['rp-menu-item'], selectedGroupBy === groupByData.groupBy && styles['active'])],
      icon: true,
      iconClasses: {
        icon: groupByData.icon,
        root: styles['rp-menu-item-icon'],
      },
      onClick: handleClick(groupByData.groupBy),
    };
  });

  return (
    <PulseMenuButton
      classes={{
        menuCtn: styles['rp-groupby-menu-ctn'],
      }}
      menuButtonData={menuButtonData}
      PulseButtonProps={{
        classes: [styles['rp-menu-btn']],
        iconClasses: {
          icon: `${styles['rp-menu-btn-icon']} fal fa-chevron-down`,
        },
        variant: ButtonVariants.contained,
        label: 'Group By',
        onClick: noop,
      }}
      TippyProps={{
        arrow: false,
        offset: [0, 0],
        zIndex: 1000,
        trigger: 'mouseenter click',
      }}
    />
  );
};

export default React.memo(ResourcePlannerGroupBy);
