import { AjaxStoreConfig } from '@bryntum/core-thin';
import moment from 'moment';
import { NonWorkingDateType } from '../non-working-days-modal-types';

export const USER = window?.pulse?.config?.user;

export const BASE_URL = window?.pulse?.config?.urls?.app;

export const formatDate = (dateString: string): string => {
  if (dateString) {
    const formattedDate = moment(dateString).format(USER ? USER.dateFormat : 'MM/DD/YYYY');
    return formattedDate;
  }
  return '';
};

export const getCurrentDate = (): string => moment().format(USER ? USER.dateFormat : 'MM/DD/YYYY');

export const getAjaxStoreConfig = (
  timelineId: string | number,
  nonWorkingType: NonWorkingDateType,
): Partial<AjaxStoreConfig> => ({
  readUrl: `${BASE_URL}api/timelines-non-working-days`,
  autoLoad: false,
  autoCommit: false,
  autoTree: false,
  params: {
    'filter[type]': nonWorkingType,
    'filter[timelines_id]': timelineId,
    jq: `{ data: [ .data[] as $data | $data | { id: .id, name: .attributes.name, startDate: .attributes.startDate, endDate: .attributes.endDate, cls: .attributes.cls, isWorking: .attributes.isWorking } ], page: .meta.page }`,
  },
  pageSize: 5,
  pageParamName: 'page[number]',
  pageSizeParamName: 'page[size]',
  responseTotalProperty: 'page.total',
});
