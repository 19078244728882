import React, { ReactElement, ReactNode, useContext, useEffect, useReducer } from 'react';
import PulseDatePickerReducer, {
  Actions,
  PulseDatePickerAction,
  pulseDatePickerInitialState,
  PulseDatePickerState,
} from '../reducer/pulse-datepicker-reducer';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isFunction from 'lodash/isFunction';

export const PulseDatePickerStateContext: React.Context<PulseDatePickerState> = React.createContext(
  {} as PulseDatePickerState,
);
export const PulseDatePickerDispatchContext: React.Context<React.Dispatch<PulseDatePickerAction>> = React.createContext(
  {} as React.Dispatch<PulseDatePickerAction>,
);

export const PulseDatePickerProvider = ({
  children,
  providedState,
}: {
  children: ReactNode;
  providedState: Partial<PulseDatePickerState>;
}): ReactElement => {
  const [state, dispatch] = useReducer(PulseDatePickerReducer, pulseDatePickerInitialState);

  useEffect(() => {
    dispatch({
      type: Actions.set,
      payload: providedState,
    });
  }, [providedState]);

  useEffect(() => {
    isFunction(state.onChange) && state.onChange(state);
  }, [
    state.values?.startDate && isValid(state.values?.startDate) && format(state.values?.startDate, 'yyyy-MM-dd'),
    state.values?.endDate && isValid(state.values?.endDate) && format(state.values?.endDate, 'yyyy-MM-dd'),
  ]);

  return (
    <PulseDatePickerStateContext.Provider value={state}>
      <PulseDatePickerDispatchContext.Provider value={dispatch}>{children}</PulseDatePickerDispatchContext.Provider>
    </PulseDatePickerStateContext.Provider>
  );
};

export const usePulseDatePickerState = (): PulseDatePickerState => {
  const context = useContext(PulseDatePickerStateContext);
  if (context === undefined) {
    throw new Error('usePulseDatePickerState must be used within a PulseDatePickerProvider');
  }
  return context;
};

export const usePulseDatePickerDispatch = (): React.Dispatch<PulseDatePickerAction> => {
  const context = useContext(PulseDatePickerDispatchContext);
  if (context === undefined) {
    throw new Error('usePulseDatePickerDispatch must be used within a PulseDatePickerProvider');
  }
  return context;
};
