import {
  Gantt,
  ProjectModel,
  CalendarManagerStore,
  TaskStore,
  AssignmentStore,
  DependencyStore,
  ResourceStore,
} from '@bryntum/gantt-thin';
import { PulseTimelineTaskModel } from 'components/pulse-timeline/model/pulse-timeline-model';
import moment from 'moment';
import {
  MsProjectAssignmentModel,
  MsProjectCalendarModel,
  MsProjectDataModel,
  MsProjectDependencyModel,
  MsProjectResourceModel,
  MsProjectTaskModel,
} from './ms-project-import-models';

export default class MsProjectImportHelper {
  private ganttInstance: Gantt;
  private project: ProjectModel;

  private calendarStore: CalendarManagerStore;

  private taskStore: TaskStore;
  private assignmentStore: AssignmentStore;
  private resourceStore: ResourceStore;
  private dependencyStore: DependencyStore;

  private calendarMap: Map<number | string, any>;
  private taskMap: Map<number | string, any>;
  private resourceMap: Map<number | string, any>;

  constructor(ganttInstance: Gantt) {
    this.ganttInstance = ganttInstance;

    const project = ganttInstance.project;

    this.project = project;

    this.calendarStore = project.calendarManagerStore;
    this.taskStore = project.taskStore;
    this.assignmentStore = project.assignmentStore;
    this.resourceStore = project.resourceStore;
    this.dependencyStore = project.dependencyStore;

    this.calendarMap = new Map();
    this.taskMap = new Map();
    this.resourceMap = new Map();
  }

  async loadMsProject(msProjectData: MsProjectDataModel): Promise<ProjectModel> {
    const { assignments, dependencies, resources, tasks, project, calendars } = msProjectData;

    this.loadCalendars(calendars);

    const addedTasks = (Array.isArray(tasks) ? tasks : [tasks]).map(this.parseTask, this);

    // Add Resource
    this.resourceStore.add(resources.map(this.parseResource, this));

    // Add Assignments
    this.assignmentStore.add(assignments.map(this.parseAssignment, this));

    this.taskStore.rootNode.appendChild(addedTasks[0].children as any);

    this.dependencyStore.add(dependencies.map(this.parseDependency, this));

    // Temparory close for multi calendar config in furture feature
    // Add calendar to Gantt Project
    // if ('calendar' in project) {
    //   Object.assign(this.project, {
    //     ...project,
    //     calendar: this.calendarMap.get(project.calendar || ''),
    //   });
    // }

    // Assign the new project to the gantt before launching commitAsync()
    // to let Gantt resolve possible scheduling conflicts
    const importStartDate = moment(project.startDate);
    const importEndDate = moment(project.endDate);
    const currentStartDate = moment(this.ganttInstance.project.startDate);
    const currentEndDate = moment(this.ganttInstance.project.endDate);

    // Set project start date to import start date if current start date > import start date
    if (currentStartDate.isAfter(importStartDate)) {
      this.ganttInstance.project.startDate = importStartDate.format();
    }

    // Set project end date to import end date if current end date < import start date
    if (currentEndDate.isBefore(importEndDate)) {
      this.ganttInstance.project.endDate = importEndDate.format();
    }
    // this.ganttInstance.project = this.project;

    await this.project.commitAsync();

    await this.ganttInstance.scrollRowIntoView(this.taskStore.last);

    return this.project;
  }

  /**
   * Load calendar data from MS Project to Gantt
   * @param calendars - the calendars from Ms Projects
   */
  private loadCalendars(calendars: Partial<MsProjectCalendarModel>) {
    const { children } = calendars;
    if (!children) {
      return;
    }

    // Parse and add to Calendar Store
    this.calendarStore.add(children.map(this.parseCalendar, this));
  }

  /**
   * Parse Ms Project Calendar to Gantt Calendar
   * @param data - calendar data
   * @returns Gantt Calendar Model
   */
  private parseCalendar(data: Partial<MsProjectCalendarModel>) {
    const { id = '', children, intervals, ...rest } = data;

    const calendar = new this.calendarStore.modelClass({
      intervals: intervals?.filter(item => !item.recurrentStartDate),
      ...rest,
    } as any);

    // Continue add all children of current calendar
    if (children) {
      calendar.appendChild(children.map(this.parseCalendar, this));
    }

    calendar.set('_id', id);

    // Push to map for mapping task calendar when add task
    this.calendarMap.set(id, calendar);

    return calendar;
  }

  /**
   * Parse Ms Project Task to Gantt Task Item
   * @param data - task data
   * @returns Gantt Task Model
   */
  private parseTask(data: Partial<MsProjectTaskModel>) {
    const {
      id = '',
      children,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      calendar: calendarId = '',
      startDate,
      endDate,
      ...rest
    } = data;

    // Data will be save as pulse timeline item
    const pulseTimelineTask = {
      ...rest,
      startDate: moment(startDate).startOf('day').toDate(),
      endDate: moment(endDate).startOf('day').toDate(),
    };

    // Remove unecessary poperty from ms project
    delete pulseTimelineTask.milestone;

    // Let Bryntum Gantt re-calculate duration base on calendar settings
    // delete pulseTimelineTask.duration;

    // Because multi calendar feature is disable the bellow code will be marked as comment
    // const calendar = this.calendarMap.get(calendarId);

    const task = new PulseTimelineTaskModel({
      ...pulseTimelineTask,
      manuallyScheduled: true,
    } as any);

    // Continue add all children of current task
    if (children && children.length) {
      task.set('status', 'Parent');
      task.set('constraintDate', null);
      task.set('constraintType', null);
      task.appendChild(children.map(it => this.parseTask({ ...it }), this));
    } else {
      task.set('status', 'New');
    }

    task.set('_id', id);

    // Push to map for mapping dependencies, assigments and resources
    this.taskMap.set(id, task);

    return task;
  }

  /**
   * Parse Ms Project Resource to Gantt Resource
   * @param data - resource data
   * @returns Gantt Resource Model
   */
  private parseResource(data: Partial<MsProjectResourceModel>) {
    const { id = '', calendar: calendarId = '', name } = data;

    const calendar = this.calendarMap.get(calendarId);

    const resource = new this.resourceStore.modelClass({
      name,
      calendar,
    } as any);

    // Push to map for mapping assigments and resources
    this.resourceMap.set(id, resource);

    return resource;
  }

  /**
   * Parse Ms Project Resource to Gantt Resource
   * @param data - resource data
   * @returns Gantt Resource Model
   */
  private parseAssignment(data: Partial<MsProjectAssignmentModel>) {
    const { event = '', resource = '', units } = data;

    const assignment = new this.assignmentStore.modelClass({
      units,
      event: this.taskMap.get(event),
      resource: this.resourceMap.get(resource),
    } as any);

    return assignment;
  }

  /**
   * Parse Ms Project Resource to Gantt Resource
   * @param data - resource data
   * @returns Gantt Resource Model
   */
  private parseDependency(data: Partial<MsProjectDependencyModel>) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id = '',
      fromEvent = '',
      toEvent = '',
      ...rest
    } = data;
    const from = this.taskMap.get(fromEvent)?.id;
    const to = this.taskMap.get(toEvent)?.id;
    const dependency = new this.dependencyStore.modelClass({
      from,
      fromEvent: from,
      to,
      toEvent: to,
      ...rest,
    } as any);

    return dependency;
  }
}
