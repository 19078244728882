import React, { ReactElement, useMemo } from 'react';
import { PulseLinkTaskTimelineItemProps } from './pulse-link-task-timeline-item-types';
import styles from './pulse-link-task-timeline-item.module.scss';
import clsx from 'clsx';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';
import { format } from 'date-fns';
import { Box, Grid, Typography, TypographyProps } from '@mui/material';

const datePattern = 'MM/dd/yyyy';
export const PulseLinkTaskTimelineItem = (props: PulseLinkTaskTimelineItemProps): ReactElement => {
  const { classes, timeline, content, onClick, pulseTooltipProps, task, timelineUrl } = props;
  const startDateContent =
    timeline?.startDate instanceof Date ? format(timeline?.startDate, datePattern) : timeline?.startDate;
  const endDateContent = timeline?.endDate instanceof Date ? format(timeline?.endDate, datePattern) : timeline?.endDate;

  const renderContent = useMemo(
    () =>
      content ? (
        <span>{content}</span>
      ) : (
        <span>{`${startDateContent ?? 'invalid'} - ${endDateContent ?? 'invalid'}`}</span>
      ),
    [content, timeline],
  );

  const leftIconClasses = useMemo(
    () => clsx(classes?.leftIcon?.icon ?? 'fal fa-chart-gantt', classes?.leftIcon?.root),
    [classes?.leftIcon],
  );

  const actionIconClasses = useMemo(
    () => clsx(classes?.actionIcon?.icon ?? 'fal fa-unlink', styles['action-icon'], classes?.actionIcon?.root),
    [classes?.actionIcon],
  );

  const tooltipTimelineFields = useMemo<
    Array<{
      valueTypographyProps?: Partial<TypographyProps>;
      [key: string]: any;
    }>
  >(
    () => [
      {
        name: 'Timeline',
        value: timeline?.timelineName,
        valueTypographyProps: { sx: { textDecoration: 'underline' } },
      },
      {
        name: 'Timeline Item',
        value: task?.title,
      },
      {
        name: 'Project',
        value: task?.job?.title,
      },
      {
        name: 'Job No.',
        value: task?.job?.extension,
      },
    ],
    [timeline?.timelineName, task?.title, task?.job?.title, task?.job?.extension],
  );

  return (
    <PulseTooltip
      TippyProps={{
        placement: 'left',
        content: (
          <Grid maxWidth={280} display={'flex'} rowGap={'4px'} flexDirection={'column'}>
            {tooltipTimelineFields.map(timelineField => (
              <Box display={'flex'} columnGap={2} key={timelineField.name} alignItems={'center'}>
                <Typography fontSize={11} fontWeight={700} color={'white'} width={'50%'}>
                  {timelineField.name}:
                </Typography>
                <Typography
                  fontSize={11}
                  {...timelineField.valueTypographyProps}
                  className={timelineField.name === 'Timeline' ? styles['timeline-name'] : ''}
                  onClick={() => {
                    window.open(timelineUrl);
                  }}
                >
                  {timelineField?.value}
                </Typography>
              </Box>
            ))}
          </Grid>
        ),
      }}
      {...pulseTooltipProps}
    >
      <div
        className={clsx(styles['pulse-link-task-timeline-item'], classes?.root)}
        data-testid="pulse-link-task-timeline-item"
      >
        <i className={leftIconClasses}></i>
        {renderContent}
        <i className={actionIconClasses} onClick={onClick} data-testid="pulse-link-task-timeline-item-action"></i>
      </div>
    </PulseTooltip>
  );
};

export default PulseLinkTaskTimelineItem;
