import React, { ReactElement, useEffect, useRef, useState } from 'react';
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import { PulseDatepickerProps } from './pulse-datepicker-types';
import styles from './pulse-datepicker.module.scss';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider, { LocalizationProviderProps } from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import enAU from 'date-fns/locale/en-AU';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import isFunction from 'lodash/isFunction';

const renderInput = params => {
  return (
    <TextField
      {...params}
      fullWidth
      InputLabelProps={{
        classes: {
          root: styles.datepickerLabel,
        },
      }}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
      }}
      variant="standard"
    />
  );
};

export const PulseDatePicker = (props: PulseDatepickerProps): ReactElement => {
  const { classes, DatePickerProps = {}, LocalizationProviderProps } = props;
  const { onChange, value: PropsValue = null, ...restDatePickerProps } = DatePickerProps;
  const [value, setValue] = useState<DatePickerProps<Date>['value']>(PropsValue);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const datepickerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setAnchorEl(datepickerRef.current);
  }, [datepickerRef.current]);

  const handleChange: DatePickerProps<Date>['onChange'] = value => {
    isFunction(onChange) && onChange(value);
    setValue(value);
  };

  return (
    <div
      data-testid="pulseDatePickerRoot"
      ref={datepickerRef}
      className={clsx(styles.pulseDatePickerRoot, classes?.root)}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns as LocalizationProviderProps['dateAdapter']}
        locale={LocalizationProviderProps?.locale || enAU}
      >
        <DatePicker
          components={{
            OpenPickerIcon: () => <PulseIcon iconName="" classes={{ icon: 'fal fa-calendar-alt' }} />,
          }}
          componentsProps={{
            leftArrowButton: {
              classes: {
                root: styles.pulseDatePickerNavButton,
              },
            },
            rightArrowButton: {
              classes: {
                root: styles.pulseDatePickerNavButton,
              },
            },
            switchViewButton: {
              classes: {
                root: styles.pulseDatePickerNavButton,
              },
            },
          }}
          label="Date"
          inputRef={inputRef}
          InputAdornmentProps={{
            position: 'start',
            ...DatePickerProps?.InputAdornmentProps,
          }}
          PaperProps={{
            classes: {
              root: styles.pulseDatePickerPaperRoot,
            },
          }}
          PopperProps={{
            placement: 'auto-start',
            anchorEl: anchorEl,
            ...DatePickerProps?.PopperProps,
          }}
          renderInput={renderInput}
          value={value}
          views={['year', 'month', 'day']}
          onChange={handleChange}
          {...restDatePickerProps}
        />
      </LocalizationProvider>
    </div>
  );
};

export default PulseDatePicker;
