import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { PulseLeftNavItemProps } from './pulse-left-nav-item-types';
import styles from './pulse-left-nav-item.module.scss';
import PulseListItemButton from 'components/pulse-list/components/pulse-list-item-button/pulse-list-item-button';

export const PulseLeftNavItem = (props: PulseLeftNavItemProps): ReactElement => {
  const { children, classes, selected, ...restProps } = props;

  return (
    <PulseListItemButton
      data-testid="pulse-left-nav-item"
      classes={{
        ...classes,
        root: clsx(styles.pulseListNavItemButton__root, selected && styles.pulseListNavItemSelected, classes?.root),
      }}
      selected={selected}
      {...restProps}
    >
      {children}
    </PulseListItemButton>
  );
};

export default PulseLeftNavItem;
