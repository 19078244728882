import React, { ReactElement } from 'react';
import { components } from 'react-select';
import { PulseSelectGroupProps } from '../pulse-select-base-types';
import styles from './pulse-select-group.module.scss';

const PulseSelectGroup = ({ className, ...restProps }: PulseSelectGroupProps): ReactElement => {
  return (
    <div className={className}>
      <components.Group {...restProps} />
      <hr className={styles['pulse-select-group__divider']} />
    </div>
  );
};

export default React.memo(PulseSelectGroup);
