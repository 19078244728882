import React, { FC } from 'react';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { NoBaselineModalProps } from './baseline-modal-types';
import styles from './baseline-modal.module.scss';

const NoBaselineModal: FC<NoBaselineModalProps> = props => {
  const { isOpen, onClose } = props;

  return (
    <PulseModalBase
      variant="jqconfirm"
      open={isOpen}
      headerIconClasses="fal fa-exclamation-circle"
      PulseModalHeaderProps={{
        onCloseClick: onClose,
        primaryText: 'NO EXISTING BASELINE',
        classes: {
          root: 'header-root',
          closeBtn: 'header-root__close-btn',
        },
      }}
      footer={false}
      onModalClose={onClose}
    >
      <div className={styles['baseline-modal']}>
        <div className={styles['baseline-modal__icon']}>
          <i className="fal fa-exclamation-circle"></i>
        </div>
        <div className={styles['baseline-modal__heading']}>NO EXISTING BASELINE</div>

        <div className={styles['baseline-modal__title']}>
          <div>There is no data for the selected baseline</div>
        </div>
        <div className={styles['baseline-modal__actions']}>
          <PulseButtonBase classes={[styles['baseline-modal__button']]} label="OK" onClick={onClose} />
        </div>
      </div>
    </PulseModalBase>
  );
};

export default NoBaselineModal;
