import { PulseEditorProps } from 'components/pulse-editor/pulse-editor-types';
import React, { ReactElement, ReactNode, useContext, useReducer } from 'react';
import ResourcePlannerReducer, {
  ResourcePlannerAction,
  resourcePlannerInitialState,
  ResourcePlannerState,
} from '../reducers/resource-planner-reducer';

export const ResourcePlannerStateContext: React.Context<ResourcePlannerState> = React.createContext(
  {} as ResourcePlannerState,
);
export const ResourcePlannerDispatchContext: React.Context<React.Dispatch<ResourcePlannerAction>> = React.createContext(
  {} as React.Dispatch<ResourcePlannerAction>,
);

export interface ResourcePlannerProviderProps extends Partial<ResourcePlannerState> {
  children: ReactNode;
  PulseEditorProps?: PulseEditorProps;
}

export const ResourcePlannerProvider = (props: ResourcePlannerProviderProps): ReactElement => {
  const { children, ...restProps } = props;

  const initialState = {
    ...resourcePlannerInitialState,
    ...restProps,
  };

  const [state, dispatch] = useReducer(ResourcePlannerReducer, initialState);

  return (
    <ResourcePlannerStateContext.Provider value={state}>
      <ResourcePlannerDispatchContext.Provider value={dispatch}>{children}</ResourcePlannerDispatchContext.Provider>
    </ResourcePlannerStateContext.Provider>
  );
};

export const useResourcePlannerState = (): ResourcePlannerState => {
  const context = useContext(ResourcePlannerStateContext);
  if (context === undefined) {
    throw new Error('useResourcePlannerState must be used within a ResourcePlannerProvider');
  }
  return context;
};

export const useResourcePlannerDispatch = (): React.Dispatch<ResourcePlannerAction> => {
  const context = useContext(ResourcePlannerDispatchContext);
  if (context === undefined) {
    throw new Error('useResourcePlannerDispatch must be used within a ResourcePlannerProvider');
  }
  return context;
};
