import {
  FETCHING_TIMESHEET_ERROR_TYPES,
  FETCHING_TIMESHEET_ERROR_TYPES_SUCCESS,
  FETCHING_TIMESHEET_ERROR_TYPES_ERROR,
  FETCHING_TIMESHEET_ERROR_ITEMS,
  FETCHING_TIMESHEET_ERROR_ITEMS_ERROR,
  FETCHING_TIMESHEET_ERROR_ITEMS_SUCCESS,
  TOGGLE_TIMESHEET_ERROR_ITEM,
  RESUBMIT_TIMESHEET_ERROR_ITEM,
  RESUBMIT_TIMESHEET_ERROR_ITEM_ERROR,
  RESUBMIT_TIMESHEET_ERROR_ITEM_SUCCESS,
  ARCHIVE_TIMESHEET_ERROR_ITEM,
  ARCHIVE_TIMESHEET_ERROR_ITEM_SUCCESS,
  ARCHIVE_TIMESHEET_ERROR_ITEM_ERROR,
  CLEAR_FILTERS,
} from './action-definitions';
import { v2Endpoint } from 'pulse-commons/api';
import { Action } from 'redux';
import { TimesheetErrorReportActionType } from './types';
import { ThunkAction } from 'redux-thunk';
import { composeDateRange, transformQueryParams } from 'pulse-commons/helpers';
import { TimesheetErrorDataItem } from '../../timesheeterroritem/timesheeterroritem-types';
import { AgressoInstances } from '../../../stores/agresso-instances';
import qs from 'qs';

const TIMESHEET_ERROR_TYPES_API = `/v2/ajax/agresso/timesheets/error-messages`;
const TIMESHEET_ERROR_ITEMS_API = `/v2/ajax/reports/timesheet-errors`;
const TIMESHEET_ERROR_ITEMS_API_RESUBMIT = `/v2/ajax/reports/timesheet-errors/resubmit`;
const TIMESHEET_ERROR_ITEMS_API_ARCHIVE = `/v2/ajax/reports/timesheet-errors/archive`;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, any, unknown, Action<string>>;

export const fetchTimesheetErrorTypes = (agressoInstance: AgressoInstances): AppThunk => {
  return (dispatch): void => {
    dispatch({
      type: FETCHING_TIMESHEET_ERROR_TYPES,
    });
    v2Endpoint
      .get(TIMESHEET_ERROR_TYPES_API, {
        params: {
          filter: {
            instance: agressoInstance,
          },
        },
        paramsSerializer: params => {
          return transformQueryParams(params, 'brackets');
        },
      })
      .then(res => {
        dispatch({
          type: FETCHING_TIMESHEET_ERROR_TYPES_SUCCESS,
          payload: {
            timesheetErrorTypes: res.data.data,
            meta: res.data.meta,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: FETCHING_TIMESHEET_ERROR_TYPES_ERROR,
        });
      });
  };
};

/** Fetches all the errors for a specific error type */
export const fetchTimesheetErrorItems = (errorTypeId: number, params: { [index: string]: any } = {}): AppThunk => {
  return (dispatch): void => {
    /** Dispatch that we are fetching timesheet error items from server */
    dispatch({
      type: FETCHING_TIMESHEET_ERROR_ITEMS,
      payload: {
        errorTypeId,
      },
    });
    /** Carry out the fetch with a custom serializer */
    v2Endpoint
      .get(`${TIMESHEET_ERROR_ITEMS_API}`, {
        params: {
          page: {
            per_page: 25,
            number: 1,
            ...params.page,
          },
          filter: {
            error_type_id: errorTypeId,
            ...params.filter,
          },
        },
        paramsSerializer: params => {
          return transformQueryParams(params, 'brackets');
        },
      })
      .then(response => {
        delete response.data.meta.appliedFilters.instance;
        const { error_type_id, ...rest } = response.data.meta.appliedFilters;

        /** On success, then dispatch a success message and populate the redux store */
        dispatch({
          type: FETCHING_TIMESHEET_ERROR_ITEMS_SUCCESS,
          payload: {
            errorTypeId: error_type_id,
            appliedFilters: rest,
            timesheetErrorItems: {
              ...response.data,
              data: [
                ...response.data.data.map(({ attributes }: { attributes: TimesheetErrorDataItem }) =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ({
                    ...attributes,
                    details: {
                      ...attributes.details,
                      /** Create a property called dateRange from dateFrom and dateTo */
                      dateRange: {
                        display: false,
                        label: 'Date range',
                        order: 0,
                        value: composeDateRange(attributes.details.dateFrom.value, attributes.details.dateTo.value),
                      },
                      submissionId: {
                        display: true,
                        label: 'Submission ID',
                        order: 0,
                        value: attributes.submission_id,
                      },
                    },
                  }),
                ),
              ],
            },
          },
        });
      })
      .catch(err => {
        dispatch({
          type: FETCHING_TIMESHEET_ERROR_ITEMS_ERROR,
          payload: err,
        });
      });
  };
};

export const clearFilters = (appliedFilters: Record<string, any>): AppThunk => {
  return (dispatch): void => {
    dispatch({
      type: CLEAR_FILTERS,
      payload: {
        appliedFilters,
      },
    });
  };
};

/**
 * Function to update the store's selected array of timesheet error
 * types and its timesheet error items children
 * @param errorTypeId
 * @param submissionId
 * @param selected
 */
export const toggleTimesheetErrorItem = (
  errorTypeId: number,
  errorId: number,
  selected: boolean,
): TimesheetErrorReportActionType => {
  return {
    type: TOGGLE_TIMESHEET_ERROR_ITEM,
    payload: {
      errorTypeId,
      errorId,
      selected,
    },
  };
};

export const resubmitTimesheetErrorItem = (errorTypeIds: number[], submissionIds: number[]): AppThunk => {
  return (dispatch, getState): void => {
    dispatch({
      type: RESUBMIT_TIMESHEET_ERROR_ITEM,
      payload: { errorTypeIds, submissionIds },
    });
    const requestBody = {
      timesheet_error_ids: submissionIds,
    };
    v2Endpoint
      .post(TIMESHEET_ERROR_ITEMS_API_RESUBMIT, qs.stringify(requestBody, { encode: false, arrayFormat: 'brackets' }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(res => {
        /** The following might be unnecessary */
        res.data.map((resubmittedTimesheetError: any) => {
          if (resubmittedTimesheetError.hasOwnProperty('error')) {
            dispatch({
              type: RESUBMIT_TIMESHEET_ERROR_ITEM_ERROR,
              payload: {
                submissionId: resubmittedTimesheetError.submission_id,
              },
            });
          } else {
            dispatch({
              type: RESUBMIT_TIMESHEET_ERROR_ITEM_SUCCESS,
              payload: {
                submissionId: resubmittedTimesheetError.submission_id,
              },
            });
          }
        });
      })
      .catch(() => {
        console.error('An error occured while resubmitting selected timesheet errors.');
      })
      .then(() => {
        errorTypeIds.map(errorTypeId => {
          dispatch(fetchTimesheetErrorItems(errorTypeId, { filter: getState().timesheetErrorReport.appliedFilters }));
        });
      });
  };
};

export const archiveTimesheetErrorItem = (errorTypeIds: number[], submissionIds: number[]): AppThunk => {
  return (dispatch, getState): void => {
    dispatch({
      type: ARCHIVE_TIMESHEET_ERROR_ITEM,
      submissionIds,
    });

    const requestBody = {
      timesheet_error_ids: submissionIds,
    };

    v2Endpoint
      .post(TIMESHEET_ERROR_ITEMS_API_ARCHIVE, qs.stringify(requestBody, { encode: false, arrayFormat: 'brackets' }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(() => {
        errorTypeIds.map(errorTypeId => {
          dispatch(fetchTimesheetErrorItems(errorTypeId, { filter: getState().timesheetErrorReport.appliedFilters }));
        });
        dispatch({
          type: ARCHIVE_TIMESHEET_ERROR_ITEM_SUCCESS,
          payload: null,
        });
      })
      .catch(() => {
        console.error('An error occured while archiving the selected timesheet errors.');
        dispatch({
          type: ARCHIVE_TIMESHEET_ERROR_ITEM_ERROR,
          payload: null,
        });
      });
  };
};

export const exportTimesheetErrorReport = (url: string): AppThunk => {
  return (): void => {
    v2Endpoint
      .get(url)
      .then(() => {
        console.log('Successfully exported.');
      })
      .catch(() => {
        console.error('There has been an error processing the export request.');
      });
  };
};
