import React, { FC, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AjaxStore, AjaxStoreConfig } from '@bryntum/core-thin';
import PulseBryntumGrid from '../pulse-bryntum-grid';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import styles from './pulse-history-grid.module.scss';
import clsx from 'clsx';
import { PulseHistoryGridProps } from './pulse-history-grid-types';
import {
  TRANSLATION_HISTORY_COLUMNS,
  PAGE_SIZE,
  ENDPOINT,
  INCLUDE_FIELDS,
  DEFAULT_SORT_PARAM,
  COMMON_COLUMN_CONFIGS,
} from './helpers';
import { BryntumGrid } from '@bryntum/grid-react-thin';
import { Grid } from '@bryntum/grid-thin';
import transformGridAjaxStoreData from './transformer';

const PulseHistoryGrid: FC<PulseHistoryGridProps> = (props): ReactElement => {
  const {
    includes = [],
    resourceId,
    model,
    optionChangedMappingKeys,
    extraParams = {},
    isIdColumnHidden = true,
    exceptedKeysProp = [],
    relationships,
    classes,
    flexible = false,
    ajaxStoreConfig = {},
    dateFormat,
  } = props;

  const gridRef = useRef<BryntumGrid>();
  const { t } = useTranslation();

  const getBryntumCellRenderer = useCallback(
    (isDate = false, isMappingKey = false) => ({ value }): string => {
      const renderContent = (item: string) => {
        let content = item;

        if (isMappingKey && optionChangedMappingKeys) {
          content = optionChangedMappingKeys[item] || item;
        }

        if (isDate && content) {
          content = format(parseISO(content), `${dateFormat} hh:mm a`);
        }

        return `<li
            class='${clsx(styles['pulse-history-grid__cell-li'], styles['pulse-history-grid__cell-content'])}'
          >
            ${content || ''}
          </li>`;
      };

      const valuesArray = Array.isArray(value) ? value : [value];

      return `<ul class='${styles['pulse-history-grid__cell-ul']}'>${valuesArray.map(renderContent).join(' ')}</ul>`;
    },
    [dateFormat, optionChangedMappingKeys],
  );

  const columns = React.useMemo(() => {
    let initialColumns = [
      {
        id: 1,
        field: 'userName',
        text: t(TRANSLATION_HISTORY_COLUMNS.userName),
        width: 150,
      },
      {
        id: 2,
        field: 'optionChanged',
        text: t(TRANSLATION_HISTORY_COLUMNS.optionChanged),
        width: 200,
        renderer: getBryntumCellRenderer(false, true),
      },
      {
        id: 4,
        field: 'oldValue',
        text: t(TRANSLATION_HISTORY_COLUMNS.oldValue),
        width: 200,
      },
      {
        id: 5,
        field: 'newValue',
        text: t(TRANSLATION_HISTORY_COLUMNS.newValue),
        width: 200,
      },
      {
        id: 6,
        field: 'updatedDate',
        text: t(TRANSLATION_HISTORY_COLUMNS.updatedDate),
        width: 140,
        renderer: getBryntumCellRenderer(true),
      },
    ];

    if (!isIdColumnHidden) {
      initialColumns.push({
        id: 3,
        field: 'id',
        text: t(TRANSLATION_HISTORY_COLUMNS.id),
        width: 76,
      });
    }

    initialColumns = initialColumns.map(column => {
      return {
        renderer: getBryntumCellRenderer(),
        ...column,
        ...COMMON_COLUMN_CONFIGS,
        flex: flexible ? 1 : null,
        cellCls: styles['pulse-history-grid__custom-col'],
      };
    });

    return initialColumns;
  }, [isIdColumnHidden, flexible, optionChangedMappingKeys, dateFormat]);

  const relationshipValue = useCallback(() => {
    if (relationships) {
      if (typeof relationships === 'string') {
        return { 'filter[relationship]': `App\\Models\\${relationships}` };
      }
      return relationships.reduce((previousValue: Record<string, string>, item, index) => {
        previousValue[`filter[relationship][${index}]`] = `App\\Models\\${item}`;
        return previousValue;
      }, {});
    }
    return {};
  }, [relationships]);

  const AJAX_STORE_CONFIG: Partial<AjaxStoreConfig> = useMemo(() => {
    return {
      autoLoad: false,
      readUrl: ENDPOINT,
      pageSize: PAGE_SIZE,
      pageParamName: 'page[number]',
      pageSizeParamName: 'page[per_page]',
      responseTotalProperty: 'meta.page.total',
      responseDataProperty: 'attributes',
      params: {
        include: INCLUDE_FIELDS.concat(includes).join(','),
        'filter[model]': `App\\Models\\${model}`,
        'filter[resourceId]': resourceId,
        sort: DEFAULT_SORT_PARAM,
        ...relationshipValue(),
        ...extraParams,
      },
      listeners: {
        afterRequest: ({ json }) => {
          const gridInstance = gridRef?.current?.instance as Grid;
          if (gridInstance) {
            const gridAjaxStore = gridInstance.store as AjaxStore;
            gridAjaxStore.data = transformGridAjaxStoreData(json, exceptedKeysProp);
          }
        },
      },
      ...ajaxStoreConfig,
    };
  }, [model, resourceId, extraParams, ajaxStoreConfig]);

  useEffect(() => {
    if (gridRef.current) {
      const gridInstance = gridRef.current.instance as Grid;
      const gridAjaxStore = gridInstance.store as AjaxStore;
      const paramsStore = gridAjaxStore.params;
      gridAjaxStore.loadPage(1, paramsStore);
    }
  }, []);

  return (
    <div data-testid="pulse-history-grid" className={clsx(styles['pulse-history-grid'], classes?.root)}>
      {useMemo(
        () => (
          <PulseBryntumGrid
            ref={gridRef}
            gridProps={{
              columns,
              filterFeature: false,
              cellMenuFeature: false,
              headerMenuFeature: false,
            }}
            ajaxStoreConfig={AJAX_STORE_CONFIG}
          />
        ),
        [],
      )}
    </div>
  );
};

PulseHistoryGrid.displayName = 'PulseHistoryGrid';
export default PulseHistoryGrid;
