import React, { useImperativeHandle, forwardRef, ReactElement, Ref, useEffect, useRef, useState } from 'react';
import PulseDrawer from 'components/pulse-drawer/pulse-drawer';
import selectState from '../../slice/selector';
import { useDispatch, useSelector } from 'react-redux';
import {
  openForm,
  closeForm,
  addFilter,
  copyFromSelectedFilterToAppliedFilter,
  resetFilter,
  initForm,
  Filters,
  editElementInAppliedFilter,
  removeElementInAppliedFilter,
} from '../../slice';
import PulseProjectsSelect from 'components/pulse-select/pulse-projects/pulse-projects-select';
import PulseDepartmentsSelect from 'components/pulse-select/pulse-departments/pulse-departments-select';
import PulseProjectUsersSelect from 'components/pulse-select/pulse-users/pulse-users-select';
import PulseBrandCategoriesSelect from 'components/pulse-select/pulse-brand-categories/pulse-brand-categories-select';
import PulseBrandsSelect from 'components/pulse-select/pulse-brands/pulse-brands-select';
import PulseRolesSelect from 'components/pulse-select/pulse-roles/pulse-roles-select';
import PulseClientsSelect from 'components/pulse-select/pulse-clients/pulse-clients-select';
import PulseTasksSelect from 'components/pulse-select/pulse-tasks/pulse-tasks-select';
import PulseSwitch from 'components/pulse-switch/pulse-switch';
import PulseSelectBase from 'components/pulse-select/base/pulse-select-base';
import { ComponentTypes, FilterConfig, GroupFilterConfig, PulseFilterProps } from '../../pulse-filter-types';
import styles from './filter.module.scss';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import PulseDatepicker from 'components/pulse-date-picker/pulse-datepicker';
import { DateString, FilterStateProps } from 'components/pulse-filter/slice/types';
import { PulseSelectBasePropTypes } from 'components/pulse-select/base/pulse-select-base-types';
import { Colors } from 'pulse-commons/types';
import { PulseSelectOptionType, PulseSelectPropTypes } from 'components/pulse-select/base/pulse-select-base-types';
import { PulseFilterFormHandles } from '../../pulse-filter-types';
import { PulseDatepickerProps } from 'components/pulse-date-picker/pulse-datepicker-types';
import isFunction from 'lodash/isFunction';
import { reviewRoundStaticOptions, statusStaticOptions } from 'pulse-commons/status-options';
import { Formik, Form, FormikProps } from 'formik';
import FilterValidationSchema from 'components/pulse-filter/components/Filter/filter-validation-schema';
import { PulseDatePickerState } from 'components/pulse-date-picker/reducer/pulse-datepicker-reducer';
import PulseInput from 'components/pulse-input/pulse-input';
import PulseAccordion from 'components/pulse-accordion/pulse-accordion';
import PulseAccordionSummary from 'components/pulse-accordion/components/pulse-accordion-summary/pulse-accordion-summary';
import PulseAccordionDetails from 'components/pulse-accordion/components/pulse-accordion-details/pulse-accordion-details';
import clsx from 'clsx';
import { PulseInputProps } from 'components/pulse-input/pulse-input-types';
import { PulseSwitchProps } from 'components/pulse-switch/pulse-switch-types';
import PulseSelectGroupBase from 'components/pulse-select/pulse-group/pulse-group';
import { PulseSelectGroupBasePropTypes } from 'components/pulse-select/pulse-group/pulse-group-types';

const REQUESTED_BY_URL = `/v2/api/users?filter[action]=requested_by`;
const REVIEW_STATUS_URL = `/v2/api/stage-statuses`;
const SELECT_PAGE_SIZE = 10;
const USER_DATA_STRUCTURE = {
  dataKey: 'data',
  isJsonApi: true,
  label: 'name',
  lastPage: 'meta.page.last_page',
  pageSize: SELECT_PAGE_SIZE,
  type: 'user',
  value: 'userid',
};
const PROJECT_DATA_STRUCTURE = {
  dataKey: 'data',
  isJsonApi: true,
  label: 'jobTitle',
  lastPage: 'meta.page.last_page',
  pageSize: SELECT_PAGE_SIZE,
  searchKey: 'or.jobtitle.jobextension',
  type: 'project',
  value: 'jobId',
};
const SELECT_DEFAULT_PROPS: Partial<PulseSelectPropTypes> = {
  isMulti: true,
  pageSize: SELECT_PAGE_SIZE,
  TippyProps: {
    popperOptions: {
      strategy: 'fixed',
    },
  },
};

const REVIEW_STAGE_STATUS = {
  dataKey: 'data',
  isJsonApi: true,
  label: 'label',
  lastPage: 'meta.page.last_page',
  pageSize: SELECT_PAGE_SIZE,
  type: 'stage-statuses',
  value: 'id',
};

const REVIEW_TYPE_STRUCTURE = {
  dataKey: 'data',
  isJsonApi: true,
  label: 'name',
  lastPage: 'meta.page.last_page',
  pageSize: SELECT_PAGE_SIZE,
  type: 'round-types',
  value: 'id',
};

const PulseDatePickerClass = {
  root: styles['form-select__date-picker'],
  labelStart: styles['form-select__date-picker-label'],
  labelEnd: styles['form-select__date-picker-label'],
  start: styles['form-select__date-picker-input'],
  end: styles['form-select__date-picker-input'],
};

const INIT_EXPANDED_ACCORDION = { '0': true };

const Filter = (props: PulseFilterProps, ref: Ref<PulseFilterFormHandles>): ReactElement => {
  const { isFilterVisible, selectedFilter, appliedFilter } = useSelector(selectState);
  const dispatch = useDispatch();
  const notInitialRender = useRef(false);
  const [expandedAccordions, setExpandedAccordions] = useState<{ [key: number]: boolean }>(INIT_EXPANDED_ACCORDION);

  const {
    allowFilters,
    filterTitle,
    onApplyFilter,
    onResetFilterCustom,
    onCloseFilterCustom,
    onSelectedFilterChange,
    filterInitialValue,
    isGroup = false,
    filterValidationSchemaProps,
    PulseDrawerProps = {},
  } = props;
  const { DrawerProps, PulseModalHeaderProps, ...restPulseDrawerProps } = PulseDrawerProps;
  const {
    assignedUser,
    assignedUserOffice,
    assignedUsers,
    bookingStatus,
    brand,
    brandCat,
    brandcategories,
    brands,
    departments,
    jobOffices,
    jobs,
    office,
    project,
    reportedUser,
    requestor,
    roles,
    showPtoOnly,
    tags,
    task,
    userOffices,
    reviewStatus,
    reviewOwner,
    reviewTypes,
    projectManager,
    reviewRound,
    createdBy,
  } = selectedFilter;

  useEffect(() => {
    if (filterInitialValue) {
      dispatch(initForm(filterInitialValue));
    }
  }, []);

  useEffect(() => {
    if (notInitialRender.current) {
      onApplyFilter(appliedFilter);
    } else if (!isFunction(onSelectedFilterChange)) {
      notInitialRender.current = true;
    }
  }, [appliedFilter]);

  useEffect(() => {
    if (isFunction(onSelectedFilterChange) && notInitialRender.current) {
      onSelectedFilterChange(selectedFilter);
    } else {
      notInitialRender.current = true;
    }
  }, [selectedFilter]);

  useImperativeHandle(ref, () => {
    return {
      openForm: () => {
        dispatch(openForm());
      },
      editElementInAppliedFilter: (filterName: string, filterValue: any) => {
        const payload = { filterName, filterValue };
        dispatch(editElementInAppliedFilter(payload));
      },
      removeElementInAppliedFilter: (filterName: string, filterValue: any) => {
        const payload = { filterName, filterValue };
        dispatch(removeElementInAppliedFilter(payload));
      },
      addElementToSelectedFilter: (filterName: string, filterValue: any) => {
        const payload = { filterName, filterValue };
        dispatch(addFilter(payload));
      },
      closeForm: () => {
        dispatch(closeForm());
      },
      resetFilter: () => {
        dispatch(resetFilter());
      },
      addFilter: (filterName: string, filterValue: any) => {
        dispatch(addFilter({ filterName, filterValue }));
      },
      copyFromSelectedFilterToAppliedFilter: () => {
        dispatch(copyFromSelectedFilterToAppliedFilter());
      },
    };
  });

  const handleClose = (resetForm: () => void): void => {
    if (onCloseFilterCustom) {
      onCloseFilterCustom();
    }
    resetForm();
    dispatch(closeForm());
  };

  const submitFilter = (): void => {
    dispatch(copyFromSelectedFilterToAppliedFilter());
  };

  const onSelectChange = (
    filterName: string,
    identifier?: string,
    formikProps?: FormikProps<FilterStateProps['selectedFilter']>,
  ) => (selectedItem): void => {
    const payload = { filterName, filterValue: identifier ? selectedItem[identifier] : selectedItem };
    formikProps?.setFieldValue(filterName, identifier ? selectedItem[identifier] : selectedItem, true);
    formikProps?.setFieldValue(`${filterName}Custom`, undefined);
    dispatch(addFilter(payload));
  };

  const onSwitchchange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    const payload = { filterName: Filters.showPtoOnly, filterValue: checked };
    dispatch(addFilter(payload));
  };

  const onDateChange = (
    filterName: string,
    limitDays?: number,
    formikProps?: FormikProps<FilterStateProps['selectedFilter']>,
    isCustom = true,
  ) => (selectedDate: PulseDatePickerState) => {
    const datePayload = {
      startDate: selectedDate?.values?.startDate?.toString(),
      endDate: selectedDate?.values?.endDate?.toString(),
      limitDays: limitDays,
    };
    if (limitDays && !formikProps?.values[filterName] && selectedFilter[filterName]) {
      formikProps?.setFieldValue(filterName, selectedFilter[filterName], true);
    }
    const selectedKey = isCustom ? `${filterName}Custom` : filterName;
    const payload = { filterName: selectedKey, filterValue: datePayload };
    formikProps?.setFieldValue(selectedKey, datePayload, true);
    dispatch(addFilter(payload));
  };

  const initSelect = (filterName: string, PulseSelectBaseProps?: Partial<PulseSelectBasePropTypes>): ReactElement => {
    return (
      <PulseSelectBase
        {...SELECT_DEFAULT_PROPS}
        dataStructure={null}
        classes={PulseSelectClasses}
        value={selectedFilter[filterName] as PulseSelectOptionType}
        changeHandler={onSelectChange(filterName)}
        labelName={filterName}
        key={filterName}
        {...PulseSelectBaseProps}
      />
    );
  };
  const initDateSelect = (
    filterName: string,
    PulseSelectBaseProps?: Partial<PulseSelectBasePropTypes>,
    PulseDatePickerProps: Partial<PulseDatepickerProps> = {},
    formikProps?: FormikProps<FilterStateProps['selectedFilter']>,
  ): ReactElement => {
    const { DatepickerProps = {} } = PulseDatePickerProps;
    const filterNameCustom = selectedFilter[`${filterName}Custom`] as DateString;
    const { limitDays, DatePickerInputProps = {}, ...resDatepickerProps } = DatepickerProps;
    const dateValues = {
      startDate: filterNameCustom?.startDate ? new Date(filterNameCustom?.startDate) : null,
      endDate: filterNameCustom?.endDate ? new Date(filterNameCustom?.endDate) : null,
    };
    const errorMessage = formikProps?.errors[`${filterName}Custom`];
    return (
      <div key={filterName}>
        <PulseSelectBase
          {...SELECT_DEFAULT_PROPS}
          isMulti={false}
          dataStructure={null}
          classes={PulseSelectClasses}
          value={selectedFilter[filterName] as PulseSelectOptionType}
          changeHandler={onSelectChange(filterName, '', formikProps)}
          labelName={filterName}
          pageSize={SELECT_PAGE_SIZE}
          {...PulseSelectBaseProps}
        />
        {(selectedFilter[filterName] as PulseSelectOptionType)?.value === 'custom' && (
          <PulseDatepicker
            onChange={onDateChange(filterName, limitDays, formikProps)}
            values={dateValues}
            DatepickerProps={{
              range: true,
              variant: 'input',
              TippyProps: {
                popperOptions: {
                  strategy: 'fixed',
                },
              },
              DatePickerInputProps: {
                labelStart: 'Start Date',
                labelEnd: 'End Date',
                labelCommon: '',
                classes: PulseDatePickerClass,
                spacer: '',
                isInvalid: !!errorMessage,
                error: errorMessage?.toString(),
                ...DatePickerInputProps,
              },
              ...resDatepickerProps,
            }}
          />
        )}
      </div>
    );
  };
  const initDatePicker = (
    filterName: string,
    labelName = 'DatePicker',
    PulseDatePickerProps: Partial<PulseDatepickerProps> = {},
  ): ReactElement => {
    const selectedDate = selectedFilter[`${filterName}`] || {};
    const { DatepickerProps = {}, ...restProps } = PulseDatePickerProps;
    const { DatePickerInputProps, ...restDatePickerProps } = DatepickerProps;
    const { startDate, endDate } = selectedDate;
    const datePickervalue = {
      startDate: startDate ? new Date(startDate) : null,
      endDate: endDate ? new Date(endDate) : null,
    };
    return (
      <PulseDatepicker
        values={datePickervalue}
        onChange={onDatePickerChange(filterName)}
        key={filterName}
        DatepickerProps={{
          DatePickerInputProps: {
            fullWidth: true,
            classes: PulseDatePickerClass,
            labelStart: labelName,
            ...DatePickerInputProps,
          },
          variant: 'input',
          TippyProps: {
            popperOptions: {
              strategy: 'fixed',
            },
          },
          ...restDatePickerProps,
        }}
        {...restProps}
      />
    );
  };

  const initValueInput = (
    filterName: string,
    labelName: string = filterName,
    PulseInputProps?: Partial<PulseInputProps>,
    formikProps?: FormikProps<FilterStateProps['selectedFilter']>,
  ) => {
    const handleChangeValue = e => {
      formikProps?.setFieldValue(filterName, selectedFilter[filterName], true);
      const payload = { filterName: filterName, filterValue: e?.target?.value };
      dispatch(addFilter(payload));
    };
    return (
      <div key={filterName} className={styles['form-select__root']}>
        <PulseInput
          classes={{
            label: PulseSelectClasses.label,
          }}
          label={labelName}
          key={filterName}
          InputBaseProps={{
            onChange: handleChangeValue,
            placeholder: 'Search...',
            value: selectedFilter[filterName] || '',
            ...PulseInputProps?.InputBaseProps,
          }}
        />
      </div>
    );
  };

  const initPulseSwitch = (
    filterName: string,
    labelName = 'PulseSwitch',
    PulseSwitchProps?: Partial<PulseSwitchProps>,
  ) => {
    const onSwitchChangeCustom = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      const payload = { filterName, filterValue: checked };
      dispatch(addFilter(payload));
    };

    return (
      <div key={filterName} className={styles['form-select__root']}>
        <label className={styles['form-select__label']}>{labelName}</label>
        <PulseSwitch
          labelLeft={'No'}
          labelRight={'Yes'}
          onChange={onSwitchChangeCustom}
          checked={selectedFilter[filterName]}
          color={Colors.primary}
          {...PulseSwitchProps}
        />
      </div>
    );
  };

  const initPulseSelectGroupBase = (
    filterName: string,
    PulseSelectGroupBasePropTypes?: PulseSelectGroupBasePropTypes,
    formikProps?: FormikProps<FilterStateProps['selectedFilter']>,
  ) => {
    return (
      <div key={filterName}>
        <PulseSelectGroupBase
          dataStructure={null}
          classes={PulseSelectClasses}
          value={selectedFilter[filterName]}
          changeHandler={onSelectChange(filterName, '', formikProps)}
          {...PulseSelectGroupBasePropTypes}
        />
      </div>
    );
  };

  const onDatePickerChange = (filterName: string, formikProps?: FormikProps<FilterStateProps['selectedFilter']>) => (
    selectedDate: PulseDatePickerState,
  ) => {
    const datePayload = {
      startDate: selectedDate?.values?.startDate?.toString(),
      endDate: selectedDate?.values?.endDate?.toString(),
    };
    const payload = { filterName, filterValue: datePayload };
    formikProps?.setFieldValue(filterName, selectedFilter[filterName], true);
    dispatch(addFilter(payload));
  };

  const initPulseRangePicker = (
    filterName: string,
    PulseDatePickerProps: Partial<PulseDatepickerProps> = {},
    formikProps?: FormikProps<FilterStateProps['selectedFilter']>,
  ): ReactElement => {
    const { DatepickerProps = {} } = PulseDatePickerProps;
    const dateRangeValue = selectedFilter[filterName] as DateString;
    const { limitDays, DatePickerInputProps = {}, ...resDatepickerProps } = DatepickerProps;

    const dateValues = {
      startDate: dateRangeValue?.startDate ? new Date(dateRangeValue?.startDate) : null,
      endDate: dateRangeValue?.endDate ? new Date(dateRangeValue?.endDate) : null,
    };
    const errorMessage = formikProps?.errors[filterName];

    return (
      <div key={filterName}>
        <PulseDatepicker
          onChange={onDateChange(filterName, limitDays, formikProps, false)}
          values={dateValues}
          DatepickerProps={{
            range: true,
            variant: 'input',
            TippyProps: {
              popperOptions: {
                strategy: 'fixed',
              },
            },
            DatePickerInputProps: {
              labelStart: 'Date From',
              labelEnd: 'Date To',
              labelCommon: '',
              classes: PulseDatePickerClass,
              spacer: '',
              isInvalid: !!errorMessage,
              error: errorMessage?.toString(),
              ...DatePickerInputProps,
            },
            ...resDatepickerProps,
          }}
        />
      </div>
    );
  };

  const initCustomFilter = (
    allowFilter: FilterConfig,
    formikProps?: FormikProps<FilterStateProps['selectedFilter']>,
  ) => {
    if (allowFilter && allowFilter.customFilter) {
      const {
        customFilter,
        PulseSelectBaseProps,
        PulseSwitchProps,
        PulseDatePickerProps,
        PulseInputProps,
        PulseSelectGroupBasePropTypes,
      } = allowFilter;
      const { componentType, filterName, labelName } = customFilter;
      const customFilters: Record<ComponentTypes, ReactElement> = {
        PulseInput: initValueInput(filterName, labelName, PulseInputProps, formikProps),
        PulseSwitch: initPulseSwitch(filterName, labelName, PulseSwitchProps),
        PulseDatepicker: initDatePicker(filterName, labelName, PulseDatePickerProps),
        RangePicker: initDateSelect(filterName, PulseSelectBaseProps, PulseDatePickerProps, formikProps),
        PulseSelectBase: initSelect(filterName, PulseSelectBaseProps),
        PulseSelectGroupBase: initPulseSelectGroupBase(filterName, PulseSelectGroupBasePropTypes, formikProps),
        PulseRangePicker: initPulseRangePicker(filterName, PulseDatePickerProps, formikProps),
      };
      return customFilters[componentType];
    }

    return <></>;
  };

  const onResetFilter = (): void => {
    dispatch(resetFilter());
    if (onResetFilterCustom) {
      onResetFilterCustom();
    }
  };

  const renderFilters = (
    filters: PulseFilterProps['allowFilters'],
    formikProps: FormikProps<FilterStateProps['selectedFilter']>,
  ): ReactElement[] => {
    return filters.map(filter => {
      switch (filter.name) {
        case Filters.project:
          return (
            <PulseProjectsSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              dataStructure={PROJECT_DATA_STRUCTURE}
              key={Filters.project}
              value={project}
              valueChangeHandler={onSelectChange(Filters.project, 'projectId')}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.jobs:
          return (
            <PulseProjectsSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              dataStructure={PROJECT_DATA_STRUCTURE}
              key={Filters.jobs}
              value={jobs}
              valueChangeHandler={onSelectChange(Filters.jobs, 'projectId')}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.assignedUser:
          return (
            <PulseProjectUsersSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              dataStructure={USER_DATA_STRUCTURE}
              value={assignedUser}
              key={Filters.assignedUser}
              valueChangeHandler={onSelectChange(Filters.assignedUser, 'userId')}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.assignedUsers:
          return (
            <PulseProjectUsersSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              dataStructure={USER_DATA_STRUCTURE}
              value={assignedUsers}
              key={Filters.assignedUsers}
              valueChangeHandler={onSelectChange(Filters.assignedUsers, 'userId')}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.brandcategories:
          return (
            <PulseBrandCategoriesSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={brandcategories}
              valueChangeHandler={onSelectChange(Filters.brandcategories)}
              key={Filters.brandcategories}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.brandCat:
          return (
            <PulseBrandCategoriesSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={brandCat}
              valueChangeHandler={onSelectChange(Filters.brandCat)}
              key={Filters.brandCat}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.brands:
          return (
            <PulseBrandsSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={brands}
              valueChangeHandler={onSelectChange(Filters.brands, 'brandid')}
              key={Filters.brands}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.brand:
          return (
            <PulseBrandsSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={brand}
              valueChangeHandler={onSelectChange(Filters.brand, 'brandid')}
              key={Filters.brand}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.reportedUser:
          return (
            <PulseProjectUsersSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              dataStructure={USER_DATA_STRUCTURE}
              value={reportedUser}
              url={REQUESTED_BY_URL}
              key={Filters.reportedUser}
              valueChangeHandler={onSelectChange(Filters.reportedUser, 'userId')}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.requestor:
          return (
            <PulseProjectUsersSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              dataStructure={USER_DATA_STRUCTURE}
              value={requestor}
              url={REQUESTED_BY_URL}
              key={Filters.requestor}
              valueChangeHandler={onSelectChange(Filters.requestor, 'userId')}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.roles:
          return (
            <PulseRolesSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={roles}
              valueChangeHandler={onSelectChange(Filters.roles)}
              key={Filters.roles}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.departments:
          return (
            <PulseDepartmentsSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={departments}
              valueChangeHandler={onSelectChange(Filters.departments)}
              key={Filters.departments}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.bookingStatus:
          return (
            <PulseSelectBase
              {...SELECT_DEFAULT_PROPS}
              dataStructure={null}
              classes={PulseSelectClasses}
              value={bookingStatus}
              changeHandler={onSelectChange(Filters.bookingStatus)}
              labelName={'Booking Status'}
              key={Filters.bookingStatus}
              staticOptions={statusStaticOptions}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.jobOffices:
          return (
            <PulseClientsSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={jobOffices}
              valueChangeHandler={onSelectChange(Filters.jobOffices, 'officeid')}
              key={Filters.jobOffices}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.office:
          return (
            <PulseClientsSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={office}
              valueChangeHandler={onSelectChange(Filters.office, 'officeid')}
              key={Filters.office}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.userOffices:
          return (
            <PulseClientsSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={userOffices}
              valueChangeHandler={onSelectChange(Filters.userOffices, 'officeid')}
              key={Filters.userOffices}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.assignedUserOffice:
          return (
            <PulseClientsSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={assignedUserOffice}
              valueChangeHandler={onSelectChange(Filters.assignedUserOffice, 'officeid')}
              key={Filters.assignedUserOffice}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.showPtoOnly:
          return (
            <div key={Filters.showPtoOnly} className={styles['form-select__root']}>
              <label className={styles['form-select__label']}>SHOW PTO ONLY</label>
              <PulseSwitch
                labelLeft={'No'}
                labelRight={'Yes'}
                onChange={onSwitchchange}
                checked={showPtoOnly}
                color={Colors.primary}
                {...filter.PulseSwitchProps}
              />
            </div>
          );
        case Filters.task:
          return (
            <PulseTasksSelect
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={task}
              changeHandler={onSelectChange(Filters.task)}
              labelName={'Search'}
              key={Filters.task}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.tags:
          return (
            <PulseSelectBase
              {...SELECT_DEFAULT_PROPS}
              dataStructure={null}
              classes={PulseSelectClasses}
              value={tags}
              changeHandler={onSelectChange(Filters.tags)}
              labelName={'tags'}
              key={Filters.tags}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.reviewStatus:
          return (
            <PulseSelectBase
              {...SELECT_DEFAULT_PROPS}
              dataStructure={REVIEW_STAGE_STATUS}
              classes={PulseSelectClasses}
              url={REVIEW_STATUS_URL}
              value={reviewStatus}
              labelName="Review Status"
              changeHandler={onSelectChange(Filters.reviewStatus)}
              key={Filters.reviewStatus}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.reviewOwner:
          return (
            <PulseSelectBase
              {...SELECT_DEFAULT_PROPS}
              dataStructure={USER_DATA_STRUCTURE}
              classes={PulseSelectClasses}
              value={reviewOwner}
              labelName="Review Owner"
              changeHandler={onSelectChange(Filters.reviewOwner)}
              key={Filters.reviewOwner}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.reviewTypes:
          return (
            <PulseSelectBase
              {...SELECT_DEFAULT_PROPS}
              dataStructure={REVIEW_TYPE_STRUCTURE}
              classes={PulseSelectClasses}
              value={reviewTypes}
              labelName="Review Types"
              changeHandler={onSelectChange(Filters.reviewTypes)}
              key={Filters.reviewTypes}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.projectManager:
          return (
            <PulseSelectBase
              {...SELECT_DEFAULT_PROPS}
              dataStructure={USER_DATA_STRUCTURE}
              classes={PulseSelectClasses}
              value={projectManager}
              labelName="Project Manager"
              changeHandler={onSelectChange(Filters.projectManager)}
              key={Filters.projectManager}
              {...filter.PulseSelectBaseProps}
            />
          );
        case Filters.reviewStage: {
          return initValueInput(Filters.reviewStage, 'REVIEW STAGE', filter.PulseInputProps);
        }
        case Filters.reviewRound: {
          return (
            <PulseSelectBase
              {...SELECT_DEFAULT_PROPS}
              dataStructure={null}
              classes={PulseSelectClasses}
              value={reviewRound}
              labelName="Review Round"
              changeHandler={onSelectChange(Filters.reviewRound)}
              key={Filters.reviewRound}
              staticOptions={reviewRoundStaticOptions}
              {...filter.PulseSelectBaseProps}
            />
          );
        }
        case Filters.createdDate:
        case Filters.dueDate:
        case Filters.dateRange:
        case Filters.validDate:
          return initDateSelect(filter.name, filter?.PulseSelectBaseProps, filter?.PulseDatePickerProps, formikProps);
        case Filters.customFilter: {
          return initCustomFilter(filter, formikProps);
        }
        case Filters.createdBy:
          return (
            <PulseSelectBase
              {...SELECT_DEFAULT_PROPS}
              classes={PulseSelectClasses}
              value={createdBy}
              changeHandler={onSelectChange(Filters.createdBy)}
              key={Filters.createdBy}
              {...filter.PulseSelectBaseProps}
            />
          );
        default:
          return initSelect(filter.name, filter?.PulseSelectBaseProps);
      }
    });
  };

  const onChangeAccordion = (tabIndex: number) => (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => {
    const expandedAccordionsUpdate = { ...expandedAccordions };
    expandedAccordionsUpdate[tabIndex] = expanded;
    setExpandedAccordions(expandedAccordionsUpdate);
  };

  const PulseSelectClasses = {
    root: styles['form-select__root'],
    label: styles['form-select__label'],
  };

  return (
    <Formik
      initialValues={selectedFilter}
      validationSchema={
        filterValidationSchemaProps
          ? FilterValidationSchema.concat(filterValidationSchemaProps)
          : FilterValidationSchema
      }
      onSubmit={() => submitFilter()}
      initialTouched={{
        dateRangeCustom: true,
        dateRange: true,
      }}
    >
      {formikProps => {
        const { handleSubmit, isValid, resetForm } = formikProps;
        return (
          <Form>
            <PulseDrawer
              closeDrawerHandler={() => handleClose(resetForm)}
              DrawerProps={{
                classes: {
                  root: styles['filter-form__drawer-root'],
                  paperAnchorRight: styles['filter-form__drawer-paper-anchor-right'],
                },
                anchor: 'right',
                open: isFilterVisible,
                onClose: () => handleClose(resetForm),
                ...DrawerProps,
              }}
              drawerFooterComponent={<></>}
              PulseModalHeaderProps={{
                classes: {
                  root: styles['filter-form__modal-header-root'],
                  titleContainer: styles['filter-form__title-container'],
                  titleContainerText: styles['filter-form__title-container-text'],
                  titlePrimary: styles['filter-form__title'],
                  closeBtn: styles['filter-form__btn-close'],
                },
                headerIconClasses: 'fal fa-filter',
                primaryText: filterTitle,
                ...PulseModalHeaderProps,
              }}
              {...restPulseDrawerProps}
            >
              <div
                className={clsx(styles['filter-form__container'], isGroup && styles['filter-form__container-group'])}
              >
                <div className={styles['filter-form__body']}>
                  {isGroup
                    ? allowFilters.map((groupFilter, index) => {
                        const { title = '', allowFilters } = groupFilter as GroupFilterConfig;

                        return (
                          <PulseAccordion
                            key={`${title}-${index}`}
                            onChange={onChangeAccordion(index)}
                            expanded={expandedAccordions[index] || false}
                            classes={{
                              root: styles['filter-form__accordion-root'],
                            }}
                          >
                            <PulseAccordionSummary
                              classes={{
                                root: styles['filter-form__accordion-summary-root'],
                                content: styles['filter-form__accordion-summary-content'],
                                expanded: styles['filter-form__accordion-summary-expanded'],
                                expandIconWrapper: styles['filter-form__accordion-summary-expandedIcon'],
                              }}
                              expanded={expandedAccordions[index]}
                              iconFirst={false}
                            >
                              {title}
                            </PulseAccordionSummary>
                            <PulseAccordionDetails
                              classes={{
                                root: styles['filter-form__accordion-details-root'],
                              }}
                            >
                              {renderFilters(allowFilters, formikProps)}
                            </PulseAccordionDetails>
                          </PulseAccordion>
                        );
                      })
                    : renderFilters(allowFilters, formikProps)}
                </div>
              </div>
              <div className={styles['filter-form__footer']}>
                <PulseButtonBase
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                  classes={[styles['filter-form__apply-btn']]}
                  label={'apply filters'}
                  color={Colors.success}
                  icon
                  iconClasses={{
                    icon: 'fal fa-filter',
                  }}
                />
                <PulseButtonBase
                  classes={[styles['filter-form__reset-btn']]}
                  onClick={onResetFilter}
                  label={'reset filters'}
                />
              </div>
            </PulseDrawer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default forwardRef(Filter);
