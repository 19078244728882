/** Action definition for fetching the data from the server */
export const FETCHING_TIMESHEET_ERROR_TYPES = 'FETCHING_TIMESHEET_ERROR_TYPES';
export const FETCHING_TIMESHEET_ERROR_TYPES_DEMO = 'FETCHING_TIMESHEET_ERROR_TYPES_DEMO';
export const FETCHING_TIMESHEET_ERROR_TYPES_SUCCESS = 'FETCHING_TIMESHEET_ERROR_TYPES_SUCCESS';
export const FETCHING_TIMESHEET_ERROR_TYPES_ERROR = 'FETCHING_TIMESHEET_ERROR_TYPES_ERROR';
export const FETCHING_TIMESHEET_ERROR_ITEMS = 'FETCHING_TIMESHEET_ERROR_ITEMS';
export const FETCHING_TIMESHEET_ERROR_ITEMS_DEMO = 'FETCHING_TIMESHEET_ERROR_ITEMS_DEMO';
export const FETCHING_TIMESHEET_ERROR_ITEMS_SUCCESS = 'FETCHING_TIMESHEET_ERROR_ITEMS_SUCCESS';
export const FETCHING_TIMESHEET_ERROR_ITEMS_ERROR = 'FETCHING_TIMESHEET_ERROR_ITEMS_ERROR';
export const FILTERING_TIMESHEET_ERROR_ITEMS = 'FILTERING_TIMESHEET_ERROR_ITEMS';
export const TOGGLE_TIMESHEET_ERROR_ITEM = 'TOGGLE_TIMESHEET_ERROR_ITEM';
export const RESUBMIT_TIMESHEET_ERROR_ITEM = 'RESUBMIT_TIMESHEET_ERROR_ITEM';
export const RESUBMIT_TIMESHEET_ERROR_ITEM_SUCCESS = 'RESUBMIT_TIMESHEET_ERROR_ITEM_SUCCESS';
export const RESUBMIT_TIMESHEET_ERROR_ITEM_ERROR = 'RESUBMIT_TIMESHEET_ERROR_ITEM_ERROR';
export const ARCHIVE_TIMESHEET_ERROR_ITEM = 'ARCHIVE_TIMESHEET_ERROR_ITEM';
export const ARCHIVE_TIMESHEET_ERROR_ITEM_SUCCESS = 'ARCHIVE_TIMESHEET_ERROR_ITEM_SUCCESS';
export const ARCHIVE_TIMESHEET_ERROR_ITEM_ERROR = 'ARCHIVE_TIMESHEET_ERROR_ITEM_ERROR';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const EXPORT_TIMESHEET_ERROR_ITEM = 'EXPORT_TIMESHEET_ERROR_ITEM';
