import React, { ReactElement, PropsWithChildren } from 'react';
import Button from '@mui/material/Button';
import getDate from 'date-fns/getDate';

/**
 * Presentational component that just display the dates in the date picker
 */
const DayButton = (props: any): ReactElement => {
  const { dayInCurrentMonth, wrapperClassName, date } = props;

  return (
    <Button disabled={!dayInCurrentMonth} className={wrapperClassName} disableElevation={true} disableRipple={true}>
      {getDate(date)}
    </Button>
  );
};

const isEqual = (prevProps: PropsWithChildren<any>, nextProps: PropsWithChildren<any>): boolean => {
  return (
    prevProps.dayInCurrentMonth === nextProps.dayInCurrentMonth &&
    prevProps.wrapperClassName === nextProps.wrapperClassName
  );
};

export default React.memo(DayButton, isEqual);
