const passport = `${process.env.PULSE_LOCAL_URL || ''}/v2/passport`;
const addressBookPath = `${process.env.PULSE_LOCAL_URL || ''}/v2/address-book`;
const addressBookUserPath = `${addressBookPath}/users`;
const timesheetUserPath = `${process.env.PULSE_LOCAL_URL || ''}/v2/timesheets?action=view&mode=simple`;

export default {
  projectByIdPassport: ({ projectId }: { projectId: number }): string => `${passport}/${projectId}`,
  projectByIdFiles: ({ projectId }: { projectId: number }): string => `${passport}/${projectId}/files`,
  projectByIdFilesFolder: ({ projectId, folderId }: { projectId: number; folderId: number }): string =>
    `${passport}/${projectId}/files/${folderId}`,
  addressBookUser: ({ userid }: { userid: number }): string => `${addressBookUserPath}/${userid}`,
  userTimesheet: ({ userId, selectedDate }: { userId: number; selectedDate: string }): string =>
    `${timesheetUserPath}&userid=${userId}&selected_date=${selectedDate}`,
};
