export default [
  { value: 'active', label: 'Active' },
  { value: 'pending start', label: 'Pending Start' },
  { value: 'on hold', label: 'On Hold' },
  { value: 'in review', label: 'In Review' },
  { value: 'complete', label: 'Complete' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'non-active projects', label: 'Non-Active Projects' },
  { value: 'all', label: 'All Active and Inactive Projects' },
  { value: 'active and on-hold', label: 'Active and On-Hold' },
  { value: 'active and in review', label: 'Active and In Review' },
];
