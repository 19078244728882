import React, { ReactElement, useEffect, useState } from 'react';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import { useResourcePlannerDispatch, useResourcePlannerState } from '../../context/resource-planner-context';
import { Actions } from '../../reducers/resource-planner-reducer';
import styles from './user-setting-modal.module.scss';
import { PulseTabsProps } from 'components/pulse-tabs/pulse-tabs-types';
import PulseTabs from 'components/pulse-tabs/pulse-tabs';
import { PulseTabsTheme } from 'pulse-commons/types';
import ResourceBookingsTab, { convertData, getDefaultValue } from './components/resource-booking';
import AssignedGroupsTab from './components/assigned-groups';
import clsx from 'clsx';
import { PULSE_LOCAL_URL } from 'pulse-commons/helpers';
import { v2Endpoint } from 'pulse-api/base';
import { Group } from './user-setting-modal-types';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { Colors } from 'pulse-commons/types';
import qs from 'qs';
import { SchedulerPro } from '@bryntum/schedulerpro-thin';

export const groupsUrl = `${PULSE_LOCAL_URL}/api.v2.php`;
export const params = {
  action: 'social',
  itemType: null,
  limit: 200,
  paginate: 'y',
  socialType: 'rooms',
  type: 'getGroups',
  filter: {
    type: ['status', 'invited-projects', 'projectType'],
    value: 'all',
  },
};
const queryParamsString = qs.stringify(params, { encode: false });

const UserForm = ({
  removeResource,
  schedulerInstance,
}: {
  removeResource?: (resourceId: number) => void;
  schedulerInstance: SchedulerPro;
}): ReactElement => {
  const rpState = useResourcePlannerState();
  const rpDispatch = useResourcePlannerDispatch();
  const { isEditUserFormVisible, editUserIdForm } = rpState;

  const [userInfo, setUserInfo] = useState<any>();
  const [availableGroups, setAvailableGroups] = useState<Group[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group[]>([]);
  const [schedulerAccess, setSchedulerAccess] = useState<string>('');

  const handleCloseModal = () => {
    rpDispatch({
      type: Actions.hideUserFormVisible,
      payload: {
        isEditUserFormVisible: false,
      },
    });
  };

  const onSavingSettingForm = async () => {
    setUserInfo({ ...userInfo, mapped_groups: selectedGroup });

    rpDispatch({
      type: Actions.setLoadingTrue,
    });

    const user = {
      scheduler_access: schedulerAccess,
      user_name: userInfo.name,
      user_email: userInfo.email,
      user_password: 'password',
      access: userInfo.access,
      category: userInfo.category,
      clientid: userInfo.clientid,
      bookable: userInfo.bookable,
      default_timesheet_activity_id: userInfo.default_timesheet_activity_id,
      department_id: userInfo.department_id,
      role_id: userInfo.role_id,
      max_hours: userInfo.max_hours,
      getGroups: selectedGroup.map(group => group.id),
    };

    await v2Endpoint
      .request({
        method: 'POST',
        url: `${PULSE_LOCAL_URL}/v2/ajax/address-book/users/${editUserIdForm}`,
        data: user,
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      })
      .then(async () => {
        handleCloseModal();
        window.utilities?.notification?.success('User Updated');
        const updatedUser = schedulerInstance.resourceStore.getById(editUserIdForm);
        if (updatedUser) {
          updatedUser.set({
            departments: {
              department_id: userInfo.department_id,
              name: userInfo.department?.label,
            },
            roles: {
              role_id: userInfo.role_id,
              name: userInfo.role?.label,
            },
          });
        }
        rpDispatch({
          type: Actions.setLoadingFalse,
        });
      })
      .catch(() => {
        window.utilities?.notification?.warning('Update Failed');
        rpDispatch({
          type: Actions.setLoadingFalse,
        });
      });
    if (userInfo.bookable === 'n' && removeResource) {
      removeResource(editUserIdForm);
    }
  };

  const handleChangeBookable = event => {
    const value = event.target.value;
    setUserInfo({ ...userInfo, bookable: value });
  };

  const handleChangePermission = event => {
    const value = event.target.value;
    switch (value) {
      case '0':
        setUserInfo({ ...userInfo, scheduler_access_edit: 'n', scheduler_access_view: 'n' });
        setSchedulerAccess('n');
        break;
      case '1':
        setUserInfo({ ...userInfo, scheduler_access_edit: 'n', scheduler_access_view: 'y' });
        setSchedulerAccess('view');
        break;
      case '2':
        setUserInfo({ ...userInfo, scheduler_access_edit: 'y', scheduler_access_view: 'y' });
        setSchedulerAccess('edit');
        break;
      default:
        return;
    }
  };

  const handleChangeDepartment = record => {
    setUserInfo({
      ...userInfo,
      department: record ?? null,
      department_id: record ? Number(record.value) : null,
    });
  };

  const handleChangeActivity = record => {
    setUserInfo({ ...userInfo, default_timesheet_activity_id: record ? Number(record.value) : null });
  };

  const handleChangeRole = record => {
    setUserInfo({
      ...userInfo,
      role: record ?? null,
      role_id: record ? Number(record.value) : null,
    });
  };

  const handleChangeBookableHours = event => {
    setUserInfo({ ...userInfo, max_hours: event.target.value });
  };

  const handleAddGroup = (group: Group) => {
    const removeGroupFromAvailable = availableGroups.filter(each => each.id !== group.id);
    setAvailableGroups(removeGroupFromAvailable);
    setSelectedGroup([...selectedGroup, group]);
  };

  const handleRemoveGroup = (group: Group) => {
    const removeGroupFromSelected = selectedGroup?.filter(each => each.id !== group.id);
    setSelectedGroup(removeGroupFromSelected);
    setAvailableGroups([...availableGroups, group]);
  };

  const settingTabs: PulseTabsProps['tabData'] = [
    {
      id: 'resource_bookings',
      tabLabel: 'RESOURCE BOOKINGS',
      TabProps: {
        classes: {
          selected: styles['tabs__header--selected'],
        },
      },
      tabContent: userInfo && (
        <ResourceBookingsTab
          userData={userInfo}
          handleChangeBookable={handleChangeBookable}
          handleChangeDepartment={handleChangeDepartment}
          handleChangeActivity={handleChangeActivity}
          handleChangeRole={handleChangeRole}
          handleChangeBookableHours={handleChangeBookableHours}
          handleChangePermission={handleChangePermission}
        />
      ),
      TabPanelProps: {
        classes: {
          root: styles['resource-booking__body'],
        },
      },
    },
    {
      id: 'assigned_groups',
      tabLabel: 'ASSGINED GROUPS',
      TabProps: {
        classes: {
          selected: styles['tabs__header--selected'],
        },
      },
      tabContent: userInfo && (
        <AssignedGroupsTab
          availableGroups={availableGroups}
          selectedGroup={selectedGroup}
          handleAddGroup={handleAddGroup}
          handleRemoveGroup={handleRemoveGroup}
        />
      ),
      TabPanelProps: {
        classes: {
          root: styles['assigned-groups__body'],
        },
      },
    },
  ];

  const fetchUser = async () => {
    const userInfoUrl = `${PULSE_LOCAL_URL}/v2/ajax/address-book/users/view/${editUserIdForm}`;
    let currentGroups;
    rpDispatch({
      type: Actions.setLoadingTrue,
    });
    await v2Endpoint
      .get(userInfoUrl)
      .then(res => {
        const defaultDeaprtmentValue = getDefaultValue(
          res.data.user.department_id,
          convertData(res.data.user.departments),
        );
        const defaultRoleValue = getDefaultValue(res.data.user.role_id, convertData(res.data.user.roles));
        setUserInfo({
          ...res.data.user,
          department: defaultDeaprtmentValue ? defaultDeaprtmentValue[0] : null,
          role: defaultRoleValue ? defaultRoleValue[0] : null,
        });
        setSelectedGroup(res.data.user.mapped_groups);
        currentGroups = res.data.user.mapped_groups;
        rpDispatch({
          type: Actions.setLoadingFalse,
        });
        return v2Endpoint(`${groupsUrl}?${queryParamsString}`);
      })
      .then(res => {
        const filterAvailableGroup = res.data.data;
        const newArr = filterAvailableGroup.filter(each => {
          return !currentGroups.find(selected => selected.id === each.id);
        });
        setAvailableGroups(newArr);
      })
      .catch(error => {
        window.utilities && window.utilities?.notification.danger(error);
      });
  };

  useEffect(() => {
    if (!isEditUserFormVisible) return;
    fetchUser();
  }, [isEditUserFormVisible]);

  return (
    <PulseModalBase
      open={isEditUserFormVisible}
      headerPrimaryText={`User settings: ${userInfo?.name}`}
      onModalClose={handleCloseModal}
      classes={{
        root: [styles['user-setting__body']],
        dialog: {
          paper: styles['user-setting__root'],
        },
      }}
      PulseModalHeaderProps={{
        classes: {
          root: styles['user-setting__header__root'],
          titleContainer: styles['user-setting__header__ctn'],
          titlePrimary: styles['user-setting__header__title'],
          closeBtn: styles['user-setting__header__closeBtn'],
        },
        headerIconClasses: clsx(styles['user-setting__header__icon'], 'fal fa-user'),
      }}
      footer={true}
      footerComponent={
        <div className={styles['user-setting__footer']}>
          <PulseButtonBase label={'Cancel'} icon={false} onClick={handleCloseModal} />
          <PulseButtonBase
            label={'Update User'}
            icon={true}
            iconClasses={{ icon: 'fal fa-save' }}
            onClick={onSavingSettingForm}
            color={Colors.success}
          />
        </div>
      }
    >
      <PulseTabs
        classes={{
          root: styles['user-setting__tabs'],
        }}
        TabsProps={{
          classes: {
            root: styles['tabs__header'],
          },
        }}
        tabData={settingTabs}
        theme={PulseTabsTheme.light}
      />
    </PulseModalBase>
  );
};

export default UserForm;
