import React, { ReactElement, Ref, useEffect, useMemo, useRef } from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputAdornmentProps } from '@mui/material/InputAdornment';
import { InputLabelProps } from '@mui/material/InputLabel';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import DateFnsUtils from '@date-io/date-fns';
import PulseDatePickerInputTypes from './pulse-datepicker-input-types';
import styles from './pulse-datepicker-input.module.scss';
import clsx from 'clsx';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import { usePulseDatePickerDispatch, usePulseDatePickerState } from '../context/pulse-datepicker-context';
import { Actions } from '../reducer/pulse-datepicker-reducer';
import _ from 'lodash';
import PulseFieldError from 'components/pulse-field-error/pulse-field-error';
import { BaseKeyboardPickerProps } from '@material-ui/pickers/_shared/hooks/useKeyboardPickerState';
import { timezoneDateFormat } from 'pulse-commons/helpers';

const INPUT_VARIANT = 'outlined';

const inputAdornmentConfig: Partial<InputAdornmentProps> = {
  className: clsx(styles['pulse-datepicker-input__inputAdornment']),
  position: 'start',
  disablePointerEvents: true,
};

const inputLabelConfig = {
  variant: 'standard' as InputLabelProps['variant'],
  disableAnimation: true,
  shrink: true,
  classes: {
    root: 'date-picker__label',
  },
};

const inputConfig = {
  notched: false,
  classes: {
    root: styles['pulse-datepicker-inputRoot'],
    input: styles['pulse-datepicker-input__input'],
    focused: styles['pulse-datepicker-input--focused'],
    notchedOutline: styles['pulse-datepicker-input__notchedOutline'],
  },
};

const commonProps: Partial<KeyboardDatePickerProps> & Partial<OutlinedInputProps> = {
  autoOk: true,
  format: timezoneDateFormat(),
  fullWidth: true,
  InputAdornmentProps: inputAdornmentConfig,
  InputLabelProps: inputLabelConfig,
  inputVariant: INPUT_VARIANT,
  invalidDateMessage: false,
  KeyboardButtonProps: {
    classes: {
      root: styles['pulse-datepicker-input__keyboardButtonRoot'],
    },
    disableFocusRipple: true,
  },
  keyboardIcon: (
    <PulseIcon classes={{ icon: clsx('fal fa-calendar-alt', styles['pulse-datepicker-input__icon']) }} iconName="" />
  ),
};

const DatePickerInput = (props: PulseDatePickerInputTypes): ReactElement => {
  const {
    classes,
    displayType = 'linear',
    error,
    fullWidth = false,
    isInvalid,
    isRequired,
    KeyboardDatePickerEndProps,
    KeyboardDatePickerStartProps,
    labelCommon = 'Date Range',
    labelEnd,
    labelStart,
    placeholderStart,
    placeholderEnd,
    range,
    spacer = 'to',
    disabled = false,
  } = props;

  const datePickerContextState = usePulseDatePickerState();
  const datePickerContextDispatch = usePulseDatePickerDispatch();
  const datePickerInputControlCtnRef: Ref<HTMLDivElement> = useRef(null);
  const startDateInputRef: Ref<HTMLInputElement> = useRef(null);
  const endDateInputRef: Ref<HTMLInputElement> = useRef(null);

  useEffect(() => {
    if (range && startDateInputRef.current && endDateInputRef.current) {
      datePickerContextState.inputFocus === 'start' && startDateInputRef.current.focus();
      datePickerContextState.inputFocus === 'end' && endDateInputRef.current.focus();
    }
    if (!range && startDateInputRef.current && datePickerContextState.inputFocus === null) {
      startDateInputRef.current.blur();
    }
  }, [datePickerContextState.inputFocus, datePickerContextState.values]);

  const setDateAtPositionStart = (): void => {
    datePickerContextDispatch({
      type: Actions.showCalendarPicker,
      payload: {
        anchorEl: datePickerInputControlCtnRef,
        inputFocus: 'start',
      },
    });
  };

  const setDateAtPositionEnd = (): void => {
    datePickerContextDispatch({
      type: Actions.showCalendarPicker,
      payload: {
        anchorEl: datePickerInputControlCtnRef,
        inputFocus: 'end',
      },
    });
  };

  const handleRangeStartChange = _.debounce((date: Date, value: string): void | boolean => {
    datePickerContextDispatch({
      type: Actions.setStartDate,
      payload: {
        inputValues: {
          start: value,
        },
        values: {
          startDate: date,
        },
      },
    });
  }, 500) as BaseKeyboardPickerProps['onChange'];

  const handleRangeEndChange = _.debounce((date: Date, value: string): void => {
    datePickerContextDispatch({
      type: Actions.setEndDate,
      payload: {
        inputValues: {
          end: value,
        },
        values: {
          endDate: date,
        },
      },
    });
  }, 500) as BaseKeyboardPickerProps['onChange'];

  const handleSingleChange = _.debounce((date: Date): void => {
    datePickerContextDispatch({
      type: Actions.setSingle,
      payload: {
        date,
      },
    });
  }, 500) as BaseKeyboardPickerProps['onChange'];

  const getDatePickerInputProps = (
    datePickerProps?: Partial<KeyboardDatePickerProps>,
  ): Partial<KeyboardDatePickerProps>['InputProps'] => {
    const { classes: datePickerInputClasses } = datePickerProps?.InputProps || {};
    return {
      ...inputConfig,
      ...datePickerProps?.InputProps,
      classes: {
        root: clsx(
          inputConfig.classes.root,
          datePickerInputClasses?.root,
          disabled && styles['pulse-datepicker-input--disabled'],
        ),
        input: clsx(inputConfig.classes.input, datePickerInputClasses?.input),
        focused: clsx(inputConfig.classes.focused, datePickerInputClasses?.focused),
      },
    };
  };

  return (
    <div className={clsx(styles['pulse-datepicker-input__root'], fullWidth && styles['fullWidth'], classes?.root)}>
      {range && labelCommon && (
        <label className={clsx(styles['pulse-datepicker-input__label'], classes?.labelCommon)}>{labelCommon}</label>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div
          data-testid="date-picker__input-control-ctn"
          className={clsx(
            styles['pulse-datepicker-input__controlCtn'],
            styles[`pulse-datepicker-input__controlCtn--${displayType}`],
          )}
          ref={datePickerInputControlCtnRef}
        >
          <span className={clsx(styles['pulse-datepicker-input__ctn'], fullWidth && styles['fullWidth'])}>
            {labelStart && (
              <label
                className={clsx([
                  styles['pulse-datepicker-input__label'],
                  disabled && styles['pulse-datepicker-input__label--disabled'],
                  classes?.labelEnd,
                ])}
              >
                {labelStart}
                {isRequired && <span className={styles['pulse-datepicker-input__label--required']}>*Required</span>}
              </label>
            )}
            <KeyboardDatePicker
              {...commonProps}
              className={clsx(classes?.start, isInvalid && styles['pulse-datepicker-input--invalid'])}
              inputProps={{
                ref: startDateInputRef,
              }}
              label=""
              onChange={range ? handleRangeStartChange : handleSingleChange}
              onFocus={setDateAtPositionStart}
              placeholder={placeholderStart || (range ? 'Select start date' : 'Select a date')}
              value={datePickerContextState.values?.startDate || null}
              disabled={disabled}
              {...KeyboardDatePickerStartProps}
              InputProps={getDatePickerInputProps(KeyboardDatePickerStartProps)}
            />
          </span>
          {range &&
            useMemo(
              () => (
                <span className={clsx(styles['pulse-datepicker-input__rangeSpacer'], classes?.rangeSpacer)}>
                  {typeof spacer === 'string' ? spacer : <PulseIcon {...spacer} />}
                </span>
              ),
              [],
            )}
          {range && (
            <span className={styles['pulse-datepicker-input__ctn']}>
              {labelEnd && (
                <label
                  className={clsx([
                    styles['pulse-datepicker-input__label'],
                    disabled && styles['pulse-datepicker-input__label--disabled'],
                    classes?.labelEnd,
                  ])}
                >
                  {labelEnd}
                  {isRequired && <span className={styles['pulse-datepicker-input__label--required']}>*Required</span>}
                </label>
              )}

              <KeyboardDatePicker
                {...commonProps}
                className={clsx(classes?.end, isInvalid && styles['pulse-datepicker-input--invalid'])}
                inputProps={{
                  ref: endDateInputRef,
                }}
                label=""
                onChange={handleRangeEndChange}
                onFocus={setDateAtPositionEnd}
                placeholder={placeholderEnd || 'Select end date'}
                value={datePickerContextState.values?.endDate || null}
                disabled={disabled}
                {...KeyboardDatePickerEndProps}
                InputProps={getDatePickerInputProps(KeyboardDatePickerEndProps)}
              />
            </span>
          )}
          {isInvalid && error && <PulseFieldError error={error} />}
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePickerInput;
