import { configureStore } from '@reduxjs/toolkit';
import { getCachedItems, saveCachedItems } from '../helpers';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import suggesstionSlice from './slices';

// Preloaded State from local storeage
const preloadedState = getCachedItems('PRELOADED_STATE');

// Config Redux store
const store = configureStore({
  reducer: {
    suggestion: suggesstionSlice,
  },
  preloadedState,
  devTools: process.env.NODE_ENV === 'development',
});

// Save state to localstorage when state changed
store.subscribe(() => {
  const rootState = store.getState();
  saveCachedItems('PRELOADED_STATE', rootState);
});

export type PSSState = ReturnType<typeof store.getState>;
export type PSSDispatch = typeof store.dispatch;

export const usePSSDispatch = () => useDispatch<PSSDispatch>();
export const usePSSSelector: TypedUseSelectorHook<PSSState> = useSelector;

export default store;
