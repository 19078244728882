import React, { ReactElement } from 'react';

import PulseSelectBase from '../base/pulse-select-base';
import { PulseActivitiesGroupTypes, PulseActivitiesSelectPropTypes } from './pulse-activities-types';
import { CancelToken } from 'axios';
import { groupOptions } from '../utils';
import { PulseSelectArgsStructureGroupTypes } from '../base/pulse-select-base-types';

const ACTIVITY_URL = '/v2/api/agresso-ratecard-activities';

const PulseActivitiesSelect = (props: PulseActivitiesSelectPropTypes): ReactElement => {
  const { extraParams, isGroup = true, ...restProps } = props;

  const handleGroupOptions = async (
    cancelToken: CancelToken,
    searchValue = '',
  ): Promise<PulseActivitiesGroupTypes[]> => {
    const groupOptionsData: PulseSelectArgsStructureGroupTypes = {
      cancelToken,
      searchValue,
      searchKey: 'name',
      groupTitleKey: 'activity',
      optionTitleKey: 'name',
      identifierKey: 'act_type',
      url: ACTIVITY_URL,
      extraParams,
    };
    return groupOptions(groupOptionsData) as Promise<PulseActivitiesGroupTypes[]>;
  };

  return (
    <PulseSelectBase {...restProps} isGroup={isGroup} handleGroupOptions={handleGroupOptions} dataStructure={null} />
  );
};

export default React.memo(PulseActivitiesSelect);
