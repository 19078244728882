import { TranslationTypes } from './pulse-history-grid-types';
import { ALLOWED_ENV, getEnv } from 'pulse-commons/helpers';

const TRANSLATION_HISTORY_COLUMNS: TranslationTypes = {
  userName: 'UserName',
  optionChanged: 'OptionChanged',
  id: 'ID',
  oldValue: 'OldValue',
  newValue: 'NewValue',
  updatedDate: 'UpdatedDate',
};

const PAGE_SIZE = 5;
const DEFAULT_SORT_PARAM = '-updated_at';
const PULSE_LOCAL_URL = getEnv(ALLOWED_ENV['PULSE_LOCAL_URL'], '');
const ENDPOINT = `${PULSE_LOCAL_URL}/v2/api/audit-history`;
const INCLUDE_FIELDS = ['user'];

const COMMON_COLUMN_CONFIGS = {
  draggable: false,
  htmlEncode: false,
  autoHeight: true,
};

export { TRANSLATION_HISTORY_COLUMNS, PAGE_SIZE, ENDPOINT, INCLUDE_FIELDS, DEFAULT_SORT_PARAM, COMMON_COLUMN_CONFIGS };
