import { get, isFunction } from 'lodash';
import { composeClassNameFromArray } from 'pulse-commons/helpers';
import React, { FC, ReactElement } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import Checkbox from 'components/checkbox/checkbox';
import { PulseListSelectorItemClasses, PulseListSelectorItemData } from './pulse-list-selector-item-types';
import styles from './pulse-list-selector-item.module.scss';

type PulseListSelectorItemDraggableProps = {
  index: number;
  actionComponents?: ReactElement;
  classes?: PulseListSelectorItemClasses;
  data: PulseListSelectorItemData;
  selectable?: boolean;
  /** onClick signature when 1 row is clicked */
  onClick?: (event: React.MouseEvent, data: PulseListSelectorItemData) => void;
  /** onSelect signature when the checkbox is clicked */
  onSelect?: (event: React.MouseEvent | React.ChangeEvent, data: PulseListSelectorItemData) => void;
  selectOnClick?: boolean;
};

const PulseListSelectorItemDraggable: FC<PulseListSelectorItemDraggableProps> = props => {
  const { index, data, classes, actionComponents, selectOnClick, onClick, onSelect } = props;

  const { primaryText = '', secondaryText = '', selectable = true, selected } = data || {};

  const handleOnClick = (e): void => {
    selectable && isFunction(onClick) && onClick && onClick(e, data);
  };

  const handleOnSelect = (e): void => {
    selectable && isFunction(onSelect) && onSelect(e, data);
  };

  /** parse styles */
  const rootClasses = composeClassNameFromArray([
    styles['pulse-list-item__root'],
    get(classes, 'root', ''),
    selectable && selected ? styles['selected'] : '',
  ]);

  const primaryTextClasses = composeClassNameFromArray([
    styles['pulse-list-item__primary-text'],
    get(classes, 'primaryText', ''),
  ]);

  const secondaryTextClasses = composeClassNameFromArray([
    styles['pulse-list-item__secondary-text'],
    get(classes, 'secondaryText', ''),
  ]);

  return (
    <Draggable draggableId={data.id as string} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          data-testid="pulse-list-item__ctn"
          className={clsx(
            styles['pulse-list-item__ctn'],
            !selectable && styles['pulse-list-item__ctn--disabled'],
            snapshot.isDragging && styles['pulse-list-item__ctn--dragging'],
            classes?.ctn,
          )}
          onClick={selectOnClick ? handleOnClick : undefined}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={rootClasses}>
            <span uk-icon="icon: table"></span>
            <div className={styles['pulse-list-item__typography']}>
              <div className={primaryTextClasses}>{primaryText}</div>
              <div className={secondaryTextClasses}>{secondaryText}</div>
            </div>
            <div className={styles['pulse-list-item__actions']}>
              {!actionComponents && selectable && (
                <Checkbox onClick={selectOnClick ? undefined : handleOnSelect} checked={selected} />
              )}
              {actionComponents && actionComponents}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default PulseListSelectorItemDraggable;
