import React, { ReactElement, Ref, useRef } from 'react';
import { PulseSearchPropTypes, InputTypes } from './pulse-search-types';
import styles from './pulse-search.module.scss';
import clsx from 'clsx';

/** @todo move this to a base input field */
const PulseSearch = (props: PulseSearchPropTypes): ReactElement => {
  const {
    labelText = 'Search',
    placeholder = 'Search',
    type = InputTypes.text,
    presetValue = '',
    onChangeHandler,
    classes,
  } = props;
  const inputRef: Ref<HTMLInputElement> = useRef(null);
  /**
   * Handles changes to the input field
   * @todo might need a way to debounce the changes
   */
  const handleInputChange = (): void => {
    onChangeHandler({
      search: inputRef.current?.value || '',
    });
  };
  const labelClasses = clsx(styles['pulse-search__label'], classes?.label);
  const inputClasses = clsx(styles['pulse-search__input'], classes?.input);
  return (
    <>
      <div data-testid="pulse-search__ctn" className={styles['pulse-search__ctn']}>
        <label htmlFor="pulse-search" className={labelClasses}>
          {labelText}
        </label>
        <input
          id="pulse-search"
          ref={inputRef}
          className={inputClasses}
          placeholder={placeholder}
          type={type}
          value={presetValue}
          onChange={handleInputChange}
        />
      </div>
    </>
  );
};

export default PulseSearch;
