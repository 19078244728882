import clsx from 'clsx';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import PulseSelectOption, { PulseSelectOptionProps } from 'components/pulse-select/base/components/pulse-select-option';
import React, { ReactElement, Ref, useMemo } from 'react';
import Flag from 'react-world-flags';
import styles from './pulse-currencies-option.module.scss';
import { IconSizes } from 'pulse-commons/types';

export const PulseCurrenciesOption = (props: PulseSelectOptionProps, ref: Ref<HTMLDivElement>): ReactElement => {
  const { data = {}, isValue = false, PulseLabelProps } = props;

  const { classes: PulseLabelClasses } = PulseLabelProps || {};

  /**
   * We want to pass a custom PulseLabelProps
   */
  const internalPulseLabelProps: Partial<typeof PulseLabelProps> = {
    classes: {
      ...PulseLabelClasses,
      root: clsx(styles['pulse-currency-option__label-root'], PulseLabelClasses?.root),
    },
    icon: true,
    iconComponent: (
      <span className={styles['pulse-currency-option__flag']}>
        <Flag
          code={data?.value?.slice(0, 2)}
          fallback={<PulseIcon classes={{ icon: 'fal fa-font-awesome' }} iconName="" size={IconSizes.lg} />}
        />
      </span>
    ),
    isShowFull: !isValue,
    label: data?.label,
    ...PulseLabelProps,
  };

  return (
    <PulseSelectOption
      {...props}
      forwardedRef={ref}
      PulseLabelProps={useMemo(() => internalPulseLabelProps, [PulseLabelProps])}
    />
  );
};

export default React.forwardRef<HTMLDivElement, PulseSelectOptionProps>(PulseCurrenciesOption);
