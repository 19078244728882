import { AxiosResponse } from 'axios';
import { getConfig as baseGetConfig, METHODS, PulseDeleteObject, v2Endpoint } from 'pulse-api/base';
import { GetConfig, PulseSubRequestObject } from 'types/pulse-resource';
import merge from 'lodash/merge';
import { BookingRequest, BookingResourceObject, BookingResponse, DELETE_BOOKING_RESPONSES } from './bookings-types';

const url = `/v2/api/bookings`;

const getConfig = (params: GetConfig, customUrl = url): PulseSubRequestObject => {
  const defaultParams: GetConfig = {
    options: {
      axiosRequestConfig: {
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      },
    },
  };
  return baseGetConfig(merge(params, defaultParams), customUrl);
};

const get: (params: GetConfig) => Promise<AxiosResponse<BookingResponse>> = params => {
  return v2Endpoint.request(getConfig(params));
};

const post: (
  bookingData: BookingRequest<METHODS.POST>,
  params: GetConfig,
) => Promise<AxiosResponse<{ data: BookingResourceObject }>> = (bookingData, params) => {
  return v2Endpoint.post(getConfig(params).url ?? url, bookingData, getConfig(params));
};

const patch: (
  bookingData: BookingRequest<METHODS.PATCH>,
  params: GetConfig,
) => Promise<AxiosResponse<{ data: BookingResourceObject }>> = (bookingData, params) => {
  const bookingId = bookingData?.data?.id;
  const patchUrl = `${url}/${bookingId}`;
  return v2Endpoint.patch(getConfig(params, patchUrl).url ?? patchUrl, bookingData, getConfig(params, patchUrl));
};

/** 'delete' is not allowed as variable name */
const remove: (
  bookingId: string,
  params: GetConfig,
) => Promise<AxiosResponse<PulseDeleteObject<DELETE_BOOKING_RESPONSES.SUCCESS>>> = (bookingId, params) => {
  const deleteUrl = `${url}/${bookingId}`;
  return v2Endpoint.delete(deleteUrl, getConfig(params));
};

export const bookingsApi = {
  get,
  post,
  patch,
  remove,
  getConfig,
};
