import React, { ReactElement, useEffect } from 'react';
import { PulseTimepickerProps } from './pulse-timepicker-types';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import clsx from 'clsx';
import styles from 'components/pulse-timepicker/pulse-timepicker.module.scss';
import 'components/pulse-timepicker/pulse-timepicker.scss';

export const PulseTimePicker = (props: PulseTimepickerProps): ReactElement => {
  const {
    classes,
    selectedTime,
    options,
    isFailedValidation,
    errorMessage = 'Oops something has gone wrong!',
    ...rest
  } = props;
  const flatpickrOptions = {
    static: true,
    focus: false,
    disableMobile: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: 'h:i K',
    time_24hr: false,
    minuteIncrement: 15,
    ...options,
  };

  /**
   * Put a listener on '.numInputWrapper' to ensure that the
   * user gets focus in the input for changing time
   */
  useEffect(() => {
    const numInputWrapperEls = document.querySelectorAll('.numInputWrapper');
    numInputWrapperEls.forEach(el => {
      el.addEventListener('click', e => {
        const numInput = (e.target as HTMLElement).querySelector('.numInput') as HTMLElement;
        numInput?.focus();
      });
    });
  }, []);

  return (
    <div className={clsx(styles['pulse-time-picker__root'], classes?.root)}>
      <Flatpickr
        options={flatpickrOptions}
        value={selectedTime}
        className={clsx(
          styles['pulse-time-picker__text-field'],
          classes?.textField,
          isFailedValidation && styles['pulse-time-picker__text-field--error'],
        )}
        {...rest}
      />
      {isFailedValidation && (
        <div className={clsx(styles['pulse-time-picker__error-message'], classes?.errorMessage)}>{errorMessage}</div>
      )}
    </div>
  );
};

export default PulseTimePicker;
