import React, { ReactElement, useRef } from 'react';
import clsx from 'clsx';
import DatePickerInput from './pulse-datepicker-input';
import { DatepickerProps } from './datepicker-types';
import { usePulseDatePickerDispatch, usePulseDatePickerState } from '../context/pulse-datepicker-context';
import { Actions } from '../reducer/pulse-datepicker-reducer';
import PulseMenu from 'components/pulse-menu/pulse-menu';
import PulseDatepickerCalendar from './pulse-datepicker-calendar';
import styles from './datepicker.module.scss';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import pulseButtonStyles from 'components/pulse-button/base/pulse-button-base.module.scss';

/**
 * Start of Date Picker Component
 *
 */
const Datepicker = (props: DatepickerProps): ReactElement => {
  const {
    // presets,
    classes,
    DatePickerInputProps,
    PulseButtonBaseProps,
    PulseDatepickerCalendarProps,
    PulseMenuProps,
    range = false,
    variant = 'calendar',
    TippyProps,
  } = props;

  const datePickerContextState = usePulseDatePickerState();
  const datePickerContextDispatch = usePulseDatePickerDispatch();

  const calendarPickerButtonRef = useRef(null);

  const { classes: pulseButtonBaseClasses = [], ...restPulseButtonBaseProps } = PulseButtonBaseProps || {};

  const internalClasses = [
    clsx(...pulseButtonBaseClasses, datePickerContextState.anchorEl?.current && pulseButtonStyles['active']),
  ];

  /** Handles hiding the pop over containing the calendar picker */
  const handleClickAway = (): void => {
    datePickerContextDispatch({
      type: Actions.hideCalendarPicker,
    });
  };

  const showCalendarPicker = () => {
    datePickerContextDispatch({
      type: datePickerContextState.anchorEl?.current ? Actions.hideCalendarPicker : Actions.showCalendarPicker,
      payload: {
        anchorEl: calendarPickerButtonRef,
      },
    });
  };

  return (
    <div data-testid="date-picker__ctn" className={clsx('date-picker__ctn', classes?.root)}>
      <PulseMenu
        classes={{
          menuCtn: styles['pulse-datepicker-calendar__menuCtn'],
          childrenRoot: clsx(DatePickerInputProps?.fullWidth && styles['pulse-datepicker--fullWidth']),
        }}
        menuChildren={<PulseDatepickerCalendar range={range} {...PulseDatepickerCalendarProps} />}
        TippyProps={{
          arrow: false,
          onClickOutside: handleClickAway,
          visible: Boolean(datePickerContextState.anchorEl?.current),
          ...TippyProps,
        }}
        {...PulseMenuProps}
      >
        {variant === 'input' && (
          <>
            <DatePickerInput range={range} {...DatePickerInputProps} />
          </>
        )}
        {variant === 'calendar' && (
          <>
            <PulseButtonBase
              ref={calendarPickerButtonRef}
              label=""
              icon
              iconClasses={{ icon: clsx('fal fa-calendar-alt', styles['pulse-datepicker-calendar__icon']) }}
              onClick={showCalendarPicker}
              classes={internalClasses}
              {...restPulseButtonBaseProps}
            />
          </>
        )}
      </PulseMenu>
    </div>
  );
};

export default Datepicker;
