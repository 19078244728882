import React, { ReactElement, useState } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react/headless';
import isFunction from 'lodash/isFunction';

// Will only render the `content` or `render` elements if the tippy is mounted to the DOM.
// Replace <Tippy /> with <LazyTippy /> component and it should work the same.

// https://gist.github.com/atomiks/520f4b0c7b537202a23a3059d4eec908

const PulseLazyTippy = (props: TippyProps): ReactElement => {
  const [mounted, setMounted] = useState(false);

  const lazyPlugin = {
    fn: () => ({
      onShow: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  };

  const computedProps = { ...props };

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])];

  if (props.render) {
    computedProps.render = (...args) => (mounted ? isFunction(props.render) && props.render(...args) : '');
  } else {
    computedProps.content = mounted ? props.content : '';
  }

  return <Tippy {...computedProps} />;
};

export default PulseLazyTippy;
