import React, { FC, useCallback } from 'react';
import PulseRadioGroup from 'components/pulse-radio-group/pulse-radio-group';
import { WeekendNonWorkingDaysTabPanelProps } from '../non-working-days-modal-types';
import styles from '../non-working-days-modal.module.scss';

const RADIO_DATA = [
  { value: 'no', label: 'No - I don’t want to work on weekends', disabled: false },
  { value: 'yes', label: 'Yes - I DO want to work on weekends', disabled: false },
];

const WeekendNonWorkingDaysTabPanel: FC<WeekendNonWorkingDaysTabPanelProps> = props => {
  const { isWeekendWorking, onWeekendWorkingChanged } = props;

  const handleWeekendWorkingChanged = useCallback(event => onWeekendWorkingChanged(event.target.value === 'yes'), []);

  return (
    <>
      <div className={styles['non-working-days-modal__warning-ctn']}>
        <div className={styles['non-working-days-modal__warning-ctn__icon-wrapper']}>
          <i className="fal fa-info-circle"></i>
        </div>
        <div className={styles['non-working-days-modal__warning-ctn__content']}>
          <div className={styles['non-working-days-modal__warning-ctn__content__header']}>Office Non-Working Days</div>
          <div className={styles['non-working-days-modal__warning-ctn__content__description']}>
            Use this control to define whether or not weekends should be allowed as working days for timeline
            calculations. This setting will only apply to this timeline.
          </div>
        </div>
      </div>
      <div className={styles['non-working-days-modal__tabs__radio-group__root']}>
        <strong>Are weekends considered working days?</strong>
        <PulseRadioGroup
          data={RADIO_DATA}
          RadioGroupProps={{
            value: isWeekendWorking ? 'yes' : 'no',
            onChange: handleWeekendWorkingChanged,
          }}
          classes={{
            root: styles['non-working-days-modal__tabs__radio-group__ctn'],
          }}
        />
      </div>
    </>
  );
};

export default WeekendNonWorkingDaysTabPanel;
