import { RequestStatus } from 'pulse-commons/types';
import { ReactElement } from 'react';

export interface PulseXhrRequestStatusProps {
  description?: ReactElement;
  errorIcon?: string | React.FunctionComponent<any> | React.ReactElement;
  errorMessage?: string;
  pendingIcon?: string | React.FunctionComponent<any> | React.ReactElement;
  pendingMessage?: string;
  status?: RequestStatus;
  successIcon?: string | React.FunctionComponent<any> | React.ReactElement;
  successMessage?: string;
}

export enum PulseXhrRequestDefaultMessages {
  error = 'An error occurred',
  pending = 'Processing your request',
  success = 'Your request was successful',
}
