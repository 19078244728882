import React, { ReactElement } from 'react';
import { PulseModalHeaderProps } from './pulse-modal-header-types';
import styles from './pulse-modal-header.module.scss';
import PulseIconButton from '../../../pulse-icon-button/pulse-icon-button';
import Icon from '../../../pulse-icons/pulse-icons';
import clsx from 'clsx';
import { IconSizes } from 'pulse-commons/types';

const PulseModalHeader: React.FunctionComponent<PulseModalHeaderProps> = (
  props: PulseModalHeaderProps,
): ReactElement => {
  const {
    additionalContent,
    allowClose = true,
    classes,
    closeButtonSize = IconSizes.lg,
    headerIconClasses,
    onCloseClick,
    primaryText = 'title',
    PulseIconButtonProps,
    secondaryText,
    closeButtonTooltip = 'Close modal',
    headerColor = '',
  } = props;

  const rootClasses = clsx(styles['pulse-modal-header__root'], classes?.root);
  const titleContainer = clsx(styles['pulse-modal-header__title--ctn'], classes?.titleContainer);
  const titleContainerText = clsx(styles['pulse-modal-header__title--text-ctn'], classes?.titleContainerText);
  const titlePrimary = clsx(styles['pulse-modal-header__title-primary'], classes?.titlePrimary);
  const titleSecondary = clsx(styles['pulse-modal-header__title-secondary'], classes?.titleSecondary);
  const additionalContentClass = clsx(styles['pulse-modal-header__additional-content'], classes?.additionalContent);
  const closeBtn = clsx(styles['pulse-modal-header__btn--close'], classes?.closeBtn);

  return (
    <div
      data-testid="pulse-modal-header__root"
      className={rootClasses}
      style={{
        ...(headerColor && { background: headerColor }),
      }}
    >
      <div className={titleContainer}>
        {headerIconClasses && (
          <Icon
            classes={{
              root: styles['pulse-modal-header__title-icon'],
              icon: headerIconClasses,
            }}
            size={IconSizes.lg}
            iconName=""
          />
        )}
        <div className={titleContainerText}>
          <h2 className={titlePrimary}>{primaryText}</h2>
          {secondaryText && <p className={titleSecondary}>{secondaryText}</p>}
        </div>
      </div>
      {additionalContent && <div className={additionalContentClass}>{additionalContent}</div>}
      {allowClose && (
        <PulseIconButton
          classes={{
            root: [closeBtn],
            pulseIcon: {
              icon: clsx(styles['pulse-modal-header__close-icon'], 'fal fa-times'),
            },
          }}
          handleClick={onCloseClick}
          iconName={null}
          size={closeButtonSize}
          tooltip={closeButtonTooltip}
          {...PulseIconButtonProps}
        />
      )}
    </div>
  );
};

export default PulseModalHeader;
