import React, { ReactElement } from 'react';
import styles from './pulse-no-filter-selected-view.module.scss';
import clsx from 'clsx';
import { PulseNoFilterSelectedViewProps } from './pulse-no-filter-selected-view-types';

export const PulseNoFilterSelectedView = (props: PulseNoFilterSelectedViewProps): ReactElement => {
  const {
    classes,
    icon = true,
    iconName = 'fa-light fa-filter',
    headerTitle = 'NO FILTERS SELECTED',
    title = 'Begin by setting filters above to see results.',
    content,
  } = props;
  return (
    <div className={clsx(styles['pulse-no-filter-selected'], classes?.root)} data-testid="no-filter-selected">
      <div className={clsx(styles['pulse-no-filter-selected__container'], classes?.container)}>
        {icon && (
          <i
            className={clsx(iconName, styles['pulse-no-filter-selected__container-icon'], classes?.iconClasses)}
            data-testid="no-filter-selected-icon"
          />
        )}
        <h2
          className={clsx(styles['pulse-no-filter-selected__container-header'], classes?.headerTitle)}
          data-testid="no-filter-selected-header-title"
        >
          {headerTitle}
        </h2>
        <p
          className={clsx(styles['pulse-no-filter-selected__container-title'], classes?.title)}
          data-testid="no-filter-selected-title"
        >
          {title}
        </p>
        {content}
      </div>
    </div>
  );
};

export default PulseNoFilterSelectedView;
