import React, { ReactElement } from 'react';
import eventTooltipStyles from './event-tooltip.module.scss';
import '../pulse-resource-planner-events.scss';
import PulseModalHeader from '../../pulse-modal-base/components/pulse-modal-header/pulse-modal-header';
import clsx from 'clsx';
import isValid from 'date-fns/isValid';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import PulseScrollbar from 'components/pulse-scrollbar/pulse-scrollbar';
import PulseWithMore from 'components/pulse-with-more/pulse-with-more';
import EventTooltipHeader from 'components/pulse-resource-planner/components/event-tooltip-header';
import { statusStaticOptions } from 'pulse-commons/status-options';
import { useResourcePlannerState } from '../context/resource-planner-context';
import Tippy from '@tippyjs/react';
import { TaskOptionType } from './booking-types';
import PulseIcons from 'components/pulse-icons/pulse-icons';
import { IconSizes } from 'pulse-commons/types';
import { getEventColor, getEventColorCustom } from '../utils';
import { recalculateEndHours } from './edit-form';
import { isSameDay } from 'date-fns';

const LOCAL_URL = process.env.PULSE_LOCAL_URL || '';

const JOB_URL = `${LOCAL_URL}/v2/passport`;
const BRAND_URL = `${LOCAL_URL}/v2/address-book/brands`;

const MAX_HEIGHT = 100;
const MIN_HEIGHT = 20;

/**
 *
 * Return a formated date string or N/A
 */
const dueDate = (date: Required<TaskOptionType>['raw']['dueDate']): string => {
  if (!date) {
    return 'N/A';
  }

  const dueDateTask = parseISO(date);

  return isValid(dueDateTask) ? format(dueDateTask, 'MM-dd-yyyy') : 'N/A';
};

const EventTooltipTemplate = (): ReactElement => {
  const { tooltipData: data } = useResourcePlannerState();
  const { eventRecord, resourceRecord } = data || {};
  const showDailyTime = isSameDay(eventRecord?.startDate as Date, eventRecord?.endDate as Date);
  const formatStartHour = format(eventRecord?.startDate as Date, "hh:mm aaaaa'm'");

  const formatEndHour = format(
    recalculateEndHours(eventRecord?.startDate as Date, Number(eventRecord?.hoursPerDay)),
    "hh:mm aaaaa'm'",
  );

  if (!resourceRecord) {
    throw new Error('Missing resourceRecord');
  }
  /**
   * Safe guard against invalid date data being provided.
   *
   * This prevents the whole app from crashing
   *
   * If startDate or endDate is not valid then don't show a tooltip
   */
  if (!isValid(eventRecord?.startDate) || !isValid(eventRecord?.endDate)) {
    return <></>;
  }

  const displayHours = (hoursPerDay: string, totalHours: string): string => {
    let displayText = '';
    if (hoursPerDay) {
      displayText = `${hoursPerDay} Daily`;
    }
    if (totalHours) {
      displayText = `${totalHours} Total`;
    }
    if (hoursPerDay && totalHours) {
      displayText = `${hoursPerDay} Daily / ${totalHours} Total`;
    }
    return displayText;
  };

  if (!eventRecord) {
    return <div></div>;
  }
  const hanldeOpenTab = (urlTicket: string | undefined): void => {
    urlTicket && window.open(urlTicket, '_blank');
  };

  return (
    <div className={eventTooltipStyles['event-tooltip__root']}>
      <PulseModalHeader
        headerColor={
          eventRecord?.isCustomBookingColor && !(eventRecord?.job?.isNC || eventRecord?.job?.raw?.isNC)
            ? getEventColorCustom(eventRecord?.bookingCustomColor, eventRecord.bookingStatus?.value)
            : ''
        }
        primaryText={
          statusStaticOptions.find(option => option.value === eventRecord?.bookingStatus?.value)?.label ??
          eventRecord?.bookingStatus?.label
        }
        allowClose={false}
        additionalContent={<EventTooltipHeader eventRecord={eventRecord} resourceRecord={resourceRecord} />}
        classes={{
          root: clsx(`event-color-${getEventColor(eventRecord)}`, eventTooltipStyles['event-tooltip__header-root']),
          titleContainer: eventTooltipStyles['event-tooltip__title-container'],
          titleContainerText: clsx(
            eventTooltipStyles['event-tooltip-dark-background'],
            eventTooltipStyles['event-tooltip__title-container-text'],
          ),
          titlePrimary: clsx(
            eventTooltipStyles['event-tooltip__title-primary'],
            eventTooltipStyles['event-tooltip-text'],
          ),
        }}
      />
      <div className={eventTooltipStyles['event-tooltip__container']}>
        <div className={eventTooltipStyles['event-tooltip__projectOverview']}>
          <div className={eventTooltipStyles['event-tooltip__title-container']}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${JOB_URL}/${eventRecord.job?.value}`}
              className={eventTooltipStyles['event-tooltip__project-title']}
            >
              {`${eventRecord.job?.label}`}
            </a>
            <span className={eventTooltipStyles['event-tooltip__row']}>
              {eventRecord.ptoRequestId ||
                (eventRecord.job?.jobExtension && (
                  <span className={eventTooltipStyles['event-tooltip__jobExtension']}>
                    {eventRecord.ptoRequestId ? '[PTO] PULSE ADMIN' : `[${eventRecord.job?.jobExtension}]`}
                  </span>
                ))}

              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${BRAND_URL}/${eventRecord.job?.brandId}`}
                className={eventTooltipStyles['event-tooltip__brand']}
              >
                {eventRecord.job?.brandTitle}
              </a>
            </span>
          </div>
        </div>

        {eventRecord.showDescription && eventRecord.description && (
          <div className={eventTooltipStyles['event-tooltip__descriptionRoot']}>
            <PulseScrollbar
              ScrollbarProps={{
                autoHeightMax: MAX_HEIGHT,
                autoHeightMin: MIN_HEIGHT,
                autoHeight: true,
              }}
            >
              <div
                className={eventTooltipStyles['event-tooltip__description']}
                dangerouslySetInnerHTML={{ __html: eventRecord.description }}
              />
            </PulseScrollbar>
          </div>
        )}

        {eventRecord.ptoRequestId && (
          <div className={eventTooltipStyles['event-tooltip__item-pto-text']}>Taking time off!</div>
        )}
        <div className={eventTooltipStyles['event-tooltip__item-container']}>
          <div className={eventTooltipStyles['event-tooltip__item-detail']}>
            <div className={eventTooltipStyles['event-tooltip__label']}>assigned to</div>
            <div className={eventTooltipStyles['event-tooltip__avatar-container']}>
              <div>{eventRecord.bookedUser?.label}</div>
            </div>
          </div>
          <div className={eventTooltipStyles['event-tooltip__item-detail']}>
            <div className={eventTooltipStyles['event-tooltip__label']}>hours</div>
            <div>{displayHours(eventRecord?.hoursPerDay, eventRecord?.totalHours)}</div>
            {showDailyTime && (
              <div>
                {formatStartHour} - {formatEndHour}
              </div>
            )}
          </div>
          <div
            className={clsx(
              eventTooltipStyles['event-tooltip__item-detail'],
              eventTooltipStyles['event-tooltip__requested-by-container'],
            )}
          >
            <div className={eventTooltipStyles['event-tooltip__label']}>requested by</div>
            <div className={eventTooltipStyles['event-tooltip__avatar-container']}>
              {(eventRecord.requestedBy?.length || false) && (
                <PulseWithMore
                  childrenKey="userid"
                  maxWidth={120}
                  MoreLabelProps={{
                    TippyProps: {
                      disabled: true,
                    },
                  }}
                  classes={{
                    root: eventTooltipStyles['event-tooltip__with-more-root'],
                    hidden: {
                      root: eventTooltipStyles['event-tooltip__with-more-hidden-root'],
                      content: eventTooltipStyles['event-tooltip__with-more-hidden-content'],
                    },
                    more: {
                      root: eventTooltipStyles['event-tooltip__with-more-child-more-root'],
                      label: {
                        active: eventTooltipStyles['event-tooltip__with-more-child-more-active'],
                      },
                    },
                  }}
                >
                  <div className={eventTooltipStyles['event-tooltip__requested-by-username']}>{`${
                    eventRecord.requestedBy?.length === 1 ? eventRecord.requestedBy[0]?.label : ''
                  }`}</div>
                </PulseWithMore>
              )}
            </div>
          </div>
        </div>
        {eventRecord.linkedTasks?.length !== 0 && (
          <div className={clsx(eventTooltipStyles['event-tooltip__tasks-container'])}>
            <div className={clsx(eventTooltipStyles['event-tooltip__taskCtn'])}>
              <div
                className={clsx(
                  eventTooltipStyles['event-tooltip__label'],
                  eventTooltipStyles['event-tooltip__label--task'],
                )}
              >
                linked task
              </div>
              <div
                className={clsx(
                  eventTooltipStyles['event-tooltip__label'],
                  eventTooltipStyles['event-tooltip__label--end-date'],
                )}
              >
                linked task end date
              </div>
            </div>
            <PulseScrollbar
              ScrollbarProps={{
                autoHeightMax: MAX_HEIGHT,
                autoHeightMin: MIN_HEIGHT,
                autoHeight: true,
              }}
            >
              <div className={clsx(eventTooltipStyles['event-tooltip__task-content'])}>
                {eventRecord.linkedTasks?.map(task => {
                  return (
                    <div key={task.value} className={eventTooltipStyles['event-tooltip__taskCtn']}>
                      <span
                        onClick={() => hanldeOpenTab(task?.raw?.ticketUrl ? task?.raw?.ticketUrl : task?.taskUrl)}
                        key={task?.value}
                        className={clsx(
                          eventTooltipStyles['event-tooltip__task--text'],
                          eventTooltipStyles['event-tooltip__task--link'],
                        )}
                      >
                        {`[#${task?.value}] ${task?.label}`}
                      </span>
                      <Tippy
                        content="The linked task end date exceeds the end date for this booking"
                        disabled={!task.raw?.dueDate || eventRecord.endDate < parseISO(task.raw?.dueDate)}
                      >
                        <div
                          key={task?.value}
                          className={clsx(
                            eventTooltipStyles['event-tooltip__task--text'],
                            eventTooltipStyles['event-tooltip__task--end-date'],
                            task.raw?.dueDate &&
                              eventRecord.endDate > parseISO(task.raw?.dueDate) &&
                              eventTooltipStyles['event-tooltip__task--overdue'],
                          )}
                        >
                          {dueDate(task?.raw?.dueDate)}
                          {task.raw?.dueDate && eventRecord.endDate > parseISO(task.raw?.dueDate) && (
                            <PulseIcons
                              size={IconSizes.sm}
                              iconName=""
                              classes={{
                                root: eventTooltipStyles['warning-icon'],
                                icon: 'fal fa-exclamation-triangle',
                              }}
                            />
                          )}
                        </div>
                      </Tippy>
                    </div>
                  );
                })}
              </div>
            </PulseScrollbar>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventTooltipTemplate;
