import { AxiosRequestConfig } from 'axios';
import { METHODS, v2Endpoint } from 'pulse-api/base';
import { PulseSubRequestObject } from 'pulse-types/pulse-resource';
import qs from 'qs';

const BATCH_URL = '/v2/api/batch';

type BatchPayloadType = {
  jq?: string;
  type: 'load';
  requests: BatchRequestType[];
};

export type BatchChainType = {
  batchPayload: BatchPayloadType;
  fetch: (axiosRequestConfig: AxiosRequestConfig) => Promise<Record<string, any>>;
};

export type BatchApiReturnType = {
  url: string;
  batchPayload: BatchPayloadType;
  get: (url: string, params: PulseSubRequestObject['params']) => BatchChainType;
};

type BatchRequestType = {
  method: METHODS;
  url: string;
};

const batchApi = (rootPayload: Partial<BatchPayloadType>): BatchApiReturnType => {
  const batchPayload: BatchPayloadType = {
    ...rootPayload,
    type: 'load',
    requests: [],
  };

  const chainedReturn: BatchChainType = {
    batchPayload,
    fetch: (axiosRequestConfig: AxiosRequestConfig) => {
      return v2Endpoint.post(BATCH_URL, batchPayload, axiosRequestConfig).then(res => {
        if (res.status === 200) {
          return res.data;
        }
      });
    },
  };

  return {
    url: BATCH_URL,
    batchPayload,
    get: (url, params) => {
      let getUrl = url;
      if (params) {
        const queryParamsString = qs.stringify(params, { addQueryPrefix: true });
        getUrl = `${url}${queryParamsString}`;
      }
      const request: BatchRequestType = {
        method: METHODS.GET,
        url: getUrl,
      };
      chainedReturn.batchPayload.requests.push(request);

      return chainedReturn;
    },
  };
};

export default batchApi;
