import axios from 'axios';
import { getEnv, ALLOWED_ENV } from 'pulse-commons/helpers';

const PULSE_LOCAL_URL = getEnv(ALLOWED_ENV['PULSE_LOCAL_URL'], '');
const isProd = process.env.NODE_ENV === 'production';

const v2Endpoint = axios.create({
  baseURL: isProd ? undefined : window?.pulse?.config?.urls?.pulse || PULSE_LOCAL_URL,
  responseType: 'json',
  withCredentials: true,
});

const CancelToken = axios.CancelToken;

export { v2Endpoint, CancelToken };
