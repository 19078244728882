import React, { useState, FC, useRef, ChangeEvent, useCallback } from 'react';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import { RollUpReportAddTimelinesModalProps } from './roll-up-report-add-timelines-modal-types';
import PulseBryntumGrid from 'components/pulse-bryntum-grid';
import styles from './roll-up-report-add-timelines-modal.modules.scss';
import clsx from 'clsx';
import PulseInput from 'components/pulse-input/pulse-input';
import PulseIconButton from 'components/pulse-icon-button/pulse-icon-button';
import { IconSizes } from 'pulse-commons/types';
import { debounce } from 'lodash';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import MockData from '../../mock/roll-up-report-data.json';
import PulseScrollbar from 'components/pulse-scrollbar/pulse-scrollbar';
import { AlignSpec } from '@bryntum/core-thin';

const RollUpReportAddTimelinesModal: FC<RollUpReportAddTimelinesModalProps> = props => {
  const { isOpen, handleCloseModel, handleCancelModal, handleSelectRollupReportTimelines } = props;
  const [searchingValue, setSearchingValue] = useState('');
  const rollupReportAddTimelineRef = useRef<any>();

  const deboundSearch = useCallback(
    debounce(search => {
      // To do later
      // Handle call API for searching BE
      console.log(search);
    }, 300),
    [],
  );

  const handleSearchingValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchingValue(e?.target?.value);
    deboundSearch(e?.target?.value);
  };

  const handleClearSearchInput = () => {
    setSearchingValue('');
  };

  const handleOpenReview = () => {
    console.log('Open preview');
  };

  const handleGetSelectedRollupTimelines = () => {
    const { gridInstance } = rollupReportAddTimelineRef.current;

    if (!gridInstance) {
      return;
    }

    const selectedRows = gridInstance.selectedRecords;
    handleSelectRollupReportTimelines(selectedRows);
  };

  const columns = [
    {
      field: 'title',
      text: 'TIMELINE TITLE',
      width: 150,
      draggable: false,
    },
    {
      field: 'job_name',
      text: 'PROJECT',
      width: 100,
      draggable: false,
    },
    {
      field: 'job_id',
      text: 'JOB NO.',
      width: 100,
      draggable: false,
    },
    {
      field: 'author',
      text: 'AUTHOR',
      width: 100,
      draggable: false,
    },
    {
      field: 'start_date',
      text: 'TIMELINE START',
      width: 100,
      draggable: false,
    },
    {
      field: 'brand',
      text: 'BRAND',
      width: 90,
      draggable: false,
    },
    {
      field: 'timeline_type',
      text: 'TYPE',
      width: 90,
      draggable: false,
      renderer: ({ value }) => {
        const isMaster = value.toLowerCase() === 'master';
        return (
          <div
            className={clsx(
              styles['add-roll-up__grid--view__type-container'],
              isMaster && styles['add-roll-up__grid--view__type-master'],
            )}
          >
            <span>{isMaster ? 'Master' : 'Scenario'}</span>
          </div>
        );
      },
    },
    {
      field: 'view_btn',
      region: 'right',
      text: '',
      align: 'center' as AlignSpec,
      hideable: false,
      sortable: false,
      draggable: false,
      resizable: false,
      minWidth: 140,
      cellCls: styles['view-btn'],
      renderer: ({}) => {
        return (
          <PulseButtonBase
            classes={[styles['btn__view-preview']]}
            icon
            iconClasses={{
              icon: 'fal fa-search',
            }}
            label={'PREVIEW'}
            onClick={handleOpenReview}
          />
        );
      },
    },
  ];

  return (
    <PulseModalBase
      variant="jqconfirm"
      open={isOpen}
      headerIconClasses={'fal fa-plus'}
      footer={true}
      classes={{
        dialog: {
          paper: styles['roll-up-report__add-timeline-modal__root'],
        },
      }}
      PulseModalHeaderProps={{
        onCloseClick: handleCloseModel,
        primaryText: 'roll-up report: add timelines',
        classes: {
          closeBtn: styles['roll-up-report__add-timeline-modal__close-btn'],
        },
      }}
      onModalClose={handleCloseModel}
      footerComponent={
        <div className={styles['roll-up-report__add-timeline-modal__footer']}>
          <PulseButtonBase onClick={handleCancelModal} label={'Cancel'} />
          <PulseButtonBase
            icon
            iconClasses={{ icon: 'fal fa-plus' }}
            onClick={handleGetSelectedRollupTimelines}
            label={'ADD SELECTED TIMELINES TO ROLL-UP'}
          />
        </div>
      }
    >
      <div className={styles['roll-up-report__add-timeline-modal__content']}>
        <PulseScrollbar>
          <div className={styles['roll-up-report__add-timeline-modal__content--info']}>
            <div className={styles['content--info-icon']}>
              <i className="fal fa-info-circle"></i>
            </div>
            <div className={styles['content--info-text']}>
              <p>
                <b>Timeline Search</b>
              </p>
              <p>
                Use the search field below to locate Timelines for your Roll-up Report. Search by{' '}
                <i>Timeline Title, Project Title, Job No., Brand</i> or <i>Author</i>
              </p>
            </div>
          </div>

          <PulseInput
            InputBaseProps={{
              onChange: handleSearchingValue,
              classes: {
                root: styles['roll-up-report__add-timeline-modal__searching--root'],
                input: styles['roll-up-report__add-timeline-modal__searching--input'],
              },
              placeholder: 'Search Timelines and Projects',
              startAdornment: (
                <i className={clsx('fal fa-search', styles['roll-up-report__add-timeline-modal__searching--icon'])} />
              ),
              endAdornment: searchingValue && (
                <PulseIconButton
                  classes={{
                    pulseIcon: {
                      icon: clsx('fal fa-times', styles['roll-up-report__add-timeline-modal__searching--end-icon']),
                    },
                  }}
                  iconName=""
                  size={IconSizes.md}
                  handleClick={handleClearSearchInput}
                />
              ),
              value: searchingValue,
            }}
          />

          <PulseBryntumGrid
            selectable={true}
            ref={rollupReportAddTimelineRef}
            gridProps={{
              data: MockData,
              columns,
            }}
            paginate={true}
          />
        </PulseScrollbar>
      </div>
    </PulseModalBase>
  );
};

export default RollUpReportAddTimelinesModal;
