// Disable eslint rule until enable filter feature again.
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { debounce, noop } from 'lodash';
import Tippy from '@tippyjs/react';

import PulseIcon from 'components/pulse-icons/pulse-icons';
import PulseInput from 'components/pulse-input/pulse-input';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import PulseMenuButton from 'components/pulse-button/menu/pulse-menu-button';
import PulseIconButton from 'components/pulse-icon-button/pulse-icon-button';
import PulseCheckBox from 'components/checkbox/checkbox';

import { PulseButtonBaseProps } from 'components/pulse-button/base/pulse-button-base-types';
import PulseWithMore from 'components/pulse-with-more/pulse-with-more';
import PulseLabel from 'components/pulse-label/pulse-label';
import { Colors, ButtonVariants, LabelSizes, ButtonSizes, IconSizes } from 'pulse-commons/types';
import { GanttDisplayModel, PulseTimelineGanttToolbarProps } from 'components/pulse-timeline/pulse-timeline-types';
import { Filters } from 'components/pulse-filter/slice';
import { PulseFilterProps } from 'components/pulse-filter/pulse-filter-types';
import PulseFilter from 'components/pulse-filter/pulse-filter';
import PulseSelectBase from 'components/pulse-select/base/pulse-select-base';
import { components } from 'react-select';

import { GANTT_EXPORT_FILE_TYPES } from './pulse-timeline-gantt';
import { deepLinktoFilterInitialState } from './pulse-timeline-helpers';
import styles from './pulse-timeline-gantt.module.scss';
import { TimelineTemplateOption } from '../TimelineTemplateModal/timeline-template-modal-types';
import { SESSION_STORAGE_GANTT_DISPLAY_CONFIG } from './column-helpers';

const DEFAULT_FILTER_TAGS_MAX_WIDTH = 300;

const GANTT_TIMELINE_ITEM_STATUS = [
  { label: 'Any Status', value: 'any' },
  { label: 'Open', value: 'open' },
  { label: 'New', value: 'new' },
  { label: 'In Progress', value: 'in-progress' },
  { label: 'Completed', value: 'completed' },
];

const USER_DATA_STRUCTURE = {
  dataKey: 'data',
  isJsonApi: true,
  label: 'name',
  lastPage: 'meta.page.last_page',
  pageSize: 15,
  type: 'user',
  value: 'userid',
};

const DISPLAY_OPTIONS: Array<GanttDisplayModel> = [
  {
    name: 'project-labels',
    label: 'Show Labels',
  },
  {
    name: 'project-dates',
    label: 'Show Projected Start/End',
  },
  {
    name: 'project-phases',
    label: 'Show Phases',
  },
];

const badgeFormatter = (value: number) => (value > 99 ? '99+' : value.toString());

const GanttChartToolbar: FC<PulseTimelineGanttToolbarProps> = props => {
  const {
    canEditTimelines,
    canCreateTemplates,
    canPromoteToMaster,
    shouldDisableBaselines,
    baselines,
    currentBaselines,
    templates = [],
    enableSaveAsTemplate,
    zoomLevel,
    onSearch,
    onApplyFilters,
    onBeforeFilterFlyout,
    onAddTask,
    onSync,
    onExpand,
    onCollapsed,
    onUndo,
    onRedo,
    onZoomIn,
    onZoomOut,
    onZoomToFit,
    onShiftNext,
    onShiftPrevious,
    onExport,
    onOpenPromoteToMasterModal,
    onOpenPhaseFlyout,
    onOpenDeleteTimelineModal,
    onSetBaselines,
    onShowBaselines,
    onManageNonWorkingDaysHanlde,
    onImportFileModal,
    onOpenShareTimelineModal,
    onApplyTempate,
    onOpenSaveAsTemplate,
    onOpenEditTemplate,
    onOpenEditTimelineModal,
    onDisplayChanged,
    undoCount,
    redoCount,
    changeCount,
    onOpenTaskImportModal,
  } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [filterLabels, setFilterLabels] = useState<any>([]);
  const [filterTagsMaxWidth, setFilterTagsMaxWidth] = useState(DEFAULT_FILTER_TAGS_MAX_WIDTH);
  const [display, setDisplay] = useState<Array<string>>([]);
  const withMoreEl = useRef<any>(null);
  const ganttFilterRef = useRef<any>();

  // We shouldn't be showing public link menu when the timeline's been opened via a public link
  const shouldShowShareButton = !window.location?.href?.includes('public-links');

  const handleSearchValueChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    [],
  );

  const handleClearSearchValue = useCallback(() => setSearchValue(''), []);

  const handleTriggerFilter = () => {
    const shouldOpen = onBeforeFilterFlyout(ganttFilterRef?.current.openForm);
    if (shouldOpen) {
      ganttFilterRef?.current.openForm();
    }
  };

  const handleApplyFilters = useCallback(filters => {
    setFilterLabels(filters);
    ganttFilterRef.current = filters;
    onApplyFilters(filters);
  }, []);

  const handleResize = useCallback(() => {
    debounce(() => {
      const parentNodeOfWithMore = withMoreEl?.current?.parentNode;
      if (parentNodeOfWithMore.clientWidth - 60 < DEFAULT_FILTER_TAGS_MAX_WIDTH) {
        setFilterTagsMaxWidth(parentNodeOfWithMore.DEFAULT_FILTER_TAGS_MAX_WIDTH - 60);
      } else {
        setFilterTagsMaxWidth(DEFAULT_FILTER_TAGS_MAX_WIDTH);
      }
    }, 300);
  }, [withMoreEl?.current]);

  const allowFilters = useMemo<PulseFilterProps['allowFilters']>(
    () => [
      {
        name: Filters.assignedUsers,
        PulseSelectBaseProps: {
          labelName: 'Assigned Users',
          placeholder: 'Search for Assigned Users...',
          url: '/v2/api/users?fields[users]=userid,name',
          variant: 'select2',
          minCharacters: 3,
          extraParams: { sort: 'name' },
          classes: {
            root: styles['gantt-chart__filter-flyout__select-root'],
            selectOptionRoot: styles['gantt-chart__filter-flyout__select-option'],
          },
        },
      },
      {
        name: Filters.assignedUserOffice,
        PulseSelectBaseProps: {
          labelName: 'Assigned Groups',
          placeholder: 'Search for Assigned Group...',
          variant: 'select2',
          minCharacters: 3,
          classes: {
            root: styles['gantt-chart__filter-flyout__select-root'],
            selectOptionRoot: styles['gantt-chart__filter-flyout__select-option'],
          },
        },
      },
      {
        name: Filters.departments,
        PulseSelectBaseProps: {
          labelName: 'Department (of the Assigned User)',
          placeholder: 'Search for Department (of the Assigned User)...',
          variant: 'select2',
          minCharacters: 3,
          classes: {
            root: styles['gantt-chart__filter-flyout__select-root'],
            selectOptionRoot: styles['gantt-chart__filter-flyout__select-option'],
          },
        },
      },
      {
        name: Filters.customFilter,
        customFilter: {
          componentType: 'PulseSelectBase',
          filterName: Filters.createdBy,
        },
        PulseSelectBaseProps: {
          labelName: 'Created by',
          placeholder: 'Search for Created Users...',
          url: '/v2/api/users?fields[users]=userid,name',
          variant: 'select2',
          minCharacters: 3,
          extraParams: { sort: 'name' },
          dataStructure: USER_DATA_STRUCTURE,
          classes: {
            root: styles['gantt-chart__filter-flyout__select-root'],
            selectOptionRoot: styles['gantt-chart__filter-flyout__select-option'],
          },
        },
      },
      {
        name: Filters.status,
        PulseSelectBaseProps: {
          labelName: 'Item Status',
          placeholder: 'Search for Item Status...',
          dataStructure: null,
          variant: 'select2',
          defaultOptions: true,
          isSearchable: true,
          staticOptions: GANTT_TIMELINE_ITEM_STATUS,
          classes: {
            root: styles['gantt-chart__filter-flyout__select-root'],
            selectOptionRoot: styles['gantt-chart__filter-flyout__select-option'],
          },
        },
      },
    ],
    [],
  );

  const handleOpenManageNonWorkingDaysModal = () => {
    onManageNonWorkingDaysHanlde();
  };

  const handleDisplayChanged = useCallback(
    (arg: GanttDisplayModel) => {
      const nextDisplay = [...display];

      // Find display values in current display
      const index = display.findIndex(it => it === arg.name);

      // Check display enable or not
      if (index !== -1) {
        nextDisplay.splice(index, 1);
      } else {
        nextDisplay.push(arg.name);
      }

      // Update state
      setDisplay(nextDisplay);

      // Publish change display actions
      onDisplayChanged && onDisplayChanged({ ...arg, enabled: index === -1 });
    },
    [display],
  );

  const actionMenus = useMemo<Array<PulseButtonBaseProps>>(() => {
    // Default actions
    const accessorOptions: Array<PulseButtonBaseProps> = [
      {
        classes: [styles['actions-menu__btn']],
        children: (
          <div className={styles['gantt-chart__ultility_menu_item__ctn']}>
            <i className={clsx(['fal fa-file-export', styles['gantt-chart__menu-item-btn__icon']])} />
            <PulseMenuButton
              menuButtonData={[
                {
                  label: 'Export PDF/PNG',
                  icon: true,
                  iconClasses: {
                    root: [styles['actions-menu__btn__icon-wrapper']],
                    icon: clsx('fal fa-file-image', styles['actions-menu__btn__icon']),
                  },
                  classes: [styles['actions-menu__btn']],
                  onClick: () => {
                    onExport(GANTT_EXPORT_FILE_TYPES.FILE_PDF);
                  },
                },
                {
                  label: 'Export Excel',
                  icon: true,
                  iconClasses: {
                    root: [styles['actions-menu__btn__icon-wrapper']],
                    icon: clsx('fal fa-file-excel', styles['actions-menu__btn__icon']),
                  },
                  classes: [styles['actions-menu__btn']],
                  onClick: () => {
                    onExport(GANTT_EXPORT_FILE_TYPES.FILE_EXCEL);
                  },
                },
                {
                  label: 'Export MS Project',
                  icon: true,
                  iconClasses: {
                    root: [styles['actions-menu__btn__icon-wrapper']],
                    icon: clsx('fal fa-file-powerpoint', styles['actions-menu__btn__icon']),
                  },
                  classes: [styles['actions-menu__btn']],
                  onClick: () => {
                    onExport(GANTT_EXPORT_FILE_TYPES.FILE_MSP);
                  },
                },
              ]}
              classes={{ menuCtn: styles['gantt-chart__feature-btn__template-menu-ctn'] }}
              PulseButtonProps={{
                label: 'Export',
                iconPosition: 'right',
                iconClasses: {
                  icon: clsx('fal fa-chevron-right', styles['gantt-chart__menu-item-btn__icon']),
                },
                iconSize: IconSizes.md,
                variant: ButtonVariants.text,
                classes: [styles['gantt-chart__menu-item-btn__ctn']],
              }}
              TippyProps={{
                arrow: false,
                offset: [0, 25],
                placement: 'left-start',
                zIndex: 1000,
                trigger: 'mouseenter',
              }}
            />
          </div>
        ),
      },
    ];

    if (!canEditTimelines) {
      return accessorOptions;
    }

    const templateMenuOptions: Array<any> = [];

    // if (canCreateTemplates && enableSaveAsTemplate) {
    templateMenuOptions.push({
      label: 'Save as Timeline Template',
      icon: true,
      iconClasses: {
        root: [styles['actions-menu__btn__icon-wrapper']],
        icon: clsx('fa-light fa-floppy-disk-pen', styles['actions-menu__btn__icon']),
      },
      classes: [styles['actions-menu__btn']],
      onClick: onOpenSaveAsTemplate,
    });
    // }

    // if (templates.length) {
    templateMenuOptions.push({
      label: 'Apply Timeline Template',
      icon: true,
      iconClasses: {
        root: [styles['actions-menu__btn__icon-wrapper']],
        icon: clsx('fa-light fa-chart-gantt', styles['actions-menu__btn__icon']),
      },
      classes: [styles['actions-menu__btn']],
      onClick: () => {
        // TODO: Open apply template modal
      },
    });

    templateMenuOptions.push({
      label: 'Manage Timeline Templates',
      icon: true,
      iconClasses: {
        root: [styles['actions-menu__btn__icon-wrapper']],
        icon: clsx('fal fa-sliders-h', styles['actions-menu__btn__icon']),
      },
      classes: [styles['actions-menu__btn']],
      onClick: () => {
        // TODO: Open apply template modal
      },
    });
    // }

    // TODO: commenting this out untill the template settings modal development is completed.
    // if (templateMenuOptions.length) {
    //   accessorOptions.unshift({
    //     classes: [styles['actions-menu__btn']],
    //     children: (
    //       <div className={styles['gantt-chart__ultility_menu_item__ctn']}>
    //         <i className={clsx(['fal fa-paste', styles['gantt-chart__menu-item-btn__icon']])} />
    //         <PulseMenuButton
    //           menuButtonData={templateMenuOptions}
    //           classes={{ menuCtn: styles['gantt-chart__feature-btn__template-menu-ctn'] }}
    //           PulseButtonProps={{
    //             label: 'Templates',
    //             iconPosition: 'right',
    //             iconClasses: {
    //               icon: clsx('fal fa-chevron-right', styles['gantt-chart__menu-item-btn__icon']),
    //             },
    //             iconSize: IconSizes.md,
    //             variant: ButtonVariants.text,
    //             classes: [styles['gantt-chart__menu-item-btn__ctn']],
    //           }}
    //           TippyProps={{
    //             arrow: false,
    //             offset: [0, 25],
    //             placement: 'left-start',
    //             zIndex: 1000,
    //             trigger: 'mouseenter',
    //           }}
    //         />
    //       </div>
    //     ),
    //   });
    // }

    const editorOptionPrefix: Array<PulseButtonBaseProps> = [
      {
        label: 'Timeline Settings',
        icon: true,
        iconClasses: {
          root: [styles['actions-menu__btn__icon-wrapper']],
          icon: clsx('fal fa-cog', styles['actions-menu__btn__icon']),
        },
        classes: [styles['actions-menu__btn']],
        onClick: onOpenEditTimelineModal,
      },
    ];

    const editorOptionSuffix: Array<PulseButtonBaseProps> = [
      {
        label: 'Import',
        icon: true,
        iconClasses: {
          root: [styles['actions-menu__btn__icon-wrapper']],
          icon: clsx('fal fa-file-import', styles['actions-menu__btn__icon']),
        },
        classes: [styles['actions-menu__btn']],
        children: (
          <div className={styles['gantt-chart__ultility_menu_item__ctn']}>
            <i className={clsx(['fal fa-file-import', styles['gantt-chart__menu-item-btn__icon']])} />
            <PulseMenuButton
              menuButtonData={[
                {
                  label: 'Import Project Tasks',
                  icon: true,
                  iconClasses: {
                    root: [styles['actions-menu__btn__icon-wrapper']],
                    icon: clsx('fal fa-tasks', styles['actions-menu__btn__icon']),
                  },
                  classes: [styles['actions-menu__btn']],
                  onClick: () => {
                    onOpenTaskImportModal?.();
                  },
                },
                {
                  label: 'Import Data',
                  icon: true,
                  iconClasses: {
                    root: [styles['actions-menu__btn__icon-wrapper']],
                    icon: clsx('fal fa-file-excel', styles['actions-menu__btn__icon']),
                  },
                  classes: [styles['actions-menu__btn']],
                  onClick: onImportFileModal,
                },
              ]}
              classes={{ menuCtn: styles['gantt-chart__feature-btn__template-menu-ctn'] }}
              PulseButtonProps={{
                label: 'Import',
                iconPosition: 'right',
                iconClasses: {
                  icon: clsx('fal fa-chevron-right', styles['gantt-chart__menu-item-btn__icon']),
                },
                iconSize: IconSizes.md,
                variant: ButtonVariants.text,
                classes: [styles['gantt-chart__menu-item-btn__ctn']],
              }}
              TippyProps={{
                arrow: false,
                offset: [0, 25],
                placement: 'right-start',
                zIndex: 1000,
                trigger: 'mouseenter',
              }}
            />
          </div>
        ),
      },
      {
        label: 'Manage Non-Working Days',
        icon: true,
        iconClasses: {
          root: [styles['actions-menu__btn__icon-wrapper']],
          icon: clsx('fal fa-tree-palm', styles['actions-menu__btn__icon']),
        },
        classes: [styles['actions-menu__btn']],
        onClick: () => {
          handleOpenManageNonWorkingDaysModal();
        },
      },
      {
        label: 'Delete Timeline',
        icon: true,
        iconClasses: {
          root: [styles['actions-menu__btn__icon-wrapper']],
          icon: clsx('fal fa-trash-alt', styles['actions-menu__btn__icon']),
        },
        classes: [styles['actions-menu__btn']],
        onClick: () => {
          onOpenDeleteTimelineModal && onOpenDeleteTimelineModal();
        },
      },
    ];

    // Enable Promote To Master if current timeline is not master
    // Add to first of edit menus
    if (canPromoteToMaster) {
      editorOptionPrefix.push({
        label: 'Promote to Master',
        icon: true,
        iconClasses: {
          root: [styles['actions-menu__btn__icon-wrapper']],
          icon: clsx('fal fa-crown', styles['actions-menu__btn__icon']),
        },
        classes: [styles['actions-menu__btn']],
        onClick: () => {
          onOpenPromoteToMasterModal && onOpenPromoteToMasterModal();
        },
      });
    }

    // Full actions if current user has edit permission
    return editorOptionPrefix.concat(accessorOptions).concat(editorOptionSuffix);
  }, [canEditTimelines, changeCount]);

  const displayMenus = useMemo(() => {
    if (shouldShowShareButton) {
      return DISPLAY_OPTIONS;
    }
    // Do not show project dates when open by public links
    return DISPLAY_OPTIONS.filter(it => it.name !== 'project-dates');
  }, [shouldShowShareButton]);

  // Search value on Gantt chart
  useEffect(() => {
    onSearch && onSearch(searchValue);
  }, [searchValue]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const ganttFeatureDisplay = localStorage.getItem(SESSION_STORAGE_GANTT_DISPLAY_CONFIG);
    const displays: Array<string> = [];

    // Set default enabled or base on previous choices for Project Start/End
    if (!ganttFeatureDisplay || ganttFeatureDisplay.includes('project-dates')) {
      displays.push('project-dates');
    }

    // Set default enabled or base on previous choices for Project Phases and Marker
    if (!ganttFeatureDisplay || ganttFeatureDisplay.includes('project-phases')) {
      displays.push('project-phases');
    }

    // Set enabled base on previous choices for Item labels
    if (ganttFeatureDisplay?.includes('project-labels')) {
      displays.push('project-labels');
    }

    if (displays.length) {
      setDisplay(displays);
    }
  }, []);

  const templateMenus = useMemo<Array<TimelineTemplateOption>>(() => {
    return templates.map(it => ({
      label: it.name,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      value: it.id!,
      raw: it,
    }));
  }, [templates]);

  return (
    <>
      <div className={styles['gantt-chart__header']}>
        <div className={styles['gantt-chart__actions']}>
          <div className={styles['gantt-chart__search']}>
            <PulseInput
              classes={{
                root: styles['gantt-chart-input'],
                input: styles['gantt-chart-input__input'],
                label: styles['gantt-chart-input__label'],
              }}
              label={''}
              InputBaseProps={{
                value: searchValue,
                placeholder: 'Quick Search...',
                onChange: handleSearchValueChanged,
                startAdornment: (
                  <PulseIcon
                    classes={{
                      root: styles['gantt-chart-input__start-icon-root'],
                      icon: clsx('fal fa-search', styles['gantt-chart-input__start-icon']),
                    }}
                    iconName=""
                    size={IconSizes.lg}
                  />
                ),
                endAdornment: searchValue && (
                  <PulseIconButton
                    classes={{
                      pulseIcon: {
                        icon: clsx('fal fa-times', styles['gantt-chart-input__end-icon']),
                      },
                    }}
                    iconName=""
                    size={IconSizes.lg}
                    onClick={handleClearSearchValue}
                  />
                ),
              }}
            />
          </div>
          <div className={styles['gantt-chart__buttons']}>
            <Tippy content="Expand all rows" placement="top" appendTo={document.body}>
              <PulseButtonBase
                classes={[styles['actions-btn']]}
                variant={ButtonVariants.outlined}
                icon
                iconClasses={{ icon: clsx('fal fa-chevron-double-down', styles['actions-btn-icon']) }}
                label=""
                onClick={onExpand}
              />
            </Tippy>
            <Tippy content="Collapse all rows" placement="top" appendTo={document.body}>
              <PulseButtonBase
                classes={[styles['actions-btn']]}
                variant={ButtonVariants.outlined}
                icon
                iconClasses={{ icon: clsx('fal fa-chevron-double-up', styles['actions-btn-icon']) }}
                label=""
                onClick={onCollapsed}
              />
            </Tippy>
          </div>
          {canEditTimelines && (
            <>
              <div className={styles['gantt-chart__buttons']}>
                <Tippy content="Undo" placement="top" appendTo={document.body}>
                  <PulseButtonBase
                    classes={[
                      styles['actions-btn'],
                      styles['actions-btn__undoredo'],
                      undoCount <= 0 ? styles['actions-btn--disabled'] : '',
                      undoCount || redoCount ? styles['actions-btn__undoredo--can-undoredo'] : '',
                    ]}
                    variant={ButtonVariants.outlined}
                    icon
                    label=""
                    onClick={() => undoCount > 0 && onUndo && onUndo()}
                  >
                    <i className={clsx('fal fa-undo', styles['actions-btn-icon'])}></i>
                    {Boolean(undoCount > 0) && <span className={styles['badge']}>{badgeFormatter(undoCount)}</span>}
                  </PulseButtonBase>
                </Tippy>
                <Tippy content="Redo" placement="top" appendTo={document.body}>
                  <PulseButtonBase
                    classes={[
                      styles['actions-btn'],
                      styles['actions-btn__undoredo'],
                      redoCount <= 0 ? styles['actions-btn--disabled'] : '',
                    ]}
                    variant={ButtonVariants.outlined}
                    icon
                    label=""
                    onClick={() => redoCount > 0 && onRedo && onRedo()}
                  >
                    <i className={clsx('fal fa-redo', styles['actions-btn-icon'])}></i>
                    {Boolean(redoCount > 0) && <span className={styles['badge']}>{badgeFormatter(redoCount)}</span>}
                  </PulseButtonBase>
                </Tippy>
              </div>
              <Tippy content="Add new item to the end" placement="top" appendTo={document.body}>
                <PulseButtonBase
                  color={Colors.primary}
                  icon
                  classes={[styles['action-btn__add']]}
                  iconClasses={{ icon: clsx('fal fa-plus', styles['actions-btn-icon']) }}
                  label="Add Item"
                  onClick={onAddTask}
                />
              </Tippy>
            </>
          )}
        </div>
        <div className={styles['gantt-chart__actions']}>
          <div className={styles['gantt-chart-toolbar__middle']}>
            <PulseWithMore
              ref={withMoreEl}
              maxWidth={filterTagsMaxWidth}
              classes={{
                root: styles['gantt-chart-toolbar__with-more'],
              }}
              childrenKey="label"
            >
              {Object.keys(filterLabels)
                .filter(key => filterLabels[key])
                .map(keys => {
                  return filterLabels[keys].map(filter => {
                    return (
                      <PulseLabel
                        classes={{
                          root: styles['gantt-chart-toolbar__with-more-label'],
                        }}
                        actionButton
                        actionButtonProps={{
                          classes: {
                            root: styles['gantt-chart-toolbar__with-more-button'],
                            pulseIcon: {
                              icon: 'fal fa-times',
                            },
                          },
                          handleClick: () => {
                            ganttFilterRef?.current.removeElementInAppliedFilter(keys, filter);
                          },

                          variant: ButtonVariants.contained,
                        }}
                        key={filter.value}
                        color={Colors.default}
                        size={LabelSizes.md}
                        label={filter.label}
                        icon={false}
                      />
                    );
                  });
                })}
            </PulseWithMore>
          </div>
          {/* TODO:: commenting out for now. Have to fix some issues on a laer stage
          <Tippy content="Filter" placement="top" appendTo={document.body}>
            <PulseButtonBase
              classes={[styles['gantt-chart__filter-btn']]}
              variant={ButtonVariants.outlined}
              icon
              iconClasses={{ icon: clsx('fal fa-filter', styles['actions-btn-icon']) }}
              label=""
              onClick={handleTriggerFilter}
            />
          </Tippy> */}
          {canEditTimelines && (
            <PulseButtonBase
              classes={[styles['gantt-chart__feature-btn']]}
              variant={ButtonVariants.outlined}
              label="Phases"
              onClick={onOpenPhaseFlyout}
            />
          )}
          {!shouldDisableBaselines && (
            <PulseMenuButton
              classes={{ menuCtn: styles['gantt-chart__feature-btn__menu-ctn'] }}
              PulseButtonProps={{
                label: 'baselines',
                iconPosition: 'right',
                classes: [styles['gantt-chart__feature-btn']],
                variant: ButtonVariants.outlined,
              }}
              TippyProps={{
                arrow: false,
                offset: [0, 0],
                zIndex: 1000,
                trigger: 'mouseenter click',
              }}
            >
              {canEditTimelines && (
                <div className={styles['gantt-chart__menu-item-btn']}>
                  <i className={clsx(['fal fa-save', styles['gantt-chart__menu-item-btn__icon']])} />
                  <PulseMenuButton
                    menuButtonData={[
                      {
                        label: 'Baseline 1',
                        onClick: () => onSetBaselines(1),
                      },
                      {
                        label: 'Baseline 2',
                        onClick: () => onSetBaselines(2),
                      },
                      {
                        label: 'Baseline 3',
                        onClick: () => onSetBaselines(3),
                      },
                    ]}
                    classes={{ menuCtn: styles['gantt-chart__feature-btn__template-menu-ctn'] }}
                    PulseButtonProps={{
                      label: 'Set',
                      iconPosition: 'right',
                      iconClasses: {
                        icon: clsx('fal fa-chevron-right', styles['gantt-chart__menu-item-btn__icon']),
                      },
                      iconSize: IconSizes.md,
                      variant: ButtonVariants.text,
                      classes: [styles['gantt-chart__menu-item-btn__ctn']],
                    }}
                    TippyProps={{
                      arrow: false,
                      offset: [0, 0],
                      placement: 'right-start',
                      zIndex: 1000,
                      trigger: 'mouseenter',
                    }}
                  />
                </div>
              )}
              <div className={styles['gantt-chart__menu-item-btn']}>
                <i className={clsx(['fal fa-eye', styles['gantt-chart__menu-item-btn__icon']])} />
                {baselines.length ? (
                  <PulseMenuButton
                    classes={{ menuCtn: styles['gantt-chart__feature-btn__menu-ctn'] }}
                    PulseButtonProps={{
                      label: 'Show',
                      iconPosition: 'right',
                      iconClasses: {
                        icon: clsx('fal fa-chevron-right', styles['gantt-chart__menu-item-btn__icon']),
                      },
                      iconSize: IconSizes.md,
                      variant: ButtonVariants.text,
                      classes: [styles['gantt-chart__menu-item-btn__ctn']],
                    }}
                    TippyProps={{
                      arrow: false,
                      offset: [0, 0],
                      placement: 'right-start',
                      zIndex: 1000,
                      trigger: 'mouseenter click',
                    }}
                  >
                    {baselines
                      .filter((baseline, index) => baselines.indexOf(baseline) === index)
                      .map(bl => (
                        <div key={bl} className={styles['gantt-chart__menu-checkbox_ctn']}>
                          <PulseCheckBox
                            name={`baseline-${bl}`}
                            label={`Baseline ${bl}`}
                            labelPlacement="right"
                            labelClasses={styles['gantt-chart__menu-checkbox_label']}
                            showLabel
                            checked={currentBaselines.includes(bl)}
                            onClick={() => onShowBaselines(bl)}
                          />
                        </div>
                      ))}
                  </PulseMenuButton>
                ) : (
                  <PulseButtonBase
                    label="show"
                    icon
                    iconPosition="right"
                    iconClasses={{
                      icon: clsx('fal fa-chevron-right', styles['gantt-chart__menu-item-btn__icon']),
                    }}
                    iconSize={IconSizes.md}
                    variant={ButtonVariants.text}
                    classes={[styles['gantt-chart__menu-item-btn__ctn']]}
                    onClick={() => onShowBaselines(0)}
                  />
                )}
              </div>
            </PulseMenuButton>
          )}
          {canEditTimelines && (
            <PulseMenuButton
              classes={{ menuCtn: styles['gantt-chart__feature-btn__template-menu-ctn'] }}
              PulseButtonProps={{
                label: 'templates',
                iconPosition: 'right',
                classes: [styles['gantt-chart__feature-btn']],
                variant: ButtonVariants.outlined,
              }}
              TippyProps={{
                arrow: false,
                offset: [0, 0],
                zIndex: 1000,
                trigger: 'mouseenter click',
              }}
            >
              {canCreateTemplates && enableSaveAsTemplate && (
                <div>
                  <PulseButtonBase
                    classes={[styles['gantt-chart__feature-btn__template-menu__btn']]}
                    color={Colors.default}
                    icon
                    iconClasses={{ icon: clsx('fal fa-save', styles['gantt-chart__menu-item-btn__icon']) }}
                    label="Save as Template"
                    sizes={ButtonSizes.sm}
                    variant={ButtonVariants.text}
                    onClick={() => onOpenSaveAsTemplate()}
                  />
                </div>
              )}
              <div className={styles['gantt-chart__feature-btn__menu-ctn']}>
                <PulseSelectBase
                  dataStructure={null}
                  placeholder="Quick Search ..."
                  TippyProps={{
                    offset: [0, 0],
                    placement: 'right-start',
                    popperOptions: {
                      strategy: 'fixed',
                    },
                  }}
                  classes={{
                    root: styles['gantt-chart__menu-item__template__root'],
                    selectContainer: styles['gantt-chart__menu-item__template__select'],
                    menu: { menuCtn: styles['gantt-chart__menu-item__template__menuCtn'] },
                  }}
                  hideMenuOnValueChange={true}
                  staticOptions={templateMenus}
                  isClearable={false}
                  insideModal={true}
                  closeMenuOnSelect
                  isSearchable={true}
                  menuShouldScrollIntoView
                  isMulti={false}
                  components={{
                    Control: controlProps => {
                      return (
                        <div className={styles['gantt-chart__menu-item__template__control']}>
                          <i
                            className={clsx('fal fa-search', styles['gantt-chart__menu-item__template__search-icon'])}
                          ></i>
                          <components.Control
                            className={styles['gantt-chart__menu-item__template__search-text']}
                            {...controlProps}
                          />
                        </div>
                      );
                    },
                    Placeholder: placeholderProps => (
                      <components.Placeholder
                        className={styles['gantt-chart__menu-item__template__placeholder']}
                        {...placeholderProps}
                      />
                    ),
                    Menu: menuProps => (
                      <components.Menu className={styles['gantt-chart__menu-item__template__menu']} {...menuProps} />
                    ),
                    MenuList: menuListProps => (
                      <components.MenuList
                        className={styles['gantt-chart__menu-item__template__menu--list']}
                        {...menuListProps}
                      />
                    ),
                    Option: optionProps => {
                      const { innerProps, label, data } = optionProps;

                      return (
                        <div className={styles['gantt-chart__menu-item__template__option']} {...innerProps}>
                          <span
                            className={styles['gantt-chart__text-truncate']}
                            onClick={() => onApplyTempate(data.raw.timelineItems)}
                          >
                            {label}
                          </span>
                          {canCreateTemplates && data.raw.editable && (
                            <i className="fal fa-edit" onClick={() => onOpenEditTemplate(data.raw.id)} />
                          )}
                        </div>
                      );
                    },
                  }}
                  customContainer={
                    <div className={styles['gantt-chart__menu-item__template__section']}>
                      <PulseButtonBase
                        classes={[styles['gantt-chart__feature-btn__template-menu__btn']]}
                        color={Colors.default}
                        icon={true}
                        iconClasses={{
                          icon: clsx('fal fa-paste', styles['gantt-chart__menu-item-btn__icon']),
                        }}
                        label="Apply Template"
                        sizes={ButtonSizes.sm}
                        variant={ButtonVariants.text}
                      />
                      <i className={clsx('fal fa-chevron-right', styles['gantt-chart__menu-item-btn__icon'])} />
                    </div>
                  }
                />
              </div>
            </PulseMenuButton>
          )}
          <Tippy content="Toggle display" placement="top" appendTo={document.body}>
            <PulseMenuButton
              classes={{ menuCtn: styles['gantt-chart__feature-btn__template-menu-ctn'] }}
              PulseButtonProps={{
                label: 'DISPLAY',
                iconPosition: 'right',
                classes: [styles['gantt-chart__feature-btn']],
                variant: ButtonVariants.outlined,
              }}
              TippyProps={{
                arrow: false,
                offset: [0, 0],
                zIndex: 1000,
                trigger: 'mouseenter click',
              }}
            >
              {displayMenus.map(it => (
                <div key={it.name} className={styles['gantt-chart__menu-checkbox_ctn']}>
                  <PulseCheckBox
                    name={`display-${it.name}`}
                    label={it.label}
                    labelPlacement="right"
                    labelClasses={styles['gantt-chart__menu-checkbox_label']}
                    showLabel
                    checked={display.includes(it.name)}
                    onClick={() => handleDisplayChanged(it)}
                  />
                </div>
              ))}
            </PulseMenuButton>
          </Tippy>
          <div className={styles['gantt-chart__buttons']}>
            <Tippy content="Zoom In" placement="top">
              <PulseButtonBase
                classes={[styles['actions-btn']]}
                variant={ButtonVariants.outlined}
                icon
                iconClasses={{
                  icon: clsx('fal fa-search-plus', styles['actions-btn-icon']),
                }}
                label=""
                onClick={onZoomIn}
                disabled={zoomLevel >= 10}
              />
            </Tippy>
            <Tippy content="Zoom Out" placement="top">
              <PulseButtonBase
                classes={[styles['actions-btn']]}
                variant={ButtonVariants.outlined}
                icon
                iconClasses={{
                  icon: clsx('fal fa-search-minus', styles['actions-btn-icon']),
                }}
                label=""
                onClick={onZoomOut}
                disabled={zoomLevel == 0}
              />
            </Tippy>
            <Tippy content="Zoom To Fit" placement="top">
              <PulseButtonBase
                classes={[styles['actions-btn']]}
                variant={ButtonVariants.outlined}
                icon
                iconClasses={{
                  icon: clsx('fal fa-compress-arrows-alt', styles['actions-btn-icon']),
                }}
                label=""
                onClick={onZoomToFit}
              />
            </Tippy>
            <Tippy content="Previous time span" placement="top">
              <PulseButtonBase
                classes={[styles['actions-btn']]}
                variant={ButtonVariants.outlined}
                icon
                iconClasses={{
                  icon: clsx('fal fa-angle-left', styles['actions-btn-icon']),
                }}
                label=""
                onClick={onShiftPrevious}
              />
            </Tippy>
            <Tippy content="Next time span" placement="top">
              <PulseButtonBase
                classes={[styles['actions-btn']]}
                variant={ButtonVariants.outlined}
                icon
                iconClasses={{
                  icon: clsx('fal fa-angle-right', styles['actions-btn-icon']),
                }}
                label=""
                onClick={onShiftNext}
              />
            </Tippy>
          </div>
          {shouldShowShareButton && (
            <Tippy content="Share Timeline" placement="top" appendTo={document.body}>
              <PulseButtonBase
                icon
                classes={[styles['gantt-chart__feature-btn']]}
                variant={ButtonVariants.outlined}
                iconClasses={{ icon: clsx('fal fa-share-alt', styles['actions-btn-icon']) }}
                label="Share"
                onClick={onOpenShareTimelineModal}
              />
            </Tippy>
          )}
          <Tippy content="Save all changes" placement="top" appendTo={document.body}>
            <PulseButtonBase
              disabled={!changeCount}
              color={Colors.success}
              icon
              iconClasses={{ icon: clsx('fal fa-save', styles['actions-btn-icon']) }}
              label="SAVE"
              onClick={() => onSync()}
              classes={[styles['gantt-chart__feature-btn__save']]}
            />
          </Tippy>
          <PulseMenuButton
            classes={{
              root: [styles['gantt-chart__menu-btn']],
            }}
            PulseButtonProps={{
              label: '',
              iconPosition: 'right',
              classes: [styles['gantt-chart__feature-btn'], styles['gantt-chart__feature-btn__ellipsis']],
              variant: ButtonVariants.outlined,
              icon: true,
              iconClasses: {
                icon: clsx('fal fa-ellipsis-v', styles['actions-btn-icon']),
              },
              iconSize: IconSizes.md,
              onClick: noop,
            }}
            TippyProps={{
              arrow: false,
              zIndex: 1000,
              trigger: 'click',
            }}
            menuButtonData={actionMenus}
          />
        </div>
      </div>
      {/* TODO:: commenting out for now. Have to fix some issues on a laer stage
      <PulseFilter
        ref={ganttFilterRef}
        filterTitle="GANTT: FILTERS"
        filterInitialValue={deepLinktoFilterInitialState()}
        onApplyFilter={handleApplyFilters}
        onResetFilterCustom={() => {
          ganttFilterRef?.current?.addFilter('status', [GANTT_TIMELINE_ITEM_STATUS[0]]);
          ganttFilterRef?.current?.copyFromSelectedFilterToAppliedFilter();
        }}
        allowFilters={allowFilters}
      /> */}
    </>
  );
};

export default GanttChartToolbar;
