import { createStore, applyMiddleware, compose, combineReducers, Store } from 'redux';
import thunk from 'redux-thunk';
import { TimesheetErrorReportReducer } from './reducers';

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
  compose;

const rootReducer = combineReducers({
  timesheetErrorReport: TimesheetErrorReportReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const TimesheetErrorReportStore: Store<RootState> = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default TimesheetErrorReportStore;
