import React, { FC, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseInput from 'components/pulse-input/pulse-input';
import PulseDatePicker from 'components/pulse-date-picker/pulse-datepicker';
import { AddNonWorkingDaysModalProps, NonWorkingDaysModel } from '../non-working-days-modal-types';
import styles from '../non-working-days-modal.module.scss';

const AddNonWorkingDaysModal: FC<AddNonWorkingDaysModalProps> = props => {
  const { onCancel, onSave } = props;

  const [nonWorkingDay, setNonWorkingDay] = useState<NonWorkingDaysModel>({
    name: '',
    cls: 'timelineHoliday',
    isWorking: true,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });

  const handleSaveNonWorkingDays = () => {
    onSave && onSave(nonWorkingDay);
  };

  const handleNameChange = ({ target }) =>
    setNonWorkingDay({
      ...nonWorkingDay,
      name: target.value,
    });

  const handleDateChange = dateValue =>
    setNonWorkingDay({
      ...nonWorkingDay,
      startDate: moment(dateValue.values.startDate).format('YYYY-MM-DD'),
      endDate: moment(dateValue.values.endDate).format('YYYY-MM-DD'),
    });

  return (
    <PulseModalBase
      variant="jqconfirm"
      open
      headerIconClasses="fal fa-calendar-alt"
      classes={{
        dialog: {
          paper: styles['non-working-days-modal__dialog'],
        },
      }}
      PulseModalHeaderProps={{
        onCloseClick: onCancel,
        primaryText: 'ADD TIMELINE NON-WORKING DAYS',
      }}
      onModalClose={onCancel}
      PulseModalFooterProps={{
        classes: {
          root: [styles['non-working-days-modal__footer']],
        },
        confirmText: 'Save Non-Working Day',
        confirmIconClasses: 'fal fa-save',
        confirmDisabled: !nonWorkingDay.name,
        onConfirmClick: handleSaveNonWorkingDays,
      }}
    >
      <div className={clsx(styles['non-working-days-modal'], styles['non-working-days-modal--add'])}>
        <PulseInput
          label="Holiday Name"
          classes={{
            root: styles['non-working-days-modal__name__input-ctn'],
          }}
          InputBaseProps={{
            classes: {
              input: styles['non-working-days-modal__name__input'],
            },
            placeholder: 'Enter holiday name',
            onChange: handleNameChange,
            value: nonWorkingDay.name,
          }}
        />
        <div className={styles['non-working-days-modal__date-picker__ctn']}>
          <PulseDatePicker
            classes={{
              root: 'non-working-days-modal__date-picker__select',
            }}
            DatepickerProps={{
              DatePickerInputProps: {
                labelStart: 'Start date',
                labelEnd: 'End date',
                classes: {
                  root: styles['non-working-days-modal_date-picker'],
                  labelStart: styles['non-working-days-modal_date-picker__label'],
                  labelEnd: styles['non-working-days-modal__date-picker__label'],
                  start: styles['non-working-days-modal__date-picker__input'],
                  end: styles['non-working-days-modal__date-picker__input'],
                },
              },
              TippyProps: {
                offset: [0, 0],
                zIndex: 1000,
                popperOptions: {
                  strategy: 'fixed',
                },
              },
              variant: 'input',
              range: true,
            }}
            onChange={handleDateChange}
            values={{
              startDate: moment(nonWorkingDay.startDate).toDate(),
              endDate: moment(nonWorkingDay.endDate).toDate(),
            }}
          />
        </div>
      </div>
    </PulseModalBase>
  );
};

export default AddNonWorkingDaysModal;
