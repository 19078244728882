import clsx from 'clsx';
import { Colors, Sizes } from 'pulse-commons/types';
import React, { ReactElement } from 'react';
import { LabelProps } from '../../types/label-types';
import Icon from '../pulse-icons/pulse-icons';
import styles from './label.module.scss';

const Label = (props: LabelProps): ReactElement => {
  const { color = Colors.default, size = Sizes.md, iconName, text } = props;
  const processedClassName = clsx(styles['pcs-label'], styles[color], styles[size]);
  return (
    <span className={processedClassName}>
      {iconName && (
        <Icon
          classes={{
            icon: ['material-icons', styles['pcs-icon']],
          }}
          iconName={iconName}
        />
      )}
      <span className={styles['pcs-label__text-ctn']}>
        <span className={styles['pcs-label__text']}>{text}</span>
      </span>
    </span>
  );
};

export default Label;
