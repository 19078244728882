import React, { ReactElement, Ref } from 'react';
import { PulseFilterProps } from './pulse-filter-types';
import { Provider } from 'react-redux';
import { forwardRef } from 'react';
import store from './store';
import Filter from './components/Filter/filter';
import { PulseFilterFormHandles } from './pulse-filter-types';

export const PulseFilter = (props: PulseFilterProps, ref: Ref<PulseFilterFormHandles>): ReactElement => {
  return (
    <Provider store={store}>
      <Filter {...props} ref={ref} />
    </Provider>
  );
};

export default forwardRef(PulseFilter);
