import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { PulseDonutChartProps } from './pulse-donut-chart-types';
import am5 from 'components/amchart5/pcs-amchart-base';
import * as am5percent from '@amcharts/amcharts5/percent';
import styles from './pulse-donut-chart.module.scss';
import clsx from 'clsx';

const DEFAULT_INNER_RADIUS = 22;
const DEFAULT_OUTER_RADIUS = 30;

const isColor = (strColor: string) => {
  return /^#[0-9A-F]{6}$/i.test(strColor);
};

const getDataValue = (actual: number, estimate: number): { value: number }[] => {
  if (estimate === 0) {
    return [{ value: 0 }, { value: 1 }];
  }
  if (Math.abs(actual) > Math.abs(estimate)) {
    return [
      {
        value: Math.abs(actual) - (Math.ceil(Math.abs(actual) / Math.abs(estimate)) - 1) * Math.abs(estimate),
      },
      {
        value: Math.ceil(Math.abs(actual) / Math.abs(estimate)) * Math.abs(estimate) - Math.abs(actual),
      },
    ];
  }
  return [{ value: Math.abs(actual) }, { value: Math.abs(estimate) - Math.abs(actual) }];
};

const PulseDonutChart = (props: PulseDonutChartProps): React.ReactElement => {
  const {
    id,
    classes,
    innerColor,
    outerColor,
    innerRadius = DEFAULT_INNER_RADIUS,
    outerRadius = DEFAULT_OUTER_RADIUS,
    actualValue,
    estimatedValue,
    innerTextStyles,
    showTooltip,
    tooltipText,
  } = props;
  const root = useRef<am5.Root>();
  const pieSeries = useRef<am5percent.PieSeries>();
  const pieSeriesInner = useRef<am5percent.PieSeries>();

  const setSeriesColor = useCallback((): void => {
    if (pieSeries.current && pieSeriesInner.current) {
      const colorLevel = actualValue / estimatedValue;

      // set default colors in series of piechart
      switch (true) {
        case estimatedValue === 0:
          pieSeries.current.get('colors')?.set('colors', [am5.color('#e5e5e5'), am5.color('#e5e5e5')]);
          pieSeriesInner.current.get('colors')?.set('colors', [am5.color('#fff'), am5.color('#fff')]);
          break;
        case colorLevel < -1:
          if (actualValue % estimatedValue === 0) {
            pieSeries.current.get('colors')?.set('colors', [am5.color('#5c5c5c'), am5.color('#5c5c5c')]);
            pieSeriesInner.current.get('colors')?.set('colors', [am5.color('#e5e5e5'), am5.color('#e5e5e5')]);
            break;
          }
          pieSeries.current.get('colors')?.set('colors', [am5.color('#999999'), am5.color('#5c5c5c')]);
          pieSeriesInner.current.get('colors')?.set('colors', [am5.color('#f5f5f5'), am5.color('#e5e5e5')]);
          break;
        case colorLevel === -1:
          pieSeries.current.get('colors')?.set('colors', [am5.color('#999999'), am5.color('#999999')]);
          pieSeriesInner.current.get('colors')?.set('colors', [am5.color('#f5f5f5'), am5.color('#f5f5f5')]);
          break;
        case colorLevel < 0:
          pieSeries.current.get('colors')?.set('colors', [am5.color('#f5f5f5'), am5.color('#999999')]);
          pieSeriesInner.current.get('colors')?.set('colors', [am5.color('#fff'), am5.color('#f5f5f5')]);
          break;
        case colorLevel <= 1:
          pieSeries.current.get('colors')?.set('colors', [am5.color('#52a94a'), am5.color('#ecf4e7')]);
          pieSeriesInner.current.get('colors')?.set('colors', [am5.color('#f6f6f6'), am5.color('#fff')]);
          break;
        case colorLevel > 1:
          if (actualValue % estimatedValue === 0) {
            pieSeries.current.get('colors')?.set('colors', [am5.color('#7a4040'), am5.color('#7a4040')]);
            pieSeriesInner.current.get('colors')?.set('colors', [am5.color('#e5e5e5'), am5.color('#e5e5e5')]);
            break;
          }
          pieSeries.current.get('colors')?.set('colors', [am5.color('#7a4040'), am5.color('#e2585b')]);
          pieSeriesInner.current.get('colors')?.set('colors', [am5.color('#e5e5e5'), am5.color('#fff')]);
          break;
        default:
          break;
      }

      // set colors from props
      if (
        outerColor?.actualColor &&
        outerColor?.estimatedColor &&
        isColor(outerColor?.actualColor) &&
        isColor(outerColor?.estimatedColor)
      ) {
        pieSeries.current
          .get('colors')
          ?.set('colors', [am5.color(outerColor.actualColor), am5.color(outerColor.estimatedColor)]);
      }
      if (
        innerColor?.actualColor &&
        innerColor?.estimatedColor &&
        isColor(innerColor?.actualColor) &&
        isColor(innerColor?.estimatedColor)
      ) {
        pieSeriesInner.current
          .get('colors')
          ?.set('colors', [am5.color(innerColor.actualColor), am5.color(innerColor.estimatedColor)]);
      }
    }
  }, [actualValue, estimatedValue, innerColor, outerColor]);

  useLayoutEffect(() => {
    root.current = am5.Root.new(`pieChart-${id}`);
    const pieChart = root.current.container.children.push(
      am5percent.PieChart.new(root.current, {
        layout: root.current.horizontalLayout,
        radius: am5.percent(outerRadius),
      }),
    );
    const pieChartInner = root.current.container.children.push(
      am5percent.PieChart.new(root.current, {
        layout: root.current.horizontalLayout,
        radius: am5.percent(innerRadius),
      }),
    );

    // field to get value in data
    pieSeries.current = pieChart.series.push(
      am5percent.PieSeries.new(root.current, {
        valueField: 'value',
      }),
    );
    pieSeriesInner.current = pieChartInner.series.push(
      am5percent.PieSeries.new(root.current, {
        valueField: 'value',
      }),
    );

    setSeriesColor();

    // disable hover and click effects on pie charts
    const slice = pieSeries.current.slices.template;
    slice.states.create('hover', {
      scale: 1,
      shadowColor: am5.color('#000'),
    });
    slice.states.create('active', {
      shiftRadius: 0,
    });
    const sliceInner = pieSeriesInner.current.slices.template;
    sliceInner.states.create('hover', {
      scale: 1,
      shadowColor: am5.color('#000'),
    });
    sliceInner.states.create('active', {
      shiftRadius: 0,
    });

    return () => {
      root.current?.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    pieSeries.current?.set('radius', outerRadius);
    pieSeriesInner.current?.set('radius', innerRadius);
  }, [innerRadius, outerRadius]);

  useLayoutEffect(() => {
    setSeriesColor();
  }, [actualValue, estimatedValue]);

  useLayoutEffect(() => {
    const dataValue = getDataValue(actualValue, estimatedValue);
    if (pieSeries.current && pieSeriesInner.current && dataValue.length > 0 && root.current) {
      pieSeries.current.data.clear();
      pieSeriesInner.current.data.clear();

      pieSeries.current.data.setAll(dataValue);
      pieSeries.current.ticks.template.set('visible', false);
      pieSeries.current.labels.template.set('visible', false);

      pieSeriesInner.current.data.setAll(dataValue);
      pieSeriesInner.current.ticks.template.set('visible', false);
      pieSeriesInner.current.labels.template.set('visible', false);

      const chartText: string = estimatedValue === 0 ? 'N/A' : `${((actualValue / estimatedValue) * 100).toFixed(0)}%`;

      // label(%) in pie chart
      root.current.tooltipContainer.children.clear();
      root.current.tooltipContainer.children.push(
        am5.Label.new(root.current, {
          x: am5.p50,
          y: am5.p50,
          centerX: am5.p50,
          centerY: am5.p50,
          fill: am5.color(innerTextStyles?.color ?? '#000'),
          fontSize: innerTextStyles?.fontSize ?? 14,
          fontStyle: innerTextStyles?.fontStyle ?? 'normal',
          fontVariant: innerTextStyles?.fontVariant ?? 'normal',
          fontWeight: innerTextStyles?.fontWeight ?? '700',
          text: chartText,
          ...innerTextStyles,
        }),
      );
    }
  }, [actualValue, estimatedValue, innerTextStyles]);

  useLayoutEffect(() => {
    if (root.current && pieSeries.current && pieSeriesInner.current) {
      // disable tooltip of piechart
      const tooltip = am5.Tooltip.new(root.current, {
        getFillFromSprite: false,
        labelText: tooltipText ?? '',
        autoTextColor: false,
        forceHidden: !showTooltip,
      });
      tooltip.get('background')?.setAll({
        fill: am5.color('#000'),
      });
      tooltip.label.setAll({
        fill: am5.color('#fff'),
      });
      pieSeries.current.set('tooltip', tooltip);
      pieSeriesInner.current.set('tooltip', tooltip);
    }
  }, [showTooltip, tooltipText]);

  return (
    <div
      data-testid="pulse-donut-chart"
      className={clsx(styles['donut-chart__root'], classes)}
      id={`pieChart-${id}`}
    ></div>
  );
};

export default PulseDonutChart;
