import clsx from 'clsx';
import PCSSpinner, { PCSSpinnerVariants } from 'components/spinner/pcs-spinner';
import { v2Endpoint } from 'pulse-api/base';
import { Sizes } from 'pulse-commons/types';
import React, { ReactElement, Ref, useEffect, useRef, useState } from 'react';
import PulseUnsupportedViewer from '../pulse-unsupported-viewer/pulse-unsupported-viewer';
import { PulseOfficeViewerProps } from './pulse-office-viewer-types';
import styles from './pulse-office-viewer.module.scss';

const isEnabledWopi = !!window.pulse?.config?.wopi?.enabled;
const URLS = window.pulse?.config?.urls || '';
const SAMPLE_PPT_ENDPOINT =
  'https://view.officeapps.live.com/op/embed.aspx?src=https://scholar.harvard.edu/files/torman_personal/files/samplepptx.pptx';

export const PulseOfficeViewer = (props: PulseOfficeViewerProps): ReactElement => {
  const { classes, PulseUnsupportedViewerProps, assetId, enableMock } = props;
  const [hasWopiEndpoint, setHasWopiEndpoint] = useState(true);
  const iframeRef: Ref<HTMLIFrameElement> = useRef(null);
  const showOfficePreview = hasWopiEndpoint && isEnabledWopi;

  useEffect(() => {
    if (!isEnabledWopi) {
      return;
    }
    const wopiApiUrl = `${URLS.app}wopi/${assetId}?wopi_action=embedview`;
    v2Endpoint
      .get(wopiApiUrl)
      .then(async ({ data }) => {
        const actionUrl = data?.data?.actionUrl;

        if (iframeRef.current && actionUrl) {
          iframeRef.current.src = actionUrl;
        }
        setHasWopiEndpoint(true);
      })
      .catch(() => {
        setHasWopiEndpoint(false);
      });
  }, [assetId]);

  useEffect(() => {
    if (enableMock) {
      const timeout = setTimeout(() => {
        if (iframeRef.current) {
          iframeRef.current.src = SAMPLE_PPT_ENDPOINT;
        }
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [enableMock]);

  const OfficeViewer = () => {
    return (
      <div className={clsx(styles['iframe-root'], styles.overlay)}>
        <PCSSpinner
          variant={PCSSpinnerVariants.circularSpinner}
          size={Sizes.lg}
          classes={{
            root: [styles.spinner],
          }}
        />
        <iframe
          className={clsx(styles.preview, styles.overlay)}
          ref={iframeRef as Ref<HTMLIFrameElement>}
          loading="lazy"
        />
      </div>
    );
  };

  if (enableMock) {
    return (
      <div data-testid="pulse-office-viewer" className={clsx(styles.pulseOfficeViewerRoot, classes?.root)}>
        <OfficeViewer />
      </div>
    );
  }
  return (
    <div data-testid="pulse-office-viewer" className={clsx(styles.pulseOfficeViewerRoot, classes?.root)}>
      {/*
       Disabling the following as per  
       [#[55142](https://beta.extranet-system.com/v2/passport/1260/tasklist/55142)] Design QA: Fix issues with Font Styling in Asset Detail Tabs + Broken Image Icon display
       [#[54780](https://beta.extranet-system.com/v2/passport/1416/tasklist/54780)] Hotfix: Rollback recent changes to displaying a Word or Powerpoint preview
       */}
      {/* <img data-testid="pulse-office-viewer__img" className={clsx(classes?.img)} src={src} alt={title} /> */}
      {showOfficePreview ? (
        <OfficeViewer />
      ) : (
        <PulseUnsupportedViewer
          classes={{ root: styles.overlay }}
          showOfficeOnline={isEnabledWopi}
          {...PulseUnsupportedViewerProps}
        />
      )}
    </div>
  );
};

export default PulseOfficeViewer;
