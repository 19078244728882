import React, { ReactElement } from 'react';
import PulseSelectBase from '../base/pulse-select-base';
import { PulseSelectGroupBasePropTypes } from './pulse-group-types';

const BATCH_URL = '/v2/api/batch';

const PulseSelectGroupBase = (props: PulseSelectGroupBasePropTypes): ReactElement => {
  const { requestBatching, url = BATCH_URL, variant = 'select2', extraParams, ...restProps } = props;

  return (
    <PulseSelectBase
      url={url}
      isGroup
      requestBatching={requestBatching}
      variant={variant}
      extraParams={extraParams}
      dataStructure={null}
      {...restProps}
    />
  );
};

export default React.memo(PulseSelectGroupBase);
