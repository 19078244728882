import React, { FC, ReactElement } from 'react';
import PulseSelectBase from '../base/pulse-select-base';
import {
  PulseSelectPropTypes,
  PulseSelectBasePropTypes,
  PulseSelectDataStructureTypes,
  PulseSelectOptionType,
} from '../base/pulse-select-base-types';
import { ValueType, OptionProps } from 'react-select';
import PulseBrandOption from './components/pulse-brand-option';
import isFunction from 'lodash/isFunction';

const DATA_STRUCTURE: PulseSelectDataStructureTypes = {
  dataKey: 'data',
  label: 'title',
  lastPage: 'meta.page.last-page',
  type: 'brands',
  value: 'id',
  isJsonApi: true,
  searchKey: 'title',
  pageSize: 25,
};

const DEFAULT_PLACEHOLDER: PulseSelectBasePropTypes['placeholder'] = 'Filter by brand';
const DEFAULT_CLIENT_ENDPOINT: PulseSelectBasePropTypes['url'] = '/v2/api/brands';

export const PulseBrandsSelect = React.memo(
  (props: PulseSelectPropTypes): ReactElement => {
    const {
      value = [],
      isMulti = false,
      labelName = 'Brands',
      placeholder = DEFAULT_PLACEHOLDER,
      requestFilterKey = 'brandid',
      url = DEFAULT_CLIENT_ENDPOINT,
      valueChangeHandler,
    } = props;

    const handleValueChange: PulseSelectPropTypes['valueChangeHandler'] = (
      value: ValueType<PulseSelectOptionType>,
    ): void => {
      isFunction(valueChangeHandler) &&
        valueChangeHandler({
          [requestFilterKey]: value,
        });
    };

    return (
      <PulseSelectBase
        changeHandler={handleValueChange}
        components={{
          Option: PulseBrandOption as FC<OptionProps<PulseSelectOptionType>>,
        }}
        dataStructure={DATA_STRUCTURE}
        isMulti={isMulti}
        labelName={labelName}
        placeholder={placeholder}
        url={url}
        value={value}
        {...props}
      />
    );
  },
);

PulseBrandsSelect.displayName = 'PulseBrandsSelect';

export default PulseBrandsSelect;
