import { AxiosResponse } from 'axios';
import { v2Endpoint } from 'pulse-api/base';
import { GroupOptionApiType } from './group-options-types';

const BATCH_URL = '/v2/api/batch';

function get<T = any, R = AxiosResponse<T>>({
  requests,
  jq,
  axiosOptions,
  url = BATCH_URL,
}: GroupOptionApiType): Promise<R> {
  return v2Endpoint.post(
    url,
    {
      requests,
      jq,
    },
    axiosOptions,
  );
}

export const groupOptionApi = { get };
