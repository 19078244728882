import React, { FC } from 'react';
import clsx from 'clsx';
import { Colors, IconSizes } from 'pulse-commons/types';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { DeleteTimelineModalProps } from './delete-timeline-modal-types';
import styles from './delete-timeline-modal.module.scss';

const DeleteTimelineModal: FC<DeleteTimelineModalProps> = props => {
  const { isOpen, timeline, onCancel, onConfirm } = props;

  return (
    <PulseModalBase
      variant="jqconfirm"
      open={isOpen}
      headerIconClasses="fal fa-trash-alt"
      PulseModalHeaderProps={{
        onCloseClick: onCancel,
        primaryText: 'DELETE TIMELINE',
        classes: {
          root: 'header-root',
          closeBtn: 'header-root__close-btn',
        },
      }}
      footer={false}
      onModalClose={onCancel}
    >
      <div className={styles['delete-timeline-modal']}>
        <div className={styles['delete-timeline-modal__icon']}>
          <i className="fal fa-exclamation-circle"></i>
        </div>
        <div className={styles['delete-timeline-modal__heading']}>DELETE TIMELINE</div>

        <div className={styles['delete-timeline-modal__title']}>
          <div>
            Are you sure you want to delete <strong>{timeline.timelineName}</strong>?
          </div>
          <div className={styles['delete-timeline-modal__title--warning']}>This action is not reversible.</div>
        </div>
        <div className={styles['delete-timeline-modal__button-ctn']}>
          <PulseButtonBase classes={[styles['delete-timeline-modal__button']]} label="Cancel" onClick={onCancel} />
          <PulseButtonBase
            classes={[clsx(styles['delete-timeline-modal__button'], styles['delete-timeline-modal__button--confirm'])]}
            iconSize={IconSizes.sm}
            color={Colors.danger}
            onClick={onConfirm}
            label="delete timeline"
          />
        </div>
      </div>
    </PulseModalBase>
  );
};

export default DeleteTimelineModal;
