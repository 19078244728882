import React, { MouseEvent, ReactElement } from 'react';
import Checkbox from '../checkbox/checkbox';
import { PulseListSelectorItemProps } from './pulse-list-selector-item-types';
import styles from './pulse-list-selector-item.module.scss';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import clsx from 'clsx';
import { composeClassNameFromArray } from 'pulse-commons/helpers';

const PulseListItem: React.FunctionComponent<PulseListSelectorItemProps> = (
  props: PulseListSelectorItemProps,
): ReactElement => {
  const { data: itemData, index, style } = props;

  const { actionComponents, classes, data = [], onClick, onSelect, selectOnClick = true } = itemData || {};

  const { primaryText = '', secondaryText = '', selected = false, selectable = true } = data[index] || {};

  const handleOnClick = (e: MouseEvent): void => {
    selectable && isFunction(onClick) && onClick && onClick(e, data[index]);
  };

  const handleOnSelect = (e): void => {
    selectable && isFunction(onSelect) && onSelect(e, data[index]);
  };

  /** parse styles */
  const rootClasses = composeClassNameFromArray([
    styles['pulse-list-item__root'],
    get(classes, 'root', ''),
    selectable && selected ? styles['selected'] : '',
  ]);

  const primaryTextClasses = composeClassNameFromArray([
    styles['pulse-list-item__primary-text'],
    get(classes, 'primaryText', ''),
  ]);

  const secondaryTextClasses = composeClassNameFromArray([
    styles['pulse-list-item__secondary-text'],
    get(classes, 'secondaryText', ''),
  ]);

  return (
    <div
      data-testid="pulse-list-item__ctn"
      className={clsx(styles['pulse-list-item__ctn'], !selectable && styles['pulse-list-item__ctn--disabled'])}
      onClick={selectOnClick ? handleOnClick : undefined}
      style={style}
    >
      <div className={rootClasses}>
        <div className={styles['pulse-list-item__typography']}>
          <div className={primaryTextClasses}>{primaryText}</div>
          <div className={secondaryTextClasses}>{secondaryText}</div>
        </div>
        {!actionComponents && selectable && (
          <Checkbox onClick={selectOnClick ? undefined : handleOnSelect} checked={selected} />
        )}
        {actionComponents && actionComponents}
      </div>
    </div>
  );
};

function areEqual(
  prevProps: React.PropsWithoutRef<PulseListSelectorItemProps>,
  nextProps: React.PropsWithoutRef<PulseListSelectorItemProps>,
): boolean {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return prevProps.data.data[prevProps.index] === nextProps.data.data[prevProps.index];
}

export default React.memo(PulseListItem, areEqual);
